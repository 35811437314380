@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&display=swap");
@charset "UTF-8";
/*!
 * HUD v4.0.0 (https://seantheme.com/hud/)
 * Copyright 2025 SeanTheme
 */
/*
   01. Color system 
   02. Options toggle setting (rtl, shadow, caret, negative margin, color mode)
   03. Variable prefix setting
   04. Body setting
   05. Spacer setting
   06. Font & headings settings
   07. Grid settings
   08. Container padding
   09. Component border settings
   10. Component border-radius settings
   11. Component box-shadow settings 
   12. Component color settings 
   13. Component caret settings 
   14. Component transition settings 
   15. Component ratio settings 
   16. Container padding settings
   17. App - Header settings
   18. App - Sidebar settings
   19. App - Content settings
   20. App - Pos system settings
   21. Link settings
   22. Alerts settings
   23. Breadcrumbs settings
   24. Progress bars settings
   25. Buttons + Forms settings
   26. Buttons settings
   27. Button close settings
   28. Forms settings
   29. Form placeholders settings
   30. Form check settings
   31. Form switch settings
   32. Form input group settings
   33. Form select settings
   34. Form range settings
   35. Form file settings
   36. Form Floating settings
   37. Form validation settings
   38. Cards settings
   39. Tooltips settings
   40. Popovers
   41. Modals settings
   42. Offcanvas
   43. Toasts settings
   44. Accordion settings
   45. Tables settings
   46. Dropdown settings
   47. List group settings
   48. Nav settings
   49. Navbar settings
   50. Pagination settings
   51. Z-index settings
   52. Badges settings
   53. Position settings
   54. Image thumbnails settings
   55. Figures settings
   56. Carousel settings
   57. Spinners settings
   58. Code settings
*/
/*
   Dark color mode variables

   01. Color system 
   02. Body setting
   03. Font & headings settings
   04. Border settings
   05. Component settings
   06. App - Header settings
   07. App - Sidebar settings
   08. App - Theme panel settings
   09. Form switch settings
   10. Form select settings
   11. Form check settings
   12. Accordion settings
   13. Modal settings
*/
/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0271ff;
  --bs-indigo: #5c25ff;
  --bs-purple: #b90dff;
  --bs-pink: #e61079;
  --bs-red: #e00000;
  --bs-orange: #ff9f0c;
  --bs-yellow: #f9e80d;
  --bs-green: #148922;
  --bs-teal: #249d79;
  --bs-cyan: #009be3;
  --bs-black: #000000;
  --bs-white: #FFFFFF;
  --bs-gray: #526872;
  --bs-gray-dark: #35434a;
  --bs-gray-100: #c0cacf;
  --bs-gray-200: #a8b6bc;
  --bs-gray-300: #90a2aa;
  --bs-gray-400: #798e98;
  --bs-gray-500: #617a86;
  --bs-gray-600: #526872;
  --bs-gray-700: #44555e;
  --bs-gray-800: #35434a;
  --bs-gray-900: #273136;
  --bs-default: #798e98;
  --bs-theme: #249d79;
  --bs-theme-color: #FFFFFF;
  --bs-primary: #0271ff;
  --bs-secondary: #798e98;
  --bs-warning: #ff9f0c;
  --bs-danger: #e00000;
  --bs-success: #249d79;
  --bs-inverse: #000000;
  --bs-info: #009be3;
  --bs-dark: #35434a;
  --bs-black: #000000;
  --bs-white: #FFFFFF;
  --bs-purple: #b90dff;
  --bs-indigo: #5c25ff;
  --bs-red: #e00000;
  --bs-pink: #e61079;
  --bs-green: #148922;
  --bs-yellow: #f9e80d;
  --bs-teal: #249d79;
  --bs-muted: #617a86;
  --bs-light: #c0cacf;
  --bs-lime: #50b00a;
  --bs-gray-100: #c0cacf;
  --bs-gray-200: #a8b6bc;
  --bs-gray-300: #90a2aa;
  --bs-gray-400: #798e98;
  --bs-gray-500: #617a86;
  --bs-gray-600: #526872;
  --bs-gray-700: #44555e;
  --bs-gray-800: #35434a;
  --bs-gray-900: #273136;
  --bs-default-rgb: 121, 142, 152;
  --bs-theme-rgb: 36, 157, 121;
  --bs-theme-color-rgb: 255, 255, 255;
  --bs-primary-rgb: 2, 113, 255;
  --bs-secondary-rgb: 121, 142, 152;
  --bs-warning-rgb: 255, 159, 12;
  --bs-danger-rgb: 224, 0, 0;
  --bs-success-rgb: 36, 157, 121;
  --bs-inverse-rgb: 0, 0, 0;
  --bs-info-rgb: 0, 155, 227;
  --bs-dark-rgb: 53, 67, 74;
  --bs-black-rgb: 0, 0, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-purple-rgb: 185, 13, 255;
  --bs-indigo-rgb: 92, 37, 255;
  --bs-red-rgb: 224, 0, 0;
  --bs-pink-rgb: 230, 16, 121;
  --bs-green-rgb: 20, 137, 34;
  --bs-yellow-rgb: 249, 232, 13;
  --bs-teal-rgb: 36, 157, 121;
  --bs-muted-rgb: 97, 122, 134;
  --bs-light-rgb: 192, 202, 207;
  --bs-lime-rgb: 80, 176, 10;
  --bs-gray-100-rgb: 192, 202, 207;
  --bs-gray-200-rgb: 168, 182, 188;
  --bs-gray-300-rgb: 144, 162, 170;
  --bs-gray-400-rgb: 121, 142, 152;
  --bs-gray-500-rgb: 97, 122, 134;
  --bs-gray-600-rgb: 82, 104, 114;
  --bs-gray-700-rgb: 68, 85, 94;
  --bs-gray-800-rgb: 53, 67, 74;
  --bs-gray-900-rgb: 39, 49, 54;
  --bs-primary-text-emphasis: #012d66;
  --bs-secondary-text-emphasis: #30393d;
  --bs-success-text-emphasis: #0e3f30;
  --bs-info-text-emphasis: #003e5b;
  --bs-warning-text-emphasis: #664005;
  --bs-danger-text-emphasis: #5a0000;
  --bs-light-text-emphasis: #44555e;
  --bs-dark-text-emphasis: #44555e;
  --bs-primary-bg-subtle: #cce3ff;
  --bs-secondary-bg-subtle: #c0cacf;
  --bs-success-bg-subtle: #d0e7d3;
  --bs-info-bg-subtle: #ccebf9;
  --bs-warning-bg-subtle: #fefacf;
  --bs-danger-bg-subtle: #f9cccc;
  --bs-light-bg-subtle: #e0e5e7;
  --bs-dark-bg-subtle: #798e98;
  --bs-primary-border-subtle: #9ac6ff;
  --bs-secondary-border-subtle: #a8b6bc;
  --bs-success-border-subtle: #a1d0a7;
  --bs-info-border-subtle: #99d7f4;
  --bs-warning-border-subtle: #fdf69e;
  --bs-danger-border-subtle: #f39999;
  --bs-light-border-subtle: #a8b6bc;
  --bs-dark-border-subtle: #617a86;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Chakra Petch", sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: rgba(0, 0, 0, 0.75);
  --bs-body-color-rgb: 0, 0, 0;
  --bs-body-bg: #FFFFFF;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(0, 0, 0, 0.75);
  --bs-secondary-color-rgb: 0, 0, 0;
  --bs-secondary-bg: #c0cacf;
  --bs-secondary-bg-rgb: 192, 202, 207;
  --bs-tertiary-color: rgba(0, 0, 0, 0.5);
  --bs-tertiary-color-rgb: 0, 0, 0;
  --bs-tertiary-bg: rgba(0, 0, 0, 0.1);
  --bs-tertiary-bg-rgb: 0, 0, 0;
  --bs-heading-color: #000000;
  --bs-link-color: #249d79;
  --bs-link-color-rgb: 36, 157, 121;
  --bs-link-decoration: underline;
  --bs-link-hover-color: rgba(36, 157, 121, 0.5);
  --bs-link-hover-color-rgb: 36, 157, 121;
  --bs-code-color: #e61079;
  --bs-highlight-color: rgba(0, 0, 0, 0.75);
  --bs-highlight-bg: #fefacf;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: rgba(0, 0, 0, 0.2);
  --bs-border-color-translucent: rgba(0, 0, 0, 0.1);
  --bs-border-radius: 4px;
  --bs-border-radius-sm: 2px;
  --bs-border-radius-lg: 8px;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--bs-inverse-rgb), 0.035);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(var(--bs-inverse-rgb), 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--bs-inverse-rgb), 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(2, 113, 255, 0.25);
  --bs-form-valid-color: #249d79;
  --bs-form-valid-border-color: #249d79;
  --bs-form-invalid-color: #e00000;
  --bs-form-invalid-border-color: #e00000;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: rgba(255, 255, 255, 0.75);
  --bs-body-color-rgb: 255, 255, 255;
  --bs-body-bg: #1d2835;
  --bs-body-bg-rgb: 29, 40, 53;
  --bs-emphasis-color: #c0cacf;
  --bs-emphasis-color-rgb: 192, 202, 207;
  --bs-secondary-color: rgba(255, 255, 255, 0.75);
  --bs-secondary-color-rgb: 255, 255, 255;
  --bs-secondary-bg: #35434a;
  --bs-secondary-bg-rgb: 53, 67, 74;
  --bs-tertiary-color: rgba(255, 255, 255, 0.5);
  --bs-tertiary-color-rgb: 255, 255, 255;
  --bs-tertiary-bg: rgba(0, 0, 0, 0.1);
  --bs-tertiary-bg-rgb: 0, 0, 0;
  --bs-primary-text-emphasis: #67aaff;
  --bs-secondary-text-emphasis: #90a2aa;
  --bs-success-text-emphasis: #72b87a;
  --bs-info-text-emphasis: #66c3ee;
  --bs-warning-text-emphasis: #fbf16e;
  --bs-danger-text-emphasis: #ec6666;
  --bs-light-text-emphasis: #c0cacf;
  --bs-dark-text-emphasis: #90a2aa;
  --bs-primary-bg-subtle: #001733;
  --bs-secondary-bg-subtle: #273136;
  --bs-success-bg-subtle: #041b07;
  --bs-info-bg-subtle: #001f2d;
  --bs-warning-bg-subtle: #322e03;
  --bs-danger-bg-subtle: #2d0000;
  --bs-light-bg-subtle: #35434a;
  --bs-dark-bg-subtle: #1b2225;
  --bs-primary-border-subtle: #014499;
  --bs-secondary-border-subtle: #44555e;
  --bs-success-border-subtle: #0c5214;
  --bs-info-border-subtle: #003e5b;
  --bs-warning-border-subtle: #645d05;
  --bs-danger-border-subtle: #860000;
  --bs-light-border-subtle: #44555e;
  --bs-dark-border-subtle: #35434a;
  --bs-heading-color: #fff;
  --bs-link-color: #67aaff;
  --bs-link-hover-color: #9ac6ff;
  --bs-link-color-rgb: 103, 170, 255;
  --bs-link-hover-color-rgb: 154, 198, 255;
  --bs-code-color: #f070af;
  --bs-highlight-color: rgba(255, 255, 255, 0.75);
  --bs-highlight-bg: #645d05;
  --bs-border-color: rgba(255, 255, 255, 0.25);
  --bs-border-color-translucent: rgba(255, 255, 255, 0.25);
  --bs-form-valid-color: #72b87a;
  --bs-form-valid-border-color: #72b87a;
  --bs-form-invalid-color: #ec6666;
  --bs-form-invalid-border-color: #ec6666;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.34375rem + 1.125vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.1875rem;
  }
}

h2, .h2 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}

h3, .h3 {
  font-size: calc(1.278125rem + 0.3375vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.53125rem;
  }
}

h4, .h4 {
  font-size: calc(1.25625rem + 0.075vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.3125rem;
  }
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 2px;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #798e98;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #526872;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1660px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1660px;
  --bs-breakpoint-xxxl: 1900px;
}

.row {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1660px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1900px) {
  .col-xxxl {
    flex: 1 0 0%;
  }
  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxxl-0 {
    margin-left: 0;
  }
  .offset-xxxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxxl-0,
  .gx-xxxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxxl-0,
  .gy-xxxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxxl-1,
  .gx-xxxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxxl-1,
  .gy-xxxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxxl-2,
  .gx-xxxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxxl-2,
  .gy-xxxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxxl-3,
  .gx-xxxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxxl-3,
  .gy-xxxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxxl-4,
  .gx-xxxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxxl-4,
  .gy-xxxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxxl-5,
  .gx-xxxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxxl-5,
  .gy-xxxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(var(--bs-inverse-rgb), 0.1);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(var(--bs-inverse-rgb), 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(var(--bs-inverse-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000000;
  --bs-table-bg: #cce3ff;
  --bs-table-border-color: #b8cce6;
  --bs-table-striped-bg: #b8cce6;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #b8cce6;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #bdd2ec;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000000;
  --bs-table-bg: #e4e8ea;
  --bs-table-border-color: #cdd1d3;
  --bs-table-striped-bg: #cdd1d3;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #cdd1d3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #d3d7d8;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000000;
  --bs-table-bg: #d3ebe4;
  --bs-table-border-color: #bed4cd;
  --bs-table-striped-bg: #bed4cd;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bed4cd;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c3d9d3;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000000;
  --bs-table-bg: #ccebf9;
  --bs-table-border-color: #b8d4e0;
  --bs-table-striped-bg: #b8d4e0;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #b8d4e0;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #bdd9e6;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000000;
  --bs-table-bg: #ffecce;
  --bs-table-border-color: #e6d4b9;
  --bs-table-striped-bg: #e6d4b9;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6d4b9;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ecdabf;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000000;
  --bs-table-bg: #f9cccc;
  --bs-table-border-color: #e0b8b8;
  --bs-table-striped-bg: #e0b8b8;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e0b8b8;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e6bdbd;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000000;
  --bs-table-bg: #c0cacf;
  --bs-table-border-color: #adb6ba;
  --bs-table-striped-bg: #adb6ba;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #adb6ba;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #b2bbbf;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #FFFFFF;
  --bs-table-bg: #35434a;
  --bs-table-border-color: #49565c;
  --bs-table-striped-bg: #49565c;
  --bs-table-striped-color: #FFFFFF;
  --bs-table-active-bg: #49565c;
  --bs-table-active-color: #FFFFFF;
  --bs-table-hover-bg: #445158;
  --bs-table-hover-color: #FFFFFF;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1659.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1899.98px) {
  .table-responsive-xxxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.8125rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #798e98;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: transparent;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: transparent;
  border-color: rgba(var(--bs-inverse-rgb), 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-inverse-rgb), 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: rgba(var(--bs-body-color-rgb), 0.5);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-form-control-disabled-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-border-color);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  border-radius: 2px;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 8px;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 4px;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 4px;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2335434a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: transparent;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: rgba(var(--bs-inverse-rgb), 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-inverse-rgb), 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-form-control-disabled-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.8125rem;
  border-radius: 2px;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: 8px;
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.3em;
  padding-left: 1.8em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.8em;
}

.form-check-reverse {
  padding-right: 1.8em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.8em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: transparent;
  flex-shrink: 0;
  width: 1.3em;
  height: 1.3em;
  margin-top: 0.1em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: rgba(var(--bs-inverse-rgb), 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-inverse-rgb), 0.25);
}
.form-check-input:checked {
  background-color: #249d79;
  border-color: #249d79;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #249d79;
  border-color: #249d79;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.8em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2.3em;
  margin-left: -2.8em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2.3em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='gray'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.8em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.8em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px transparent, 0 0 0 0.25rem rgba(var(--bs-inverse-rgb), 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px transparent, 0 0 0 0.25rem rgba(var(--bs-inverse-rgb), 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: var(--bs-theme);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: rgba(var(--bs-theme-rgb), 0.75);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: rgba(var(--bs-inverse-rgb), 0.1);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: var(--bs-theme);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: rgba(var(--bs-theme-rgb), 0.75);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: rgba(var(--bs-inverse-rgb), 0.1);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.875em;
  content: "";
  background-color: transparent;
  border-radius: 4px;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #526872;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-form-control-disabled-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 4px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 8px;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  border-radius: 2px;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-success-text);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-success);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23249d79' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-success);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23249d79' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-success);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-success-text);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-success-text);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-danger-text);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-danger);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e00000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e00000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-danger);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e00000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e00000' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-danger);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-danger-text);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-danger-text);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 4px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-default {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #798e98;
  --bs-btn-border-color: #798e98;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #677981;
  --bs-btn-hover-border-color: #61727a;
  --bs-btn-focus-shadow-rgb: 141, 159, 167;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #61727a;
  --bs-btn-active-border-color: #5b6b72;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #798e98;
  --bs-btn-disabled-border-color: #798e98;
}

.btn-theme {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #249d79;
  --bs-btn-border-color: #249d79;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #1f8567;
  --bs-btn-hover-border-color: #1d7e61;
  --bs-btn-focus-shadow-rgb: 69, 172, 141;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #1d7e61;
  --bs-btn-active-border-color: #1b765b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #249d79;
  --bs-btn-disabled-border-color: #249d79;
}

.btn-theme-color {
  --bs-btn-color: #000000;
  --bs-btn-bg: #FFFFFF;
  --bs-btn-border-color: #FFFFFF;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #FFFFFF;
  --bs-btn-disabled-border-color: #FFFFFF;
}

.btn-primary {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #0271ff;
  --bs-btn-border-color: #0271ff;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0260d9;
  --bs-btn-hover-border-color: #025acc;
  --bs-btn-focus-shadow-rgb: 40, 134, 255;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #025acc;
  --bs-btn-active-border-color: #0255bf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #0271ff;
  --bs-btn-disabled-border-color: #0271ff;
}

.btn-secondary {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #798e98;
  --bs-btn-border-color: #798e98;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #677981;
  --bs-btn-hover-border-color: #61727a;
  --bs-btn-focus-shadow-rgb: 141, 159, 167;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #61727a;
  --bs-btn-active-border-color: #5b6b72;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #798e98;
  --bs-btn-disabled-border-color: #798e98;
}

.btn-warning {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ff9f0c;
  --bs-btn-border-color: #ff9f0c;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffad30;
  --bs-btn-hover-border-color: #ffa924;
  --bs-btn-focus-shadow-rgb: 217, 135, 10;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffb23d;
  --bs-btn-active-border-color: #ffa924;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ff9f0c;
  --bs-btn-disabled-border-color: #ff9f0c;
}

.btn-danger {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #e00000;
  --bs-btn-border-color: #e00000;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #be0000;
  --bs-btn-hover-border-color: #b30000;
  --bs-btn-focus-shadow-rgb: 229, 38, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #b30000;
  --bs-btn-active-border-color: #a80000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #e00000;
  --bs-btn-disabled-border-color: #e00000;
}

.btn-success {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #249d79;
  --bs-btn-border-color: #249d79;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #1f8567;
  --bs-btn-hover-border-color: #1d7e61;
  --bs-btn-focus-shadow-rgb: 69, 172, 141;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #1d7e61;
  --bs-btn-active-border-color: #1b765b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #249d79;
  --bs-btn-disabled-border-color: #249d79;
}

.btn-inverse {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #000000;
  --bs-btn-disabled-border-color: #000000;
}

.btn-info {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #009be3;
  --bs-btn-border-color: #009be3;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0084c1;
  --bs-btn-hover-border-color: #007cb6;
  --bs-btn-focus-shadow-rgb: 38, 170, 231;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #007cb6;
  --bs-btn-active-border-color: #0074aa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #009be3;
  --bs-btn-disabled-border-color: #009be3;
}

.btn-dark {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #35434a;
  --bs-btn-border-color: #35434a;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #535f65;
  --bs-btn-hover-border-color: #49565c;
  --bs-btn-focus-shadow-rgb: 83, 95, 101;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #5d696e;
  --bs-btn-active-border-color: #49565c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #35434a;
  --bs-btn-disabled-border-color: #35434a;
}

.btn-black {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #000000;
  --bs-btn-disabled-border-color: #000000;
}

.btn-white {
  --bs-btn-color: #000000;
  --bs-btn-bg: #FFFFFF;
  --bs-btn-border-color: #FFFFFF;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #FFFFFF;
  --bs-btn-disabled-border-color: #FFFFFF;
}

.btn-purple {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #b90dff;
  --bs-btn-border-color: #b90dff;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #9d0bd9;
  --bs-btn-hover-border-color: #940acc;
  --bs-btn-focus-shadow-rgb: 196, 49, 255;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #940acc;
  --bs-btn-active-border-color: #8b0abf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #b90dff;
  --bs-btn-disabled-border-color: #b90dff;
}

.btn-indigo {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #5c25ff;
  --bs-btn-border-color: #5c25ff;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #4e1fd9;
  --bs-btn-hover-border-color: #4a1ecc;
  --bs-btn-focus-shadow-rgb: 116, 70, 255;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #4a1ecc;
  --bs-btn-active-border-color: #451cbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #5c25ff;
  --bs-btn-disabled-border-color: #5c25ff;
}

.btn-red {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #e00000;
  --bs-btn-border-color: #e00000;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #be0000;
  --bs-btn-hover-border-color: #b30000;
  --bs-btn-focus-shadow-rgb: 229, 38, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #b30000;
  --bs-btn-active-border-color: #a80000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #e00000;
  --bs-btn-disabled-border-color: #e00000;
}

.btn-pink {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #e61079;
  --bs-btn-border-color: #e61079;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #c40e67;
  --bs-btn-hover-border-color: #b80d61;
  --bs-btn-focus-shadow-rgb: 234, 52, 141;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #b80d61;
  --bs-btn-active-border-color: #ad0c5b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #e61079;
  --bs-btn-disabled-border-color: #e61079;
}

.btn-green {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #148922;
  --bs-btn-border-color: #148922;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #11741d;
  --bs-btn-hover-border-color: #106e1b;
  --bs-btn-focus-shadow-rgb: 55, 155, 67;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #106e1b;
  --bs-btn-active-border-color: #0f671a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #148922;
  --bs-btn-disabled-border-color: #148922;
}

.btn-yellow {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f9e80d;
  --bs-btn-border-color: #f9e80d;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #faeb31;
  --bs-btn-hover-border-color: #faea25;
  --bs-btn-focus-shadow-rgb: 212, 197, 11;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #faed3d;
  --bs-btn-active-border-color: #faea25;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f9e80d;
  --bs-btn-disabled-border-color: #f9e80d;
}

.btn-teal {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #249d79;
  --bs-btn-border-color: #249d79;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #1f8567;
  --bs-btn-hover-border-color: #1d7e61;
  --bs-btn-focus-shadow-rgb: 69, 172, 141;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #1d7e61;
  --bs-btn-active-border-color: #1b765b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #249d79;
  --bs-btn-disabled-border-color: #249d79;
}

.btn-muted {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #617a86;
  --bs-btn-border-color: #617a86;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #526872;
  --bs-btn-hover-border-color: #4e626b;
  --bs-btn-focus-shadow-rgb: 121, 142, 152;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #4e626b;
  --bs-btn-active-border-color: #495c65;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #617a86;
  --bs-btn-disabled-border-color: #617a86;
}

.btn-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #c0cacf;
  --bs-btn-border-color: #c0cacf;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #a3acb0;
  --bs-btn-hover-border-color: #9aa2a6;
  --bs-btn-focus-shadow-rgb: 163, 172, 176;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #9aa2a6;
  --bs-btn-active-border-color: #90989b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #c0cacf;
  --bs-btn-disabled-border-color: #c0cacf;
}

.btn-lime {
  --bs-btn-color: #000000;
  --bs-btn-bg: #50b00a;
  --bs-btn-border-color: #50b00a;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #6abc2f;
  --bs-btn-hover-border-color: #62b823;
  --bs-btn-focus-shadow-rgb: 68, 150, 9;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #73c03b;
  --bs-btn-active-border-color: #62b823;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #50b00a;
  --bs-btn-disabled-border-color: #50b00a;
}

.btn-gray-100 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #c0cacf;
  --bs-btn-border-color: #c0cacf;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #c9d2d6;
  --bs-btn-hover-border-color: #c6cfd4;
  --bs-btn-focus-shadow-rgb: 163, 172, 176;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #cdd5d9;
  --bs-btn-active-border-color: #c6cfd4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #c0cacf;
  --bs-btn-disabled-border-color: #c0cacf;
}

.btn-gray-200 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #a8b6bc;
  --bs-btn-border-color: #a8b6bc;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #b5c1c6;
  --bs-btn-hover-border-color: #b1bdc3;
  --bs-btn-focus-shadow-rgb: 143, 155, 160;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #b9c5c9;
  --bs-btn-active-border-color: #b1bdc3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #a8b6bc;
  --bs-btn-disabled-border-color: #a8b6bc;
}

.btn-gray-300 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #90a2aa;
  --bs-btn-border-color: #90a2aa;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #a1b0b7;
  --bs-btn-hover-border-color: #9babb3;
  --bs-btn-focus-shadow-rgb: 122, 138, 145;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #a6b5bb;
  --bs-btn-active-border-color: #9babb3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #90a2aa;
  --bs-btn-disabled-border-color: #90a2aa;
}

.btn-gray-400 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #798e98;
  --bs-btn-border-color: #798e98;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #677981;
  --bs-btn-hover-border-color: #61727a;
  --bs-btn-focus-shadow-rgb: 141, 159, 167;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #61727a;
  --bs-btn-active-border-color: #5b6b72;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #798e98;
  --bs-btn-disabled-border-color: #798e98;
}

.btn-gray-500 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #617a86;
  --bs-btn-border-color: #617a86;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #526872;
  --bs-btn-hover-border-color: #4e626b;
  --bs-btn-focus-shadow-rgb: 121, 142, 152;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #4e626b;
  --bs-btn-active-border-color: #495c65;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #617a86;
  --bs-btn-disabled-border-color: #617a86;
}

.btn-gray-600 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #526872;
  --bs-btn-border-color: #526872;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #465861;
  --bs-btn-hover-border-color: #42535b;
  --bs-btn-focus-shadow-rgb: 108, 127, 135;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #42535b;
  --bs-btn-active-border-color: #3e4e56;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #526872;
  --bs-btn-disabled-border-color: #526872;
}

.btn-gray-700 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #44555e;
  --bs-btn-border-color: #44555e;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #3a4850;
  --bs-btn-hover-border-color: #36444b;
  --bs-btn-focus-shadow-rgb: 96, 111, 118;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #36444b;
  --bs-btn-active-border-color: #334047;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #44555e;
  --bs-btn-disabled-border-color: #44555e;
}

.btn-gray-800 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #35434a;
  --bs-btn-border-color: #35434a;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #2d393f;
  --bs-btn-hover-border-color: #2a363b;
  --bs-btn-focus-shadow-rgb: 83, 95, 101;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #2a363b;
  --bs-btn-active-border-color: #283238;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #35434a;
  --bs-btn-disabled-border-color: #35434a;
}

.btn-gray-900 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #273136;
  --bs-btn-border-color: #273136;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #212a2e;
  --bs-btn-hover-border-color: #1f272b;
  --bs-btn-focus-shadow-rgb: 71, 80, 84;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #1f272b;
  --bs-btn-active-border-color: #1d2529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #273136;
  --bs-btn-disabled-border-color: #273136;
}

.btn-outline-default {
  --bs-btn-color: #798e98;
  --bs-btn-border-color: #798e98;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #798e98;
  --bs-btn-hover-border-color: #798e98;
  --bs-btn-focus-shadow-rgb: 121, 142, 152;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #798e98;
  --bs-btn-active-border-color: #798e98;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #798e98;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #798e98;
  --bs-gradient: none;
}

.btn-outline-theme {
  --bs-btn-color: #249d79;
  --bs-btn-border-color: #249d79;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #249d79;
  --bs-btn-hover-border-color: #249d79;
  --bs-btn-focus-shadow-rgb: 36, 157, 121;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #249d79;
  --bs-btn-active-border-color: #249d79;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #249d79;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #249d79;
  --bs-gradient: none;
}

.btn-outline-theme-color {
  --bs-btn-color: #FFFFFF;
  --bs-btn-border-color: #FFFFFF;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #FFFFFF;
  --bs-btn-hover-border-color: #FFFFFF;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #FFFFFF;
  --bs-btn-active-border-color: #FFFFFF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFFFFF;
  --bs-gradient: none;
}

.btn-outline-primary {
  --bs-btn-color: #0271ff;
  --bs-btn-border-color: #0271ff;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0271ff;
  --bs-btn-hover-border-color: #0271ff;
  --bs-btn-focus-shadow-rgb: 2, 113, 255;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0271ff;
  --bs-btn-active-border-color: #0271ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0271ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0271ff;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #798e98;
  --bs-btn-border-color: #798e98;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #798e98;
  --bs-btn-hover-border-color: #798e98;
  --bs-btn-focus-shadow-rgb: 121, 142, 152;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #798e98;
  --bs-btn-active-border-color: #798e98;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #798e98;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #798e98;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ff9f0c;
  --bs-btn-border-color: #ff9f0c;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ff9f0c;
  --bs-btn-hover-border-color: #ff9f0c;
  --bs-btn-focus-shadow-rgb: 255, 159, 12;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ff9f0c;
  --bs-btn-active-border-color: #ff9f0c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff9f0c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff9f0c;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #e00000;
  --bs-btn-border-color: #e00000;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #e00000;
  --bs-btn-hover-border-color: #e00000;
  --bs-btn-focus-shadow-rgb: 224, 0, 0;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #e00000;
  --bs-btn-active-border-color: #e00000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e00000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e00000;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #249d79;
  --bs-btn-border-color: #249d79;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #249d79;
  --bs-btn-hover-border-color: #249d79;
  --bs-btn-focus-shadow-rgb: 36, 157, 121;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #249d79;
  --bs-btn-active-border-color: #249d79;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #249d79;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #249d79;
  --bs-gradient: none;
}

.btn-outline-inverse {
  --bs-btn-color: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border-color: #000000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #000000;
  --bs-btn-active-border-color: #000000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000000;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #009be3;
  --bs-btn-border-color: #009be3;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #009be3;
  --bs-btn-hover-border-color: #009be3;
  --bs-btn-focus-shadow-rgb: 0, 155, 227;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #009be3;
  --bs-btn-active-border-color: #009be3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #009be3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #009be3;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #35434a;
  --bs-btn-border-color: #35434a;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #35434a;
  --bs-btn-hover-border-color: #35434a;
  --bs-btn-focus-shadow-rgb: 53, 67, 74;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #35434a;
  --bs-btn-active-border-color: #35434a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #35434a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #35434a;
  --bs-gradient: none;
}

.btn-outline-black {
  --bs-btn-color: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border-color: #000000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #000000;
  --bs-btn-active-border-color: #000000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000000;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #FFFFFF;
  --bs-btn-border-color: #FFFFFF;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #FFFFFF;
  --bs-btn-hover-border-color: #FFFFFF;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #FFFFFF;
  --bs-btn-active-border-color: #FFFFFF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFFFFF;
  --bs-gradient: none;
}

.btn-outline-purple {
  --bs-btn-color: #b90dff;
  --bs-btn-border-color: #b90dff;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #b90dff;
  --bs-btn-hover-border-color: #b90dff;
  --bs-btn-focus-shadow-rgb: 185, 13, 255;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #b90dff;
  --bs-btn-active-border-color: #b90dff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #b90dff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #b90dff;
  --bs-gradient: none;
}

.btn-outline-indigo {
  --bs-btn-color: #5c25ff;
  --bs-btn-border-color: #5c25ff;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #5c25ff;
  --bs-btn-hover-border-color: #5c25ff;
  --bs-btn-focus-shadow-rgb: 92, 37, 255;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #5c25ff;
  --bs-btn-active-border-color: #5c25ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5c25ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5c25ff;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #e00000;
  --bs-btn-border-color: #e00000;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #e00000;
  --bs-btn-hover-border-color: #e00000;
  --bs-btn-focus-shadow-rgb: 224, 0, 0;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #e00000;
  --bs-btn-active-border-color: #e00000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e00000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e00000;
  --bs-gradient: none;
}

.btn-outline-pink {
  --bs-btn-color: #e61079;
  --bs-btn-border-color: #e61079;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #e61079;
  --bs-btn-hover-border-color: #e61079;
  --bs-btn-focus-shadow-rgb: 230, 16, 121;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #e61079;
  --bs-btn-active-border-color: #e61079;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e61079;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e61079;
  --bs-gradient: none;
}

.btn-outline-green {
  --bs-btn-color: #148922;
  --bs-btn-border-color: #148922;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #148922;
  --bs-btn-hover-border-color: #148922;
  --bs-btn-focus-shadow-rgb: 20, 137, 34;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #148922;
  --bs-btn-active-border-color: #148922;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #148922;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #148922;
  --bs-gradient: none;
}

.btn-outline-yellow {
  --bs-btn-color: #f9e80d;
  --bs-btn-border-color: #f9e80d;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f9e80d;
  --bs-btn-hover-border-color: #f9e80d;
  --bs-btn-focus-shadow-rgb: 249, 232, 13;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f9e80d;
  --bs-btn-active-border-color: #f9e80d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f9e80d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f9e80d;
  --bs-gradient: none;
}

.btn-outline-teal {
  --bs-btn-color: #249d79;
  --bs-btn-border-color: #249d79;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #249d79;
  --bs-btn-hover-border-color: #249d79;
  --bs-btn-focus-shadow-rgb: 36, 157, 121;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #249d79;
  --bs-btn-active-border-color: #249d79;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #249d79;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #249d79;
  --bs-gradient: none;
}

.btn-outline-muted {
  --bs-btn-color: #617a86;
  --bs-btn-border-color: #617a86;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #617a86;
  --bs-btn-hover-border-color: #617a86;
  --bs-btn-focus-shadow-rgb: 97, 122, 134;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #617a86;
  --bs-btn-active-border-color: #617a86;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #617a86;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #617a86;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #c0cacf;
  --bs-btn-border-color: #c0cacf;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #c0cacf;
  --bs-btn-hover-border-color: #c0cacf;
  --bs-btn-focus-shadow-rgb: 192, 202, 207;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c0cacf;
  --bs-btn-active-border-color: #c0cacf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c0cacf;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c0cacf;
  --bs-gradient: none;
}

.btn-outline-lime {
  --bs-btn-color: #50b00a;
  --bs-btn-border-color: #50b00a;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #50b00a;
  --bs-btn-hover-border-color: #50b00a;
  --bs-btn-focus-shadow-rgb: 80, 176, 10;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #50b00a;
  --bs-btn-active-border-color: #50b00a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #50b00a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #50b00a;
  --bs-gradient: none;
}

.btn-outline-gray-100 {
  --bs-btn-color: #c0cacf;
  --bs-btn-border-color: #c0cacf;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #c0cacf;
  --bs-btn-hover-border-color: #c0cacf;
  --bs-btn-focus-shadow-rgb: 192, 202, 207;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c0cacf;
  --bs-btn-active-border-color: #c0cacf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c0cacf;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c0cacf;
  --bs-gradient: none;
}

.btn-outline-gray-200 {
  --bs-btn-color: #a8b6bc;
  --bs-btn-border-color: #a8b6bc;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #a8b6bc;
  --bs-btn-hover-border-color: #a8b6bc;
  --bs-btn-focus-shadow-rgb: 168, 182, 188;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #a8b6bc;
  --bs-btn-active-border-color: #a8b6bc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #a8b6bc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a8b6bc;
  --bs-gradient: none;
}

.btn-outline-gray-300 {
  --bs-btn-color: #90a2aa;
  --bs-btn-border-color: #90a2aa;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #90a2aa;
  --bs-btn-hover-border-color: #90a2aa;
  --bs-btn-focus-shadow-rgb: 144, 162, 170;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #90a2aa;
  --bs-btn-active-border-color: #90a2aa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #90a2aa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #90a2aa;
  --bs-gradient: none;
}

.btn-outline-gray-400 {
  --bs-btn-color: #798e98;
  --bs-btn-border-color: #798e98;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #798e98;
  --bs-btn-hover-border-color: #798e98;
  --bs-btn-focus-shadow-rgb: 121, 142, 152;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #798e98;
  --bs-btn-active-border-color: #798e98;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #798e98;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #798e98;
  --bs-gradient: none;
}

.btn-outline-gray-500 {
  --bs-btn-color: #617a86;
  --bs-btn-border-color: #617a86;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #617a86;
  --bs-btn-hover-border-color: #617a86;
  --bs-btn-focus-shadow-rgb: 97, 122, 134;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #617a86;
  --bs-btn-active-border-color: #617a86;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #617a86;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #617a86;
  --bs-gradient: none;
}

.btn-outline-gray-600 {
  --bs-btn-color: #526872;
  --bs-btn-border-color: #526872;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #526872;
  --bs-btn-hover-border-color: #526872;
  --bs-btn-focus-shadow-rgb: 82, 104, 114;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #526872;
  --bs-btn-active-border-color: #526872;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #526872;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #526872;
  --bs-gradient: none;
}

.btn-outline-gray-700 {
  --bs-btn-color: #44555e;
  --bs-btn-border-color: #44555e;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #44555e;
  --bs-btn-hover-border-color: #44555e;
  --bs-btn-focus-shadow-rgb: 68, 85, 94;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #44555e;
  --bs-btn-active-border-color: #44555e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #44555e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #44555e;
  --bs-gradient: none;
}

.btn-outline-gray-800 {
  --bs-btn-color: #35434a;
  --bs-btn-border-color: #35434a;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #35434a;
  --bs-btn-hover-border-color: #35434a;
  --bs-btn-focus-shadow-rgb: 53, 67, 74;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #35434a;
  --bs-btn-active-border-color: #35434a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #35434a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #35434a;
  --bs-gradient: none;
}

.btn-outline-gray-900 {
  --bs-btn-color: #273136;
  --bs-btn-border-color: #273136;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #273136;
  --bs-btn-hover-border-color: #273136;
  --bs-btn-focus-shadow-rgb: 39, 49, 54;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #273136;
  --bs-btn-active-border-color: #273136;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #273136;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #273136;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #526872;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 69, 172, 141;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 8px;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.8125rem;
  --bs-btn-border-radius: 2px;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: rgba(var(--bs-body-bg-rgb), 0.98);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0;
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(0 - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: rgba(var(--bs-body-color-rgb), 0.15);
  --bs-dropdown-link-active-color: #FFFFFF;
  --bs-dropdown-link-active-bg: #249d79;
  --bs-dropdown-link-disabled-color: #617a86;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.375rem;
  --bs-dropdown-header-color: rgba(var(--bs-body-color-rgb), 0.5);
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1660px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1900px) {
  .dropdown-menu-xxxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #90a2aa;
  --bs-dropdown-bg: #35434a;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #90a2aa;
  --bs-dropdown-link-hover-color: #FFFFFF;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #FFFFFF;
  --bs-dropdown-link-active-bg: #249d79;
  --bs-dropdown-link-disabled-color: #617a86;
  --bs-dropdown-header-color: #617a86;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 4px;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(2, 113, 255, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-inverse);
  --bs-nav-tabs-link-active-bg: transparent;
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) transparent;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 4px;
  --bs-nav-pills-link-active-color: #FFFFFF;
  --bs-nav-pills-link-active-bg: #249d79;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 600;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-inverse-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-inverse-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-inverse-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-inverse-rgb), 1);
  --bs-navbar-brand-padding-y: 0.40625rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1rem;
  --bs-navbar-brand-color: rgba(var(--bs-inverse-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-inverse-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-inverse-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 4px;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1660px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1900px) {
  .navbar-expand-xxxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #FFFFFF;
  --bs-navbar-brand-color: #FFFFFF;
  --bs-navbar-brand-hover-color: #FFFFFF;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: transparent;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%280, 0, 0, 0.75%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%280, 0, 0, 0.75%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-inverse-rgb), 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-inverse);
  --bs-accordion-active-bg: rgba(var(--bs-theme-rgb), 0.15);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.75%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.75%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-font-size: 85%;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-color: var(--bs-inverse);
  --bs-pagination-bg: transparent;
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-inverse);
  --bs-pagination-hover-bg: rgba(var(--bs-inverse-rgb), 0.1);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-inverse-rgb), 0.25);
  --bs-pagination-active-color: var(--bs-theme-color);
  --bs-pagination-active-bg: var(--bs-theme);
  --bs-pagination-active-border-color: var(--bs-theme);
  --bs-pagination-disabled-color: rgba(var(--bs-inverse-rgb), 0.35);
  --bs-pagination-disabled-bg: rgba(var(--bs-inverse-rgb), 0.1);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-border-radius: 8px;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.8125rem;
  --bs-pagination-border-radius: 2px;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 600;
  --bs-badge-color: #FFFFFF;
  --bs-badge-border-radius: 4px;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 4px;
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-default {
  --bs-alert-color: var(--bs-default-text-emphasis);
  --bs-alert-bg: var(--bs-default-bg-subtle);
  --bs-alert-border-color: var(--bs-default-border-subtle);
  --bs-alert-link-color: var(--bs-default-text-emphasis);
}

.alert-theme {
  --bs-alert-color: var(--bs-theme-text-emphasis);
  --bs-alert-bg: var(--bs-theme-bg-subtle);
  --bs-alert-border-color: var(--bs-theme-border-subtle);
  --bs-alert-link-color: var(--bs-theme-text-emphasis);
}

.alert-theme-color {
  --bs-alert-color: var(--bs-theme-color-text-emphasis);
  --bs-alert-bg: var(--bs-theme-color-bg-subtle);
  --bs-alert-border-color: var(--bs-theme-color-border-subtle);
  --bs-alert-link-color: var(--bs-theme-color-text-emphasis);
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-inverse {
  --bs-alert-color: var(--bs-inverse-text-emphasis);
  --bs-alert-bg: var(--bs-inverse-bg-subtle);
  --bs-alert-border-color: var(--bs-inverse-border-subtle);
  --bs-alert-link-color: var(--bs-inverse-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.alert-black {
  --bs-alert-color: var(--bs-black-text-emphasis);
  --bs-alert-bg: var(--bs-black-bg-subtle);
  --bs-alert-border-color: var(--bs-black-border-subtle);
  --bs-alert-link-color: var(--bs-black-text-emphasis);
}

.alert-white {
  --bs-alert-color: var(--bs-white-text-emphasis);
  --bs-alert-bg: var(--bs-white-bg-subtle);
  --bs-alert-border-color: var(--bs-white-border-subtle);
  --bs-alert-link-color: var(--bs-white-text-emphasis);
}

.alert-purple {
  --bs-alert-color: var(--bs-purple-text-emphasis);
  --bs-alert-bg: var(--bs-purple-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-border-subtle);
  --bs-alert-link-color: var(--bs-purple-text-emphasis);
}

.alert-indigo {
  --bs-alert-color: var(--bs-indigo-text-emphasis);
  --bs-alert-bg: var(--bs-indigo-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-text-emphasis);
}

.alert-red {
  --bs-alert-color: var(--bs-red-text-emphasis);
  --bs-alert-bg: var(--bs-red-bg-subtle);
  --bs-alert-border-color: var(--bs-red-border-subtle);
  --bs-alert-link-color: var(--bs-red-text-emphasis);
}

.alert-pink {
  --bs-alert-color: var(--bs-pink-text-emphasis);
  --bs-alert-bg: var(--bs-pink-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-border-subtle);
  --bs-alert-link-color: var(--bs-pink-text-emphasis);
}

.alert-green {
  --bs-alert-color: var(--bs-green-text-emphasis);
  --bs-alert-bg: var(--bs-green-bg-subtle);
  --bs-alert-border-color: var(--bs-green-border-subtle);
  --bs-alert-link-color: var(--bs-green-text-emphasis);
}

.alert-yellow {
  --bs-alert-color: var(--bs-yellow-text-emphasis);
  --bs-alert-bg: var(--bs-yellow-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-text-emphasis);
}

.alert-teal {
  --bs-alert-color: var(--bs-teal-text-emphasis);
  --bs-alert-bg: var(--bs-teal-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-border-subtle);
  --bs-alert-link-color: var(--bs-teal-text-emphasis);
}

.alert-muted {
  --bs-alert-color: var(--bs-muted-text-emphasis);
  --bs-alert-bg: var(--bs-muted-bg-subtle);
  --bs-alert-border-color: var(--bs-muted-border-subtle);
  --bs-alert-link-color: var(--bs-muted-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-lime {
  --bs-alert-color: var(--bs-lime-text-emphasis);
  --bs-alert-bg: var(--bs-lime-bg-subtle);
  --bs-alert-border-color: var(--bs-lime-border-subtle);
  --bs-alert-link-color: var(--bs-lime-text-emphasis);
}

.alert-gray-100 {
  --bs-alert-color: var(--bs-gray-100-text-emphasis);
  --bs-alert-bg: var(--bs-gray-100-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-100-border-subtle);
  --bs-alert-link-color: var(--bs-gray-100-text-emphasis);
}

.alert-gray-200 {
  --bs-alert-color: var(--bs-gray-200-text-emphasis);
  --bs-alert-bg: var(--bs-gray-200-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-200-border-subtle);
  --bs-alert-link-color: var(--bs-gray-200-text-emphasis);
}

.alert-gray-300 {
  --bs-alert-color: var(--bs-gray-300-text-emphasis);
  --bs-alert-bg: var(--bs-gray-300-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-300-border-subtle);
  --bs-alert-link-color: var(--bs-gray-300-text-emphasis);
}

.alert-gray-400 {
  --bs-alert-color: var(--bs-gray-400-text-emphasis);
  --bs-alert-bg: var(--bs-gray-400-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-400-border-subtle);
  --bs-alert-link-color: var(--bs-gray-400-text-emphasis);
}

.alert-gray-500 {
  --bs-alert-color: var(--bs-gray-500-text-emphasis);
  --bs-alert-bg: var(--bs-gray-500-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-500-border-subtle);
  --bs-alert-link-color: var(--bs-gray-500-text-emphasis);
}

.alert-gray-600 {
  --bs-alert-color: var(--bs-gray-600-text-emphasis);
  --bs-alert-bg: var(--bs-gray-600-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-600-border-subtle);
  --bs-alert-link-color: var(--bs-gray-600-text-emphasis);
}

.alert-gray-700 {
  --bs-alert-color: var(--bs-gray-700-text-emphasis);
  --bs-alert-bg: var(--bs-gray-700-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-700-border-subtle);
  --bs-alert-link-color: var(--bs-gray-700-text-emphasis);
}

.alert-gray-800 {
  --bs-alert-color: var(--bs-gray-800-text-emphasis);
  --bs-alert-bg: var(--bs-gray-800-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-800-border-subtle);
  --bs-alert-link-color: var(--bs-gray-800-text-emphasis);
}

.alert-gray-900 {
  --bs-alert-color: var(--bs-gray-900-text-emphasis);
  --bs-alert-bg: var(--bs-gray-900-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-900-border-subtle);
  --bs-alert-link-color: var(--bs-gray-900-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: rgba(var(--bs-inverse-rgb), 0.15);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #FFFFFF;
  --bs-progress-bar-bg: #0271ff;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: transparent;
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-inverse);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: transparent;
  --bs-list-group-active-color: #FFFFFF;
  --bs-list-group-active-bg: #249d79;
  --bs-list-group-active-border-color: #249d79;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1660px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1900px) {
  .list-group-horizontal-xxxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-default {
  --bs-list-group-color: var(--bs-default-text-emphasis);
  --bs-list-group-bg: var(--bs-default-bg-subtle);
  --bs-list-group-border-color: var(--bs-default-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-default-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-default-border-subtle);
  --bs-list-group-active-color: var(--bs-default-bg-subtle);
  --bs-list-group-active-bg: var(--bs-default-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-default-text-emphasis);
}

.list-group-item-theme {
  --bs-list-group-color: var(--bs-theme-text-emphasis);
  --bs-list-group-bg: var(--bs-theme-bg-subtle);
  --bs-list-group-border-color: var(--bs-theme-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-theme-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-theme-border-subtle);
  --bs-list-group-active-color: var(--bs-theme-bg-subtle);
  --bs-list-group-active-bg: var(--bs-theme-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-theme-text-emphasis);
}

.list-group-item-theme-color {
  --bs-list-group-color: var(--bs-theme-color-text-emphasis);
  --bs-list-group-bg: var(--bs-theme-color-bg-subtle);
  --bs-list-group-border-color: var(--bs-theme-color-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-theme-color-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-theme-color-border-subtle);
  --bs-list-group-active-color: var(--bs-theme-color-bg-subtle);
  --bs-list-group-active-bg: var(--bs-theme-color-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-theme-color-text-emphasis);
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-inverse {
  --bs-list-group-color: var(--bs-inverse-text-emphasis);
  --bs-list-group-bg: var(--bs-inverse-bg-subtle);
  --bs-list-group-border-color: var(--bs-inverse-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-inverse-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-inverse-border-subtle);
  --bs-list-group-active-color: var(--bs-inverse-bg-subtle);
  --bs-list-group-active-bg: var(--bs-inverse-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-inverse-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.list-group-item-black {
  --bs-list-group-color: var(--bs-black-text-emphasis);
  --bs-list-group-bg: var(--bs-black-bg-subtle);
  --bs-list-group-border-color: var(--bs-black-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-black-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-black-border-subtle);
  --bs-list-group-active-color: var(--bs-black-bg-subtle);
  --bs-list-group-active-bg: var(--bs-black-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-black-text-emphasis);
}

.list-group-item-white {
  --bs-list-group-color: var(--bs-white-text-emphasis);
  --bs-list-group-bg: var(--bs-white-bg-subtle);
  --bs-list-group-border-color: var(--bs-white-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-white-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-white-border-subtle);
  --bs-list-group-active-color: var(--bs-white-bg-subtle);
  --bs-list-group-active-bg: var(--bs-white-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-white-text-emphasis);
}

.list-group-item-purple {
  --bs-list-group-color: var(--bs-purple-text-emphasis);
  --bs-list-group-bg: var(--bs-purple-bg-subtle);
  --bs-list-group-border-color: var(--bs-purple-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-purple-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-purple-border-subtle);
  --bs-list-group-active-color: var(--bs-purple-bg-subtle);
  --bs-list-group-active-bg: var(--bs-purple-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-purple-text-emphasis);
}

.list-group-item-indigo {
  --bs-list-group-color: var(--bs-indigo-text-emphasis);
  --bs-list-group-bg: var(--bs-indigo-bg-subtle);
  --bs-list-group-border-color: var(--bs-indigo-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-indigo-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-indigo-border-subtle);
  --bs-list-group-active-color: var(--bs-indigo-bg-subtle);
  --bs-list-group-active-bg: var(--bs-indigo-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-indigo-text-emphasis);
}

.list-group-item-red {
  --bs-list-group-color: var(--bs-red-text-emphasis);
  --bs-list-group-bg: var(--bs-red-bg-subtle);
  --bs-list-group-border-color: var(--bs-red-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-red-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-red-border-subtle);
  --bs-list-group-active-color: var(--bs-red-bg-subtle);
  --bs-list-group-active-bg: var(--bs-red-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-red-text-emphasis);
}

.list-group-item-pink {
  --bs-list-group-color: var(--bs-pink-text-emphasis);
  --bs-list-group-bg: var(--bs-pink-bg-subtle);
  --bs-list-group-border-color: var(--bs-pink-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-pink-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-pink-border-subtle);
  --bs-list-group-active-color: var(--bs-pink-bg-subtle);
  --bs-list-group-active-bg: var(--bs-pink-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-pink-text-emphasis);
}

.list-group-item-green {
  --bs-list-group-color: var(--bs-green-text-emphasis);
  --bs-list-group-bg: var(--bs-green-bg-subtle);
  --bs-list-group-border-color: var(--bs-green-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-green-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-green-border-subtle);
  --bs-list-group-active-color: var(--bs-green-bg-subtle);
  --bs-list-group-active-bg: var(--bs-green-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-green-text-emphasis);
}

.list-group-item-yellow {
  --bs-list-group-color: var(--bs-yellow-text-emphasis);
  --bs-list-group-bg: var(--bs-yellow-bg-subtle);
  --bs-list-group-border-color: var(--bs-yellow-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-yellow-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-yellow-border-subtle);
  --bs-list-group-active-color: var(--bs-yellow-bg-subtle);
  --bs-list-group-active-bg: var(--bs-yellow-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-yellow-text-emphasis);
}

.list-group-item-teal {
  --bs-list-group-color: var(--bs-teal-text-emphasis);
  --bs-list-group-bg: var(--bs-teal-bg-subtle);
  --bs-list-group-border-color: var(--bs-teal-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-teal-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-teal-border-subtle);
  --bs-list-group-active-color: var(--bs-teal-bg-subtle);
  --bs-list-group-active-bg: var(--bs-teal-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-teal-text-emphasis);
}

.list-group-item-muted {
  --bs-list-group-color: var(--bs-muted-text-emphasis);
  --bs-list-group-bg: var(--bs-muted-bg-subtle);
  --bs-list-group-border-color: var(--bs-muted-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-muted-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-muted-border-subtle);
  --bs-list-group-active-color: var(--bs-muted-bg-subtle);
  --bs-list-group-active-bg: var(--bs-muted-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-muted-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-lime {
  --bs-list-group-color: var(--bs-lime-text-emphasis);
  --bs-list-group-bg: var(--bs-lime-bg-subtle);
  --bs-list-group-border-color: var(--bs-lime-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-lime-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-lime-border-subtle);
  --bs-list-group-active-color: var(--bs-lime-bg-subtle);
  --bs-list-group-active-bg: var(--bs-lime-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-lime-text-emphasis);
}

.list-group-item-gray-100 {
  --bs-list-group-color: var(--bs-gray-100-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-100-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-100-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-100-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-100-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-100-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-100-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-100-text-emphasis);
}

.list-group-item-gray-200 {
  --bs-list-group-color: var(--bs-gray-200-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-200-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-200-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-200-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-200-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-200-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-200-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-200-text-emphasis);
}

.list-group-item-gray-300 {
  --bs-list-group-color: var(--bs-gray-300-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-300-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-300-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-300-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-300-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-300-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-300-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-300-text-emphasis);
}

.list-group-item-gray-400 {
  --bs-list-group-color: var(--bs-gray-400-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-400-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-400-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-400-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-400-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-400-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-400-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-400-text-emphasis);
}

.list-group-item-gray-500 {
  --bs-list-group-color: var(--bs-gray-500-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-500-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-500-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-500-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-500-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-500-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-500-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-500-text-emphasis);
}

.list-group-item-gray-600 {
  --bs-list-group-color: var(--bs-gray-600-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-600-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-600-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-600-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-600-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-600-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-600-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-600-text-emphasis);
}

.list-group-item-gray-700 {
  --bs-list-group-color: var(--bs-gray-700-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-700-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-700-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-700-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-700-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-700-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-700-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-700-text-emphasis);
}

.list-group-item-gray-800 {
  --bs-list-group-color: var(--bs-gray-800-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-800-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-800-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-800-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-800-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-800-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-800-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-800-text-emphasis);
}

.list-group-item-gray-900 {
  --bs-list-group-color: var(--bs-gray-900-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-900-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-900-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-900-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-900-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-900-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-900-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-900-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(var(--bs-inverse-rgb), 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 4px;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 20px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-app-header-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: 0;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-inverse-rgb), 0.035);
  --bs-modal-inner-border-radius: 0;
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #e6e6e6;
  --bs-backdrop-opacity: 0.95;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1659.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1899.98px) {
  .modal-fullscreen-xxxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-header,
  .modal-fullscreen-xxxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1130;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.8125rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-inverse);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: "Chakra Petch", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.8125rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.875rem;
  --bs-popover-header-color: #000000;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Chakra Petch", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #FFFFFF;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #FFFFFF;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #FFFFFF;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxxl, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-inverse-rgb), 0.035);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1659.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1659.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1659.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1660px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1899.98px) {
  .offcanvas-xxxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1899.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxxl {
    transition: none;
  }
}
@media (max-width: 1899.98px) {
  .offcanvas-xxxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxxl.showing, .offcanvas-xxxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxxl.showing, .offcanvas-xxxl.hiding, .offcanvas-xxxl.show {
    visibility: visible;
  }
}
@media (min-width: 1900px) {
  .offcanvas-xxxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-body-bg);
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.95;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-default {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-default-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-theme {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-theme-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-theme-color {
  color: #000000 !important;
  background-color: RGBA(var(--bs-theme-color-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-inverse {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-inverse-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-black {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-black-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000000 !important;
  background-color: RGBA(var(--bs-white-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-purple-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-indigo-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-red-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-pink-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-green-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow {
  color: #000000 !important;
  background-color: RGBA(var(--bs-yellow-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-teal-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-muted {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-muted-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-lime {
  color: #000000 !important;
  background-color: RGBA(var(--bs-lime-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-100 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-gray-100-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-200 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-gray-200-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-300 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-gray-300-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-400 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-400-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-500 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-500-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-600 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-600-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-700 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-700-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-800 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-800-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-900 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-900-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-default {
  color: RGBA(var(--bs-default-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-default-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-default:hover, .link-default:focus {
  color: RGBA(97, 114, 122, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(97, 114, 122, var(--bs-link-underline-opacity, 1)) !important;
}

.link-theme {
  color: RGBA(var(--bs-theme-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-theme-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-theme:hover, .link-theme:focus {
  color: RGBA(29, 126, 97, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(29, 126, 97, var(--bs-link-underline-opacity, 1)) !important;
}

.link-theme-color {
  color: RGBA(var(--bs-theme-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-theme-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-theme-color:hover, .link-theme-color:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(2, 90, 204, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(2, 90, 204, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(97, 114, 122, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(97, 114, 122, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 178, 61, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 178, 61, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(179, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(179, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(29, 126, 97, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(29, 126, 97, var(--bs-link-underline-opacity, 1)) !important;
}

.link-inverse {
  color: RGBA(var(--bs-inverse-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-inverse-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-inverse:hover, .link-inverse:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(0, 124, 182, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 124, 182, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(42, 54, 59, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(42, 54, 59, var(--bs-link-underline-opacity, 1)) !important;
}

.link-black {
  color: RGBA(var(--bs-black-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-black-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-black:hover, .link-black:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-white {
  color: RGBA(var(--bs-white-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-white-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-white:hover, .link-white:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-purple {
  color: RGBA(var(--bs-purple-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-purple-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-purple:hover, .link-purple:focus {
  color: RGBA(148, 10, 204, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(148, 10, 204, var(--bs-link-underline-opacity, 1)) !important;
}

.link-indigo {
  color: RGBA(var(--bs-indigo-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-indigo-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-indigo:hover, .link-indigo:focus {
  color: RGBA(74, 30, 204, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(74, 30, 204, var(--bs-link-underline-opacity, 1)) !important;
}

.link-red {
  color: RGBA(var(--bs-red-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-red-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-red:hover, .link-red:focus {
  color: RGBA(179, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(179, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-pink {
  color: RGBA(var(--bs-pink-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-pink-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-pink:hover, .link-pink:focus {
  color: RGBA(184, 13, 97, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(184, 13, 97, var(--bs-link-underline-opacity, 1)) !important;
}

.link-green {
  color: RGBA(var(--bs-green-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-green-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-green:hover, .link-green:focus {
  color: RGBA(16, 110, 27, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(16, 110, 27, var(--bs-link-underline-opacity, 1)) !important;
}

.link-yellow {
  color: RGBA(var(--bs-yellow-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-yellow-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-yellow:hover, .link-yellow:focus {
  color: RGBA(250, 237, 61, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(250, 237, 61, var(--bs-link-underline-opacity, 1)) !important;
}

.link-teal {
  color: RGBA(var(--bs-teal-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-teal-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-teal:hover, .link-teal:focus {
  color: RGBA(29, 126, 97, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(29, 126, 97, var(--bs-link-underline-opacity, 1)) !important;
}

.link-muted {
  color: RGBA(var(--bs-muted-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-muted-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-muted:hover, .link-muted:focus {
  color: RGBA(78, 98, 107, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(78, 98, 107, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(205, 213, 217, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(205, 213, 217, var(--bs-link-underline-opacity, 1)) !important;
}

.link-lime {
  color: RGBA(var(--bs-lime-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-lime-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-lime:hover, .link-lime:focus {
  color: RGBA(115, 192, 59, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(115, 192, 59, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-100 {
  color: RGBA(var(--bs-gray-100-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-100-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-100:hover, .link-gray-100:focus {
  color: RGBA(205, 213, 217, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(205, 213, 217, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-200 {
  color: RGBA(var(--bs-gray-200-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-200-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-200:hover, .link-gray-200:focus {
  color: RGBA(185, 197, 201, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(185, 197, 201, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-300 {
  color: RGBA(var(--bs-gray-300-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-300-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-300:hover, .link-gray-300:focus {
  color: RGBA(166, 181, 187, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(166, 181, 187, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-400 {
  color: RGBA(var(--bs-gray-400-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-400-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-400:hover, .link-gray-400:focus {
  color: RGBA(97, 114, 122, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(97, 114, 122, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-500 {
  color: RGBA(var(--bs-gray-500-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-500-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-500:hover, .link-gray-500:focus {
  color: RGBA(78, 98, 107, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(78, 98, 107, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-600 {
  color: RGBA(var(--bs-gray-600-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-600-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-600:hover, .link-gray-600:focus {
  color: RGBA(66, 83, 91, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(66, 83, 91, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-700 {
  color: RGBA(var(--bs-gray-700-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-700-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-700:hover, .link-gray-700:focus {
  color: RGBA(54, 68, 75, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(54, 68, 75, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-800 {
  color: RGBA(var(--bs-gray-800-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-800-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-800:hover, .link-gray-800:focus {
  color: RGBA(42, 54, 59, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(42, 54, 59, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-900 {
  color: RGBA(var(--bs-gray-900-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-900-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-900:hover, .link-gray-900:focus {
  color: RGBA(31, 39, 43, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(31, 39, 43, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1660px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1900px) {
  .sticky-xxxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-default {
  --bs-focus-ring-color: rgba(var(--bs-default-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-theme {
  --bs-focus-ring-color: rgba(var(--bs-theme-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-theme-color {
  --bs-focus-ring-color: rgba(var(--bs-theme-color-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-inverse {
  --bs-focus-ring-color: rgba(var(--bs-inverse-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-black {
  --bs-focus-ring-color: rgba(var(--bs-black-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-white {
  --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-purple {
  --bs-focus-ring-color: rgba(var(--bs-purple-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-indigo {
  --bs-focus-ring-color: rgba(var(--bs-indigo-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-red {
  --bs-focus-ring-color: rgba(var(--bs-red-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-pink {
  --bs-focus-ring-color: rgba(var(--bs-pink-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-green {
  --bs-focus-ring-color: rgba(var(--bs-green-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-yellow {
  --bs-focus-ring-color: rgba(var(--bs-yellow-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-teal {
  --bs-focus-ring-color: rgba(var(--bs-teal-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-muted {
  --bs-focus-ring-color: rgba(var(--bs-muted-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-lime {
  --bs-focus-ring-color: rgba(var(--bs-lime-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-100 {
  --bs-focus-ring-color: rgba(var(--bs-gray-100-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-200 {
  --bs-focus-ring-color: rgba(var(--bs-gray-200-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-300 {
  --bs-focus-ring-color: rgba(var(--bs-gray-300-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-400 {
  --bs-focus-ring-color: rgba(var(--bs-gray-400-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-500 {
  --bs-focus-ring-color: rgba(var(--bs-gray-500-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-600 {
  --bs-focus-ring-color: rgba(var(--bs-gray-600-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-700 {
  --bs-focus-ring-color: rgba(var(--bs-gray-700-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-800 {
  --bs-focus-ring-color: rgba(var(--bs-gray-800-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-900 {
  --bs-focus-ring-color: rgba(var(--bs-gray-900-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-default {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-default-rgb), var(--bs-border-opacity)) !important;
}

.border-theme {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-theme-rgb), var(--bs-border-opacity)) !important;
}

.border-theme-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-theme-color-rgb), var(--bs-border-opacity)) !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-inverse {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-inverse-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-rgb), var(--bs-border-opacity)) !important;
}

.border-red {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-rgb), var(--bs-border-opacity)) !important;
}

.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-green {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-rgb), var(--bs-border-opacity)) !important;
}

.border-teal {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-rgb), var(--bs-border-opacity)) !important;
}

.border-muted {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-muted-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-lime {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-lime-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-100-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-200-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-300-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-400-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-500-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-600-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-700-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-800-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-900 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-900-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.34375rem + 1.125vw) !important;
}

.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-3 {
  font-size: calc(1.278125rem + 0.3375vw) !important;
}

.fs-4 {
  font-size: calc(1.25625rem + 0.075vw) !important;
}

.fs-5 {
  font-size: 1.09375rem !important;
}

.fs-6 {
  font-size: 0.875rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-default {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-default-rgb), var(--bs-text-opacity)) !important;
}

.text-theme {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-theme-rgb), var(--bs-text-opacity)) !important;
}

.text-theme-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-theme-color-rgb), var(--bs-text-opacity)) !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-inverse {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-inverse-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-rgb), var(--bs-text-opacity)) !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-rgb), var(--bs-text-opacity)) !important;
}

.text-teal {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-lime {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-lime-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-100-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-200-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-300-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-400-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-500-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-600-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-700-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-800-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-900-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-default {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-default-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-theme {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-theme-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-theme-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-theme-color-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-inverse {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-inverse-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-black {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-black-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-white {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-purple {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-purple-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-indigo {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-indigo-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-red {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-red-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-pink {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-pink-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-green {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-green-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-yellow {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-yellow-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-teal {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-teal-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-muted {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-muted-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-lime {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-lime-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-100 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-100-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-200 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-200-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-300 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-300-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-400 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-400-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-500 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-500-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-600 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-600-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-700 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-700-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-800 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-800-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-900 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-900-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-default {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-default-rgb), var(--bs-bg-opacity)) !important;
}

.bg-theme {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-theme-rgb), var(--bs-bg-opacity)) !important;
}

.bg-theme-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-theme-color-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-inverse {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-inverse-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
}

.bg-muted {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-muted-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-lime {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-lime-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-900-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1660px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1900px) {
  .float-xxxl-start {
    float: left !important;
  }
  .float-xxxl-end {
    float: right !important;
  }
  .float-xxxl-none {
    float: none !important;
  }
  .object-fit-xxxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxxl-none {
    object-fit: none !important;
  }
  .d-xxxl-inline {
    display: inline !important;
  }
  .d-xxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxl-block {
    display: block !important;
  }
  .d-xxxl-grid {
    display: grid !important;
  }
  .d-xxxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxxl-table {
    display: table !important;
  }
  .d-xxxl-table-row {
    display: table-row !important;
  }
  .d-xxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxl-flex {
    display: flex !important;
  }
  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxxl-none {
    display: none !important;
  }
  .flex-xxxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxxl-row {
    flex-direction: row !important;
  }
  .flex-xxxl-column {
    flex-direction: column !important;
  }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxxl-center {
    justify-content: center !important;
  }
  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxxl-center {
    align-items: center !important;
  }
  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxxl-center {
    align-content: center !important;
  }
  .align-content-xxxl-between {
    align-content: space-between !important;
  }
  .align-content-xxxl-around {
    align-content: space-around !important;
  }
  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxxl-auto {
    align-self: auto !important;
  }
  .align-self-xxxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxxl-center {
    align-self: center !important;
  }
  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }
  .order-xxxl-first {
    order: -1 !important;
  }
  .order-xxxl-0 {
    order: 0 !important;
  }
  .order-xxxl-1 {
    order: 1 !important;
  }
  .order-xxxl-2 {
    order: 2 !important;
  }
  .order-xxxl-3 {
    order: 3 !important;
  }
  .order-xxxl-4 {
    order: 4 !important;
  }
  .order-xxxl-5 {
    order: 5 !important;
  }
  .order-xxxl-last {
    order: 6 !important;
  }
  .m-xxxl-0 {
    margin: 0 !important;
  }
  .m-xxxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxxl-3 {
    margin: 1rem !important;
  }
  .m-xxxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxxl-5 {
    margin: 3rem !important;
  }
  .m-xxxl-auto {
    margin: auto !important;
  }
  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxxl-auto {
    margin-top: auto !important;
  }
  .me-xxxl-0 {
    margin-right: 0 !important;
  }
  .me-xxxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxxl-auto {
    margin-right: auto !important;
  }
  .mb-xxxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxxl-auto {
    margin-left: auto !important;
  }
  .m-xxxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxxl-n3 {
    margin: -1rem !important;
  }
  .m-xxxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxxl-n5 {
    margin: -3rem !important;
  }
  .mx-xxxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xxxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xxxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxxl-n5 {
    margin-top: -3rem !important;
  }
  .me-xxxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xxxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxxl-n5 {
    margin-left: -3rem !important;
  }
  .p-xxxl-0 {
    padding: 0 !important;
  }
  .p-xxxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxxl-3 {
    padding: 1rem !important;
  }
  .p-xxxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxxl-5 {
    padding: 3rem !important;
  }
  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxxl-0 {
    gap: 0 !important;
  }
  .gap-xxxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxxl-3 {
    gap: 1rem !important;
  }
  .gap-xxxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxxl-start {
    text-align: left !important;
  }
  .text-xxxl-end {
    text-align: right !important;
  }
  .text-xxxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.1875rem !important;
  }
  .fs-2 {
    font-size: 1.75rem !important;
  }
  .fs-3 {
    font-size: 1.53125rem !important;
  }
  .fs-4 {
    font-size: 1.3125rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root,
[data-bs-theme=light] {
  --bs-body-bg-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.99) 100%);
  --bs-body-bg-image: url("images/pattern.png");
  --bs-body-bg-image-size: 4.6875rem;
  --bs-body-bg-cover: url("images/cover.jpg");
  --bs-body-font-weight: 400;
  --bs-body-letter-spacing: 0.046875rem;
  --bs-component-bg: #FFFFFF;
  --bs-component-bg-rgb: 255, 255, 255;
  --bs-component-color: rgba(0, 0, 0, 0.75);
  --bs-component-color-rgb: 0, 0, 0;
  --bs-app-header-bg: #FFFFFF;
  --bs-app-header-brand-img: url("images/logo.png");
  --bs-app-header-link-color: #000000;
  --bs-app-header-link-color-rgb: 0, 0, 0;
  --bs-app-header-link-hover-color: #000000;
  --bs-app-header-scroll-box-shadow: ;
  --bs-app-sidebar-menu-header-color: rgba(var(--bs-inverse-rgb), 0.3);
  --bs-app-sidebar-menu-link-color: rgba(var(--bs-inverse-rgb), 0.5);
  --bs-app-sidebar-menu-link-hover-color: #000000;
  --bs-app-sidebar-menu-link-active-color: #000000;
  --bs-app-sidebar-mobile-bg: rgba(255, 255, 255, 0.98);
  --bs-app-top-nav-bg: #FFFFFF;
  --bs-app-top-nav-menu-link-color: rgba(var(--bs-inverse-rgb), 0.5);
  --bs-app-top-nav-menu-link-hover-color: #000000;
  --bs-app-top-nav-menu-link-active-color: #000000;
  --bs-app-top-nav-menu-submenu-bg: #FFFFFF;
  --bs-app-theme-panel-bg: rgba(255, 255, 255, 0.95);
  --bs-theme-bg-subtle: #d3ebe4;
}

[data-bs-theme=dark] {
  --bs-body-bg-gradient: linear-gradient(180deg, rgba(50, 70, 80, 0.9) 0%, rgb(13, 16, 27) 100%);
  --bs-body-bg-image: url("images/pattern-dark.png");
  --bs-body-bg-image-size: 4.6875rem;
  --bs-body-bg-cover: url("images/cover-dark.jpg");
  --bs-body-font-weight: 300;
  --bs-body-letter-spacing: 0.046875rem;
  --bs-component-bg: #28292b;
  --bs-component-bg-rgb: 40, 41, 43;
  --bs-component-color: rgba(255, 255, 255, 0.75);
  --bs-component-color-rgb: 255, 255, 255;
  --bs-app-header-bg: rgba(29, 40, 53, 0.95);
  --bs-app-header-brand-img: url("images/logo-dark.png");
  --bs-app-header-link-color: #FFFFFF;
  --bs-app-header-link-color-rgb: 255, 255, 255;
  --bs-app-header-link-hover-color: rgba(255, 255, 255, 0.5);
  --bs-app-header-scroll-box-shadow: ;
  --bs-app-sidebar-menu-header-color: rgba(var(--bs-inverse-rgb), 0.3);
  --bs-app-sidebar-menu-link-color: rgba(var(--bs-inverse-rgb), 0.5);
  --bs-app-sidebar-menu-link-hover-color: #FFFFFF;
  --bs-app-sidebar-menu-link-active-color: #FFFFFF;
  --bs-app-sidebar-mobile-bg: rgba(44, 56, 70, 0.98);
  --bs-app-top-nav-bg: rgba(29, 40, 53, 0.95);
  --bs-app-top-nav-menu-link-color: rgba(var(--bs-inverse-rgb), 0.5);
  --bs-app-top-nav-menu-link-hover-color: #FFFFFF;
  --bs-app-top-nav-menu-link-active-color: #FFFFFF;
  --bs-app-top-nav-menu-submenu-bg: rgba(29, 40, 53, 0.95);
  --bs-app-theme-panel-bg: rgba(68, 85, 94, 0.95);
  --bs-theme-bg-subtle: #071f18;
}

html {
  height: 100%;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-body-bg-gradient);
}
html:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -5;
  background: var(--bs-body-bg-gradient);
}
html:after {
  content: "";
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
  z-index: -10;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-body-bg-gradient);
  background-image: var(--bs-body-bg-cover);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: initial;
  height: 100%;
  transition: background 0.2s linear;
  background-size: cover;
}

body {
  background: none;
  font-weight: var(--bs-body-font-weight);
  letter-spacing: var(--bs-body-letter-spacing);
  position: relative;
  min-height: 100vh;
  z-index: 9999;
}
body:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-attachment: initial;
  background-color: transparent;
  background-image: var(--bs-body-bg-image);
  background-size: var(--bs-body-bg-image-size);
  background-repeat: repeat;
  background-attachment: initial;
  z-index: -5;
}
body:not(.app-init) * {
  transition: none !important;
  animation: none !important;
}

/* Helper Margin */
.top-auto {
  top: auto !important;
}

.bottom-auto {
  bottom: auto !important;
}

.start-auto {
  left: auto !important;
}

.end-auto {
  right: auto !important;
}

.m-1px {
  margin: 1px !important;
}

.m-2px {
  margin: 2px !important;
}

.m-3px {
  margin: 3px !important;
}

.m-4px {
  margin: 4px !important;
}

.m-5px {
  margin: 5px !important;
}

.m-6px {
  margin: 6px !important;
}

.m-7px {
  margin: 7px !important;
}

.m-8px {
  margin: 8px !important;
}

.m-9px {
  margin: 9px !important;
}

.m-10px {
  margin: 10px !important;
}

.m-5px {
  margin: 5px !important;
}

.m-10px {
  margin: 10px !important;
}

.m-15px {
  margin: 15px !important;
}

.m-20px {
  margin: 20px !important;
}

.m-25px {
  margin: 25px !important;
}

.m-30px {
  margin: 30px !important;
}

.m-35px {
  margin: 35px !important;
}

.m-40px {
  margin: 40px !important;
}

.m-45px {
  margin: 45px !important;
}

.m-50px {
  margin: 50px !important;
}

.mt-1px {
  margin-top: 1px !important;
}

.mt-2px {
  margin-top: 2px !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.mt-4px {
  margin-top: 4px !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt-6px {
  margin-top: 6px !important;
}

.mt-7px {
  margin-top: 7px !important;
}

.mt-8px {
  margin-top: 8px !important;
}

.mt-9px {
  margin-top: 9px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mt-25px {
  margin-top: 25px !important;
}

.mt-30px {
  margin-top: 30px !important;
}

.mt-35px {
  margin-top: 35px !important;
}

.mt-40px {
  margin-top: 40px !important;
}

.mt-45px {
  margin-top: 45px !important;
}

.mt-50px {
  margin-top: 50px !important;
}

.me-1px {
  margin-right: 1px !important;
}

.me-2px {
  margin-right: 2px !important;
}

.me-3px {
  margin-right: 3px !important;
}

.me-4px {
  margin-right: 4px !important;
}

.me-5px {
  margin-right: 5px !important;
}

.me-6px {
  margin-right: 6px !important;
}

.me-7px {
  margin-right: 7px !important;
}

.me-8px {
  margin-right: 8px !important;
}

.me-9px {
  margin-right: 9px !important;
}

.me-10px {
  margin-right: 10px !important;
}

.me-5px {
  margin-right: 5px !important;
}

.me-10px {
  margin-right: 10px !important;
}

.me-15px {
  margin-right: 15px !important;
}

.me-20px {
  margin-right: 20px !important;
}

.me-25px {
  margin-right: 25px !important;
}

.me-30px {
  margin-right: 30px !important;
}

.me-35px {
  margin-right: 35px !important;
}

.me-40px {
  margin-right: 40px !important;
}

.me-45px {
  margin-right: 45px !important;
}

.me-50px {
  margin-right: 50px !important;
}

.mb-1px {
  margin-bottom: 1px !important;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.mb-3px {
  margin-bottom: 3px !important;
}

.mb-4px {
  margin-bottom: 4px !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-6px {
  margin-bottom: 6px !important;
}

.mb-7px {
  margin-bottom: 7px !important;
}

.mb-8px {
  margin-bottom: 8px !important;
}

.mb-9px {
  margin-bottom: 9px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.mb-25px {
  margin-bottom: 25px !important;
}

.mb-30px {
  margin-bottom: 30px !important;
}

.mb-35px {
  margin-bottom: 35px !important;
}

.mb-40px {
  margin-bottom: 40px !important;
}

.mb-45px {
  margin-bottom: 45px !important;
}

.mb-50px {
  margin-bottom: 50px !important;
}

.ms-1px {
  margin-left: 1px !important;
}

.ms-2px {
  margin-left: 2px !important;
}

.ms-3px {
  margin-left: 3px !important;
}

.ms-4px {
  margin-left: 4px !important;
}

.ms-5px {
  margin-left: 5px !important;
}

.ms-6px {
  margin-left: 6px !important;
}

.ms-7px {
  margin-left: 7px !important;
}

.ms-8px {
  margin-left: 8px !important;
}

.ms-9px {
  margin-left: 9px !important;
}

.ms-10px {
  margin-left: 10px !important;
}

.ms-5px {
  margin-left: 5px !important;
}

.ms-10px {
  margin-left: 10px !important;
}

.ms-15px {
  margin-left: 15px !important;
}

.ms-20px {
  margin-left: 20px !important;
}

.ms-25px {
  margin-left: 25px !important;
}

.ms-30px {
  margin-left: 30px !important;
}

.ms-35px {
  margin-left: 35px !important;
}

.ms-40px {
  margin-left: 40px !important;
}

.ms-45px {
  margin-left: 45px !important;
}

.ms-50px {
  margin-left: 50px !important;
}

.mx-1px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.mx-2px {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.mx-3px {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.mx-4px {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mx-5px {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mx-6px {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.mx-7px {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.mx-8px {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mx-9px {
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.mx-10px {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx-5px {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mx-10px {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx-15px {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mx-20px {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mx-25px {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.mx-30px {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mx-35px {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.mx-40px {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mx-45px {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.mx-50px {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.my-1px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.my-2px {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.my-3px {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.my-4px {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-5px {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-6px {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.my-7px {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.my-8px {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-9px {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.my-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-5px {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-15px {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-20px {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-25px {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-30px {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-35px {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.my-40px {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-45px {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.my-50px {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

/* Helper Padding */
.p-1px {
  padding: 1px !important;
}

.p-2px {
  padding: 2px !important;
}

.p-3px {
  padding: 3px !important;
}

.p-4px {
  padding: 4px !important;
}

.p-5px {
  padding: 5px !important;
}

.p-6px {
  padding: 6px !important;
}

.p-7px {
  padding: 7px !important;
}

.p-8px {
  padding: 8px !important;
}

.p-9px {
  padding: 9px !important;
}

.p-10px {
  padding: 10px !important;
}

.p-5px {
  padding: 5px !important;
}

.p-10px {
  padding: 10px !important;
}

.p-15px {
  padding: 15px !important;
}

.p-20px {
  padding: 20px !important;
}

.p-25px {
  padding: 25px !important;
}

.p-30px {
  padding: 30px !important;
}

.p-35px {
  padding: 35px !important;
}

.p-40px {
  padding: 40px !important;
}

.p-45px {
  padding: 45px !important;
}

.p-50px {
  padding: 50px !important;
}

.pt-1px {
  padding-top: 1px !important;
}

.pt-2px {
  padding-top: 2px !important;
}

.pt-3px {
  padding-top: 3px !important;
}

.pt-4px {
  padding-top: 4px !important;
}

.pt-5px {
  padding-top: 5px !important;
}

.pt-6px {
  padding-top: 6px !important;
}

.pt-7px {
  padding-top: 7px !important;
}

.pt-8px {
  padding-top: 8px !important;
}

.pt-9px {
  padding-top: 9px !important;
}

.pt-10px {
  padding-top: 10px !important;
}

.pt-5px {
  padding-top: 5px !important;
}

.pt-10px {
  padding-top: 10px !important;
}

.pt-15px {
  padding-top: 15px !important;
}

.pt-20px {
  padding-top: 20px !important;
}

.pt-25px {
  padding-top: 25px !important;
}

.pt-30px {
  padding-top: 30px !important;
}

.pt-35px {
  padding-top: 35px !important;
}

.pt-40px {
  padding-top: 40px !important;
}

.pt-45px {
  padding-top: 45px !important;
}

.pt-50px {
  padding-top: 50px !important;
}

.pe-1px {
  padding-right: 1px !important;
}

.pe-2px {
  padding-right: 2px !important;
}

.pe-3px {
  padding-right: 3px !important;
}

.pe-4px {
  padding-right: 4px !important;
}

.pe-5px {
  padding-right: 5px !important;
}

.pe-6px {
  padding-right: 6px !important;
}

.pe-7px {
  padding-right: 7px !important;
}

.pe-8px {
  padding-right: 8px !important;
}

.pe-9px {
  padding-right: 9px !important;
}

.pe-10px {
  padding-right: 10px !important;
}

.pe-5px {
  padding-right: 5px !important;
}

.pe-10px {
  padding-right: 10px !important;
}

.pe-15px {
  padding-right: 15px !important;
}

.pe-20px {
  padding-right: 20px !important;
}

.pe-25px {
  padding-right: 25px !important;
}

.pe-30px {
  padding-right: 30px !important;
}

.pe-35px {
  padding-right: 35px !important;
}

.pe-40px {
  padding-right: 40px !important;
}

.pe-45px {
  padding-right: 45px !important;
}

.pe-50px {
  padding-right: 50px !important;
}

.pb-1px {
  padding-bottom: 1px !important;
}

.pb-2px {
  padding-bottom: 2px !important;
}

.pb-3px {
  padding-bottom: 3px !important;
}

.pb-4px {
  padding-bottom: 4px !important;
}

.pb-5px {
  padding-bottom: 5px !important;
}

.pb-6px {
  padding-bottom: 6px !important;
}

.pb-7px {
  padding-bottom: 7px !important;
}

.pb-8px {
  padding-bottom: 8px !important;
}

.pb-9px {
  padding-bottom: 9px !important;
}

.pb-10px {
  padding-bottom: 10px !important;
}

.pb-5px {
  padding-bottom: 5px !important;
}

.pb-10px {
  padding-bottom: 10px !important;
}

.pb-15px {
  padding-bottom: 15px !important;
}

.pb-20px {
  padding-bottom: 20px !important;
}

.pb-25px {
  padding-bottom: 25px !important;
}

.pb-30px {
  padding-bottom: 30px !important;
}

.pb-35px {
  padding-bottom: 35px !important;
}

.pb-40px {
  padding-bottom: 40px !important;
}

.pb-45px {
  padding-bottom: 45px !important;
}

.pb-50px {
  padding-bottom: 50px !important;
}

.ps-1px {
  padding-left: 1px !important;
}

.ps-2px {
  padding-left: 2px !important;
}

.ps-3px {
  padding-left: 3px !important;
}

.ps-4px {
  padding-left: 4px !important;
}

.ps-5px {
  padding-left: 5px !important;
}

.ps-6px {
  padding-left: 6px !important;
}

.ps-7px {
  padding-left: 7px !important;
}

.ps-8px {
  padding-left: 8px !important;
}

.ps-9px {
  padding-left: 9px !important;
}

.ps-10px {
  padding-left: 10px !important;
}

.ps-5px {
  padding-left: 5px !important;
}

.ps-10px {
  padding-left: 10px !important;
}

.ps-15px {
  padding-left: 15px !important;
}

.ps-20px {
  padding-left: 20px !important;
}

.ps-25px {
  padding-left: 25px !important;
}

.ps-30px {
  padding-left: 30px !important;
}

.ps-35px {
  padding-left: 35px !important;
}

.ps-40px {
  padding-left: 40px !important;
}

.ps-45px {
  padding-left: 45px !important;
}

.ps-50px {
  padding-left: 50px !important;
}

.px-1px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.px-2px {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.px-3px {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.px-4px {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.px-5px {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.px-6px {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.px-7px {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.px-8px {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-9px {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.px-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-5px {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.px-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-15px {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.px-20px {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-25px {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.px-30px {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.px-35px {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.px-40px {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.px-45px {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.px-50px {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.py-1px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.py-3px {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.py-4px {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-5px {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py-6px {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.py-7px {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.py-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-9px {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-5px {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-15px {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-20px {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-25px {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py-30px {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-35px {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.py-40px {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-45px {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.py-50px {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

/* Helper Font */
.fs-0px {
  font-size: 0px !important;
}

.fs-1px {
  font-size: 1px !important;
}

.fs-2px {
  font-size: 2px !important;
}

.fs-3px {
  font-size: 3px !important;
}

.fs-4px {
  font-size: 4px !important;
}

.fs-5px {
  font-size: 5px !important;
}

.fs-6px {
  font-size: 6px !important;
}

.fs-7px {
  font-size: 7px !important;
}

.fs-8px {
  font-size: 8px !important;
}

.fs-9px {
  font-size: 9px !important;
}

.fs-10px {
  font-size: 10px !important;
}

.fs-11px {
  font-size: 11px !important;
}

.fs-12px {
  font-size: 12px !important;
}

.fs-13px {
  font-size: 13px !important;
}

.fs-14px {
  font-size: 14px !important;
}

.fs-15px {
  font-size: 15px !important;
}

.fs-16px {
  font-size: 16px !important;
}

.fs-17px {
  font-size: 17px !important;
}

.fs-18px {
  font-size: 18px !important;
}

.fs-19px {
  font-size: 19px !important;
}

.fs-20px {
  font-size: 20px !important;
}

.fs-21px {
  font-size: 21px !important;
}

.fs-22px {
  font-size: 22px !important;
}

.fs-23px {
  font-size: 23px !important;
}

.fs-24px {
  font-size: 24px !important;
}

.fs-25px {
  font-size: 25px !important;
}

.fs-26px {
  font-size: 26px !important;
}

.fs-27px {
  font-size: 27px !important;
}

.fs-28px {
  font-size: 28px !important;
}

.fs-29px {
  font-size: 29px !important;
}

.fs-30px {
  font-size: 30px !important;
}

.fs-31px {
  font-size: 31px !important;
}

.fs-32px {
  font-size: 32px !important;
}

.fs-33px {
  font-size: 33px !important;
}

.fs-34px {
  font-size: 34px !important;
}

.fs-35px {
  font-size: 35px !important;
}

.fs-36px {
  font-size: 36px !important;
}

.fs-37px {
  font-size: 37px !important;
}

.fs-38px {
  font-size: 38px !important;
}

.fs-39px {
  font-size: 39px !important;
}

.fs-40px {
  font-size: 40px !important;
}

.fs-41px {
  font-size: 41px !important;
}

.fs-42px {
  font-size: 42px !important;
}

.fs-43px {
  font-size: 43px !important;
}

.fs-44px {
  font-size: 44px !important;
}

.fs-45px {
  font-size: 45px !important;
}

.fs-46px {
  font-size: 46px !important;
}

.fs-47px {
  font-size: 47px !important;
}

.fs-48px {
  font-size: 48px !important;
}

.fs-49px {
  font-size: 49px !important;
}

.fs-50px {
  font-size: 50px !important;
}

.fs-51px {
  font-size: 51px !important;
}

.fs-52px {
  font-size: 52px !important;
}

.fs-53px {
  font-size: 53px !important;
}

.fs-54px {
  font-size: 54px !important;
}

.fs-55px {
  font-size: 55px !important;
}

.fs-56px {
  font-size: 56px !important;
}

.fs-57px {
  font-size: 57px !important;
}

.fs-58px {
  font-size: 58px !important;
}

.fs-59px {
  font-size: 59px !important;
}

.fs-60px {
  font-size: 60px !important;
}

.fs-61px {
  font-size: 61px !important;
}

.fs-62px {
  font-size: 62px !important;
}

.fs-63px {
  font-size: 63px !important;
}

.fs-64px {
  font-size: 64px !important;
}

.fs-65px {
  font-size: 65px !important;
}

.fs-66px {
  font-size: 66px !important;
}

.fs-67px {
  font-size: 67px !important;
}

.fs-68px {
  font-size: 68px !important;
}

.fs-69px {
  font-size: 69px !important;
}

.fs-70px {
  font-size: 70px !important;
}

.fs-71px {
  font-size: 71px !important;
}

.fs-72px {
  font-size: 72px !important;
}

.fs-73px {
  font-size: 73px !important;
}

.fs-74px {
  font-size: 74px !important;
}

.fs-75px {
  font-size: 75px !important;
}

.fs-76px {
  font-size: 76px !important;
}

.fs-77px {
  font-size: 77px !important;
}

.fs-78px {
  font-size: 78px !important;
}

.fs-79px {
  font-size: 79px !important;
}

.fs-80px {
  font-size: 80px !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

/* Helper Position Direction */
.top-0 {
  top: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.start-0 {
  left: 0 !important;
}

.end-0 {
  right: 0 !important;
}

.top-auto {
  top: auto !important;
}

.bottom-auto {
  bottom: auto !important;
}

.start-auto {
  left: auto !important;
}

.end-auto {
  right: auto !important;
}

/* Helper Cursor */
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-text {
  cursor: text !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-inherit {
  cursor: inherit !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-progress {
  cursor: progress !important;
}

/* Helper Position Responsive */
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.transform-none {
  transform: none !important;
}

.transform-initial {
  transform: initial !important;
}

.visibility-none {
  visibility: none !important;
}

.visibility-initial {
  visibility: initial !important;
}

.z-xs-n1 {
  z-index: -1 !important;
}

.z-xs-0 {
  z-index: 0 !important;
}

.z-xs-1 {
  z-index: 1 !important;
}

.z-xs-2 {
  z-index: 2 !important;
}

.z-xs-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .position-sm-static {
    position: static !important;
  }
}
@media (min-width: 576px) {
  .position-sm-relative {
    position: relative !important;
  }
}
@media (min-width: 576px) {
  .position-sm-absolute {
    position: absolute !important;
  }
}
@media (min-width: 576px) {
  .position-sm-fixed {
    position: fixed !important;
  }
}
@media (min-width: 576px) {
  .position-sm-sticky {
    position: sticky !important;
  }
}
@media (min-width: 576px) {
  .transform-sm-none {
    transform: none !important;
  }
}
@media (min-width: 576px) {
  .transform-sm-initial {
    transform: initial !important;
  }
}
@media (min-width: 576px) {
  .visibility-sm-none {
    visibility: none !important;
  }
}
@media (min-width: 576px) {
  .visibility-sm-initial {
    visibility: initial !important;
  }
}
@media (min-width: 576px) {
  .z-sm-n1 {
    z-index: -1 !important;
  }
}

@media (min-width: 576px) {
  .z-sm-0 {
    z-index: 0 !important;
  }
}

@media (min-width: 576px) {
  .z-sm-1 {
    z-index: 1 !important;
  }
}

@media (min-width: 576px) {
  .z-sm-2 {
    z-index: 2 !important;
  }
}

@media (min-width: 576px) {
  .z-sm-3 {
    z-index: 3 !important;
  }
}

@media (min-width: 768px) {
  .position-md-static {
    position: static !important;
  }
}
@media (min-width: 768px) {
  .position-md-relative {
    position: relative !important;
  }
}
@media (min-width: 768px) {
  .position-md-absolute {
    position: absolute !important;
  }
}
@media (min-width: 768px) {
  .position-md-fixed {
    position: fixed !important;
  }
}
@media (min-width: 768px) {
  .position-md-sticky {
    position: sticky !important;
  }
}
@media (min-width: 768px) {
  .transform-md-none {
    transform: none !important;
  }
}
@media (min-width: 768px) {
  .transform-md-initial {
    transform: initial !important;
  }
}
@media (min-width: 768px) {
  .visibility-md-none {
    visibility: none !important;
  }
}
@media (min-width: 768px) {
  .visibility-md-initial {
    visibility: initial !important;
  }
}
@media (min-width: 768px) {
  .z-md-n1 {
    z-index: -1 !important;
  }
}

@media (min-width: 768px) {
  .z-md-0 {
    z-index: 0 !important;
  }
}

@media (min-width: 768px) {
  .z-md-1 {
    z-index: 1 !important;
  }
}

@media (min-width: 768px) {
  .z-md-2 {
    z-index: 2 !important;
  }
}

@media (min-width: 768px) {
  .z-md-3 {
    z-index: 3 !important;
  }
}

@media (min-width: 992px) {
  .position-lg-static {
    position: static !important;
  }
}
@media (min-width: 992px) {
  .position-lg-relative {
    position: relative !important;
  }
}
@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute !important;
  }
}
@media (min-width: 992px) {
  .position-lg-fixed {
    position: fixed !important;
  }
}
@media (min-width: 992px) {
  .position-lg-sticky {
    position: sticky !important;
  }
}
@media (min-width: 992px) {
  .transform-lg-none {
    transform: none !important;
  }
}
@media (min-width: 992px) {
  .transform-lg-initial {
    transform: initial !important;
  }
}
@media (min-width: 992px) {
  .visibility-lg-none {
    visibility: none !important;
  }
}
@media (min-width: 992px) {
  .visibility-lg-initial {
    visibility: initial !important;
  }
}
@media (min-width: 992px) {
  .z-lg-n1 {
    z-index: -1 !important;
  }
}

@media (min-width: 992px) {
  .z-lg-0 {
    z-index: 0 !important;
  }
}

@media (min-width: 992px) {
  .z-lg-1 {
    z-index: 1 !important;
  }
}

@media (min-width: 992px) {
  .z-lg-2 {
    z-index: 2 !important;
  }
}

@media (min-width: 992px) {
  .z-lg-3 {
    z-index: 3 !important;
  }
}

@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important;
  }
}
@media (min-width: 1200px) {
  .position-xl-relative {
    position: relative !important;
  }
}
@media (min-width: 1200px) {
  .position-xl-absolute {
    position: absolute !important;
  }
}
@media (min-width: 1200px) {
  .position-xl-fixed {
    position: fixed !important;
  }
}
@media (min-width: 1200px) {
  .position-xl-sticky {
    position: sticky !important;
  }
}
@media (min-width: 1200px) {
  .transform-xl-none {
    transform: none !important;
  }
}
@media (min-width: 1200px) {
  .transform-xl-initial {
    transform: initial !important;
  }
}
@media (min-width: 1200px) {
  .visibility-xl-none {
    visibility: none !important;
  }
}
@media (min-width: 1200px) {
  .visibility-xl-initial {
    visibility: initial !important;
  }
}
@media (min-width: 1200px) {
  .z-xl-n1 {
    z-index: -1 !important;
  }
}

@media (min-width: 1200px) {
  .z-xl-0 {
    z-index: 0 !important;
  }
}

@media (min-width: 1200px) {
  .z-xl-1 {
    z-index: 1 !important;
  }
}

@media (min-width: 1200px) {
  .z-xl-2 {
    z-index: 2 !important;
  }
}

@media (min-width: 1200px) {
  .z-xl-3 {
    z-index: 3 !important;
  }
}

@media (min-width: 1660px) {
  .position-xxl-static {
    position: static !important;
  }
}
@media (min-width: 1660px) {
  .position-xxl-relative {
    position: relative !important;
  }
}
@media (min-width: 1660px) {
  .position-xxl-absolute {
    position: absolute !important;
  }
}
@media (min-width: 1660px) {
  .position-xxl-fixed {
    position: fixed !important;
  }
}
@media (min-width: 1660px) {
  .position-xxl-sticky {
    position: sticky !important;
  }
}
@media (min-width: 1660px) {
  .transform-xxl-none {
    transform: none !important;
  }
}
@media (min-width: 1660px) {
  .transform-xxl-initial {
    transform: initial !important;
  }
}
@media (min-width: 1660px) {
  .visibility-xxl-none {
    visibility: none !important;
  }
}
@media (min-width: 1660px) {
  .visibility-xxl-initial {
    visibility: initial !important;
  }
}
@media (min-width: 1660px) {
  .z-xxl-n1 {
    z-index: -1 !important;
  }
}

@media (min-width: 1660px) {
  .z-xxl-0 {
    z-index: 0 !important;
  }
}

@media (min-width: 1660px) {
  .z-xxl-1 {
    z-index: 1 !important;
  }
}

@media (min-width: 1660px) {
  .z-xxl-2 {
    z-index: 2 !important;
  }
}

@media (min-width: 1660px) {
  .z-xxl-3 {
    z-index: 3 !important;
  }
}

@media (min-width: 1900px) {
  .position-xxxl-static {
    position: static !important;
  }
}
@media (min-width: 1900px) {
  .position-xxxl-relative {
    position: relative !important;
  }
}
@media (min-width: 1900px) {
  .position-xxxl-absolute {
    position: absolute !important;
  }
}
@media (min-width: 1900px) {
  .position-xxxl-fixed {
    position: fixed !important;
  }
}
@media (min-width: 1900px) {
  .position-xxxl-sticky {
    position: sticky !important;
  }
}
@media (min-width: 1900px) {
  .transform-xxxl-none {
    transform: none !important;
  }
}
@media (min-width: 1900px) {
  .transform-xxxl-initial {
    transform: initial !important;
  }
}
@media (min-width: 1900px) {
  .visibility-xxxl-none {
    visibility: none !important;
  }
}
@media (min-width: 1900px) {
  .visibility-xxxl-initial {
    visibility: initial !important;
  }
}
@media (min-width: 1900px) {
  .z-xxxl-n1 {
    z-index: -1 !important;
  }
}

@media (min-width: 1900px) {
  .z-xxxl-0 {
    z-index: 0 !important;
  }
}

@media (min-width: 1900px) {
  .z-xxxl-1 {
    z-index: 1 !important;
  }
}

@media (min-width: 1900px) {
  .z-xxxl-2 {
    z-index: 2 !important;
  }
}

@media (min-width: 1900px) {
  .z-xxxl-3 {
    z-index: 3 !important;
  }
}

/* Helper Text */
.text-underline {
  text-decoration: underline !important;
}

.text-gradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.lh-10 {
  line-height: 1 !important;
}

.rounded-10px {
  border-radius: 10px !important;
}

.lh-11 {
  line-height: 1.1 !important;
}

.rounded-11px {
  border-radius: 11px !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

.rounded-12px {
  border-radius: 12px !important;
}

.lh-13 {
  line-height: 1.3 !important;
}

.rounded-13px {
  border-radius: 13px !important;
}

.lh-14 {
  line-height: 1.4 !important;
}

.rounded-14px {
  border-radius: 14px !important;
}

.lh-15 {
  line-height: 1.5 !important;
}

.rounded-15px {
  border-radius: 15px !important;
}

.lh-16 {
  line-height: 1.6 !important;
}

.rounded-16px {
  border-radius: 16px !important;
}

.lh-17 {
  line-height: 1.7 !important;
}

.rounded-17px {
  border-radius: 17px !important;
}

.lh-18 {
  line-height: 1.8 !important;
}

.rounded-18px {
  border-radius: 18px !important;
}

.lh-19 {
  line-height: 1.9 !important;
}

.rounded-19px {
  border-radius: 19px !important;
}

.lh-20 {
  line-height: 2 !important;
}

.rounded-20px {
  border-radius: 20px !important;
}

/* Helper Hover */
.hover-show:hover .hover-show-elm {
  display: block !important;
}

.hover-bg-component:hover {
  background-color: var(--bs-component-bg) !important;
}

.hover-bg-body:hover {
  background-color: var(--bs-body-bg) !important;
}

/* Helper Max Width & Height */
.mw-75 {
  max-width: 75% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-25 {
  max-width: 25% !important;
}

.mh-75 {
  max-height: 75% !important;
}

.mh-50 {
  max-height: 50% !important;
}

.mh-25 {
  max-height: 25% !important;
}

/* Helper Size */
.w-5px {
  width: 5px !important;
}

.w-1px {
  width: 1px !important;
}

.w-10px {
  width: 10px !important;
}

.w-2px {
  width: 2px !important;
}

.w-15px {
  width: 15px !important;
}

.w-3px {
  width: 3px !important;
}

.w-20px {
  width: 20px !important;
}

.w-4px {
  width: 4px !important;
}

.w-25px {
  width: 25px !important;
}

.w-5px {
  width: 5px !important;
}

.w-30px {
  width: 30px !important;
}

.w-6px {
  width: 6px !important;
}

.w-35px {
  width: 35px !important;
}

.w-7px {
  width: 7px !important;
}

.w-40px {
  width: 40px !important;
}

.w-8px {
  width: 8px !important;
}

.w-45px {
  width: 45px !important;
}

.w-9px {
  width: 9px !important;
}

.w-50px {
  width: 50px !important;
}

.w-10px {
  width: 10px !important;
}

.w-55px {
  width: 55px !important;
}

.w-11px {
  width: 11px !important;
}

.w-60px {
  width: 60px !important;
}

.w-12px {
  width: 12px !important;
}

.w-65px {
  width: 65px !important;
}

.w-13px {
  width: 13px !important;
}

.w-70px {
  width: 70px !important;
}

.w-14px {
  width: 14px !important;
}

.w-75px {
  width: 75px !important;
}

.w-15px {
  width: 15px !important;
}

.w-80px {
  width: 80px !important;
}

.w-16px {
  width: 16px !important;
}

.w-85px {
  width: 85px !important;
}

.w-17px {
  width: 17px !important;
}

.w-90px {
  width: 90px !important;
}

.w-18px {
  width: 18px !important;
}

.w-95px {
  width: 95px !important;
}

.w-19px {
  width: 19px !important;
}

.w-100px {
  width: 100px !important;
}

.w-20px {
  width: 20px !important;
}

.w-105px {
  width: 105px !important;
}

.w-21px {
  width: 21px !important;
}

.w-110px {
  width: 110px !important;
}

.w-22px {
  width: 22px !important;
}

.w-115px {
  width: 115px !important;
}

.w-23px {
  width: 23px !important;
}

.w-120px {
  width: 120px !important;
}

.w-24px {
  width: 24px !important;
}

.w-125px {
  width: 125px !important;
}

.w-25px {
  width: 25px !important;
}

.w-130px {
  width: 130px !important;
}

.w-26px {
  width: 26px !important;
}

.w-135px {
  width: 135px !important;
}

.w-27px {
  width: 27px !important;
}

.w-140px {
  width: 140px !important;
}

.w-28px {
  width: 28px !important;
}

.w-145px {
  width: 145px !important;
}

.w-29px {
  width: 29px !important;
}

.w-150px {
  width: 150px !important;
}

.w-30px {
  width: 30px !important;
}

.w-155px {
  width: 155px !important;
}

.w-31px {
  width: 31px !important;
}

.w-160px {
  width: 160px !important;
}

.w-32px {
  width: 32px !important;
}

.w-165px {
  width: 165px !important;
}

.w-33px {
  width: 33px !important;
}

.w-170px {
  width: 170px !important;
}

.w-34px {
  width: 34px !important;
}

.w-175px {
  width: 175px !important;
}

.w-35px {
  width: 35px !important;
}

.w-180px {
  width: 180px !important;
}

.w-36px {
  width: 36px !important;
}

.w-185px {
  width: 185px !important;
}

.w-37px {
  width: 37px !important;
}

.w-190px {
  width: 190px !important;
}

.w-38px {
  width: 38px !important;
}

.w-195px {
  width: 195px !important;
}

.w-39px {
  width: 39px !important;
}

.w-200px {
  width: 200px !important;
}

.w-40px {
  width: 40px !important;
}

.w-250px {
  width: 250px !important;
}

.w-300px {
  width: 300px !important;
}

.w-350px {
  width: 350px !important;
}

.w-400px {
  width: 400px !important;
}

.w-450px {
  width: 450px !important;
}

.w-500px {
  width: 500px !important;
}

.w-550px {
  width: 550px !important;
}

.w-600px {
  width: 600px !important;
}

.h-5px {
  height: 5px !important;
}

.h-1px {
  height: 1px !important;
}

.h-10px {
  height: 10px !important;
}

.h-2px {
  height: 2px !important;
}

.h-15px {
  height: 15px !important;
}

.h-3px {
  height: 3px !important;
}

.h-20px {
  height: 20px !important;
}

.h-4px {
  height: 4px !important;
}

.h-25px {
  height: 25px !important;
}

.h-5px {
  height: 5px !important;
}

.h-30px {
  height: 30px !important;
}

.h-6px {
  height: 6px !important;
}

.h-35px {
  height: 35px !important;
}

.h-7px {
  height: 7px !important;
}

.h-40px {
  height: 40px !important;
}

.h-8px {
  height: 8px !important;
}

.h-45px {
  height: 45px !important;
}

.h-9px {
  height: 9px !important;
}

.h-50px {
  height: 50px !important;
}

.h-10px {
  height: 10px !important;
}

.h-55px {
  height: 55px !important;
}

.h-11px {
  height: 11px !important;
}

.h-60px {
  height: 60px !important;
}

.h-12px {
  height: 12px !important;
}

.h-65px {
  height: 65px !important;
}

.h-13px {
  height: 13px !important;
}

.h-70px {
  height: 70px !important;
}

.h-14px {
  height: 14px !important;
}

.h-75px {
  height: 75px !important;
}

.h-15px {
  height: 15px !important;
}

.h-80px {
  height: 80px !important;
}

.h-16px {
  height: 16px !important;
}

.h-85px {
  height: 85px !important;
}

.h-17px {
  height: 17px !important;
}

.h-90px {
  height: 90px !important;
}

.h-18px {
  height: 18px !important;
}

.h-95px {
  height: 95px !important;
}

.h-19px {
  height: 19px !important;
}

.h-100px {
  height: 100px !important;
}

.h-20px {
  height: 20px !important;
}

.h-105px {
  height: 105px !important;
}

.h-21px {
  height: 21px !important;
}

.h-110px {
  height: 110px !important;
}

.h-22px {
  height: 22px !important;
}

.h-115px {
  height: 115px !important;
}

.h-23px {
  height: 23px !important;
}

.h-120px {
  height: 120px !important;
}

.h-24px {
  height: 24px !important;
}

.h-125px {
  height: 125px !important;
}

.h-25px {
  height: 25px !important;
}

.h-130px {
  height: 130px !important;
}

.h-26px {
  height: 26px !important;
}

.h-135px {
  height: 135px !important;
}

.h-27px {
  height: 27px !important;
}

.h-140px {
  height: 140px !important;
}

.h-28px {
  height: 28px !important;
}

.h-145px {
  height: 145px !important;
}

.h-29px {
  height: 29px !important;
}

.h-150px {
  height: 150px !important;
}

.h-30px {
  height: 30px !important;
}

.h-155px {
  height: 155px !important;
}

.h-31px {
  height: 31px !important;
}

.h-160px {
  height: 160px !important;
}

.h-32px {
  height: 32px !important;
}

.h-165px {
  height: 165px !important;
}

.h-33px {
  height: 33px !important;
}

.h-170px {
  height: 170px !important;
}

.h-34px {
  height: 34px !important;
}

.h-175px {
  height: 175px !important;
}

.h-35px {
  height: 35px !important;
}

.h-180px {
  height: 180px !important;
}

.h-36px {
  height: 36px !important;
}

.h-185px {
  height: 185px !important;
}

.h-37px {
  height: 37px !important;
}

.h-190px {
  height: 190px !important;
}

.h-38px {
  height: 38px !important;
}

.h-195px {
  height: 195px !important;
}

.h-39px {
  height: 39px !important;
}

.h-200px {
  height: 200px !important;
}

.h-40px {
  height: 40px !important;
}

.h-250px {
  height: 250px !important;
}

.h-300px {
  height: 300px !important;
}

.h-350px {
  height: 350px !important;
}

.h-400px {
  height: 400px !important;
}

.h-450px {
  height: 450px !important;
}

.h-500px {
  height: 500px !important;
}

.h-550px {
  height: 550px !important;
}

.h-600px {
  height: 600px !important;
}

/* Helper Color */
.bg-gradient-default {
  background-color: #798e98 !important;
  background-image: linear-gradient(to bottom, #96a6ae, #6c818c) !important;
}

.bg-gradient-from-default {
  --bs-bg-gradient-from: #798e98;
}

.bg-gradient-to-default {
  --bs-bg-gradient-to: #798e98;
}

.hover-bg-default:hover {
  background-color: rgba(var(--bs-default-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-default:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-default {
  --bs-theme: #798e98 !important;
  --bs-theme-rgb: 121, 142, 152 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-default-100 {
  --bs-text-opacity: 1;
  color: rgba(228, 232, 234, var(--bs-text-opacity)) !important;
}

.bg-default-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(228, 232, 234, var(--bs-bg-opacity)) !important;
}

.text-default-600 {
  --bs-text-opacity: 1;
  color: rgba(97, 114, 122, var(--bs-text-opacity)) !important;
}

.bg-default-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(97, 114, 122, var(--bs-bg-opacity)) !important;
}

.text-default-200 {
  --bs-text-opacity: 1;
  color: rgba(201, 210, 214, var(--bs-text-opacity)) !important;
}

.bg-default-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(201, 210, 214, var(--bs-bg-opacity)) !important;
}

.text-default-700 {
  --bs-text-opacity: 1;
  color: rgba(73, 85, 91, var(--bs-text-opacity)) !important;
}

.bg-default-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(73, 85, 91, var(--bs-bg-opacity)) !important;
}

.text-default-300 {
  --bs-text-opacity: 1;
  color: rgba(175, 187, 193, var(--bs-text-opacity)) !important;
}

.bg-default-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(175, 187, 193, var(--bs-bg-opacity)) !important;
}

.text-default-800 {
  --bs-text-opacity: 1;
  color: rgba(48, 57, 61, var(--bs-text-opacity)) !important;
}

.bg-default-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 57, 61, var(--bs-bg-opacity)) !important;
}

.text-default-400 {
  --bs-text-opacity: 1;
  color: rgba(148, 165, 173, var(--bs-text-opacity)) !important;
}

.bg-default-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(148, 165, 173, var(--bs-bg-opacity)) !important;
}

.text-default-900 {
  --bs-text-opacity: 1;
  color: rgba(24, 28, 30, var(--bs-text-opacity)) !important;
}

.bg-default-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 28, 30, var(--bs-bg-opacity)) !important;
}

.bg-gradient-theme {
  background-color: #249d79 !important;
  background-image: linear-gradient(to bottom, #2ec699, #1f8869) !important;
}

.bg-gradient-from-theme {
  --bs-bg-gradient-from: #249d79;
}

.bg-gradient-to-theme {
  --bs-bg-gradient-to: #249d79;
}

.hover-bg-theme:hover {
  background-color: rgba(var(--bs-theme-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-theme:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

.border-theme {
  border-color: var(--bs-theme) !important;
}

:root .theme-theme {
  --bs-theme: #249d79 !important;
  --bs-theme-rgb: 36, 157, 121 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-theme-100 {
  --bs-text-opacity: 1;
  color: rgba(211, 235, 228, var(--bs-text-opacity)) !important;
}

.bg-theme-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(211, 235, 228, var(--bs-bg-opacity)) !important;
}

.text-theme-600 {
  --bs-text-opacity: 1;
  color: rgba(29, 126, 97, var(--bs-text-opacity)) !important;
}

.bg-theme-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(29, 126, 97, var(--bs-bg-opacity)) !important;
}

.text-theme-200 {
  --bs-text-opacity: 1;
  color: rgba(167, 216, 201, var(--bs-text-opacity)) !important;
}

.bg-theme-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(167, 216, 201, var(--bs-bg-opacity)) !important;
}

.text-theme-700 {
  --bs-text-opacity: 1;
  color: rgba(22, 94, 73, var(--bs-text-opacity)) !important;
}

.bg-theme-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(22, 94, 73, var(--bs-bg-opacity)) !important;
}

.text-theme-300 {
  --bs-text-opacity: 1;
  color: rgba(124, 196, 175, var(--bs-text-opacity)) !important;
}

.bg-theme-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(124, 196, 175, var(--bs-bg-opacity)) !important;
}

.text-theme-800 {
  --bs-text-opacity: 1;
  color: rgba(14, 63, 48, var(--bs-text-opacity)) !important;
}

.bg-theme-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(14, 63, 48, var(--bs-bg-opacity)) !important;
}

.text-theme-400 {
  --bs-text-opacity: 1;
  color: rgba(80, 177, 148, var(--bs-text-opacity)) !important;
}

.bg-theme-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(80, 177, 148, var(--bs-bg-opacity)) !important;
}

.text-theme-900 {
  --bs-text-opacity: 1;
  color: rgba(7, 31, 24, var(--bs-text-opacity)) !important;
}

.bg-theme-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(7, 31, 24, var(--bs-bg-opacity)) !important;
}

.bg-gradient-theme-color {
  background-color: #FFFFFF !important;
  background-image: linear-gradient(to bottom, white, #f2f2f2) !important;
}

.bg-gradient-from-theme-color {
  --bs-bg-gradient-from: #FFFFFF;
}

.bg-gradient-to-theme-color {
  --bs-bg-gradient-to: #FFFFFF;
}

.hover-bg-theme-color:hover {
  background-color: rgba(var(--bs-theme-color-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-theme-color:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-theme-color {
  --bs-theme: #FFFFFF !important;
  --bs-theme-rgb: 255, 255, 255 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}

.text-theme-color-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-theme-color-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.text-theme-color-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity)) !important;
}

.bg-theme-color-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity)) !important;
}

.text-theme-color-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-theme-color-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.text-theme-color-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 153, 153, var(--bs-text-opacity)) !important;
}

.bg-theme-color-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--bs-bg-opacity)) !important;
}

.text-theme-color-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-theme-color-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.text-theme-color-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 102, 102, var(--bs-text-opacity)) !important;
}

.bg-theme-color-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--bs-bg-opacity)) !important;
}

.text-theme-color-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-theme-color-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.text-theme-color-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 51, 51, var(--bs-text-opacity)) !important;
}

.bg-theme-color-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--bs-bg-opacity)) !important;
}

.bg-gradient-primary {
  background-color: #0271ff !important;
  background-image: linear-gradient(to bottom, #358eff, #0066e8) !important;
}

.bg-gradient-from-primary {
  --bs-bg-gradient-from: #0271ff;
}

.bg-gradient-to-primary {
  --bs-bg-gradient-to: #0271ff;
}

.hover-bg-primary:hover {
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-primary:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-primary {
  --bs-theme: #0271ff !important;
  --bs-theme-rgb: 2, 113, 255 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-primary-100 {
  --bs-text-opacity: 1;
  color: rgba(204, 227, 255, var(--bs-text-opacity)) !important;
}

.bg-primary-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 227, 255, var(--bs-bg-opacity)) !important;
}

.text-primary-600 {
  --bs-text-opacity: 1;
  color: rgba(2, 90, 204, var(--bs-text-opacity)) !important;
}

.bg-primary-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(2, 90, 204, var(--bs-bg-opacity)) !important;
}

.text-primary-200 {
  --bs-text-opacity: 1;
  color: rgba(154, 198, 255, var(--bs-text-opacity)) !important;
}

.bg-primary-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(154, 198, 255, var(--bs-bg-opacity)) !important;
}

.text-primary-700 {
  --bs-text-opacity: 1;
  color: rgba(1, 68, 153, var(--bs-text-opacity)) !important;
}

.bg-primary-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(1, 68, 153, var(--bs-bg-opacity)) !important;
}

.text-primary-300 {
  --bs-text-opacity: 1;
  color: rgba(103, 170, 255, var(--bs-text-opacity)) !important;
}

.bg-primary-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(103, 170, 255, var(--bs-bg-opacity)) !important;
}

.text-primary-800 {
  --bs-text-opacity: 1;
  color: rgba(1, 45, 102, var(--bs-text-opacity)) !important;
}

.bg-primary-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(1, 45, 102, var(--bs-bg-opacity)) !important;
}

.text-primary-400 {
  --bs-text-opacity: 1;
  color: rgba(53, 141, 255, var(--bs-text-opacity)) !important;
}

.bg-primary-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(53, 141, 255, var(--bs-bg-opacity)) !important;
}

.text-primary-900 {
  --bs-text-opacity: 1;
  color: rgba(0, 23, 51, var(--bs-text-opacity)) !important;
}

.bg-primary-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 23, 51, var(--bs-bg-opacity)) !important;
}

.bg-gradient-secondary {
  background-color: #798e98 !important;
  background-image: linear-gradient(to bottom, #96a6ae, #6c818c) !important;
}

.bg-gradient-from-secondary {
  --bs-bg-gradient-from: #798e98;
}

.bg-gradient-to-secondary {
  --bs-bg-gradient-to: #798e98;
}

.hover-bg-secondary:hover {
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-secondary:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-secondary {
  --bs-theme: #798e98 !important;
  --bs-theme-rgb: 121, 142, 152 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-secondary-100 {
  --bs-text-opacity: 1;
  color: rgba(228, 232, 234, var(--bs-text-opacity)) !important;
}

.bg-secondary-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(228, 232, 234, var(--bs-bg-opacity)) !important;
}

.text-secondary-600 {
  --bs-text-opacity: 1;
  color: rgba(97, 114, 122, var(--bs-text-opacity)) !important;
}

.bg-secondary-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(97, 114, 122, var(--bs-bg-opacity)) !important;
}

.text-secondary-200 {
  --bs-text-opacity: 1;
  color: rgba(201, 210, 214, var(--bs-text-opacity)) !important;
}

.bg-secondary-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(201, 210, 214, var(--bs-bg-opacity)) !important;
}

.text-secondary-700 {
  --bs-text-opacity: 1;
  color: rgba(73, 85, 91, var(--bs-text-opacity)) !important;
}

.bg-secondary-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(73, 85, 91, var(--bs-bg-opacity)) !important;
}

.text-secondary-300 {
  --bs-text-opacity: 1;
  color: rgba(175, 187, 193, var(--bs-text-opacity)) !important;
}

.bg-secondary-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(175, 187, 193, var(--bs-bg-opacity)) !important;
}

.text-secondary-800 {
  --bs-text-opacity: 1;
  color: rgba(48, 57, 61, var(--bs-text-opacity)) !important;
}

.bg-secondary-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 57, 61, var(--bs-bg-opacity)) !important;
}

.text-secondary-400 {
  --bs-text-opacity: 1;
  color: rgba(148, 165, 173, var(--bs-text-opacity)) !important;
}

.bg-secondary-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(148, 165, 173, var(--bs-bg-opacity)) !important;
}

.text-secondary-900 {
  --bs-text-opacity: 1;
  color: rgba(24, 28, 30, var(--bs-text-opacity)) !important;
}

.bg-secondary-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 28, 30, var(--bs-bg-opacity)) !important;
}

.bg-gradient-warning {
  background-color: #ff9f0c !important;
  background-image: linear-gradient(to bottom, #ffb33f, #f29200) !important;
}

.bg-gradient-from-warning {
  --bs-bg-gradient-from: #ff9f0c;
}

.bg-gradient-to-warning {
  --bs-bg-gradient-to: #ff9f0c;
}

.hover-bg-warning:hover {
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-warning:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-warning {
  --bs-theme: #ff9f0c !important;
  --bs-theme-rgb: 255, 159, 12 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}

.text-warning-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 236, 206, var(--bs-text-opacity)) !important;
}

.bg-warning-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 236, 206, var(--bs-bg-opacity)) !important;
}

.text-warning-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 127, 10, var(--bs-text-opacity)) !important;
}

.bg-warning-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 127, 10, var(--bs-bg-opacity)) !important;
}

.text-warning-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 217, 158, var(--bs-text-opacity)) !important;
}

.bg-warning-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 217, 158, var(--bs-bg-opacity)) !important;
}

.text-warning-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 95, 7, var(--bs-text-opacity)) !important;
}

.bg-warning-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 95, 7, var(--bs-bg-opacity)) !important;
}

.text-warning-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 197, 109, var(--bs-text-opacity)) !important;
}

.bg-warning-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 197, 109, var(--bs-bg-opacity)) !important;
}

.text-warning-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 64, 5, var(--bs-text-opacity)) !important;
}

.bg-warning-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 64, 5, var(--bs-bg-opacity)) !important;
}

.text-warning-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 178, 61, var(--bs-text-opacity)) !important;
}

.bg-warning-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 178, 61, var(--bs-bg-opacity)) !important;
}

.text-warning-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 32, 2, var(--bs-text-opacity)) !important;
}

.bg-warning-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 32, 2, var(--bs-bg-opacity)) !important;
}

.bg-gradient-danger {
  background-color: #e00000 !important;
  background-image: linear-gradient(to bottom, #ff1414, #c70000) !important;
}

.bg-gradient-from-danger {
  --bs-bg-gradient-from: #e00000;
}

.bg-gradient-to-danger {
  --bs-bg-gradient-to: #e00000;
}

.hover-bg-danger:hover {
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-danger:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-danger {
  --bs-theme: #e00000 !important;
  --bs-theme-rgb: 224, 0, 0 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-danger-100 {
  --bs-text-opacity: 1;
  color: rgba(249, 204, 204, var(--bs-text-opacity)) !important;
}

.bg-danger-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(249, 204, 204, var(--bs-bg-opacity)) !important;
}

.text-danger-600 {
  --bs-text-opacity: 1;
  color: rgba(179, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-danger-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(179, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-danger-200 {
  --bs-text-opacity: 1;
  color: rgba(243, 153, 153, var(--bs-text-opacity)) !important;
}

.bg-danger-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(243, 153, 153, var(--bs-bg-opacity)) !important;
}

.text-danger-700 {
  --bs-text-opacity: 1;
  color: rgba(134, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-danger-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-danger-300 {
  --bs-text-opacity: 1;
  color: rgba(236, 102, 102, var(--bs-text-opacity)) !important;
}

.bg-danger-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(236, 102, 102, var(--bs-bg-opacity)) !important;
}

.text-danger-800 {
  --bs-text-opacity: 1;
  color: rgba(90, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-danger-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(90, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-danger-400 {
  --bs-text-opacity: 1;
  color: rgba(230, 51, 51, var(--bs-text-opacity)) !important;
}

.bg-danger-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(230, 51, 51, var(--bs-bg-opacity)) !important;
}

.text-danger-900 {
  --bs-text-opacity: 1;
  color: rgba(45, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-danger-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(45, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-gradient-success {
  background-color: #249d79 !important;
  background-image: linear-gradient(to bottom, #2ec699, #1f8869) !important;
}

.bg-gradient-from-success {
  --bs-bg-gradient-from: #249d79;
}

.bg-gradient-to-success {
  --bs-bg-gradient-to: #249d79;
}

.hover-bg-success:hover {
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-success:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-success {
  --bs-theme: #249d79 !important;
  --bs-theme-rgb: 36, 157, 121 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-success-100 {
  --bs-text-opacity: 1;
  color: rgba(211, 235, 228, var(--bs-text-opacity)) !important;
}

.bg-success-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(211, 235, 228, var(--bs-bg-opacity)) !important;
}

.text-success-600 {
  --bs-text-opacity: 1;
  color: rgba(29, 126, 97, var(--bs-text-opacity)) !important;
}

.bg-success-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(29, 126, 97, var(--bs-bg-opacity)) !important;
}

.text-success-200 {
  --bs-text-opacity: 1;
  color: rgba(167, 216, 201, var(--bs-text-opacity)) !important;
}

.bg-success-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(167, 216, 201, var(--bs-bg-opacity)) !important;
}

.text-success-700 {
  --bs-text-opacity: 1;
  color: rgba(22, 94, 73, var(--bs-text-opacity)) !important;
}

.bg-success-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(22, 94, 73, var(--bs-bg-opacity)) !important;
}

.text-success-300 {
  --bs-text-opacity: 1;
  color: rgba(124, 196, 175, var(--bs-text-opacity)) !important;
}

.bg-success-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(124, 196, 175, var(--bs-bg-opacity)) !important;
}

.text-success-800 {
  --bs-text-opacity: 1;
  color: rgba(14, 63, 48, var(--bs-text-opacity)) !important;
}

.bg-success-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(14, 63, 48, var(--bs-bg-opacity)) !important;
}

.text-success-400 {
  --bs-text-opacity: 1;
  color: rgba(80, 177, 148, var(--bs-text-opacity)) !important;
}

.bg-success-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(80, 177, 148, var(--bs-bg-opacity)) !important;
}

.text-success-900 {
  --bs-text-opacity: 1;
  color: rgba(7, 31, 24, var(--bs-text-opacity)) !important;
}

.bg-success-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(7, 31, 24, var(--bs-bg-opacity)) !important;
}

.bg-gradient-inverse {
  background-color: #000000 !important;
  background-image: linear-gradient(to bottom, #1a1a1a, black) !important;
}

.bg-gradient-from-inverse {
  --bs-bg-gradient-from: #000000;
}

.bg-gradient-to-inverse {
  --bs-bg-gradient-to: #000000;
}

.hover-bg-inverse:hover {
  background-color: rgba(var(--bs-inverse-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-inverse:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-inverse {
  --bs-theme: #000000 !important;
  --bs-theme-rgb: 0, 0, 0 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-inverse-100 {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity)) !important;
}

.bg-inverse-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity)) !important;
}

.text-inverse-600 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-inverse-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-inverse-200 {
  --bs-text-opacity: 1;
  color: rgba(153, 153, 153, var(--bs-text-opacity)) !important;
}

.bg-inverse-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--bs-bg-opacity)) !important;
}

.text-inverse-700 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-inverse-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-inverse-300 {
  --bs-text-opacity: 1;
  color: rgba(102, 102, 102, var(--bs-text-opacity)) !important;
}

.bg-inverse-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--bs-bg-opacity)) !important;
}

.text-inverse-800 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-inverse-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-inverse-400 {
  --bs-text-opacity: 1;
  color: rgba(51, 51, 51, var(--bs-text-opacity)) !important;
}

.bg-inverse-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--bs-bg-opacity)) !important;
}

.text-inverse-900 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-inverse-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-gradient-info {
  background-color: #009be3 !important;
  background-image: linear-gradient(to bottom, #17b5ff, #008aca) !important;
}

.bg-gradient-from-info {
  --bs-bg-gradient-from: #009be3;
}

.bg-gradient-to-info {
  --bs-bg-gradient-to: #009be3;
}

.hover-bg-info:hover {
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-info:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-info {
  --bs-theme: #009be3 !important;
  --bs-theme-rgb: 0, 155, 227 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-info-100 {
  --bs-text-opacity: 1;
  color: rgba(204, 235, 249, var(--bs-text-opacity)) !important;
}

.bg-info-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 235, 249, var(--bs-bg-opacity)) !important;
}

.text-info-600 {
  --bs-text-opacity: 1;
  color: rgba(0, 124, 182, var(--bs-text-opacity)) !important;
}

.bg-info-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 124, 182, var(--bs-bg-opacity)) !important;
}

.text-info-200 {
  --bs-text-opacity: 1;
  color: rgba(153, 215, 244, var(--bs-text-opacity)) !important;
}

.bg-info-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 215, 244, var(--bs-bg-opacity)) !important;
}

.text-info-700 {
  --bs-text-opacity: 1;
  color: rgba(0, 93, 136, var(--bs-text-opacity)) !important;
}

.bg-info-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 93, 136, var(--bs-bg-opacity)) !important;
}

.text-info-300 {
  --bs-text-opacity: 1;
  color: rgba(102, 195, 238, var(--bs-text-opacity)) !important;
}

.bg-info-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 195, 238, var(--bs-bg-opacity)) !important;
}

.text-info-800 {
  --bs-text-opacity: 1;
  color: rgba(0, 62, 91, var(--bs-text-opacity)) !important;
}

.bg-info-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 62, 91, var(--bs-bg-opacity)) !important;
}

.text-info-400 {
  --bs-text-opacity: 1;
  color: rgba(51, 175, 233, var(--bs-text-opacity)) !important;
}

.bg-info-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 175, 233, var(--bs-bg-opacity)) !important;
}

.text-info-900 {
  --bs-text-opacity: 1;
  color: rgba(0, 31, 45, var(--bs-text-opacity)) !important;
}

.bg-info-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 31, 45, var(--bs-bg-opacity)) !important;
}

.bg-gradient-dark {
  background-color: #35434a !important;
  background-image: linear-gradient(to bottom, #4a5e68, #2a363b) !important;
}

.bg-gradient-from-dark {
  --bs-bg-gradient-from: #35434a;
}

.bg-gradient-to-dark {
  --bs-bg-gradient-to: #35434a;
}

.hover-bg-dark:hover {
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-dark:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-dark {
  --bs-theme: #35434a !important;
  --bs-theme-rgb: 53, 67, 74 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-dark-100 {
  --bs-text-opacity: 1;
  color: rgba(215, 217, 219, var(--bs-text-opacity)) !important;
}

.bg-dark-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(215, 217, 219, var(--bs-bg-opacity)) !important;
}

.text-dark-600 {
  --bs-text-opacity: 1;
  color: rgba(42, 54, 59, var(--bs-text-opacity)) !important;
}

.bg-dark-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(42, 54, 59, var(--bs-bg-opacity)) !important;
}

.text-dark-200 {
  --bs-text-opacity: 1;
  color: rgba(174, 180, 183, var(--bs-text-opacity)) !important;
}

.bg-dark-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(174, 180, 183, var(--bs-bg-opacity)) !important;
}

.text-dark-700 {
  --bs-text-opacity: 1;
  color: rgba(32, 40, 44, var(--bs-text-opacity)) !important;
}

.bg-dark-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(32, 40, 44, var(--bs-bg-opacity)) !important;
}

.text-dark-300 {
  --bs-text-opacity: 1;
  color: rgba(134, 142, 146, var(--bs-text-opacity)) !important;
}

.bg-dark-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 142, 146, var(--bs-bg-opacity)) !important;
}

.text-dark-800 {
  --bs-text-opacity: 1;
  color: rgba(21, 27, 30, var(--bs-text-opacity)) !important;
}

.bg-dark-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(21, 27, 30, var(--bs-bg-opacity)) !important;
}

.text-dark-400 {
  --bs-text-opacity: 1;
  color: rgba(93, 105, 110, var(--bs-text-opacity)) !important;
}

.bg-dark-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(93, 105, 110, var(--bs-bg-opacity)) !important;
}

.text-dark-900 {
  --bs-text-opacity: 1;
  color: rgba(11, 13, 15, var(--bs-text-opacity)) !important;
}

.bg-dark-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(11, 13, 15, var(--bs-bg-opacity)) !important;
}

.bg-gradient-black {
  background-color: #000000 !important;
  background-image: linear-gradient(to bottom, #1a1a1a, black) !important;
}

.bg-gradient-from-black {
  --bs-bg-gradient-from: #000000;
}

.bg-gradient-to-black {
  --bs-bg-gradient-to: #000000;
}

.hover-bg-black:hover {
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-black:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-black {
  --bs-theme: #000000 !important;
  --bs-theme-rgb: 0, 0, 0 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-black-100 {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity)) !important;
}

.bg-black-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity)) !important;
}

.text-black-600 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-black-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-black-200 {
  --bs-text-opacity: 1;
  color: rgba(153, 153, 153, var(--bs-text-opacity)) !important;
}

.bg-black-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--bs-bg-opacity)) !important;
}

.text-black-700 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-black-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-black-300 {
  --bs-text-opacity: 1;
  color: rgba(102, 102, 102, var(--bs-text-opacity)) !important;
}

.bg-black-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--bs-bg-opacity)) !important;
}

.text-black-800 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-black-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-black-400 {
  --bs-text-opacity: 1;
  color: rgba(51, 51, 51, var(--bs-text-opacity)) !important;
}

.bg-black-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--bs-bg-opacity)) !important;
}

.text-black-900 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-black-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-gradient-white {
  background-color: #FFFFFF !important;
  background-image: linear-gradient(to bottom, white, #f2f2f2) !important;
}

.bg-gradient-from-white {
  --bs-bg-gradient-from: #FFFFFF;
}

.bg-gradient-to-white {
  --bs-bg-gradient-to: #FFFFFF;
}

.hover-bg-white:hover {
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-white:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-white {
  --bs-theme: #FFFFFF !important;
  --bs-theme-rgb: 255, 255, 255 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}

.text-white-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-white-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.text-white-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity)) !important;
}

.bg-white-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity)) !important;
}

.text-white-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-white-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.text-white-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 153, 153, var(--bs-text-opacity)) !important;
}

.bg-white-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--bs-bg-opacity)) !important;
}

.text-white-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-white-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.text-white-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 102, 102, var(--bs-text-opacity)) !important;
}

.bg-white-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--bs-bg-opacity)) !important;
}

.text-white-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-white-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.text-white-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 51, 51, var(--bs-text-opacity)) !important;
}

.bg-white-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--bs-bg-opacity)) !important;
}

.bg-gradient-purple {
  background-color: #b90dff !important;
  background-image: linear-gradient(to bottom, #c840ff, #ac00f3) !important;
}

.bg-gradient-from-purple {
  --bs-bg-gradient-from: #b90dff;
}

.bg-gradient-to-purple {
  --bs-bg-gradient-to: #b90dff;
}

.hover-bg-purple:hover {
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-purple:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-purple {
  --bs-theme: #b90dff !important;
  --bs-theme-rgb: 185, 13, 255 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-purple-100 {
  --bs-text-opacity: 1;
  color: rgba(241, 207, 255, var(--bs-text-opacity)) !important;
}

.bg-purple-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(241, 207, 255, var(--bs-bg-opacity)) !important;
}

.text-purple-600 {
  --bs-text-opacity: 1;
  color: rgba(148, 10, 204, var(--bs-text-opacity)) !important;
}

.bg-purple-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(148, 10, 204, var(--bs-bg-opacity)) !important;
}

.text-purple-200 {
  --bs-text-opacity: 1;
  color: rgba(227, 158, 255, var(--bs-text-opacity)) !important;
}

.bg-purple-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(227, 158, 255, var(--bs-bg-opacity)) !important;
}

.text-purple-700 {
  --bs-text-opacity: 1;
  color: rgba(111, 8, 153, var(--bs-text-opacity)) !important;
}

.bg-purple-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(111, 8, 153, var(--bs-bg-opacity)) !important;
}

.text-purple-300 {
  --bs-text-opacity: 1;
  color: rgba(213, 110, 255, var(--bs-text-opacity)) !important;
}

.bg-purple-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(213, 110, 255, var(--bs-bg-opacity)) !important;
}

.text-purple-800 {
  --bs-text-opacity: 1;
  color: rgba(74, 5, 102, var(--bs-text-opacity)) !important;
}

.bg-purple-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(74, 5, 102, var(--bs-bg-opacity)) !important;
}

.text-purple-400 {
  --bs-text-opacity: 1;
  color: rgba(199, 61, 255, var(--bs-text-opacity)) !important;
}

.bg-purple-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(199, 61, 255, var(--bs-bg-opacity)) !important;
}

.text-purple-900 {
  --bs-text-opacity: 1;
  color: rgba(37, 3, 51, var(--bs-text-opacity)) !important;
}

.bg-purple-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(37, 3, 51, var(--bs-bg-opacity)) !important;
}

.bg-gradient-indigo {
  background-color: #5c25ff !important;
  background-image: linear-gradient(to bottom, #8258ff, #490cff) !important;
}

.bg-gradient-from-indigo {
  --bs-bg-gradient-from: #5c25ff;
}

.bg-gradient-to-indigo {
  --bs-bg-gradient-to: #5c25ff;
}

.hover-bg-indigo:hover {
  background-color: rgba(var(--bs-indigo-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-indigo:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-indigo {
  --bs-theme: #5c25ff !important;
  --bs-theme-rgb: 92, 37, 255 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-indigo-100 {
  --bs-text-opacity: 1;
  color: rgba(222, 211, 255, var(--bs-text-opacity)) !important;
}

.bg-indigo-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(222, 211, 255, var(--bs-bg-opacity)) !important;
}

.text-indigo-600 {
  --bs-text-opacity: 1;
  color: rgba(74, 30, 204, var(--bs-text-opacity)) !important;
}

.bg-indigo-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(74, 30, 204, var(--bs-bg-opacity)) !important;
}

.text-indigo-200 {
  --bs-text-opacity: 1;
  color: rgba(190, 168, 255, var(--bs-text-opacity)) !important;
}

.bg-indigo-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(190, 168, 255, var(--bs-bg-opacity)) !important;
}

.text-indigo-700 {
  --bs-text-opacity: 1;
  color: rgba(55, 22, 153, var(--bs-text-opacity)) !important;
}

.bg-indigo-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(55, 22, 153, var(--bs-bg-opacity)) !important;
}

.text-indigo-300 {
  --bs-text-opacity: 1;
  color: rgba(157, 124, 255, var(--bs-text-opacity)) !important;
}

.bg-indigo-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(157, 124, 255, var(--bs-bg-opacity)) !important;
}

.text-indigo-800 {
  --bs-text-opacity: 1;
  color: rgba(37, 15, 102, var(--bs-text-opacity)) !important;
}

.bg-indigo-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(37, 15, 102, var(--bs-bg-opacity)) !important;
}

.text-indigo-400 {
  --bs-text-opacity: 1;
  color: rgba(125, 81, 255, var(--bs-text-opacity)) !important;
}

.bg-indigo-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(125, 81, 255, var(--bs-bg-opacity)) !important;
}

.text-indigo-900 {
  --bs-text-opacity: 1;
  color: rgba(18, 7, 51, var(--bs-text-opacity)) !important;
}

.bg-indigo-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(18, 7, 51, var(--bs-bg-opacity)) !important;
}

.bg-gradient-red {
  background-color: #e00000 !important;
  background-image: linear-gradient(to bottom, #ff1414, #c70000) !important;
}

.bg-gradient-from-red {
  --bs-bg-gradient-from: #e00000;
}

.bg-gradient-to-red {
  --bs-bg-gradient-to: #e00000;
}

.hover-bg-red:hover {
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-red:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-red {
  --bs-theme: #e00000 !important;
  --bs-theme-rgb: 224, 0, 0 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-red-100 {
  --bs-text-opacity: 1;
  color: rgba(249, 204, 204, var(--bs-text-opacity)) !important;
}

.bg-red-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(249, 204, 204, var(--bs-bg-opacity)) !important;
}

.text-red-600 {
  --bs-text-opacity: 1;
  color: rgba(179, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-red-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(179, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-red-200 {
  --bs-text-opacity: 1;
  color: rgba(243, 153, 153, var(--bs-text-opacity)) !important;
}

.bg-red-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(243, 153, 153, var(--bs-bg-opacity)) !important;
}

.text-red-700 {
  --bs-text-opacity: 1;
  color: rgba(134, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-red-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-red-300 {
  --bs-text-opacity: 1;
  color: rgba(236, 102, 102, var(--bs-text-opacity)) !important;
}

.bg-red-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(236, 102, 102, var(--bs-bg-opacity)) !important;
}

.text-red-800 {
  --bs-text-opacity: 1;
  color: rgba(90, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-red-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(90, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-red-400 {
  --bs-text-opacity: 1;
  color: rgba(230, 51, 51, var(--bs-text-opacity)) !important;
}

.bg-red-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(230, 51, 51, var(--bs-bg-opacity)) !important;
}

.text-red-900 {
  --bs-text-opacity: 1;
  color: rgba(45, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-red-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(45, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-gradient-pink {
  background-color: #e61079 !important;
  background-image: linear-gradient(to bottom, #f13893, #ce0e6c) !important;
}

.bg-gradient-from-pink {
  --bs-bg-gradient-from: #e61079;
}

.bg-gradient-to-pink {
  --bs-bg-gradient-to: #e61079;
}

.hover-bg-pink:hover {
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-pink:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-pink {
  --bs-theme: #e61079 !important;
  --bs-theme-rgb: 230, 16, 121 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-pink-100 {
  --bs-text-opacity: 1;
  color: rgba(250, 207, 228, var(--bs-text-opacity)) !important;
}

.bg-pink-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(250, 207, 228, var(--bs-bg-opacity)) !important;
}

.text-pink-600 {
  --bs-text-opacity: 1;
  color: rgba(184, 13, 97, var(--bs-text-opacity)) !important;
}

.bg-pink-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(184, 13, 97, var(--bs-bg-opacity)) !important;
}

.text-pink-200 {
  --bs-text-opacity: 1;
  color: rgba(245, 159, 201, var(--bs-text-opacity)) !important;
}

.bg-pink-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(245, 159, 201, var(--bs-bg-opacity)) !important;
}

.text-pink-700 {
  --bs-text-opacity: 1;
  color: rgba(138, 10, 73, var(--bs-text-opacity)) !important;
}

.bg-pink-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(138, 10, 73, var(--bs-bg-opacity)) !important;
}

.text-pink-300 {
  --bs-text-opacity: 1;
  color: rgba(240, 112, 175, var(--bs-text-opacity)) !important;
}

.bg-pink-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(240, 112, 175, var(--bs-bg-opacity)) !important;
}

.text-pink-800 {
  --bs-text-opacity: 1;
  color: rgba(92, 6, 48, var(--bs-text-opacity)) !important;
}

.bg-pink-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(92, 6, 48, var(--bs-bg-opacity)) !important;
}

.text-pink-400 {
  --bs-text-opacity: 1;
  color: rgba(235, 64, 148, var(--bs-text-opacity)) !important;
}

.bg-pink-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(235, 64, 148, var(--bs-bg-opacity)) !important;
}

.text-pink-900 {
  --bs-text-opacity: 1;
  color: rgba(46, 3, 24, var(--bs-text-opacity)) !important;
}

.bg-pink-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(46, 3, 24, var(--bs-bg-opacity)) !important;
}

.bg-gradient-green {
  background-color: #148922 !important;
  background-image: linear-gradient(to bottom, #1ab62d, #11731c) !important;
}

.bg-gradient-from-green {
  --bs-bg-gradient-from: #148922;
}

.bg-gradient-to-green {
  --bs-bg-gradient-to: #148922;
}

.hover-bg-green:hover {
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-green:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-green {
  --bs-theme: #148922 !important;
  --bs-theme-rgb: 20, 137, 34 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-green-100 {
  --bs-text-opacity: 1;
  color: rgba(208, 231, 211, var(--bs-text-opacity)) !important;
}

.bg-green-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(208, 231, 211, var(--bs-bg-opacity)) !important;
}

.text-green-600 {
  --bs-text-opacity: 1;
  color: rgba(16, 110, 27, var(--bs-text-opacity)) !important;
}

.bg-green-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 110, 27, var(--bs-bg-opacity)) !important;
}

.text-green-200 {
  --bs-text-opacity: 1;
  color: rgba(161, 208, 167, var(--bs-text-opacity)) !important;
}

.bg-green-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(161, 208, 167, var(--bs-bg-opacity)) !important;
}

.text-green-700 {
  --bs-text-opacity: 1;
  color: rgba(12, 82, 20, var(--bs-text-opacity)) !important;
}

.bg-green-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(12, 82, 20, var(--bs-bg-opacity)) !important;
}

.text-green-300 {
  --bs-text-opacity: 1;
  color: rgba(114, 184, 122, var(--bs-text-opacity)) !important;
}

.bg-green-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(114, 184, 122, var(--bs-bg-opacity)) !important;
}

.text-green-800 {
  --bs-text-opacity: 1;
  color: rgba(8, 55, 14, var(--bs-text-opacity)) !important;
}

.bg-green-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(8, 55, 14, var(--bs-bg-opacity)) !important;
}

.text-green-400 {
  --bs-text-opacity: 1;
  color: rgba(67, 161, 78, var(--bs-text-opacity)) !important;
}

.bg-green-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(67, 161, 78, var(--bs-bg-opacity)) !important;
}

.text-green-900 {
  --bs-text-opacity: 1;
  color: rgba(4, 27, 7, var(--bs-text-opacity)) !important;
}

.bg-green-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(4, 27, 7, var(--bs-bg-opacity)) !important;
}

.bg-gradient-yellow {
  background-color: #f9e80d !important;
  background-image: linear-gradient(to bottom, #faed3f, #e7d706) !important;
}

.bg-gradient-from-yellow {
  --bs-bg-gradient-from: #f9e80d;
}

.bg-gradient-to-yellow {
  --bs-bg-gradient-to: #f9e80d;
}

.hover-bg-yellow:hover {
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-yellow:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-yellow {
  --bs-theme: #f9e80d !important;
  --bs-theme-rgb: 249, 232, 13 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}

.text-yellow-100 {
  --bs-text-opacity: 1;
  color: rgba(254, 250, 207, var(--bs-text-opacity)) !important;
}

.bg-yellow-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(254, 250, 207, var(--bs-bg-opacity)) !important;
}

.text-yellow-600 {
  --bs-text-opacity: 1;
  color: rgba(199, 186, 10, var(--bs-text-opacity)) !important;
}

.bg-yellow-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(199, 186, 10, var(--bs-bg-opacity)) !important;
}

.text-yellow-200 {
  --bs-text-opacity: 1;
  color: rgba(253, 246, 158, var(--bs-text-opacity)) !important;
}

.bg-yellow-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(253, 246, 158, var(--bs-bg-opacity)) !important;
}

.text-yellow-700 {
  --bs-text-opacity: 1;
  color: rgba(149, 139, 8, var(--bs-text-opacity)) !important;
}

.bg-yellow-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(149, 139, 8, var(--bs-bg-opacity)) !important;
}

.text-yellow-300 {
  --bs-text-opacity: 1;
  color: rgba(251, 241, 110, var(--bs-text-opacity)) !important;
}

.bg-yellow-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(251, 241, 110, var(--bs-bg-opacity)) !important;
}

.text-yellow-800 {
  --bs-text-opacity: 1;
  color: rgba(100, 93, 5, var(--bs-text-opacity)) !important;
}

.bg-yellow-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(100, 93, 5, var(--bs-bg-opacity)) !important;
}

.text-yellow-400 {
  --bs-text-opacity: 1;
  color: rgba(250, 237, 61, var(--bs-text-opacity)) !important;
}

.bg-yellow-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(250, 237, 61, var(--bs-bg-opacity)) !important;
}

.text-yellow-900 {
  --bs-text-opacity: 1;
  color: rgba(50, 46, 3, var(--bs-text-opacity)) !important;
}

.bg-yellow-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(50, 46, 3, var(--bs-bg-opacity)) !important;
}

.bg-gradient-teal {
  background-color: #249d79 !important;
  background-image: linear-gradient(to bottom, #2ec699, #1f8869) !important;
}

.bg-gradient-from-teal {
  --bs-bg-gradient-from: #249d79;
}

.bg-gradient-to-teal {
  --bs-bg-gradient-to: #249d79;
}

.hover-bg-teal:hover {
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-teal:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-teal {
  --bs-theme: #249d79 !important;
  --bs-theme-rgb: 36, 157, 121 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-teal-100 {
  --bs-text-opacity: 1;
  color: rgba(211, 235, 228, var(--bs-text-opacity)) !important;
}

.bg-teal-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(211, 235, 228, var(--bs-bg-opacity)) !important;
}

.text-teal-600 {
  --bs-text-opacity: 1;
  color: rgba(29, 126, 97, var(--bs-text-opacity)) !important;
}

.bg-teal-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(29, 126, 97, var(--bs-bg-opacity)) !important;
}

.text-teal-200 {
  --bs-text-opacity: 1;
  color: rgba(167, 216, 201, var(--bs-text-opacity)) !important;
}

.bg-teal-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(167, 216, 201, var(--bs-bg-opacity)) !important;
}

.text-teal-700 {
  --bs-text-opacity: 1;
  color: rgba(22, 94, 73, var(--bs-text-opacity)) !important;
}

.bg-teal-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(22, 94, 73, var(--bs-bg-opacity)) !important;
}

.text-teal-300 {
  --bs-text-opacity: 1;
  color: rgba(124, 196, 175, var(--bs-text-opacity)) !important;
}

.bg-teal-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(124, 196, 175, var(--bs-bg-opacity)) !important;
}

.text-teal-800 {
  --bs-text-opacity: 1;
  color: rgba(14, 63, 48, var(--bs-text-opacity)) !important;
}

.bg-teal-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(14, 63, 48, var(--bs-bg-opacity)) !important;
}

.text-teal-400 {
  --bs-text-opacity: 1;
  color: rgba(80, 177, 148, var(--bs-text-opacity)) !important;
}

.bg-teal-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(80, 177, 148, var(--bs-bg-opacity)) !important;
}

.text-teal-900 {
  --bs-text-opacity: 1;
  color: rgba(7, 31, 24, var(--bs-text-opacity)) !important;
}

.bg-teal-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(7, 31, 24, var(--bs-bg-opacity)) !important;
}

.bg-gradient-muted {
  background-color: #617a86 !important;
  background-image: linear-gradient(to bottom, #7b939f, #566d77) !important;
}

.bg-gradient-from-muted {
  --bs-bg-gradient-from: #617a86;
}

.bg-gradient-to-muted {
  --bs-bg-gradient-to: #617a86;
}

.hover-bg-muted:hover {
  background-color: rgba(var(--bs-muted-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-muted:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-muted {
  --bs-theme: #617a86 !important;
  --bs-theme-rgb: 97, 122, 134 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-muted-100 {
  --bs-text-opacity: 1;
  color: rgba(223, 228, 231, var(--bs-text-opacity)) !important;
}

.bg-muted-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(223, 228, 231, var(--bs-bg-opacity)) !important;
}

.text-muted-600 {
  --bs-text-opacity: 1;
  color: rgba(78, 98, 107, var(--bs-text-opacity)) !important;
}

.bg-muted-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(78, 98, 107, var(--bs-bg-opacity)) !important;
}

.text-muted-200 {
  --bs-text-opacity: 1;
  color: rgba(192, 202, 207, var(--bs-text-opacity)) !important;
}

.bg-muted-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(192, 202, 207, var(--bs-bg-opacity)) !important;
}

.text-muted-700 {
  --bs-text-opacity: 1;
  color: rgba(58, 73, 80, var(--bs-text-opacity)) !important;
}

.bg-muted-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(58, 73, 80, var(--bs-bg-opacity)) !important;
}

.text-muted-300 {
  --bs-text-opacity: 1;
  color: rgba(160, 175, 182, var(--bs-text-opacity)) !important;
}

.bg-muted-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(160, 175, 182, var(--bs-bg-opacity)) !important;
}

.text-muted-800 {
  --bs-text-opacity: 1;
  color: rgba(39, 49, 54, var(--bs-text-opacity)) !important;
}

.bg-muted-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(39, 49, 54, var(--bs-bg-opacity)) !important;
}

.text-muted-400 {
  --bs-text-opacity: 1;
  color: rgba(129, 149, 158, var(--bs-text-opacity)) !important;
}

.bg-muted-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(129, 149, 158, var(--bs-bg-opacity)) !important;
}

.text-muted-900 {
  --bs-text-opacity: 1;
  color: rgba(19, 24, 27, var(--bs-text-opacity)) !important;
}

.bg-muted-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(19, 24, 27, var(--bs-bg-opacity)) !important;
}

.bg-gradient-light {
  background-color: #c0cacf !important;
  background-image: linear-gradient(to bottom, #dde2e5, #b2bec4) !important;
}

.bg-gradient-from-light {
  --bs-bg-gradient-from: #c0cacf;
}

.bg-gradient-to-light {
  --bs-bg-gradient-to: #c0cacf;
}

.hover-bg-light:hover {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-light:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-light {
  --bs-theme: #c0cacf !important;
  --bs-theme-rgb: 192, 202, 207 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}

.text-light-100 {
  --bs-text-opacity: 1;
  color: rgba(242, 244, 245, var(--bs-text-opacity)) !important;
}

.bg-light-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(242, 244, 245, var(--bs-bg-opacity)) !important;
}

.text-light-600 {
  --bs-text-opacity: 1;
  color: rgba(154, 162, 166, var(--bs-text-opacity)) !important;
}

.bg-light-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(154, 162, 166, var(--bs-bg-opacity)) !important;
}

.text-light-200 {
  --bs-text-opacity: 1;
  color: rgba(230, 234, 236, var(--bs-text-opacity)) !important;
}

.bg-light-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(230, 234, 236, var(--bs-bg-opacity)) !important;
}

.text-light-700 {
  --bs-text-opacity: 1;
  color: rgba(115, 121, 124, var(--bs-text-opacity)) !important;
}

.bg-light-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(115, 121, 124, var(--bs-bg-opacity)) !important;
}

.text-light-300 {
  --bs-text-opacity: 1;
  color: rgba(217, 223, 226, var(--bs-text-opacity)) !important;
}

.bg-light-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(217, 223, 226, var(--bs-bg-opacity)) !important;
}

.text-light-800 {
  --bs-text-opacity: 1;
  color: rgba(77, 81, 83, var(--bs-text-opacity)) !important;
}

.bg-light-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(77, 81, 83, var(--bs-bg-opacity)) !important;
}

.text-light-400 {
  --bs-text-opacity: 1;
  color: rgba(205, 213, 217, var(--bs-text-opacity)) !important;
}

.bg-light-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(205, 213, 217, var(--bs-bg-opacity)) !important;
}

.text-light-900 {
  --bs-text-opacity: 1;
  color: rgba(38, 40, 41, var(--bs-text-opacity)) !important;
}

.bg-light-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(38, 40, 41, var(--bs-bg-opacity)) !important;
}

.bg-gradient-lime {
  background-color: #50b00a !important;
  background-image: linear-gradient(to bottom, #66e00d, #459809) !important;
}

.bg-gradient-from-lime {
  --bs-bg-gradient-from: #50b00a;
}

.bg-gradient-to-lime {
  --bs-bg-gradient-to: #50b00a;
}

.hover-bg-lime:hover {
  background-color: rgba(var(--bs-lime-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-lime:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-lime {
  --bs-theme: #50b00a !important;
  --bs-theme-rgb: 80, 176, 10 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}

.text-lime-100 {
  --bs-text-opacity: 1;
  color: rgba(220, 239, 206, var(--bs-text-opacity)) !important;
}

.bg-lime-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 239, 206, var(--bs-bg-opacity)) !important;
}

.text-lime-600 {
  --bs-text-opacity: 1;
  color: rgba(64, 141, 8, var(--bs-text-opacity)) !important;
}

.bg-lime-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(64, 141, 8, var(--bs-bg-opacity)) !important;
}

.text-lime-200 {
  --bs-text-opacity: 1;
  color: rgba(185, 223, 157, var(--bs-text-opacity)) !important;
}

.bg-lime-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(185, 223, 157, var(--bs-bg-opacity)) !important;
}

.text-lime-700 {
  --bs-text-opacity: 1;
  color: rgba(48, 106, 6, var(--bs-text-opacity)) !important;
}

.bg-lime-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 106, 6, var(--bs-bg-opacity)) !important;
}

.text-lime-300 {
  --bs-text-opacity: 1;
  color: rgba(150, 208, 108, var(--bs-text-opacity)) !important;
}

.bg-lime-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(150, 208, 108, var(--bs-bg-opacity)) !important;
}

.text-lime-800 {
  --bs-text-opacity: 1;
  color: rgba(32, 70, 4, var(--bs-text-opacity)) !important;
}

.bg-lime-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(32, 70, 4, var(--bs-bg-opacity)) !important;
}

.text-lime-400 {
  --bs-text-opacity: 1;
  color: rgba(115, 192, 59, var(--bs-text-opacity)) !important;
}

.bg-lime-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(115, 192, 59, var(--bs-bg-opacity)) !important;
}

.text-lime-900 {
  --bs-text-opacity: 1;
  color: rgba(16, 35, 2, var(--bs-text-opacity)) !important;
}

.bg-lime-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 35, 2, var(--bs-bg-opacity)) !important;
}

.bg-gradient-gray-100 {
  background-color: #c0cacf !important;
  background-image: linear-gradient(to bottom, #dde2e5, #b2bec4) !important;
}

.bg-gradient-from-gray-100 {
  --bs-bg-gradient-from: #c0cacf;
}

.bg-gradient-to-gray-100 {
  --bs-bg-gradient-to: #c0cacf;
}

.hover-bg-gray-100:hover {
  background-color: rgba(var(--bs-gray-100-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-gray-100:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-gray-100 {
  --bs-theme: #c0cacf !important;
  --bs-theme-rgb: 192, 202, 207 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}

.text-gray-100-100 {
  --bs-text-opacity: 1;
  color: rgba(242, 244, 245, var(--bs-text-opacity)) !important;
}

.bg-gray-100-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(242, 244, 245, var(--bs-bg-opacity)) !important;
}

.text-gray-100-600 {
  --bs-text-opacity: 1;
  color: rgba(154, 162, 166, var(--bs-text-opacity)) !important;
}

.bg-gray-100-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(154, 162, 166, var(--bs-bg-opacity)) !important;
}

.text-gray-100-200 {
  --bs-text-opacity: 1;
  color: rgba(230, 234, 236, var(--bs-text-opacity)) !important;
}

.bg-gray-100-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(230, 234, 236, var(--bs-bg-opacity)) !important;
}

.text-gray-100-700 {
  --bs-text-opacity: 1;
  color: rgba(115, 121, 124, var(--bs-text-opacity)) !important;
}

.bg-gray-100-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(115, 121, 124, var(--bs-bg-opacity)) !important;
}

.text-gray-100-300 {
  --bs-text-opacity: 1;
  color: rgba(217, 223, 226, var(--bs-text-opacity)) !important;
}

.bg-gray-100-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(217, 223, 226, var(--bs-bg-opacity)) !important;
}

.text-gray-100-800 {
  --bs-text-opacity: 1;
  color: rgba(77, 81, 83, var(--bs-text-opacity)) !important;
}

.bg-gray-100-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(77, 81, 83, var(--bs-bg-opacity)) !important;
}

.text-gray-100-400 {
  --bs-text-opacity: 1;
  color: rgba(205, 213, 217, var(--bs-text-opacity)) !important;
}

.bg-gray-100-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(205, 213, 217, var(--bs-bg-opacity)) !important;
}

.text-gray-100-900 {
  --bs-text-opacity: 1;
  color: rgba(38, 40, 41, var(--bs-text-opacity)) !important;
}

.bg-gray-100-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(38, 40, 41, var(--bs-bg-opacity)) !important;
}

.bg-gradient-gray-200 {
  background-color: #a8b6bc !important;
  background-image: linear-gradient(to bottom, #c5ced2, #9aaab1) !important;
}

.bg-gradient-from-gray-200 {
  --bs-bg-gradient-from: #a8b6bc;
}

.bg-gradient-to-gray-200 {
  --bs-bg-gradient-to: #a8b6bc;
}

.hover-bg-gray-200:hover {
  background-color: rgba(var(--bs-gray-200-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-gray-200:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-gray-200 {
  --bs-theme: #a8b6bc !important;
  --bs-theme-rgb: 168, 182, 188 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}

.text-gray-200-100 {
  --bs-text-opacity: 1;
  color: rgba(238, 240, 242, var(--bs-text-opacity)) !important;
}

.bg-gray-200-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(238, 240, 242, var(--bs-bg-opacity)) !important;
}

.text-gray-200-600 {
  --bs-text-opacity: 1;
  color: rgba(134, 146, 150, var(--bs-text-opacity)) !important;
}

.bg-gray-200-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 146, 150, var(--bs-bg-opacity)) !important;
}

.text-gray-200-200 {
  --bs-text-opacity: 1;
  color: rgba(220, 226, 228, var(--bs-text-opacity)) !important;
}

.bg-gray-200-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 226, 228, var(--bs-bg-opacity)) !important;
}

.text-gray-200-700 {
  --bs-text-opacity: 1;
  color: rgba(101, 109, 113, var(--bs-text-opacity)) !important;
}

.bg-gray-200-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(101, 109, 113, var(--bs-bg-opacity)) !important;
}

.text-gray-200-300 {
  --bs-text-opacity: 1;
  color: rgba(203, 211, 215, var(--bs-text-opacity)) !important;
}

.bg-gray-200-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(203, 211, 215, var(--bs-bg-opacity)) !important;
}

.text-gray-200-800 {
  --bs-text-opacity: 1;
  color: rgba(67, 73, 75, var(--bs-text-opacity)) !important;
}

.bg-gray-200-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(67, 73, 75, var(--bs-bg-opacity)) !important;
}

.text-gray-200-400 {
  --bs-text-opacity: 1;
  color: rgba(185, 197, 201, var(--bs-text-opacity)) !important;
}

.bg-gray-200-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(185, 197, 201, var(--bs-bg-opacity)) !important;
}

.text-gray-200-900 {
  --bs-text-opacity: 1;
  color: rgba(34, 36, 38, var(--bs-text-opacity)) !important;
}

.bg-gray-200-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(34, 36, 38, var(--bs-bg-opacity)) !important;
}

.bg-gradient-gray-300 {
  background-color: #90a2aa !important;
  background-image: linear-gradient(to bottom, #adbac0, #82969f) !important;
}

.bg-gradient-from-gray-300 {
  --bs-bg-gradient-from: #90a2aa;
}

.bg-gradient-to-gray-300 {
  --bs-bg-gradient-to: #90a2aa;
}

.hover-bg-gray-300:hover {
  background-color: rgba(var(--bs-gray-300-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-gray-300:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-gray-300 {
  --bs-theme: #90a2aa !important;
  --bs-theme-rgb: 144, 162, 170 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}

.text-gray-300-100 {
  --bs-text-opacity: 1;
  color: rgba(233, 236, 238, var(--bs-text-opacity)) !important;
}

.bg-gray-300-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(233, 236, 238, var(--bs-bg-opacity)) !important;
}

.text-gray-300-600 {
  --bs-text-opacity: 1;
  color: rgba(115, 130, 136, var(--bs-text-opacity)) !important;
}

.bg-gray-300-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(115, 130, 136, var(--bs-bg-opacity)) !important;
}

.text-gray-300-200 {
  --bs-text-opacity: 1;
  color: rgba(211, 218, 221, var(--bs-text-opacity)) !important;
}

.bg-gray-300-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(211, 218, 221, var(--bs-bg-opacity)) !important;
}

.text-gray-300-700 {
  --bs-text-opacity: 1;
  color: rgba(86, 97, 102, var(--bs-text-opacity)) !important;
}

.bg-gray-300-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(86, 97, 102, var(--bs-bg-opacity)) !important;
}

.text-gray-300-300 {
  --bs-text-opacity: 1;
  color: rgba(188, 199, 204, var(--bs-text-opacity)) !important;
}

.bg-gray-300-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(188, 199, 204, var(--bs-bg-opacity)) !important;
}

.text-gray-300-800 {
  --bs-text-opacity: 1;
  color: rgba(58, 65, 68, var(--bs-text-opacity)) !important;
}

.bg-gray-300-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(58, 65, 68, var(--bs-bg-opacity)) !important;
}

.text-gray-300-400 {
  --bs-text-opacity: 1;
  color: rgba(166, 181, 187, var(--bs-text-opacity)) !important;
}

.bg-gray-300-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(166, 181, 187, var(--bs-bg-opacity)) !important;
}

.text-gray-300-900 {
  --bs-text-opacity: 1;
  color: rgba(29, 32, 34, var(--bs-text-opacity)) !important;
}

.bg-gray-300-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(29, 32, 34, var(--bs-bg-opacity)) !important;
}

.bg-gradient-gray-400 {
  background-color: #798e98 !important;
  background-image: linear-gradient(to bottom, #96a6ae, #6c818c) !important;
}

.bg-gradient-from-gray-400 {
  --bs-bg-gradient-from: #798e98;
}

.bg-gradient-to-gray-400 {
  --bs-bg-gradient-to: #798e98;
}

.hover-bg-gray-400:hover {
  background-color: rgba(var(--bs-gray-400-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-gray-400:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-gray-400 {
  --bs-theme: #798e98 !important;
  --bs-theme-rgb: 121, 142, 152 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-gray-400-100 {
  --bs-text-opacity: 1;
  color: rgba(228, 232, 234, var(--bs-text-opacity)) !important;
}

.bg-gray-400-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(228, 232, 234, var(--bs-bg-opacity)) !important;
}

.text-gray-400-600 {
  --bs-text-opacity: 1;
  color: rgba(97, 114, 122, var(--bs-text-opacity)) !important;
}

.bg-gray-400-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(97, 114, 122, var(--bs-bg-opacity)) !important;
}

.text-gray-400-200 {
  --bs-text-opacity: 1;
  color: rgba(201, 210, 214, var(--bs-text-opacity)) !important;
}

.bg-gray-400-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(201, 210, 214, var(--bs-bg-opacity)) !important;
}

.text-gray-400-700 {
  --bs-text-opacity: 1;
  color: rgba(73, 85, 91, var(--bs-text-opacity)) !important;
}

.bg-gray-400-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(73, 85, 91, var(--bs-bg-opacity)) !important;
}

.text-gray-400-300 {
  --bs-text-opacity: 1;
  color: rgba(175, 187, 193, var(--bs-text-opacity)) !important;
}

.bg-gray-400-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(175, 187, 193, var(--bs-bg-opacity)) !important;
}

.text-gray-400-800 {
  --bs-text-opacity: 1;
  color: rgba(48, 57, 61, var(--bs-text-opacity)) !important;
}

.bg-gray-400-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 57, 61, var(--bs-bg-opacity)) !important;
}

.text-gray-400-400 {
  --bs-text-opacity: 1;
  color: rgba(148, 165, 173, var(--bs-text-opacity)) !important;
}

.bg-gray-400-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(148, 165, 173, var(--bs-bg-opacity)) !important;
}

.text-gray-400-900 {
  --bs-text-opacity: 1;
  color: rgba(24, 28, 30, var(--bs-text-opacity)) !important;
}

.bg-gray-400-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 28, 30, var(--bs-bg-opacity)) !important;
}

.bg-gradient-gray-500 {
  background-color: #617a86 !important;
  background-image: linear-gradient(to bottom, #7b939f, #566d77) !important;
}

.bg-gradient-from-gray-500 {
  --bs-bg-gradient-from: #617a86;
}

.bg-gradient-to-gray-500 {
  --bs-bg-gradient-to: #617a86;
}

.hover-bg-gray-500:hover {
  background-color: rgba(var(--bs-gray-500-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-gray-500:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-gray-500 {
  --bs-theme: #617a86 !important;
  --bs-theme-rgb: 97, 122, 134 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-gray-500-100 {
  --bs-text-opacity: 1;
  color: rgba(223, 228, 231, var(--bs-text-opacity)) !important;
}

.bg-gray-500-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(223, 228, 231, var(--bs-bg-opacity)) !important;
}

.text-gray-500-600 {
  --bs-text-opacity: 1;
  color: rgba(78, 98, 107, var(--bs-text-opacity)) !important;
}

.bg-gray-500-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(78, 98, 107, var(--bs-bg-opacity)) !important;
}

.text-gray-500-200 {
  --bs-text-opacity: 1;
  color: rgba(192, 202, 207, var(--bs-text-opacity)) !important;
}

.bg-gray-500-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(192, 202, 207, var(--bs-bg-opacity)) !important;
}

.text-gray-500-700 {
  --bs-text-opacity: 1;
  color: rgba(58, 73, 80, var(--bs-text-opacity)) !important;
}

.bg-gray-500-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(58, 73, 80, var(--bs-bg-opacity)) !important;
}

.text-gray-500-300 {
  --bs-text-opacity: 1;
  color: rgba(160, 175, 182, var(--bs-text-opacity)) !important;
}

.bg-gray-500-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(160, 175, 182, var(--bs-bg-opacity)) !important;
}

.text-gray-500-800 {
  --bs-text-opacity: 1;
  color: rgba(39, 49, 54, var(--bs-text-opacity)) !important;
}

.bg-gray-500-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(39, 49, 54, var(--bs-bg-opacity)) !important;
}

.text-gray-500-400 {
  --bs-text-opacity: 1;
  color: rgba(129, 149, 158, var(--bs-text-opacity)) !important;
}

.bg-gray-500-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(129, 149, 158, var(--bs-bg-opacity)) !important;
}

.text-gray-500-900 {
  --bs-text-opacity: 1;
  color: rgba(19, 24, 27, var(--bs-text-opacity)) !important;
}

.bg-gray-500-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(19, 24, 27, var(--bs-bg-opacity)) !important;
}

.bg-gradient-gray-600 {
  background-color: #526872 !important;
  background-image: linear-gradient(to bottom, #678390, #475a63) !important;
}

.bg-gradient-from-gray-600 {
  --bs-bg-gradient-from: #526872;
}

.bg-gradient-to-gray-600 {
  --bs-bg-gradient-to: #526872;
}

.hover-bg-gray-600:hover {
  background-color: rgba(var(--bs-gray-600-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-gray-600:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-gray-600 {
  --bs-theme: #526872 !important;
  --bs-theme-rgb: 82, 104, 114 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-gray-600-100 {
  --bs-text-opacity: 1;
  color: rgba(220, 225, 227, var(--bs-text-opacity)) !important;
}

.bg-gray-600-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 225, 227, var(--bs-bg-opacity)) !important;
}

.text-gray-600-600 {
  --bs-text-opacity: 1;
  color: rgba(66, 83, 91, var(--bs-text-opacity)) !important;
}

.bg-gray-600-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(66, 83, 91, var(--bs-bg-opacity)) !important;
}

.text-gray-600-200 {
  --bs-text-opacity: 1;
  color: rgba(186, 195, 199, var(--bs-text-opacity)) !important;
}

.bg-gray-600-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(186, 195, 199, var(--bs-bg-opacity)) !important;
}

.text-gray-600-700 {
  --bs-text-opacity: 1;
  color: rgba(49, 62, 68, var(--bs-text-opacity)) !important;
}

.bg-gray-600-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(49, 62, 68, var(--bs-bg-opacity)) !important;
}

.text-gray-600-300 {
  --bs-text-opacity: 1;
  color: rgba(151, 164, 170, var(--bs-text-opacity)) !important;
}

.bg-gray-600-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(151, 164, 170, var(--bs-bg-opacity)) !important;
}

.text-gray-600-800 {
  --bs-text-opacity: 1;
  color: rgba(33, 42, 46, var(--bs-text-opacity)) !important;
}

.bg-gray-600-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(33, 42, 46, var(--bs-bg-opacity)) !important;
}

.text-gray-600-400 {
  --bs-text-opacity: 1;
  color: rgba(117, 134, 142, var(--bs-text-opacity)) !important;
}

.bg-gray-600-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(117, 134, 142, var(--bs-bg-opacity)) !important;
}

.text-gray-600-900 {
  --bs-text-opacity: 1;
  color: rgba(16, 21, 23, var(--bs-text-opacity)) !important;
}

.bg-gray-600-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 21, 23, var(--bs-bg-opacity)) !important;
}

.bg-gradient-gray-700 {
  background-color: #44555e !important;
  background-image: linear-gradient(to bottom, #59707c, #39484f) !important;
}

.bg-gradient-from-gray-700 {
  --bs-bg-gradient-from: #44555e;
}

.bg-gradient-to-gray-700 {
  --bs-bg-gradient-to: #44555e;
}

.hover-bg-gray-700:hover {
  background-color: rgba(var(--bs-gray-700-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-gray-700:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-gray-700 {
  --bs-theme: #44555e !important;
  --bs-theme-rgb: 68, 85, 94 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-gray-700-100 {
  --bs-text-opacity: 1;
  color: rgba(218, 221, 223, var(--bs-text-opacity)) !important;
}

.bg-gray-700-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(218, 221, 223, var(--bs-bg-opacity)) !important;
}

.text-gray-700-600 {
  --bs-text-opacity: 1;
  color: rgba(54, 68, 75, var(--bs-text-opacity)) !important;
}

.bg-gray-700-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(54, 68, 75, var(--bs-bg-opacity)) !important;
}

.text-gray-700-200 {
  --bs-text-opacity: 1;
  color: rgba(180, 187, 191, var(--bs-text-opacity)) !important;
}

.bg-gray-700-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(180, 187, 191, var(--bs-bg-opacity)) !important;
}

.text-gray-700-700 {
  --bs-text-opacity: 1;
  color: rgba(41, 51, 56, var(--bs-text-opacity)) !important;
}

.bg-gray-700-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(41, 51, 56, var(--bs-bg-opacity)) !important;
}

.text-gray-700-300 {
  --bs-text-opacity: 1;
  color: rgba(143, 153, 158, var(--bs-text-opacity)) !important;
}

.bg-gray-700-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(143, 153, 158, var(--bs-bg-opacity)) !important;
}

.text-gray-700-800 {
  --bs-text-opacity: 1;
  color: rgba(27, 34, 38, var(--bs-text-opacity)) !important;
}

.bg-gray-700-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(27, 34, 38, var(--bs-bg-opacity)) !important;
}

.text-gray-700-400 {
  --bs-text-opacity: 1;
  color: rgba(105, 119, 126, var(--bs-text-opacity)) !important;
}

.bg-gray-700-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(105, 119, 126, var(--bs-bg-opacity)) !important;
}

.text-gray-700-900 {
  --bs-text-opacity: 1;
  color: rgba(14, 17, 19, var(--bs-text-opacity)) !important;
}

.bg-gray-700-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(14, 17, 19, var(--bs-bg-opacity)) !important;
}

.bg-gradient-gray-800 {
  background-color: #35434a !important;
  background-image: linear-gradient(to bottom, #4a5e68, #2a363b) !important;
}

.bg-gradient-from-gray-800 {
  --bs-bg-gradient-from: #35434a;
}

.bg-gradient-to-gray-800 {
  --bs-bg-gradient-to: #35434a;
}

.hover-bg-gray-800:hover {
  background-color: rgba(var(--bs-gray-800-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-gray-800:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-gray-800 {
  --bs-theme: #35434a !important;
  --bs-theme-rgb: 53, 67, 74 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-gray-800-100 {
  --bs-text-opacity: 1;
  color: rgba(215, 217, 219, var(--bs-text-opacity)) !important;
}

.bg-gray-800-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(215, 217, 219, var(--bs-bg-opacity)) !important;
}

.text-gray-800-600 {
  --bs-text-opacity: 1;
  color: rgba(42, 54, 59, var(--bs-text-opacity)) !important;
}

.bg-gray-800-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(42, 54, 59, var(--bs-bg-opacity)) !important;
}

.text-gray-800-200 {
  --bs-text-opacity: 1;
  color: rgba(174, 180, 183, var(--bs-text-opacity)) !important;
}

.bg-gray-800-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(174, 180, 183, var(--bs-bg-opacity)) !important;
}

.text-gray-800-700 {
  --bs-text-opacity: 1;
  color: rgba(32, 40, 44, var(--bs-text-opacity)) !important;
}

.bg-gray-800-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(32, 40, 44, var(--bs-bg-opacity)) !important;
}

.text-gray-800-300 {
  --bs-text-opacity: 1;
  color: rgba(134, 142, 146, var(--bs-text-opacity)) !important;
}

.bg-gray-800-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 142, 146, var(--bs-bg-opacity)) !important;
}

.text-gray-800-800 {
  --bs-text-opacity: 1;
  color: rgba(21, 27, 30, var(--bs-text-opacity)) !important;
}

.bg-gray-800-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(21, 27, 30, var(--bs-bg-opacity)) !important;
}

.text-gray-800-400 {
  --bs-text-opacity: 1;
  color: rgba(93, 105, 110, var(--bs-text-opacity)) !important;
}

.bg-gray-800-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(93, 105, 110, var(--bs-bg-opacity)) !important;
}

.text-gray-800-900 {
  --bs-text-opacity: 1;
  color: rgba(11, 13, 15, var(--bs-text-opacity)) !important;
}

.bg-gray-800-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(11, 13, 15, var(--bs-bg-opacity)) !important;
}

.bg-gradient-gray-900 {
  background-color: #273136 !important;
  background-image: linear-gradient(to bottom, #3c4c54, #1c2427) !important;
}

.bg-gradient-from-gray-900 {
  --bs-bg-gradient-from: #273136;
}

.bg-gradient-to-gray-900 {
  --bs-bg-gradient-to: #273136;
}

.hover-bg-gray-900:hover {
  background-color: rgba(var(--bs-gray-900-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-gray-900:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-gray-900 {
  --bs-theme: #273136 !important;
  --bs-theme-rgb: 39, 49, 54 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-gray-900-100 {
  --bs-text-opacity: 1;
  color: rgba(212, 214, 215, var(--bs-text-opacity)) !important;
}

.bg-gray-900-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(212, 214, 215, var(--bs-bg-opacity)) !important;
}

.text-gray-900-600 {
  --bs-text-opacity: 1;
  color: rgba(31, 39, 43, var(--bs-text-opacity)) !important;
}

.bg-gray-900-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(31, 39, 43, var(--bs-bg-opacity)) !important;
}

.text-gray-900-200 {
  --bs-text-opacity: 1;
  color: rgba(169, 173, 175, var(--bs-text-opacity)) !important;
}

.bg-gray-900-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(169, 173, 175, var(--bs-bg-opacity)) !important;
}

.text-gray-900-700 {
  --bs-text-opacity: 1;
  color: rgba(23, 29, 32, var(--bs-text-opacity)) !important;
}

.bg-gray-900-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(23, 29, 32, var(--bs-bg-opacity)) !important;
}

.text-gray-900-300 {
  --bs-text-opacity: 1;
  color: rgba(125, 131, 134, var(--bs-text-opacity)) !important;
}

.bg-gray-900-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(125, 131, 134, var(--bs-bg-opacity)) !important;
}

.text-gray-900-800 {
  --bs-text-opacity: 1;
  color: rgba(16, 20, 22, var(--bs-text-opacity)) !important;
}

.bg-gray-900-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 20, 22, var(--bs-bg-opacity)) !important;
}

.text-gray-900-400 {
  --bs-text-opacity: 1;
  color: rgba(82, 90, 94, var(--bs-text-opacity)) !important;
}

.bg-gray-900-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(82, 90, 94, var(--bs-bg-opacity)) !important;
}

.text-gray-900-900 {
  --bs-text-opacity: 1;
  color: rgba(8, 10, 11, var(--bs-text-opacity)) !important;
}

.bg-gray-900-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(8, 10, 11, var(--bs-bg-opacity)) !important;
}

.bg-gradient-blue {
  background-color: #0271ff !important;
  background-image: linear-gradient(to bottom, #358eff, #0066e8) !important;
}

.bg-gradient-from-blue {
  --bs-bg-gradient-from: #0271ff;
}

.bg-gradient-to-blue {
  --bs-bg-gradient-to: #0271ff;
}

.hover-bg-blue:hover {
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-blue:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-blue {
  --bs-theme: #0271ff !important;
  --bs-theme-rgb: 2, 113, 255 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-blue-100 {
  --bs-text-opacity: 1;
  color: rgba(204, 227, 255, var(--bs-text-opacity)) !important;
}

.bg-blue-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 227, 255, var(--bs-bg-opacity)) !important;
}

.text-blue-600 {
  --bs-text-opacity: 1;
  color: rgba(2, 90, 204, var(--bs-text-opacity)) !important;
}

.bg-blue-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(2, 90, 204, var(--bs-bg-opacity)) !important;
}

.text-blue-200 {
  --bs-text-opacity: 1;
  color: rgba(154, 198, 255, var(--bs-text-opacity)) !important;
}

.bg-blue-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(154, 198, 255, var(--bs-bg-opacity)) !important;
}

.text-blue-700 {
  --bs-text-opacity: 1;
  color: rgba(1, 68, 153, var(--bs-text-opacity)) !important;
}

.bg-blue-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(1, 68, 153, var(--bs-bg-opacity)) !important;
}

.text-blue-300 {
  --bs-text-opacity: 1;
  color: rgba(103, 170, 255, var(--bs-text-opacity)) !important;
}

.bg-blue-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(103, 170, 255, var(--bs-bg-opacity)) !important;
}

.text-blue-800 {
  --bs-text-opacity: 1;
  color: rgba(1, 45, 102, var(--bs-text-opacity)) !important;
}

.bg-blue-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(1, 45, 102, var(--bs-bg-opacity)) !important;
}

.text-blue-400 {
  --bs-text-opacity: 1;
  color: rgba(53, 141, 255, var(--bs-text-opacity)) !important;
}

.bg-blue-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(53, 141, 255, var(--bs-bg-opacity)) !important;
}

.text-blue-900 {
  --bs-text-opacity: 1;
  color: rgba(0, 23, 51, var(--bs-text-opacity)) !important;
}

.bg-blue-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 23, 51, var(--bs-bg-opacity)) !important;
}

.bg-gradient-indigo {
  background-color: #5c25ff !important;
  background-image: linear-gradient(to bottom, #8258ff, #490cff) !important;
}

.bg-gradient-from-indigo {
  --bs-bg-gradient-from: #5c25ff;
}

.bg-gradient-to-indigo {
  --bs-bg-gradient-to: #5c25ff;
}

.hover-bg-indigo:hover {
  background-color: rgba(var(--bs-indigo-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-indigo:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-indigo {
  --bs-theme: #5c25ff !important;
  --bs-theme-rgb: 92, 37, 255 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-indigo-100 {
  --bs-text-opacity: 1;
  color: rgba(222, 211, 255, var(--bs-text-opacity)) !important;
}

.bg-indigo-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(222, 211, 255, var(--bs-bg-opacity)) !important;
}

.text-indigo-600 {
  --bs-text-opacity: 1;
  color: rgba(74, 30, 204, var(--bs-text-opacity)) !important;
}

.bg-indigo-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(74, 30, 204, var(--bs-bg-opacity)) !important;
}

.text-indigo-200 {
  --bs-text-opacity: 1;
  color: rgba(190, 168, 255, var(--bs-text-opacity)) !important;
}

.bg-indigo-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(190, 168, 255, var(--bs-bg-opacity)) !important;
}

.text-indigo-700 {
  --bs-text-opacity: 1;
  color: rgba(55, 22, 153, var(--bs-text-opacity)) !important;
}

.bg-indigo-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(55, 22, 153, var(--bs-bg-opacity)) !important;
}

.text-indigo-300 {
  --bs-text-opacity: 1;
  color: rgba(157, 124, 255, var(--bs-text-opacity)) !important;
}

.bg-indigo-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(157, 124, 255, var(--bs-bg-opacity)) !important;
}

.text-indigo-800 {
  --bs-text-opacity: 1;
  color: rgba(37, 15, 102, var(--bs-text-opacity)) !important;
}

.bg-indigo-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(37, 15, 102, var(--bs-bg-opacity)) !important;
}

.text-indigo-400 {
  --bs-text-opacity: 1;
  color: rgba(125, 81, 255, var(--bs-text-opacity)) !important;
}

.bg-indigo-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(125, 81, 255, var(--bs-bg-opacity)) !important;
}

.text-indigo-900 {
  --bs-text-opacity: 1;
  color: rgba(18, 7, 51, var(--bs-text-opacity)) !important;
}

.bg-indigo-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(18, 7, 51, var(--bs-bg-opacity)) !important;
}

.bg-gradient-purple {
  background-color: #b90dff !important;
  background-image: linear-gradient(to bottom, #c840ff, #ac00f3) !important;
}

.bg-gradient-from-purple {
  --bs-bg-gradient-from: #b90dff;
}

.bg-gradient-to-purple {
  --bs-bg-gradient-to: #b90dff;
}

.hover-bg-purple:hover {
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-purple:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-purple {
  --bs-theme: #b90dff !important;
  --bs-theme-rgb: 185, 13, 255 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-purple-100 {
  --bs-text-opacity: 1;
  color: rgba(241, 207, 255, var(--bs-text-opacity)) !important;
}

.bg-purple-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(241, 207, 255, var(--bs-bg-opacity)) !important;
}

.text-purple-600 {
  --bs-text-opacity: 1;
  color: rgba(148, 10, 204, var(--bs-text-opacity)) !important;
}

.bg-purple-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(148, 10, 204, var(--bs-bg-opacity)) !important;
}

.text-purple-200 {
  --bs-text-opacity: 1;
  color: rgba(227, 158, 255, var(--bs-text-opacity)) !important;
}

.bg-purple-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(227, 158, 255, var(--bs-bg-opacity)) !important;
}

.text-purple-700 {
  --bs-text-opacity: 1;
  color: rgba(111, 8, 153, var(--bs-text-opacity)) !important;
}

.bg-purple-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(111, 8, 153, var(--bs-bg-opacity)) !important;
}

.text-purple-300 {
  --bs-text-opacity: 1;
  color: rgba(213, 110, 255, var(--bs-text-opacity)) !important;
}

.bg-purple-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(213, 110, 255, var(--bs-bg-opacity)) !important;
}

.text-purple-800 {
  --bs-text-opacity: 1;
  color: rgba(74, 5, 102, var(--bs-text-opacity)) !important;
}

.bg-purple-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(74, 5, 102, var(--bs-bg-opacity)) !important;
}

.text-purple-400 {
  --bs-text-opacity: 1;
  color: rgba(199, 61, 255, var(--bs-text-opacity)) !important;
}

.bg-purple-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(199, 61, 255, var(--bs-bg-opacity)) !important;
}

.text-purple-900 {
  --bs-text-opacity: 1;
  color: rgba(37, 3, 51, var(--bs-text-opacity)) !important;
}

.bg-purple-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(37, 3, 51, var(--bs-bg-opacity)) !important;
}

.bg-gradient-pink {
  background-color: #e61079 !important;
  background-image: linear-gradient(to bottom, #f13893, #ce0e6c) !important;
}

.bg-gradient-from-pink {
  --bs-bg-gradient-from: #e61079;
}

.bg-gradient-to-pink {
  --bs-bg-gradient-to: #e61079;
}

.hover-bg-pink:hover {
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-pink:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-pink {
  --bs-theme: #e61079 !important;
  --bs-theme-rgb: 230, 16, 121 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-pink-100 {
  --bs-text-opacity: 1;
  color: rgba(250, 207, 228, var(--bs-text-opacity)) !important;
}

.bg-pink-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(250, 207, 228, var(--bs-bg-opacity)) !important;
}

.text-pink-600 {
  --bs-text-opacity: 1;
  color: rgba(184, 13, 97, var(--bs-text-opacity)) !important;
}

.bg-pink-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(184, 13, 97, var(--bs-bg-opacity)) !important;
}

.text-pink-200 {
  --bs-text-opacity: 1;
  color: rgba(245, 159, 201, var(--bs-text-opacity)) !important;
}

.bg-pink-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(245, 159, 201, var(--bs-bg-opacity)) !important;
}

.text-pink-700 {
  --bs-text-opacity: 1;
  color: rgba(138, 10, 73, var(--bs-text-opacity)) !important;
}

.bg-pink-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(138, 10, 73, var(--bs-bg-opacity)) !important;
}

.text-pink-300 {
  --bs-text-opacity: 1;
  color: rgba(240, 112, 175, var(--bs-text-opacity)) !important;
}

.bg-pink-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(240, 112, 175, var(--bs-bg-opacity)) !important;
}

.text-pink-800 {
  --bs-text-opacity: 1;
  color: rgba(92, 6, 48, var(--bs-text-opacity)) !important;
}

.bg-pink-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(92, 6, 48, var(--bs-bg-opacity)) !important;
}

.text-pink-400 {
  --bs-text-opacity: 1;
  color: rgba(235, 64, 148, var(--bs-text-opacity)) !important;
}

.bg-pink-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(235, 64, 148, var(--bs-bg-opacity)) !important;
}

.text-pink-900 {
  --bs-text-opacity: 1;
  color: rgba(46, 3, 24, var(--bs-text-opacity)) !important;
}

.bg-pink-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(46, 3, 24, var(--bs-bg-opacity)) !important;
}

.bg-gradient-red {
  background-color: #e00000 !important;
  background-image: linear-gradient(to bottom, #ff1414, #c70000) !important;
}

.bg-gradient-from-red {
  --bs-bg-gradient-from: #e00000;
}

.bg-gradient-to-red {
  --bs-bg-gradient-to: #e00000;
}

.hover-bg-red:hover {
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-red:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-red {
  --bs-theme: #e00000 !important;
  --bs-theme-rgb: 224, 0, 0 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-red-100 {
  --bs-text-opacity: 1;
  color: rgba(249, 204, 204, var(--bs-text-opacity)) !important;
}

.bg-red-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(249, 204, 204, var(--bs-bg-opacity)) !important;
}

.text-red-600 {
  --bs-text-opacity: 1;
  color: rgba(179, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-red-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(179, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-red-200 {
  --bs-text-opacity: 1;
  color: rgba(243, 153, 153, var(--bs-text-opacity)) !important;
}

.bg-red-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(243, 153, 153, var(--bs-bg-opacity)) !important;
}

.text-red-700 {
  --bs-text-opacity: 1;
  color: rgba(134, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-red-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-red-300 {
  --bs-text-opacity: 1;
  color: rgba(236, 102, 102, var(--bs-text-opacity)) !important;
}

.bg-red-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(236, 102, 102, var(--bs-bg-opacity)) !important;
}

.text-red-800 {
  --bs-text-opacity: 1;
  color: rgba(90, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-red-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(90, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-red-400 {
  --bs-text-opacity: 1;
  color: rgba(230, 51, 51, var(--bs-text-opacity)) !important;
}

.bg-red-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(230, 51, 51, var(--bs-bg-opacity)) !important;
}

.text-red-900 {
  --bs-text-opacity: 1;
  color: rgba(45, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-red-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(45, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-gradient-orange {
  background-color: #ff9f0c !important;
  background-image: linear-gradient(to bottom, #ffb33f, #f29200) !important;
}

.bg-gradient-from-orange {
  --bs-bg-gradient-from: #ff9f0c;
}

.bg-gradient-to-orange {
  --bs-bg-gradient-to: #ff9f0c;
}

.hover-bg-orange:hover {
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-orange:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-orange {
  --bs-theme: #ff9f0c !important;
  --bs-theme-rgb: 255, 159, 12 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}

.text-orange-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 236, 206, var(--bs-text-opacity)) !important;
}

.bg-orange-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 236, 206, var(--bs-bg-opacity)) !important;
}

.text-orange-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 127, 10, var(--bs-text-opacity)) !important;
}

.bg-orange-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 127, 10, var(--bs-bg-opacity)) !important;
}

.text-orange-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 217, 158, var(--bs-text-opacity)) !important;
}

.bg-orange-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 217, 158, var(--bs-bg-opacity)) !important;
}

.text-orange-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 95, 7, var(--bs-text-opacity)) !important;
}

.bg-orange-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 95, 7, var(--bs-bg-opacity)) !important;
}

.text-orange-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 197, 109, var(--bs-text-opacity)) !important;
}

.bg-orange-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 197, 109, var(--bs-bg-opacity)) !important;
}

.text-orange-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 64, 5, var(--bs-text-opacity)) !important;
}

.bg-orange-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 64, 5, var(--bs-bg-opacity)) !important;
}

.text-orange-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 178, 61, var(--bs-text-opacity)) !important;
}

.bg-orange-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 178, 61, var(--bs-bg-opacity)) !important;
}

.text-orange-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 32, 2, var(--bs-text-opacity)) !important;
}

.bg-orange-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 32, 2, var(--bs-bg-opacity)) !important;
}

.bg-gradient-yellow {
  background-color: #f9e80d !important;
  background-image: linear-gradient(to bottom, #faed3f, #e7d706) !important;
}

.bg-gradient-from-yellow {
  --bs-bg-gradient-from: #f9e80d;
}

.bg-gradient-to-yellow {
  --bs-bg-gradient-to: #f9e80d;
}

.hover-bg-yellow:hover {
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-yellow:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-yellow {
  --bs-theme: #f9e80d !important;
  --bs-theme-rgb: 249, 232, 13 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}

.text-yellow-100 {
  --bs-text-opacity: 1;
  color: rgba(254, 250, 207, var(--bs-text-opacity)) !important;
}

.bg-yellow-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(254, 250, 207, var(--bs-bg-opacity)) !important;
}

.text-yellow-600 {
  --bs-text-opacity: 1;
  color: rgba(199, 186, 10, var(--bs-text-opacity)) !important;
}

.bg-yellow-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(199, 186, 10, var(--bs-bg-opacity)) !important;
}

.text-yellow-200 {
  --bs-text-opacity: 1;
  color: rgba(253, 246, 158, var(--bs-text-opacity)) !important;
}

.bg-yellow-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(253, 246, 158, var(--bs-bg-opacity)) !important;
}

.text-yellow-700 {
  --bs-text-opacity: 1;
  color: rgba(149, 139, 8, var(--bs-text-opacity)) !important;
}

.bg-yellow-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(149, 139, 8, var(--bs-bg-opacity)) !important;
}

.text-yellow-300 {
  --bs-text-opacity: 1;
  color: rgba(251, 241, 110, var(--bs-text-opacity)) !important;
}

.bg-yellow-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(251, 241, 110, var(--bs-bg-opacity)) !important;
}

.text-yellow-800 {
  --bs-text-opacity: 1;
  color: rgba(100, 93, 5, var(--bs-text-opacity)) !important;
}

.bg-yellow-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(100, 93, 5, var(--bs-bg-opacity)) !important;
}

.text-yellow-400 {
  --bs-text-opacity: 1;
  color: rgba(250, 237, 61, var(--bs-text-opacity)) !important;
}

.bg-yellow-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(250, 237, 61, var(--bs-bg-opacity)) !important;
}

.text-yellow-900 {
  --bs-text-opacity: 1;
  color: rgba(50, 46, 3, var(--bs-text-opacity)) !important;
}

.bg-yellow-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(50, 46, 3, var(--bs-bg-opacity)) !important;
}

.bg-gradient-green {
  background-color: #148922 !important;
  background-image: linear-gradient(to bottom, #1ab62d, #11731c) !important;
}

.bg-gradient-from-green {
  --bs-bg-gradient-from: #148922;
}

.bg-gradient-to-green {
  --bs-bg-gradient-to: #148922;
}

.hover-bg-green:hover {
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-green:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-green {
  --bs-theme: #148922 !important;
  --bs-theme-rgb: 20, 137, 34 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-green-100 {
  --bs-text-opacity: 1;
  color: rgba(208, 231, 211, var(--bs-text-opacity)) !important;
}

.bg-green-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(208, 231, 211, var(--bs-bg-opacity)) !important;
}

.text-green-600 {
  --bs-text-opacity: 1;
  color: rgba(16, 110, 27, var(--bs-text-opacity)) !important;
}

.bg-green-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 110, 27, var(--bs-bg-opacity)) !important;
}

.text-green-200 {
  --bs-text-opacity: 1;
  color: rgba(161, 208, 167, var(--bs-text-opacity)) !important;
}

.bg-green-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(161, 208, 167, var(--bs-bg-opacity)) !important;
}

.text-green-700 {
  --bs-text-opacity: 1;
  color: rgba(12, 82, 20, var(--bs-text-opacity)) !important;
}

.bg-green-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(12, 82, 20, var(--bs-bg-opacity)) !important;
}

.text-green-300 {
  --bs-text-opacity: 1;
  color: rgba(114, 184, 122, var(--bs-text-opacity)) !important;
}

.bg-green-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(114, 184, 122, var(--bs-bg-opacity)) !important;
}

.text-green-800 {
  --bs-text-opacity: 1;
  color: rgba(8, 55, 14, var(--bs-text-opacity)) !important;
}

.bg-green-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(8, 55, 14, var(--bs-bg-opacity)) !important;
}

.text-green-400 {
  --bs-text-opacity: 1;
  color: rgba(67, 161, 78, var(--bs-text-opacity)) !important;
}

.bg-green-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(67, 161, 78, var(--bs-bg-opacity)) !important;
}

.text-green-900 {
  --bs-text-opacity: 1;
  color: rgba(4, 27, 7, var(--bs-text-opacity)) !important;
}

.bg-green-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(4, 27, 7, var(--bs-bg-opacity)) !important;
}

.bg-gradient-teal {
  background-color: #249d79 !important;
  background-image: linear-gradient(to bottom, #2ec699, #1f8869) !important;
}

.bg-gradient-from-teal {
  --bs-bg-gradient-from: #249d79;
}

.bg-gradient-to-teal {
  --bs-bg-gradient-to: #249d79;
}

.hover-bg-teal:hover {
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-teal:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-teal {
  --bs-theme: #249d79 !important;
  --bs-theme-rgb: 36, 157, 121 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-teal-100 {
  --bs-text-opacity: 1;
  color: rgba(211, 235, 228, var(--bs-text-opacity)) !important;
}

.bg-teal-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(211, 235, 228, var(--bs-bg-opacity)) !important;
}

.text-teal-600 {
  --bs-text-opacity: 1;
  color: rgba(29, 126, 97, var(--bs-text-opacity)) !important;
}

.bg-teal-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(29, 126, 97, var(--bs-bg-opacity)) !important;
}

.text-teal-200 {
  --bs-text-opacity: 1;
  color: rgba(167, 216, 201, var(--bs-text-opacity)) !important;
}

.bg-teal-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(167, 216, 201, var(--bs-bg-opacity)) !important;
}

.text-teal-700 {
  --bs-text-opacity: 1;
  color: rgba(22, 94, 73, var(--bs-text-opacity)) !important;
}

.bg-teal-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(22, 94, 73, var(--bs-bg-opacity)) !important;
}

.text-teal-300 {
  --bs-text-opacity: 1;
  color: rgba(124, 196, 175, var(--bs-text-opacity)) !important;
}

.bg-teal-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(124, 196, 175, var(--bs-bg-opacity)) !important;
}

.text-teal-800 {
  --bs-text-opacity: 1;
  color: rgba(14, 63, 48, var(--bs-text-opacity)) !important;
}

.bg-teal-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(14, 63, 48, var(--bs-bg-opacity)) !important;
}

.text-teal-400 {
  --bs-text-opacity: 1;
  color: rgba(80, 177, 148, var(--bs-text-opacity)) !important;
}

.bg-teal-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(80, 177, 148, var(--bs-bg-opacity)) !important;
}

.text-teal-900 {
  --bs-text-opacity: 1;
  color: rgba(7, 31, 24, var(--bs-text-opacity)) !important;
}

.bg-teal-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(7, 31, 24, var(--bs-bg-opacity)) !important;
}

.bg-gradient-cyan {
  background-color: #009be3 !important;
  background-image: linear-gradient(to bottom, #17b5ff, #008aca) !important;
}

.bg-gradient-from-cyan {
  --bs-bg-gradient-from: #009be3;
}

.bg-gradient-to-cyan {
  --bs-bg-gradient-to: #009be3;
}

.hover-bg-cyan:hover {
  background-color: rgba(var(--bs-cyan-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-cyan:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-cyan {
  --bs-theme: #009be3 !important;
  --bs-theme-rgb: 0, 155, 227 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-cyan-100 {
  --bs-text-opacity: 1;
  color: rgba(204, 235, 249, var(--bs-text-opacity)) !important;
}

.bg-cyan-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 235, 249, var(--bs-bg-opacity)) !important;
}

.text-cyan-600 {
  --bs-text-opacity: 1;
  color: rgba(0, 124, 182, var(--bs-text-opacity)) !important;
}

.bg-cyan-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 124, 182, var(--bs-bg-opacity)) !important;
}

.text-cyan-200 {
  --bs-text-opacity: 1;
  color: rgba(153, 215, 244, var(--bs-text-opacity)) !important;
}

.bg-cyan-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 215, 244, var(--bs-bg-opacity)) !important;
}

.text-cyan-700 {
  --bs-text-opacity: 1;
  color: rgba(0, 93, 136, var(--bs-text-opacity)) !important;
}

.bg-cyan-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 93, 136, var(--bs-bg-opacity)) !important;
}

.text-cyan-300 {
  --bs-text-opacity: 1;
  color: rgba(102, 195, 238, var(--bs-text-opacity)) !important;
}

.bg-cyan-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 195, 238, var(--bs-bg-opacity)) !important;
}

.text-cyan-800 {
  --bs-text-opacity: 1;
  color: rgba(0, 62, 91, var(--bs-text-opacity)) !important;
}

.bg-cyan-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 62, 91, var(--bs-bg-opacity)) !important;
}

.text-cyan-400 {
  --bs-text-opacity: 1;
  color: rgba(51, 175, 233, var(--bs-text-opacity)) !important;
}

.bg-cyan-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 175, 233, var(--bs-bg-opacity)) !important;
}

.text-cyan-900 {
  --bs-text-opacity: 1;
  color: rgba(0, 31, 45, var(--bs-text-opacity)) !important;
}

.bg-cyan-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 31, 45, var(--bs-bg-opacity)) !important;
}

.bg-gradient-black {
  background-color: #000000 !important;
  background-image: linear-gradient(to bottom, #1a1a1a, black) !important;
}

.bg-gradient-from-black {
  --bs-bg-gradient-from: #000000;
}

.bg-gradient-to-black {
  --bs-bg-gradient-to: #000000;
}

.hover-bg-black:hover {
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-black:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-black {
  --bs-theme: #000000 !important;
  --bs-theme-rgb: 0, 0, 0 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-black-100 {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity)) !important;
}

.bg-black-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity)) !important;
}

.text-black-600 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-black-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-black-200 {
  --bs-text-opacity: 1;
  color: rgba(153, 153, 153, var(--bs-text-opacity)) !important;
}

.bg-black-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--bs-bg-opacity)) !important;
}

.text-black-700 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-black-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-black-300 {
  --bs-text-opacity: 1;
  color: rgba(102, 102, 102, var(--bs-text-opacity)) !important;
}

.bg-black-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--bs-bg-opacity)) !important;
}

.text-black-800 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-black-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}

.text-black-400 {
  --bs-text-opacity: 1;
  color: rgba(51, 51, 51, var(--bs-text-opacity)) !important;
}

.bg-black-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--bs-bg-opacity)) !important;
}

.text-black-900 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}

.bg-black-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-gradient-white {
  background-color: #FFFFFF !important;
  background-image: linear-gradient(to bottom, white, #f2f2f2) !important;
}

.bg-gradient-from-white {
  --bs-bg-gradient-from: #FFFFFF;
}

.bg-gradient-to-white {
  --bs-bg-gradient-to: #FFFFFF;
}

.hover-bg-white:hover {
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-white:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-white {
  --bs-theme: #FFFFFF !important;
  --bs-theme-rgb: 255, 255, 255 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}

.text-white-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-white-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.text-white-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity)) !important;
}

.bg-white-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity)) !important;
}

.text-white-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-white-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.text-white-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 153, 153, var(--bs-text-opacity)) !important;
}

.bg-white-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--bs-bg-opacity)) !important;
}

.text-white-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-white-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.text-white-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 102, 102, var(--bs-text-opacity)) !important;
}

.bg-white-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--bs-bg-opacity)) !important;
}

.text-white-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-white-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.text-white-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 51, 51, var(--bs-text-opacity)) !important;
}

.bg-white-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--bs-bg-opacity)) !important;
}

.bg-gradient-gray {
  background-color: #526872 !important;
  background-image: linear-gradient(to bottom, #678390, #475a63) !important;
}

.bg-gradient-from-gray {
  --bs-bg-gradient-from: #526872;
}

.bg-gradient-to-gray {
  --bs-bg-gradient-to: #526872;
}

.hover-bg-gray:hover {
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-gray:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-gray {
  --bs-theme: #526872 !important;
  --bs-theme-rgb: 82, 104, 114 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-gray-100 {
  --bs-text-opacity: 1;
  color: rgba(220, 225, 227, var(--bs-text-opacity)) !important;
}

.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 225, 227, var(--bs-bg-opacity)) !important;
}

.text-gray-600 {
  --bs-text-opacity: 1;
  color: rgba(66, 83, 91, var(--bs-text-opacity)) !important;
}

.bg-gray-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(66, 83, 91, var(--bs-bg-opacity)) !important;
}

.text-gray-200 {
  --bs-text-opacity: 1;
  color: rgba(186, 195, 199, var(--bs-text-opacity)) !important;
}

.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(186, 195, 199, var(--bs-bg-opacity)) !important;
}

.text-gray-700 {
  --bs-text-opacity: 1;
  color: rgba(49, 62, 68, var(--bs-text-opacity)) !important;
}

.bg-gray-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(49, 62, 68, var(--bs-bg-opacity)) !important;
}

.text-gray-300 {
  --bs-text-opacity: 1;
  color: rgba(151, 164, 170, var(--bs-text-opacity)) !important;
}

.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(151, 164, 170, var(--bs-bg-opacity)) !important;
}

.text-gray-800 {
  --bs-text-opacity: 1;
  color: rgba(33, 42, 46, var(--bs-text-opacity)) !important;
}

.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(33, 42, 46, var(--bs-bg-opacity)) !important;
}

.text-gray-400 {
  --bs-text-opacity: 1;
  color: rgba(117, 134, 142, var(--bs-text-opacity)) !important;
}

.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(117, 134, 142, var(--bs-bg-opacity)) !important;
}

.text-gray-900 {
  --bs-text-opacity: 1;
  color: rgba(16, 21, 23, var(--bs-text-opacity)) !important;
}

.bg-gray-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 21, 23, var(--bs-bg-opacity)) !important;
}

.bg-gradient-gray-dark {
  background-color: #35434a !important;
  background-image: linear-gradient(to bottom, #4a5e68, #2a363b) !important;
}

.bg-gradient-from-gray-dark {
  --bs-bg-gradient-from: #35434a;
}

.bg-gradient-to-gray-dark {
  --bs-bg-gradient-to: #35434a;
}

.hover-bg-gray-dark:hover {
  background-color: rgba(var(--bs-gray-dark-rgb), var(--bs-bg-opacity)) !important;
}
.hover-bg-gray-dark:hover:not([class*=bg-opacity-]) {
  --bs-bg-opacity: 1.0;
}

:root .theme-gray-dark {
  --bs-theme: #35434a !important;
  --bs-theme-rgb: 53, 67, 74 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

.text-gray-dark-100 {
  --bs-text-opacity: 1;
  color: rgba(215, 217, 219, var(--bs-text-opacity)) !important;
}

.bg-gray-dark-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(215, 217, 219, var(--bs-bg-opacity)) !important;
}

.text-gray-dark-600 {
  --bs-text-opacity: 1;
  color: rgba(42, 54, 59, var(--bs-text-opacity)) !important;
}

.bg-gray-dark-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(42, 54, 59, var(--bs-bg-opacity)) !important;
}

.text-gray-dark-200 {
  --bs-text-opacity: 1;
  color: rgba(174, 180, 183, var(--bs-text-opacity)) !important;
}

.bg-gray-dark-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(174, 180, 183, var(--bs-bg-opacity)) !important;
}

.text-gray-dark-700 {
  --bs-text-opacity: 1;
  color: rgba(32, 40, 44, var(--bs-text-opacity)) !important;
}

.bg-gray-dark-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(32, 40, 44, var(--bs-bg-opacity)) !important;
}

.text-gray-dark-300 {
  --bs-text-opacity: 1;
  color: rgba(134, 142, 146, var(--bs-text-opacity)) !important;
}

.bg-gray-dark-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 142, 146, var(--bs-bg-opacity)) !important;
}

.text-gray-dark-800 {
  --bs-text-opacity: 1;
  color: rgba(21, 27, 30, var(--bs-text-opacity)) !important;
}

.bg-gray-dark-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(21, 27, 30, var(--bs-bg-opacity)) !important;
}

.text-gray-dark-400 {
  --bs-text-opacity: 1;
  color: rgba(93, 105, 110, var(--bs-text-opacity)) !important;
}

.bg-gray-dark-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(93, 105, 110, var(--bs-bg-opacity)) !important;
}

.text-gray-dark-900 {
  --bs-text-opacity: 1;
  color: rgba(11, 13, 15, var(--bs-text-opacity)) !important;
}

.bg-gray-dark-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(11, 13, 15, var(--bs-bg-opacity)) !important;
}

/* Helper Background */
[data-bs-theme=dark] {
  --bs-default: #a8b6bc;
  --bs-default-rgb: 168, 182, 188;
  --bs-theme: #3cd2a5;
  --bs-theme-rgb: 60, 210, 165;
  --bs-primary: #4e9cff;
  --bs-primary-rgb: 78, 156, 255;
  --bs-secondary: #798e98;
  --bs-secondary-rgb: 121, 142, 152;
  --bs-warning: #ff9f0c;
  --bs-warning-rgb: 255, 159, 12;
  --bs-danger: #ff6060;
  --bs-danger-rgb: 255, 96, 96;
  --bs-success: #3cd2a5;
  --bs-success-rgb: 60, 210, 165;
  --bs-inverse: #FFFFFF;
  --bs-inverse-rgb: 255, 255, 255;
  --bs-info: #30beff;
  --bs-info-rgb: 48, 190, 255;
  --bs-dark: #35434a;
  --bs-dark-rgb: 53, 67, 74;
  --bs-black: #000000;
  --bs-black-rgb: 0, 0, 0;
  --bs-white: #FFFFFF;
  --bs-white-rgb: 255, 255, 255;
  --bs-purple: #de8cff;
  --bs-purple-rgb: 222, 140, 255;
  --bs-indigo: #bba4ff;
  --bs-indigo-rgb: 187, 164, 255;
  --bs-red: #ff6060;
  --bs-red-rgb: 255, 96, 96;
  --bs-pink: #f34fa0;
  --bs-pink-rgb: 243, 79, 160;
  --bs-green: #1ecc33;
  --bs-green-rgb: 30, 204, 51;
  --bs-yellow: #f9e80d;
  --bs-yellow-rgb: 249, 232, 13;
  --bs-teal: #3cd2a5;
  --bs-teal-rgb: 60, 210, 165;
  --bs-muted: #617a86;
  --bs-muted-rgb: 97, 122, 134;
  --bs-light: #c0cacf;
  --bs-light-rgb: 192, 202, 207;
  --bs-lime: #81f32d;
  --bs-lime-rgb: 129, 243, 45;
  --bs-gray-100: #c0cacf;
  --bs-gray-100-rgb: 192, 202, 207;
  --bs-gray-200: #a8b6bc;
  --bs-gray-200-rgb: 168, 182, 188;
  --bs-gray-300: #90a2aa;
  --bs-gray-300-rgb: 144, 162, 170;
  --bs-gray-400: #798e98;
  --bs-gray-400-rgb: 121, 142, 152;
  --bs-gray-500: #617a86;
  --bs-gray-500-rgb: 97, 122, 134;
  --bs-gray-600: #526872;
  --bs-gray-600-rgb: 82, 104, 114;
  --bs-gray-700: #44555e;
  --bs-gray-700-rgb: 68, 85, 94;
  --bs-gray-800: #35434a;
  --bs-gray-800-rgb: 53, 67, 74;
  --bs-gray-900: #273136;
  --bs-gray-900-rgb: 39, 49, 54;
}
[data-bs-theme=dark] .bg-gradient-default {
  background-color: #a8b6bc !important;
  background-image: -moz-linear-gradient(top, #c5ced2, #9aaab1) !important;
  background-image: -webkit-linear-gradient(top, #c5ced2, #9aaab1) !important;
  background-image: linear-gradient(to bottom, #c5ced2, #9aaab1) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-default {
  --bs-theme: #a8b6bc !important;
  --bs-theme-rgb: 168, 182, 188 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-default-100 {
  --bs-text-opacity: 1;
  color: rgba(238, 240, 242, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-default-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(238, 240, 242, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-default-600 {
  --bs-text-opacity: 1;
  color: rgba(134, 146, 150, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-default-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 146, 150, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-default-200 {
  --bs-text-opacity: 1;
  color: rgba(220, 226, 228, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-default-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 226, 228, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-default-700 {
  --bs-text-opacity: 1;
  color: rgba(101, 109, 113, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-default-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(101, 109, 113, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-default-300 {
  --bs-text-opacity: 1;
  color: rgba(203, 211, 215, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-default-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(203, 211, 215, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-default-800 {
  --bs-text-opacity: 1;
  color: rgba(67, 73, 75, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-default-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(67, 73, 75, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-default-400 {
  --bs-text-opacity: 1;
  color: rgba(185, 197, 201, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-default-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(185, 197, 201, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-default-900 {
  --bs-text-opacity: 1;
  color: rgba(34, 36, 38, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-default-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(34, 36, 38, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-theme {
  background-color: #3cd2a5 !important;
  background-image: -moz-linear-gradient(top, #65dcb8, #2ec799) !important;
  background-image: -webkit-linear-gradient(top, #65dcb8, #2ec799) !important;
  background-image: linear-gradient(to bottom, #65dcb8, #2ec799) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .border-theme {
  border-color: var(--bs-theme) !important;
}
[data-bs-theme=dark] .theme-theme {
  --bs-theme: #3cd2a5 !important;
  --bs-theme-rgb: 60, 210, 165 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-theme-100 {
  --bs-text-opacity: 1;
  color: rgba(216, 246, 237, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-theme-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(216, 246, 237, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-theme-600 {
  --bs-text-opacity: 1;
  color: rgba(48, 168, 132, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-theme-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 168, 132, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-theme-200 {
  --bs-text-opacity: 1;
  color: rgba(177, 237, 219, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-theme-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(177, 237, 219, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-theme-700 {
  --bs-text-opacity: 1;
  color: rgba(36, 126, 99, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-theme-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(36, 126, 99, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-theme-300 {
  --bs-text-opacity: 1;
  color: rgba(138, 228, 201, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-theme-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(138, 228, 201, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-theme-800 {
  --bs-text-opacity: 1;
  color: rgba(24, 84, 66, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-theme-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 84, 66, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-theme-400 {
  --bs-text-opacity: 1;
  color: rgba(99, 219, 183, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-theme-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(99, 219, 183, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-theme-900 {
  --bs-text-opacity: 1;
  color: rgba(12, 42, 33, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-theme-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(12, 42, 33, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-primary {
  background-color: #4e9cff !important;
  background-image: -moz-linear-gradient(top, #81b9ff, #358eff) !important;
  background-image: -webkit-linear-gradient(top, #81b9ff, #358eff) !important;
  background-image: linear-gradient(to bottom, #81b9ff, #358eff) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-primary {
  --bs-theme: #4e9cff !important;
  --bs-theme-rgb: 78, 156, 255 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-primary-100 {
  --bs-text-opacity: 1;
  color: rgba(220, 235, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-primary-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 235, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-primary-600 {
  --bs-text-opacity: 1;
  color: rgba(62, 125, 204, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-primary-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(62, 125, 204, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-primary-200 {
  --bs-text-opacity: 1;
  color: rgba(184, 215, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-primary-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(184, 215, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-primary-700 {
  --bs-text-opacity: 1;
  color: rgba(47, 94, 153, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-primary-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(47, 94, 153, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-primary-300 {
  --bs-text-opacity: 1;
  color: rgba(149, 196, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-primary-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(149, 196, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-primary-800 {
  --bs-text-opacity: 1;
  color: rgba(31, 62, 102, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-primary-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(31, 62, 102, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-primary-400 {
  --bs-text-opacity: 1;
  color: rgba(113, 176, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-primary-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(113, 176, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-primary-900 {
  --bs-text-opacity: 1;
  color: rgba(16, 31, 51, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-primary-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 31, 51, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-secondary {
  background-color: #798e98 !important;
  background-image: -moz-linear-gradient(top, #96a6ae, #6c818c) !important;
  background-image: -webkit-linear-gradient(top, #96a6ae, #6c818c) !important;
  background-image: linear-gradient(to bottom, #96a6ae, #6c818c) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-secondary {
  --bs-theme: #798e98 !important;
  --bs-theme-rgb: 121, 142, 152 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}
[data-bs-theme=dark] .text-secondary-100 {
  --bs-text-opacity: 1;
  color: rgba(228, 232, 234, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-secondary-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(228, 232, 234, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-secondary-600 {
  --bs-text-opacity: 1;
  color: rgba(97, 114, 122, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-secondary-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(97, 114, 122, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-secondary-200 {
  --bs-text-opacity: 1;
  color: rgba(201, 210, 214, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-secondary-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(201, 210, 214, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-secondary-700 {
  --bs-text-opacity: 1;
  color: rgba(73, 85, 91, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-secondary-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(73, 85, 91, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-secondary-300 {
  --bs-text-opacity: 1;
  color: rgba(175, 187, 193, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-secondary-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(175, 187, 193, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-secondary-800 {
  --bs-text-opacity: 1;
  color: rgba(48, 57, 61, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-secondary-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 57, 61, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-secondary-400 {
  --bs-text-opacity: 1;
  color: rgba(148, 165, 173, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-secondary-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(148, 165, 173, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-secondary-900 {
  --bs-text-opacity: 1;
  color: rgba(24, 28, 30, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-secondary-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 28, 30, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-warning {
  background-color: #ff9f0c !important;
  background-image: -moz-linear-gradient(top, #ffb33f, #f29200) !important;
  background-image: -webkit-linear-gradient(top, #ffb33f, #f29200) !important;
  background-image: linear-gradient(to bottom, #ffb33f, #f29200) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-warning {
  --bs-theme: #ff9f0c !important;
  --bs-theme-rgb: 255, 159, 12 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-warning-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 236, 206, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-warning-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 236, 206, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-warning-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 127, 10, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-warning-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 127, 10, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-warning-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 217, 158, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-warning-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 217, 158, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-warning-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 95, 7, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-warning-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 95, 7, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-warning-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 197, 109, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-warning-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 197, 109, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-warning-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 64, 5, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-warning-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 64, 5, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-warning-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 178, 61, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-warning-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 178, 61, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-warning-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 32, 2, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-warning-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 32, 2, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-danger {
  background-color: #ff6060 !important;
  background-image: -moz-linear-gradient(top, #ff9393, #ff4747) !important;
  background-image: -webkit-linear-gradient(top, #ff9393, #ff4747) !important;
  background-image: linear-gradient(to bottom, #ff9393, #ff4747) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-danger {
  --bs-theme: #ff6060 !important;
  --bs-theme-rgb: 255, 96, 96 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-danger-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 223, 223, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-danger-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 223, 223, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-danger-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 77, 77, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-danger-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 77, 77, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-danger-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 191, 191, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-danger-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 191, 191, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-danger-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 58, 58, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-danger-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 58, 58, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-danger-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 160, 160, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-danger-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 160, 160, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-danger-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 38, 38, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-danger-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 38, 38, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-danger-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 128, 128, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-danger-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 128, 128, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-danger-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 19, 19, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-danger-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 19, 19, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-success {
  background-color: #3cd2a5 !important;
  background-image: -moz-linear-gradient(top, #65dcb8, #2ec799) !important;
  background-image: -webkit-linear-gradient(top, #65dcb8, #2ec799) !important;
  background-image: linear-gradient(to bottom, #65dcb8, #2ec799) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-success {
  --bs-theme: #3cd2a5 !important;
  --bs-theme-rgb: 60, 210, 165 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-success-100 {
  --bs-text-opacity: 1;
  color: rgba(216, 246, 237, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-success-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(216, 246, 237, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-success-600 {
  --bs-text-opacity: 1;
  color: rgba(48, 168, 132, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-success-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 168, 132, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-success-200 {
  --bs-text-opacity: 1;
  color: rgba(177, 237, 219, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-success-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(177, 237, 219, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-success-700 {
  --bs-text-opacity: 1;
  color: rgba(36, 126, 99, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-success-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(36, 126, 99, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-success-300 {
  --bs-text-opacity: 1;
  color: rgba(138, 228, 201, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-success-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(138, 228, 201, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-success-800 {
  --bs-text-opacity: 1;
  color: rgba(24, 84, 66, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-success-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 84, 66, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-success-400 {
  --bs-text-opacity: 1;
  color: rgba(99, 219, 183, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-success-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(99, 219, 183, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-success-900 {
  --bs-text-opacity: 1;
  color: rgba(12, 42, 33, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-success-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(12, 42, 33, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-inverse {
  background-color: #FFFFFF !important;
  background-image: -moz-linear-gradient(top, white, #f2f2f2) !important;
  background-image: -webkit-linear-gradient(top, white, #f2f2f2) !important;
  background-image: linear-gradient(to bottom, white, #f2f2f2) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-inverse {
  --bs-theme: #FFFFFF !important;
  --bs-theme-rgb: 255, 255, 255 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-inverse-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-inverse-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-inverse-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-inverse-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-inverse-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-inverse-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-inverse-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 153, 153, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-inverse-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-inverse-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-inverse-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-inverse-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 102, 102, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-inverse-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-inverse-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-inverse-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-inverse-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 51, 51, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-inverse-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-info {
  background-color: #30beff !important;
  background-image: -moz-linear-gradient(top, #63ceff, #17b6ff) !important;
  background-image: -webkit-linear-gradient(top, #63ceff, #17b6ff) !important;
  background-image: linear-gradient(to bottom, #63ceff, #17b6ff) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-info {
  --bs-theme: #30beff !important;
  --bs-theme-rgb: 48, 190, 255 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-info-100 {
  --bs-text-opacity: 1;
  color: rgba(214, 242, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-info-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(214, 242, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-info-600 {
  --bs-text-opacity: 1;
  color: rgba(38, 152, 204, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-info-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(38, 152, 204, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-info-200 {
  --bs-text-opacity: 1;
  color: rgba(172, 229, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-info-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(172, 229, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-info-700 {
  --bs-text-opacity: 1;
  color: rgba(29, 114, 153, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-info-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(29, 114, 153, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-info-300 {
  --bs-text-opacity: 1;
  color: rgba(131, 216, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-info-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(131, 216, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-info-800 {
  --bs-text-opacity: 1;
  color: rgba(19, 76, 102, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-info-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(19, 76, 102, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-info-400 {
  --bs-text-opacity: 1;
  color: rgba(89, 203, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-info-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(89, 203, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-info-900 {
  --bs-text-opacity: 1;
  color: rgba(10, 38, 51, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-info-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(10, 38, 51, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-dark {
  background-color: #35434a !important;
  background-image: -moz-linear-gradient(top, #4a5e68, #2a363b) !important;
  background-image: -webkit-linear-gradient(top, #4a5e68, #2a363b) !important;
  background-image: linear-gradient(to bottom, #4a5e68, #2a363b) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-dark {
  --bs-theme: #35434a !important;
  --bs-theme-rgb: 53, 67, 74 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}
[data-bs-theme=dark] .text-dark-100 {
  --bs-text-opacity: 1;
  color: rgba(215, 217, 219, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-dark-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(215, 217, 219, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-dark-600 {
  --bs-text-opacity: 1;
  color: rgba(42, 54, 59, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-dark-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(42, 54, 59, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-dark-200 {
  --bs-text-opacity: 1;
  color: rgba(174, 180, 183, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-dark-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(174, 180, 183, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-dark-700 {
  --bs-text-opacity: 1;
  color: rgba(32, 40, 44, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-dark-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(32, 40, 44, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-dark-300 {
  --bs-text-opacity: 1;
  color: rgba(134, 142, 146, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-dark-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 142, 146, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-dark-800 {
  --bs-text-opacity: 1;
  color: rgba(21, 27, 30, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-dark-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(21, 27, 30, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-dark-400 {
  --bs-text-opacity: 1;
  color: rgba(93, 105, 110, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-dark-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(93, 105, 110, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-dark-900 {
  --bs-text-opacity: 1;
  color: rgba(11, 13, 15, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-dark-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(11, 13, 15, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-black {
  background-color: #000000 !important;
  background-image: -moz-linear-gradient(top, #1a1a1a, black) !important;
  background-image: -webkit-linear-gradient(top, #1a1a1a, black) !important;
  background-image: linear-gradient(to bottom, #1a1a1a, black) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-black {
  --bs-theme: #000000 !important;
  --bs-theme-rgb: 0, 0, 0 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}
[data-bs-theme=dark] .text-black-100 {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-black-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-black-600 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-black-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-black-200 {
  --bs-text-opacity: 1;
  color: rgba(153, 153, 153, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-black-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-black-700 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-black-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-black-300 {
  --bs-text-opacity: 1;
  color: rgba(102, 102, 102, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-black-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-black-800 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-black-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-black-400 {
  --bs-text-opacity: 1;
  color: rgba(51, 51, 51, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-black-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-black-900 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-black-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-white {
  background-color: #FFFFFF !important;
  background-image: -moz-linear-gradient(top, white, #f2f2f2) !important;
  background-image: -webkit-linear-gradient(top, white, #f2f2f2) !important;
  background-image: linear-gradient(to bottom, white, #f2f2f2) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-white {
  --bs-theme: #FFFFFF !important;
  --bs-theme-rgb: 255, 255, 255 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-white-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-white-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-white-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-white-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-white-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-white-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-white-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 153, 153, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-white-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-white-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-white-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-white-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 102, 102, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-white-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-white-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-white-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-white-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 51, 51, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-white-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-purple {
  background-color: #de8cff !important;
  background-image: -moz-linear-gradient(top, #edbfff, #d773ff) !important;
  background-image: -webkit-linear-gradient(top, #edbfff, #d773ff) !important;
  background-image: linear-gradient(to bottom, #edbfff, #d773ff) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-purple {
  --bs-theme: #de8cff !important;
  --bs-theme-rgb: 222, 140, 255 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-purple-100 {
  --bs-text-opacity: 1;
  color: rgba(248, 232, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-purple-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(248, 232, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-purple-600 {
  --bs-text-opacity: 1;
  color: rgba(178, 112, 204, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-purple-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(178, 112, 204, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-purple-200 {
  --bs-text-opacity: 1;
  color: rgba(242, 209, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-purple-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(242, 209, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-purple-700 {
  --bs-text-opacity: 1;
  color: rgba(133, 84, 153, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-purple-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(133, 84, 153, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-purple-300 {
  --bs-text-opacity: 1;
  color: rgba(235, 186, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-purple-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(235, 186, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-purple-800 {
  --bs-text-opacity: 1;
  color: rgba(89, 56, 102, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-purple-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(89, 56, 102, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-purple-400 {
  --bs-text-opacity: 1;
  color: rgba(229, 163, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-purple-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(229, 163, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-purple-900 {
  --bs-text-opacity: 1;
  color: rgba(44, 28, 51, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-purple-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(44, 28, 51, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-indigo {
  background-color: #bba4ff !important;
  background-image: -moz-linear-gradient(top, #e1d7ff, #a88bff) !important;
  background-image: -webkit-linear-gradient(top, #e1d7ff, #a88bff) !important;
  background-image: linear-gradient(to bottom, #e1d7ff, #a88bff) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-indigo {
  --bs-theme: #bba4ff !important;
  --bs-theme-rgb: 187, 164, 255 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-indigo-100 {
  --bs-text-opacity: 1;
  color: rgba(241, 237, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-indigo-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(241, 237, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-indigo-600 {
  --bs-text-opacity: 1;
  color: rgba(150, 131, 204, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-indigo-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(150, 131, 204, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-indigo-200 {
  --bs-text-opacity: 1;
  color: rgba(228, 219, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-indigo-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(228, 219, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-indigo-700 {
  --bs-text-opacity: 1;
  color: rgba(112, 98, 153, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-indigo-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(112, 98, 153, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-indigo-300 {
  --bs-text-opacity: 1;
  color: rgba(214, 200, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-indigo-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(214, 200, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-indigo-800 {
  --bs-text-opacity: 1;
  color: rgba(75, 66, 102, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-indigo-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(75, 66, 102, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-indigo-400 {
  --bs-text-opacity: 1;
  color: rgba(201, 182, 255, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-indigo-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(201, 182, 255, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-indigo-900 {
  --bs-text-opacity: 1;
  color: rgba(37, 33, 51, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-indigo-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(37, 33, 51, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-red {
  background-color: #ff6060 !important;
  background-image: -moz-linear-gradient(top, #ff9393, #ff4747) !important;
  background-image: -webkit-linear-gradient(top, #ff9393, #ff4747) !important;
  background-image: linear-gradient(to bottom, #ff9393, #ff4747) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-red {
  --bs-theme: #ff6060 !important;
  --bs-theme-rgb: 255, 96, 96 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-red-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 223, 223, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-red-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 223, 223, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-red-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 77, 77, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-red-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 77, 77, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-red-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 191, 191, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-red-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 191, 191, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-red-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 58, 58, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-red-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 58, 58, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-red-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 160, 160, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-red-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 160, 160, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-red-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 38, 38, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-red-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 38, 38, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-red-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 128, 128, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-red-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 128, 128, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-red-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 19, 19, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-red-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 19, 19, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-pink {
  background-color: #f34fa0 !important;
  background-image: -moz-linear-gradient(top, #f67fba, #f13793) !important;
  background-image: -webkit-linear-gradient(top, #f67fba, #f13793) !important;
  background-image: linear-gradient(to bottom, #f67fba, #f13793) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-pink {
  --bs-theme: #f34fa0 !important;
  --bs-theme-rgb: 243, 79, 160 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}
[data-bs-theme=dark] .text-pink-100 {
  --bs-text-opacity: 1;
  color: rgba(253, 220, 236, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-pink-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(253, 220, 236, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-pink-600 {
  --bs-text-opacity: 1;
  color: rgba(194, 63, 128, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-pink-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(194, 63, 128, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-pink-200 {
  --bs-text-opacity: 1;
  color: rgba(250, 185, 217, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-pink-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(250, 185, 217, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-pink-700 {
  --bs-text-opacity: 1;
  color: rgba(146, 47, 96, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-pink-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(146, 47, 96, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-pink-300 {
  --bs-text-opacity: 1;
  color: rgba(248, 149, 198, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-pink-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(248, 149, 198, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-pink-800 {
  --bs-text-opacity: 1;
  color: rgba(97, 32, 64, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-pink-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(97, 32, 64, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-pink-400 {
  --bs-text-opacity: 1;
  color: rgba(245, 114, 179, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-pink-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(245, 114, 179, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-pink-900 {
  --bs-text-opacity: 1;
  color: rgba(49, 16, 32, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-pink-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(49, 16, 32, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-green {
  background-color: #1ecc33 !important;
  background-image: -moz-linear-gradient(top, #3be24f, #1bb62d) !important;
  background-image: -webkit-linear-gradient(top, #3be24f, #1bb62d) !important;
  background-image: linear-gradient(to bottom, #3be24f, #1bb62d) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-green {
  --bs-theme: #1ecc33 !important;
  --bs-theme-rgb: 30, 204, 51 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-green-100 {
  --bs-text-opacity: 1;
  color: rgba(210, 245, 214, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-green-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(210, 245, 214, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-green-600 {
  --bs-text-opacity: 1;
  color: rgba(24, 163, 41, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-green-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 163, 41, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-green-200 {
  --bs-text-opacity: 1;
  color: rgba(165, 235, 173, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-green-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(165, 235, 173, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-green-700 {
  --bs-text-opacity: 1;
  color: rgba(18, 122, 31, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-green-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(18, 122, 31, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-green-300 {
  --bs-text-opacity: 1;
  color: rgba(120, 224, 133, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-green-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(120, 224, 133, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-green-800 {
  --bs-text-opacity: 1;
  color: rgba(12, 82, 20, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-green-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(12, 82, 20, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-green-400 {
  --bs-text-opacity: 1;
  color: rgba(75, 214, 92, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-green-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(75, 214, 92, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-green-900 {
  --bs-text-opacity: 1;
  color: rgba(6, 41, 10, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-green-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(6, 41, 10, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-yellow {
  background-color: #f9e80d !important;
  background-image: -moz-linear-gradient(top, #faed3f, #e7d706) !important;
  background-image: -webkit-linear-gradient(top, #faed3f, #e7d706) !important;
  background-image: linear-gradient(to bottom, #faed3f, #e7d706) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-yellow {
  --bs-theme: #f9e80d !important;
  --bs-theme-rgb: 249, 232, 13 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-yellow-100 {
  --bs-text-opacity: 1;
  color: rgba(254, 250, 207, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-yellow-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(254, 250, 207, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-yellow-600 {
  --bs-text-opacity: 1;
  color: rgba(199, 186, 10, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-yellow-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(199, 186, 10, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-yellow-200 {
  --bs-text-opacity: 1;
  color: rgba(253, 246, 158, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-yellow-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(253, 246, 158, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-yellow-700 {
  --bs-text-opacity: 1;
  color: rgba(149, 139, 8, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-yellow-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(149, 139, 8, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-yellow-300 {
  --bs-text-opacity: 1;
  color: rgba(251, 241, 110, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-yellow-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(251, 241, 110, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-yellow-800 {
  --bs-text-opacity: 1;
  color: rgba(100, 93, 5, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-yellow-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(100, 93, 5, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-yellow-400 {
  --bs-text-opacity: 1;
  color: rgba(250, 237, 61, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-yellow-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(250, 237, 61, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-yellow-900 {
  --bs-text-opacity: 1;
  color: rgba(50, 46, 3, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-yellow-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(50, 46, 3, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-teal {
  background-color: #3cd2a5 !important;
  background-image: -moz-linear-gradient(top, #65dcb8, #2ec799) !important;
  background-image: -webkit-linear-gradient(top, #65dcb8, #2ec799) !important;
  background-image: linear-gradient(to bottom, #65dcb8, #2ec799) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-teal {
  --bs-theme: #3cd2a5 !important;
  --bs-theme-rgb: 60, 210, 165 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-teal-100 {
  --bs-text-opacity: 1;
  color: rgba(216, 246, 237, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-teal-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(216, 246, 237, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-teal-600 {
  --bs-text-opacity: 1;
  color: rgba(48, 168, 132, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-teal-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 168, 132, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-teal-200 {
  --bs-text-opacity: 1;
  color: rgba(177, 237, 219, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-teal-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(177, 237, 219, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-teal-700 {
  --bs-text-opacity: 1;
  color: rgba(36, 126, 99, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-teal-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(36, 126, 99, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-teal-300 {
  --bs-text-opacity: 1;
  color: rgba(138, 228, 201, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-teal-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(138, 228, 201, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-teal-800 {
  --bs-text-opacity: 1;
  color: rgba(24, 84, 66, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-teal-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 84, 66, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-teal-400 {
  --bs-text-opacity: 1;
  color: rgba(99, 219, 183, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-teal-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(99, 219, 183, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-teal-900 {
  --bs-text-opacity: 1;
  color: rgba(12, 42, 33, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-teal-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(12, 42, 33, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-muted {
  background-color: #617a86 !important;
  background-image: -moz-linear-gradient(top, #7b939f, #566d77) !important;
  background-image: -webkit-linear-gradient(top, #7b939f, #566d77) !important;
  background-image: linear-gradient(to bottom, #7b939f, #566d77) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-muted {
  --bs-theme: #617a86 !important;
  --bs-theme-rgb: 97, 122, 134 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}
[data-bs-theme=dark] .text-muted-100 {
  --bs-text-opacity: 1;
  color: rgba(223, 228, 231, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-muted-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(223, 228, 231, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-muted-600 {
  --bs-text-opacity: 1;
  color: rgba(78, 98, 107, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-muted-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(78, 98, 107, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-muted-200 {
  --bs-text-opacity: 1;
  color: rgba(192, 202, 207, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-muted-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(192, 202, 207, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-muted-700 {
  --bs-text-opacity: 1;
  color: rgba(58, 73, 80, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-muted-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(58, 73, 80, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-muted-300 {
  --bs-text-opacity: 1;
  color: rgba(160, 175, 182, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-muted-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(160, 175, 182, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-muted-800 {
  --bs-text-opacity: 1;
  color: rgba(39, 49, 54, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-muted-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(39, 49, 54, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-muted-400 {
  --bs-text-opacity: 1;
  color: rgba(129, 149, 158, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-muted-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(129, 149, 158, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-muted-900 {
  --bs-text-opacity: 1;
  color: rgba(19, 24, 27, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-muted-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(19, 24, 27, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-light {
  background-color: #c0cacf !important;
  background-image: -moz-linear-gradient(top, #dde2e5, #b2bec4) !important;
  background-image: -webkit-linear-gradient(top, #dde2e5, #b2bec4) !important;
  background-image: linear-gradient(to bottom, #dde2e5, #b2bec4) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-light {
  --bs-theme: #c0cacf !important;
  --bs-theme-rgb: 192, 202, 207 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-light-100 {
  --bs-text-opacity: 1;
  color: rgba(242, 244, 245, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-light-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(242, 244, 245, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-light-600 {
  --bs-text-opacity: 1;
  color: rgba(154, 162, 166, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-light-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(154, 162, 166, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-light-200 {
  --bs-text-opacity: 1;
  color: rgba(230, 234, 236, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-light-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(230, 234, 236, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-light-700 {
  --bs-text-opacity: 1;
  color: rgba(115, 121, 124, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-light-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(115, 121, 124, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-light-300 {
  --bs-text-opacity: 1;
  color: rgba(217, 223, 226, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-light-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(217, 223, 226, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-light-800 {
  --bs-text-opacity: 1;
  color: rgba(77, 81, 83, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-light-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(77, 81, 83, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-light-400 {
  --bs-text-opacity: 1;
  color: rgba(205, 213, 217, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-light-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(205, 213, 217, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-light-900 {
  --bs-text-opacity: 1;
  color: rgba(38, 40, 41, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-light-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(38, 40, 41, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-lime {
  background-color: #81f32d !important;
  background-image: -moz-linear-gradient(top, #9ef65d, #73f215) !important;
  background-image: -webkit-linear-gradient(top, #9ef65d, #73f215) !important;
  background-image: linear-gradient(to bottom, #9ef65d, #73f215) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-lime {
  --bs-theme: #81f32d !important;
  --bs-theme-rgb: 129, 243, 45 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-lime-100 {
  --bs-text-opacity: 1;
  color: rgba(230, 253, 213, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-lime-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(230, 253, 213, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-lime-600 {
  --bs-text-opacity: 1;
  color: rgba(103, 194, 36, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-lime-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(103, 194, 36, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-lime-200 {
  --bs-text-opacity: 1;
  color: rgba(205, 250, 171, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-lime-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(205, 250, 171, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-lime-700 {
  --bs-text-opacity: 1;
  color: rgba(77, 146, 27, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-lime-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(77, 146, 27, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-lime-300 {
  --bs-text-opacity: 1;
  color: rgba(179, 248, 129, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-lime-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(179, 248, 129, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-lime-800 {
  --bs-text-opacity: 1;
  color: rgba(52, 97, 18, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-lime-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(52, 97, 18, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-lime-400 {
  --bs-text-opacity: 1;
  color: rgba(154, 245, 87, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-lime-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(154, 245, 87, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-lime-900 {
  --bs-text-opacity: 1;
  color: rgba(26, 49, 9, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-lime-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(26, 49, 9, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-gray-100 {
  background-color: #c0cacf !important;
  background-image: -moz-linear-gradient(top, #dde2e5, #b2bec4) !important;
  background-image: -webkit-linear-gradient(top, #dde2e5, #b2bec4) !important;
  background-image: linear-gradient(to bottom, #dde2e5, #b2bec4) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-gray-100 {
  --bs-theme: #c0cacf !important;
  --bs-theme-rgb: 192, 202, 207 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-gray-100-100 {
  --bs-text-opacity: 1;
  color: rgba(242, 244, 245, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-100-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(242, 244, 245, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-100-600 {
  --bs-text-opacity: 1;
  color: rgba(154, 162, 166, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-100-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(154, 162, 166, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-100-200 {
  --bs-text-opacity: 1;
  color: rgba(230, 234, 236, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-100-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(230, 234, 236, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-100-700 {
  --bs-text-opacity: 1;
  color: rgba(115, 121, 124, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-100-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(115, 121, 124, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-100-300 {
  --bs-text-opacity: 1;
  color: rgba(217, 223, 226, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-100-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(217, 223, 226, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-100-800 {
  --bs-text-opacity: 1;
  color: rgba(77, 81, 83, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-100-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(77, 81, 83, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-100-400 {
  --bs-text-opacity: 1;
  color: rgba(205, 213, 217, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-100-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(205, 213, 217, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-100-900 {
  --bs-text-opacity: 1;
  color: rgba(38, 40, 41, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-100-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(38, 40, 41, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-gray-200 {
  background-color: #a8b6bc !important;
  background-image: -moz-linear-gradient(top, #c5ced2, #9aaab1) !important;
  background-image: -webkit-linear-gradient(top, #c5ced2, #9aaab1) !important;
  background-image: linear-gradient(to bottom, #c5ced2, #9aaab1) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-gray-200 {
  --bs-theme: #a8b6bc !important;
  --bs-theme-rgb: 168, 182, 188 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-gray-200-100 {
  --bs-text-opacity: 1;
  color: rgba(238, 240, 242, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-200-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(238, 240, 242, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-200-600 {
  --bs-text-opacity: 1;
  color: rgba(134, 146, 150, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-200-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 146, 150, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-200-200 {
  --bs-text-opacity: 1;
  color: rgba(220, 226, 228, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-200-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 226, 228, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-200-700 {
  --bs-text-opacity: 1;
  color: rgba(101, 109, 113, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-200-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(101, 109, 113, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-200-300 {
  --bs-text-opacity: 1;
  color: rgba(203, 211, 215, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-200-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(203, 211, 215, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-200-800 {
  --bs-text-opacity: 1;
  color: rgba(67, 73, 75, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-200-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(67, 73, 75, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-200-400 {
  --bs-text-opacity: 1;
  color: rgba(185, 197, 201, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-200-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(185, 197, 201, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-200-900 {
  --bs-text-opacity: 1;
  color: rgba(34, 36, 38, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-200-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(34, 36, 38, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-gray-300 {
  background-color: #90a2aa !important;
  background-image: -moz-linear-gradient(top, #adbac0, #82969f) !important;
  background-image: -webkit-linear-gradient(top, #adbac0, #82969f) !important;
  background-image: linear-gradient(to bottom, #adbac0, #82969f) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-gray-300 {
  --bs-theme: #90a2aa !important;
  --bs-theme-rgb: 144, 162, 170 !important;
  --bs-theme-color: #000000 !important;
  --bs-theme-color-rgb: 0, 0, 0 !important;
}
[data-bs-theme=dark] .text-gray-300-100 {
  --bs-text-opacity: 1;
  color: rgba(233, 236, 238, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-300-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(233, 236, 238, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-300-600 {
  --bs-text-opacity: 1;
  color: rgba(115, 130, 136, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-300-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(115, 130, 136, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-300-200 {
  --bs-text-opacity: 1;
  color: rgba(211, 218, 221, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-300-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(211, 218, 221, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-300-700 {
  --bs-text-opacity: 1;
  color: rgba(86, 97, 102, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-300-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(86, 97, 102, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-300-300 {
  --bs-text-opacity: 1;
  color: rgba(188, 199, 204, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-300-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(188, 199, 204, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-300-800 {
  --bs-text-opacity: 1;
  color: rgba(58, 65, 68, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-300-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(58, 65, 68, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-300-400 {
  --bs-text-opacity: 1;
  color: rgba(166, 181, 187, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-300-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(166, 181, 187, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-300-900 {
  --bs-text-opacity: 1;
  color: rgba(29, 32, 34, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-300-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(29, 32, 34, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-gray-400 {
  background-color: #798e98 !important;
  background-image: -moz-linear-gradient(top, #96a6ae, #6c818c) !important;
  background-image: -webkit-linear-gradient(top, #96a6ae, #6c818c) !important;
  background-image: linear-gradient(to bottom, #96a6ae, #6c818c) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-gray-400 {
  --bs-theme: #798e98 !important;
  --bs-theme-rgb: 121, 142, 152 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}
[data-bs-theme=dark] .text-gray-400-100 {
  --bs-text-opacity: 1;
  color: rgba(228, 232, 234, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-400-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(228, 232, 234, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-400-600 {
  --bs-text-opacity: 1;
  color: rgba(97, 114, 122, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-400-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(97, 114, 122, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-400-200 {
  --bs-text-opacity: 1;
  color: rgba(201, 210, 214, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-400-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(201, 210, 214, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-400-700 {
  --bs-text-opacity: 1;
  color: rgba(73, 85, 91, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-400-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(73, 85, 91, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-400-300 {
  --bs-text-opacity: 1;
  color: rgba(175, 187, 193, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-400-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(175, 187, 193, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-400-800 {
  --bs-text-opacity: 1;
  color: rgba(48, 57, 61, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-400-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 57, 61, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-400-400 {
  --bs-text-opacity: 1;
  color: rgba(148, 165, 173, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-400-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(148, 165, 173, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-400-900 {
  --bs-text-opacity: 1;
  color: rgba(24, 28, 30, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-400-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 28, 30, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-gray-500 {
  background-color: #617a86 !important;
  background-image: -moz-linear-gradient(top, #7b939f, #566d77) !important;
  background-image: -webkit-linear-gradient(top, #7b939f, #566d77) !important;
  background-image: linear-gradient(to bottom, #7b939f, #566d77) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-gray-500 {
  --bs-theme: #617a86 !important;
  --bs-theme-rgb: 97, 122, 134 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}
[data-bs-theme=dark] .text-gray-500-100 {
  --bs-text-opacity: 1;
  color: rgba(223, 228, 231, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-500-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(223, 228, 231, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-500-600 {
  --bs-text-opacity: 1;
  color: rgba(78, 98, 107, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-500-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(78, 98, 107, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-500-200 {
  --bs-text-opacity: 1;
  color: rgba(192, 202, 207, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-500-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(192, 202, 207, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-500-700 {
  --bs-text-opacity: 1;
  color: rgba(58, 73, 80, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-500-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(58, 73, 80, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-500-300 {
  --bs-text-opacity: 1;
  color: rgba(160, 175, 182, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-500-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(160, 175, 182, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-500-800 {
  --bs-text-opacity: 1;
  color: rgba(39, 49, 54, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-500-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(39, 49, 54, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-500-400 {
  --bs-text-opacity: 1;
  color: rgba(129, 149, 158, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-500-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(129, 149, 158, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-500-900 {
  --bs-text-opacity: 1;
  color: rgba(19, 24, 27, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-500-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(19, 24, 27, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-gray-600 {
  background-color: #526872 !important;
  background-image: -moz-linear-gradient(top, #678390, #475a63) !important;
  background-image: -webkit-linear-gradient(top, #678390, #475a63) !important;
  background-image: linear-gradient(to bottom, #678390, #475a63) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-gray-600 {
  --bs-theme: #526872 !important;
  --bs-theme-rgb: 82, 104, 114 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}
[data-bs-theme=dark] .text-gray-600-100 {
  --bs-text-opacity: 1;
  color: rgba(220, 225, 227, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-600-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 225, 227, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-600-600 {
  --bs-text-opacity: 1;
  color: rgba(66, 83, 91, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-600-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(66, 83, 91, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-600-200 {
  --bs-text-opacity: 1;
  color: rgba(186, 195, 199, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-600-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(186, 195, 199, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-600-700 {
  --bs-text-opacity: 1;
  color: rgba(49, 62, 68, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-600-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(49, 62, 68, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-600-300 {
  --bs-text-opacity: 1;
  color: rgba(151, 164, 170, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-600-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(151, 164, 170, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-600-800 {
  --bs-text-opacity: 1;
  color: rgba(33, 42, 46, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-600-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(33, 42, 46, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-600-400 {
  --bs-text-opacity: 1;
  color: rgba(117, 134, 142, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-600-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(117, 134, 142, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-600-900 {
  --bs-text-opacity: 1;
  color: rgba(16, 21, 23, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-600-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 21, 23, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-gray-700 {
  background-color: #44555e !important;
  background-image: -moz-linear-gradient(top, #59707c, #39484f) !important;
  background-image: -webkit-linear-gradient(top, #59707c, #39484f) !important;
  background-image: linear-gradient(to bottom, #59707c, #39484f) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-gray-700 {
  --bs-theme: #44555e !important;
  --bs-theme-rgb: 68, 85, 94 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}
[data-bs-theme=dark] .text-gray-700-100 {
  --bs-text-opacity: 1;
  color: rgba(218, 221, 223, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-700-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(218, 221, 223, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-700-600 {
  --bs-text-opacity: 1;
  color: rgba(54, 68, 75, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-700-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(54, 68, 75, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-700-200 {
  --bs-text-opacity: 1;
  color: rgba(180, 187, 191, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-700-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(180, 187, 191, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-700-700 {
  --bs-text-opacity: 1;
  color: rgba(41, 51, 56, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-700-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(41, 51, 56, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-700-300 {
  --bs-text-opacity: 1;
  color: rgba(143, 153, 158, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-700-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(143, 153, 158, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-700-800 {
  --bs-text-opacity: 1;
  color: rgba(27, 34, 38, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-700-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(27, 34, 38, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-700-400 {
  --bs-text-opacity: 1;
  color: rgba(105, 119, 126, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-700-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(105, 119, 126, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-700-900 {
  --bs-text-opacity: 1;
  color: rgba(14, 17, 19, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-700-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(14, 17, 19, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-gray-800 {
  background-color: #35434a !important;
  background-image: -moz-linear-gradient(top, #4a5e68, #2a363b) !important;
  background-image: -webkit-linear-gradient(top, #4a5e68, #2a363b) !important;
  background-image: linear-gradient(to bottom, #4a5e68, #2a363b) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-gray-800 {
  --bs-theme: #35434a !important;
  --bs-theme-rgb: 53, 67, 74 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}
[data-bs-theme=dark] .text-gray-800-100 {
  --bs-text-opacity: 1;
  color: rgba(215, 217, 219, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-800-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(215, 217, 219, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-800-600 {
  --bs-text-opacity: 1;
  color: rgba(42, 54, 59, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-800-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(42, 54, 59, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-800-200 {
  --bs-text-opacity: 1;
  color: rgba(174, 180, 183, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-800-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(174, 180, 183, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-800-700 {
  --bs-text-opacity: 1;
  color: rgba(32, 40, 44, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-800-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(32, 40, 44, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-800-300 {
  --bs-text-opacity: 1;
  color: rgba(134, 142, 146, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-800-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 142, 146, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-800-800 {
  --bs-text-opacity: 1;
  color: rgba(21, 27, 30, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-800-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(21, 27, 30, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-800-400 {
  --bs-text-opacity: 1;
  color: rgba(93, 105, 110, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-800-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(93, 105, 110, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-800-900 {
  --bs-text-opacity: 1;
  color: rgba(11, 13, 15, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-800-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(11, 13, 15, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .bg-gradient-gray-900 {
  background-color: #273136 !important;
  background-image: -moz-linear-gradient(top, #3c4c54, #1c2427) !important;
  background-image: -webkit-linear-gradient(top, #3c4c54, #1c2427) !important;
  background-image: linear-gradient(to bottom, #3c4c54, #1c2427) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="lighten($value, 10%)", endColorstr="darken($value, 5%)",GradientType=0 ) !important;
}
[data-bs-theme=dark] .theme-gray-900 {
  --bs-theme: #273136 !important;
  --bs-theme-rgb: 39, 49, 54 !important;
  --bs-theme-color: #FFFFFF !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}
[data-bs-theme=dark] .text-gray-900-100 {
  --bs-text-opacity: 1;
  color: rgba(212, 214, 215, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-900-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(212, 214, 215, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-900-600 {
  --bs-text-opacity: 1;
  color: rgba(31, 39, 43, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-900-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(31, 39, 43, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-900-200 {
  --bs-text-opacity: 1;
  color: rgba(169, 173, 175, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-900-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(169, 173, 175, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-900-700 {
  --bs-text-opacity: 1;
  color: rgba(23, 29, 32, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-900-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(23, 29, 32, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-900-300 {
  --bs-text-opacity: 1;
  color: rgba(125, 131, 134, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-900-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(125, 131, 134, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-900-800 {
  --bs-text-opacity: 1;
  color: rgba(16, 20, 22, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-900-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 20, 22, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-900-400 {
  --bs-text-opacity: 1;
  color: rgba(82, 90, 94, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-900-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(82, 90, 94, var(--bs-bg-opacity)) !important;
}
[data-bs-theme=dark] .text-gray-900-900 {
  --bs-text-opacity: 1;
  color: rgba(8, 10, 11, var(--bs-text-opacity)) !important;
}
[data-bs-theme=dark] .bg-gray-900-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(8, 10, 11, var(--bs-bg-opacity)) !important;
}

.bg-none {
  background: none !important;
}

.bg-position-center {
  background-position: center !important;
}

.bg-size-cover {
  background-size: cover !important;
}

.bg-repeat-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-gradient-from-transparent {
  --bs-bg-gradient-from: transparent;
}

.bg-gradient-to-transparent {
  --bs-bg-gradient-to: transparent;
}

.bg-gradient-to-r {
  background: linear-gradient(to right, var(--bs-bg-gradient-from) 0%, var(--bs-bg-gradient-to) 100%);
}

.bg-gradient-to-l {
  background: linear-gradient(to left, var(--bs-bg-gradient-from) 0%, var(--bs-bg-gradient-to) 100%);
}

.bg-gradient-to-t {
  background: linear-gradient(to top, var(--bs-bg-gradient-from) 0%, var(--bs-bg-gradient-to) 100%);
}

.bg-gradient-to-b {
  background: linear-gradient(to bottom, var(--bs-bg-gradient-from) 0%, var(--bs-bg-gradient-to) 100%);
}

.bg-gradient-to-tr {
  background: linear-gradient(to top right, var(--bs-bg-gradient-from) 0%, var(--bs-bg-gradient-to) 100%);
}

.bg-gradient-to-tl {
  background: linear-gradient(to top left, var(--bs-bg-gradient-from) 0%, var(--bs-bg-gradient-to) 100%);
}

.bg-gradient-to-br {
  background: linear-gradient(to bottom right, var(--bs-bg-gradient-from) 0%, var(--bs-bg-gradient-to) 100%);
}

.bg-gradient-to-bl {
  background: linear-gradient(to bottom left, var(--bs-bg-gradient-from) 0%, var(--bs-bg-gradient-to) 100%);
}

.bg-gradient-radial {
  background: radial-gradient(circle, var(--bs-bg-gradient-from) 0%, var(--bs-bg-gradient-to) 100%);
}

.bg-gradient-conic {
  background: conic-gradient(var(--bs-bg-gradient-from) 0deg, var(--bs-bg-gradient-to) 360deg);
}

.bg-gradient-45 {
  background: linear-gradient(45deg, var(--bs-bg-gradient-from) 0%, var(--bs-bg-gradient-to) 100%);
}

.bg-gradient-135 {
  background: linear-gradient(135deg, var(--bs-bg-gradient-from) 0%, var(--bs-bg-gradient-to) 100%);
}

.bg-blur-1 {
  backdrop-filter: blur(3px) !important;
}

.bg-blur-2 {
  backdrop-filter: blur(6px) !important;
}

.bg-blur-3 {
  backdrop-filter: blur(9px) !important;
}

.bg-gradient-orange-red {
  background: #ffb33f;
}

.bg-gradient-orange-red {
  background: -moz-linear-gradient(top, #ffb33f, #ff1414) !important;
  background: -webkit-linear-gradient(top, #ffb33f, #ff1414) !important;
  background: linear-gradient(to top, #ffb33f, #ff1414) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-red-orange {
  background: #ff1414;
}

.bg-gradient-red-orange {
  background: -moz-linear-gradient(top, #ff1414, #ffb33f) !important;
  background: -webkit-linear-gradient(top, #ff1414, #ffb33f) !important;
  background: linear-gradient(to top, #ff1414, #ffb33f) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-red-pink {
  background: #ff1414;
}

.bg-gradient-red-pink {
  background: -moz-linear-gradient(top, #ff1414, #f13893) !important;
  background: -webkit-linear-gradient(top, #ff1414, #f13893) !important;
  background: linear-gradient(to top, #ff1414, #f13893) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-yellow-orange {
  background: #faed3f;
}

.bg-gradient-yellow-orange {
  background: -moz-linear-gradient(top, #faed3f, #ffb33f) !important;
  background: -webkit-linear-gradient(top, #faed3f, #ffb33f) !important;
  background: linear-gradient(to top, #faed3f, #ffb33f) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-yellow-red {
  background: #faed3f;
}

.bg-gradient-yellow-red {
  background: -moz-linear-gradient(top, #faed3f, #ff1414) !important;
  background: -webkit-linear-gradient(top, #faed3f, #ff1414) !important;
  background: linear-gradient(to top, #faed3f, #ff1414) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-yellow-green {
  background: #faed3f;
}

.bg-gradient-yellow-green {
  background: -moz-linear-gradient(top, #faed3f, #1ab62d) !important;
  background: -webkit-linear-gradient(top, #faed3f, #1ab62d) !important;
  background: linear-gradient(to top, #faed3f, #1ab62d) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-blue-indigo {
  background: #0271ff;
}

.bg-gradient-blue-indigo {
  background: -moz-linear-gradient(top, #0271ff, #5c25ff) !important;
  background: -webkit-linear-gradient(top, #0271ff, #5c25ff) !important;
  background: linear-gradient(to top, #0271ff, #5c25ff) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-blue-cyan {
  background: #0271ff;
}

.bg-gradient-blue-cyan {
  background: -moz-linear-gradient(top, #0271ff, #009be3) !important;
  background: -webkit-linear-gradient(top, #0271ff, #009be3) !important;
  background: linear-gradient(to top, #0271ff, #009be3) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-cyan-blue {
  background: #009be3;
}

.bg-gradient-cyan-blue {
  background: -moz-linear-gradient(top, #009be3, #0271ff) !important;
  background: -webkit-linear-gradient(top, #009be3, #0271ff) !important;
  background: linear-gradient(to top, #009be3, #0271ff) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-cyan-indigo {
  background: #009be3;
}

.bg-gradient-cyan-indigo {
  background: -moz-linear-gradient(top, #009be3, #5c25ff) !important;
  background: -webkit-linear-gradient(top, #009be3, #5c25ff) !important;
  background: linear-gradient(to top, #009be3, #5c25ff) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-indigo-blue {
  background: #5c25ff;
}

.bg-gradient-indigo-blue {
  background: -moz-linear-gradient(top, #5c25ff, #0271ff) !important;
  background: -webkit-linear-gradient(top, #5c25ff, #0271ff) !important;
  background: linear-gradient(to top, #5c25ff, #0271ff) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-custom-orange {
  background: #f57260;
}

.bg-gradient-custom-orange {
  background: -moz-linear-gradient(right, #f57260, #f78f81) !important;
  background: -webkit-linear-gradient(right, #f57260, #f78f81) !important;
  background: linear-gradient(to left, #f57260, #f78f81) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-custom-pink {
  background: #ef1d84;
}

.bg-gradient-custom-pink {
  background: -moz-linear-gradient(right, #ef1d84, #f13f96) !important;
  background: -webkit-linear-gradient(right, #ef1d84, #f13f96) !important;
  background: linear-gradient(to left, #ef1d84, #f13f96) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-custom-teal {
  background: #1e8163;
}

.bg-gradient-custom-teal {
  background: -moz-linear-gradient(right, #1e8163, #249d79) !important;
  background: -webkit-linear-gradient(right, #1e8163, #249d79) !important;
  background: linear-gradient(to left, #1e8163, #249d79) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-custom-indigo {
  background: #8a66f6;
}

.bg-gradient-custom-indigo {
  background: -moz-linear-gradient(right, #8a66f6, #b39bf9) !important;
  background: -webkit-linear-gradient(right, #8a66f6, #b39bf9) !important;
  background: linear-gradient(to left, #8a66f6, #b39bf9) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-custom-blue {
  background: #0271ff;
}

.bg-gradient-custom-blue {
  background: -moz-linear-gradient(right, #0271ff, #358eff) !important;
  background: -webkit-linear-gradient(right, #0271ff, #358eff) !important;
  background: linear-gradient(to left, #0271ff, #358eff) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 ) !important;
}

.bg-gradient-indigo-teal {
  background-image: linear-gradient(to left, #5c25ff, #0271ff, #249d79) !important;
}

[data-bs-theme=dark] .invert-dark {
  filter: invert(1);
}

.bg-cover-2:after {
  background-image: url("images/cover-2.jpg");
}

.bg-cover-none:after {
  background-color: var(--bs-body-bg);
}

[data-bs-theme=dark].bg-cover-2:after {
  background-image: url("images/cover-2-dark.jpg");
}

.bg-cover-3:after {
  background-image: url("images/cover-3.jpg");
}

.bg-cover-none:after {
  background-color: var(--bs-body-bg);
}

[data-bs-theme=dark].bg-cover-3:after {
  background-image: url("images/cover-3-dark.jpg");
}

.bg-cover-4:after {
  background-image: url("images/cover-4.jpg");
}

.bg-cover-none:after {
  background-color: var(--bs-body-bg);
}

[data-bs-theme=dark].bg-cover-4:after {
  background-image: url("images/cover-4-dark.jpg");
}

.bg-cover-5:after {
  background-image: url("images/cover-5.jpg");
}

.bg-cover-none:after {
  background-color: var(--bs-body-bg);
}

[data-bs-theme=dark].bg-cover-5:after {
  background-image: url("images/cover-5-dark.jpg");
}

.bg-cover-6:after {
  background-image: url("images/cover-6.jpg");
}

.bg-cover-none:after {
  background-color: var(--bs-body-bg);
}

[data-bs-theme=dark].bg-cover-6:after {
  background-image: url("images/cover-6-dark.jpg");
}

.bg-cover-7:after {
  background-image: url("images/cover-7.jpg");
}

.bg-cover-none:after {
  background-color: var(--bs-body-bg);
}

[data-bs-theme=dark].bg-cover-7:after {
  background-image: url("images/cover-7-dark.jpg");
}

.bg-cover-8:after {
  background-image: url("images/cover-8.jpg");
}

.bg-cover-none:after {
  background-color: var(--bs-body-bg);
}

[data-bs-theme=dark].bg-cover-8:after {
  background-image: url("images/cover-8-dark.jpg");
}

.bg-cover-9:after {
  background-image: url("images/cover-9.jpg");
}

.bg-cover-none:after {
  background-color: var(--bs-body-bg);
}

[data-bs-theme=dark].bg-cover-9:after {
  background-image: url("images/cover-9-dark.jpg");
}

/* Helper Opacity */
.opacity-0 {
  opacity: 0 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.text-opacity-0 {
  --bs-text-opacity: 0;
}

.text-opacity-5 {
  --bs-text-opacity: 0.05;
}

.bg-opacity-0 {
  --bs-bg-opacity: 0;
}

.bg-opacity-5 {
  --bs-bg-opacity: 0.05;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.text-opacity-10 {
  --bs-text-opacity: 0.1;
}

.text-opacity-15 {
  --bs-text-opacity: 0.15;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-15 {
  --bs-bg-opacity: 0.15;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.text-opacity-20 {
  --bs-text-opacity: 0.2;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.bg-opacity-20 {
  --bs-bg-opacity: 0.2;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-45 {
  opacity: 0.45 !important;
}

.text-opacity-30 {
  --bs-text-opacity: 0.3;
}

.text-opacity-35 {
  --bs-text-opacity: 0.35;
}

.bg-opacity-30 {
  --bs-bg-opacity: 0.3;
}

.bg-opacity-35 {
  --bs-bg-opacity: 0.35;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.text-opacity-40 {
  --bs-text-opacity: 0.4;
}

.text-opacity-45 {
  --bs-text-opacity: 0.45;
}

.bg-opacity-40 {
  --bs-bg-opacity: 0.4;
}

.bg-opacity-45 {
  --bs-bg-opacity: 0.45;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-55 {
  --bs-text-opacity: 0.55;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-55 {
  --bs-bg-opacity: 0.55;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.text-opacity-60 {
  --bs-text-opacity: 0.6;
}

.text-opacity-65 {
  --bs-text-opacity: 0.65;
}

.bg-opacity-60 {
  --bs-bg-opacity: 0.6;
}

.bg-opacity-65 {
  --bs-bg-opacity: 0.65;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-105 {
  opacity: 1.05 !important;
}

.text-opacity-70 {
  --bs-text-opacity: 0.7;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.bg-opacity-70 {
  --bs-bg-opacity: 0.7;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-120 {
  opacity: 1.2 !important;
}

.text-opacity-80 {
  --bs-text-opacity: 0.8;
}

.text-opacity-85 {
  --bs-text-opacity: 0.85;
}

.bg-opacity-80 {
  --bs-bg-opacity: 0.8;
}

.bg-opacity-85 {
  --bs-bg-opacity: 0.85;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.opacity-135 {
  opacity: 1.35 !important;
}

.text-opacity-90 {
  --bs-text-opacity: 0.9;
}

.text-opacity-95 {
  --bs-text-opacity: 0.95;
}

.bg-opacity-90 {
  --bs-bg-opacity: 0.9;
}

.bg-opacity-95 {
  --bs-bg-opacity: 0.95;
}

.opacity-10 {
  opacity: 1 !important;
}

.opacity-150 {
  opacity: 1.5 !important;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-opacity-105 {
  --bs-text-opacity: 1.05;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-opacity-105 {
  --bs-bg-opacity: 1.05;
}

/* Helper Sticky Top */
@media (min-width: 1200px) {
  .desktop-sticky-top {
    position: sticky;
    top: 4.5rem;
  }
}

/* Helper Outline */
.outline-none {
  outline: none !important;
}

/* Helper Shimmer Loader */
.shimmer-loader {
  position: relative;
  overflow: hidden;
  background: rgba(var(--bs-body-color-rgb), 0.15);
}

.shimmer-loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent 0%, rgba(var(--bs-body-color-rgb), 0.1) 50%, transparent 100%);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
/* Helper Typing Animation */
.typing-animation {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 2.5s steps(50, end);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
/* Helper Flex */
.flex-1 {
  flex: 1 !important;
}

/* Helper Theme */
.list-group-item.active,
.page-item.active .page-link,
.form-check-input:checked,
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--bs-theme);
  border-color: var(--bs-theme);
}

.btn-theme {
  background-color: var(--bs-theme);
  border-color: var(--bs-theme);
  position: relative;
}
.btn-theme:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn-theme:before {
    transition: none;
  }
}
.btn-theme.disabled, .btn-theme:disabled {
  background-color: var(--bs-theme);
  border-color: var(--bs-theme);
}
.btn-theme:hover, .btn-theme:focus, .btn-theme:active, .btn-theme.active {
  background-color: var(--bs-theme);
  border-color: var(--bs-theme);
}
.btn-theme:hover:before, .btn-theme:focus:before, .btn-theme:active:before, .btn-theme.active:before {
  background: rgba(255, 255, 255, 0.2);
}
.btn-outline-theme {
  color: var(--bs-theme);
  border-color: var(--bs-theme);
}
.btn-outline-theme:hover, .btn-outline-theme.active {
  background-color: var(--bs-theme);
  border-color: var(--bs-theme);
}
.btn-outline-theme.disabled, .btn-outline-theme:disabled {
  color: var(--bs-theme);
}
.nav-link {
  color: var(--bs-theme);
}
.nav-link:hover {
  color: rgba(var(--bs-theme-rgb), 0.5);
}

.app-content {
  padding: 2rem 2rem;
  flex: 1;
  margin-left: 16.875rem;
}
@media (max-width: 767.98px) {
  .app-content {
    padding: 1rem 1rem;
    margin-left: 0;
  }
}
.app-content .app-content-inner-padding {
  padding: 2rem 2rem;
}
@media (max-width: 767.98px) {
  .app-content .app-content-inner-padding {
    padding: 1rem 1rem;
  }
}
.app-content .app-content-inner-padding-x {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (max-width: 767.98px) {
  .app-content .app-content-inner-padding-x {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.app-content .app-content-inner-padding-y {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media (max-width: 767.98px) {
  .app-content .app-content-inner-padding-y {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.app-footer {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.25rem 0;
  border-top: 1px solid var(--bs-border-color);
  font-weight: 600;
  color: rgba(var(--bs-body-color-rgb), 0.5);
  margin-left: 18.875rem;
  margin-right: 2rem;
}
@media (max-width: 767.98px) {
  .app-footer {
    padding: 1rem 0;
    margin: 0 1rem;
  }
}

.app-header {
  height: 3.25rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1020;
  background: var(--bs-app-header-bg);
  display: flex;
  transition: all 0.15s ease-in-out;
}
.app-header .menu-toggler {
  border: none;
  background: none;
  height: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app-header .menu-toggler .bar {
  width: 1.625rem;
  display: block;
  height: 1px;
  background: rgba(var(--bs-app-header-link-color-rgb), 0.4);
  transition: all 0.2s linear;
}
.app-header .menu-toggler .bar + .bar {
  margin-top: 0.25rem;
}
.app-header .menu-toggler:hover .bar {
  background: rgba(var(--bs-app-header-link-color-rgb), 0.75);
}
.app-header .mobile-toggler {
  height: 100%;
  display: none;
  margin-right: auto;
}
@media (max-width: 767.98px) {
  .app-header .mobile-toggler {
    display: flex;
    align-items: center;
  }
}
.app-header .desktop-toggler {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: auto;
}
@media (max-width: 767.98px) {
  .app-header .desktop-toggler {
    display: none;
  }
}
.app-header .brand {
  height: 100%;
  width: 18.875rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}
@media (max-width: 767.98px) {
  .app-header .brand {
    display: none;
  }
}
.app-header .brand .brand-logo {
  text-decoration: none;
  color: var(--bs-app-header-link-color);
  font-size: 0.91875rem;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
}
@media (max-width: 767.98px) {
  .app-header .brand .brand-logo {
    display: none;
  }
}
.app-header .brand .brand-logo img {
  max-width: 100%;
  max-height: 100%;
  margin-right: 1rem;
}
.app-header .brand .brand-logo .brand-img {
  width: 1.625rem;
  height: 1.625rem;
  background: var(--bs-app-header-brand-img);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
.app-header .brand .brand-logo .brand-img .brand-img-text {
  display: block;
  letter-spacing: initial;
  font-weight: 600;
  font-size: 1.05rem;
}
.app-header .menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}
.app-header .menu .menu-item {
  height: 100%;
  display: flex;
  align-items: center;
}
.app-header .menu .menu-item .menu-link {
  text-decoration: none;
  color: var(--bs-app-header-link-color);
  position: relative;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}
.app-header .menu .menu-item .menu-link .menu-img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.75rem;
}
.app-header .menu .menu-item .menu-link .menu-img img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 30px;
}
.app-header .menu .menu-item .menu-link .menu-icon {
  font-size: 1.25rem;
}
.app-header .menu .menu-item .menu-link .menu-badge {
  position: absolute;
  background: transparent;
  width: 6px;
  height: 6px;
  top: 0.5rem;
  border-radius: 6px;
  right: 1rem;
}
.app-header .menu-search {
  position: absolute;
  top: -3.25rem;
  left: 0;
  right: 0;
  height: 3.25rem;
  background: var(--bs-app-header-bg);
  transition: all 0.2s ease-in-out;
}
.app-header .menu-search .menu-search-container {
  max-width: 60.4375rem;
  margin: 0 auto;
  height: 3.25rem;
  display: flex;
}
.app-header .menu-search .menu-search-container .menu-search-icon {
  padding: 0 1rem;
  font-size: 1.05rem;
  display: flex;
  align-items: center;
}
.app-header .menu-search .menu-search-container .menu-search-icon .bi:before {
  display: block;
}
.app-header .menu-search .menu-search-container .menu-search-icon a {
  color: rgba(var(--bs-app-header-link-color-rgb), 0.5);
  text-decoration: none;
  display: block;
  transition: all 0.2s linear;
}
.app-header .menu-search .menu-search-container .menu-search-icon a:hover {
  color: var(--bs-app-header-link-color);
}
.app-header .menu-search .menu-search-container .menu-search-input {
  flex: 1;
}
.app-header .menu-search .menu-search-container .menu-search-input .form-control {
  border: none;
  padding-left: 0;
  height: 100%;
  box-shadow: none;
}
.app-header-menu-search-toggled .app-header .menu-search {
  top: 0;
}
@media (max-width: 991.98px) {
  .app-header.navbar .navbar-collapse {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: var(--bs-app-header-bg);
    padding: 0 1rem;
  }
}

.app-sidebar-mobile-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1020;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  margin: 0;
  outline: none;
  display: none;
  background: rgba(0, 0, 0, 0.25);
}

@keyframes appSidebarSubMenuSlideInRight {
  to {
    transform: translate3d(0, 0, 0);
  }
}
.app-sidebar {
  width: 16.875rem;
  bottom: 0;
  position: fixed;
  top: 3.25rem;
  z-index: 1010;
  left: 0;
}
@media (max-width: 767.98px) {
  .app-sidebar {
    top: 0;
    z-index: 1030;
    background: var(--bs-app-sidebar-mobile-bg);
    left: -16.875rem;
  }
}
.app-sidebar .slimScrollDiv {
  width: 100% !important;
}
.app-sidebar .slimScrollDiv .slimScrollBar, .app-sidebar .slimScrollDiv .slimScrollRail {
  display: none !important;
}
.app-sidebar .app-sidebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.app-sidebar .app-sidebar-content .menu {
  position: relative;
}
.app-sidebar .app-sidebar-content .menu .menu-header {
  padding: 1rem 1rem 0.5rem;
  font-size: 0.65625rem;
  font-weight: 600;
  color: var(--bs-app-sidebar-menu-header-color);
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-link {
  text-decoration: none;
  position: relative;
  padding: 0.3rem 1rem;
  color: var(--bs-app-sidebar-menu-link-color);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.2s ease-in-out;
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-link .menu-icon {
  width: 1.875rem;
  height: 1.875rem;
  font-size: 1.05rem;
  margin: -0.25rem 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-link .menu-caret {
  margin: -0.3125rem 0;
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-link .menu-text {
  flex: 1;
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-link:hover, .app-sidebar .app-sidebar-content .menu .menu-item .menu-link:focus {
  color: var(--bs-app-sidebar-menu-link-hover-color);
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-submenu {
  background: none;
  display: none;
  padding: 0 0 1rem;
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-submenu .menu-item {
  position: relative;
  padding-left: 2.625rem;
}
.app-sidebar .app-sidebar-content .menu .menu-item.active:not(.has-sub) > .menu-link {
  color: var(--bs-app-sidebar-menu-link-active-color);
}
.app-sidebar .app-sidebar-content .menu .menu-item.active > .menu-link .menu-icon {
  color: var(--bs-theme);
}
.app-sidebar .app-sidebar-content .menu .menu-item.active.has-sub > .menu-submenu {
  display: block;
}
.app-sidebar .app-sidebar-content .menu .menu-item.expand > .menu-submenu {
  display: block;
}
.app-sidebar .app-sidebar-content .menu .menu-item.expand > .menu-submenu > .menu-item {
  animation: appSidebarSubMenuSlideInRight 0.3s both cubic-bezier(0.7, 0, 0.3, 1);
  transform: translate3d(-100%, 0, 0);
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(2), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(2) {
  animation-delay: 45ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(3), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(3) {
  animation-delay: 60ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(4), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(4) {
  animation-delay: 75ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(5), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(5) {
  animation-delay: 90ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(6), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(6) {
  animation-delay: 105ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(7), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(7) {
  animation-delay: 120ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(8), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(8) {
  animation-delay: 135ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(9), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(9) {
  animation-delay: 150ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(10), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(10) {
  animation-delay: 165ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(11), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(11) {
  animation-delay: 180ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(12), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(12) {
  animation-delay: 195ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(13), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(13) {
  animation-delay: 210ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(14), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(14) {
  animation-delay: 225ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(15), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(15) {
  animation-delay: 240ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(16), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(16) {
  animation-delay: 255ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(17), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(17) {
  animation-delay: 270ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(18), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(18) {
  animation-delay: 285ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(19), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(19) {
  animation-delay: 300ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(20), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(20) {
  animation-delay: 315ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(21), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(21) {
  animation-delay: 330ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(22), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(22) {
  animation-delay: 345ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(23), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(23) {
  animation-delay: 360ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(24), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(24) {
  animation-delay: 375ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(25), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(25) {
  animation-delay: 390ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(26), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(26) {
  animation-delay: 405ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(27), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(27) {
  animation-delay: 420ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(28), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(28) {
  animation-delay: 435ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(29), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(29) {
  animation-delay: 450ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(30), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(30) {
  animation-delay: 465ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(31), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(31) {
  animation-delay: 480ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(32), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(32) {
  animation-delay: 495ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(33), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(33) {
  animation-delay: 510ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(34), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(34) {
  animation-delay: 525ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(35), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(35) {
  animation-delay: 540ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(36), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(36) {
  animation-delay: 555ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(37), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(37) {
  animation-delay: 570ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(38), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(38) {
  animation-delay: 585ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(39), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(39) {
  animation-delay: 600ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(40), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(40) {
  animation-delay: 615ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(41), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(41) {
  animation-delay: 630ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(42), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(42) {
  animation-delay: 645ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(43), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(43) {
  animation-delay: 660ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(44), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(44) {
  animation-delay: 675ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(45), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(45) {
  animation-delay: 690ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(46), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(46) {
  animation-delay: 705ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(47), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(47) {
  animation-delay: 720ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(48), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(48) {
  animation-delay: 735ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(49), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(49) {
  animation-delay: 750ms;
}
.app-sidebar .app-sidebar-content .menu .collapse.expand > .menu-item:nth-child(50), .app-sidebar .app-sidebar-content .menu .menu-item > .menu-submenu > .menu-item:nth-child(50) {
  animation-delay: 765ms;
}
.app-sidebar .app-sidebar-mobile-backdrop {
  display: none;
}
.app-sidebar.uppercase-mode {
  text-transform: uppercase;
}
.app-sidebar.uppercase-mode .app-sidebar-content .menu .menu-item .menu-link {
  font-size: 0.83125rem;
}

.app-theme-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1030;
  width: 0;
  display: flex;
  align-items: center;
  right: -12rem;
  transition: right 0.2s ease-in-out;
}
.app-theme-panel .app-theme-panel-container {
  width: 12rem;
  position: absolute;
  right: 0;
}
.app-theme-panel .app-theme-panel-content {
  width: 12rem;
  background: var(--bs-app-theme-panel-bg);
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  padding: 0.5rem;
  z-index: 10;
}
.app-theme-panel .app-theme-panel-content .app-theme-list {
  margin: -0.25rem;
  display: flex;
  flex-wrap: wrap;
}
.app-theme-panel .app-theme-panel-content .app-theme-list .app-theme-list-item {
  padding: 0.25rem;
  width: 25%;
}
.app-theme-panel .app-theme-panel-content .app-theme-list .app-theme-list-item .app-theme-list-link {
  height: 2rem;
  display: block;
  text-decoration: none;
  position: relative;
  transition: all 0.2s linear;
}
.app-theme-panel .app-theme-panel-content .app-theme-list .app-theme-list-item .app-theme-list-link:hover {
  opacity: 0.6;
}
.app-theme-panel .app-theme-panel-content .app-theme-list .app-theme-list-item.active .app-theme-list-link {
  opacity: 1;
}
.app-theme-panel .app-theme-panel-content .app-theme-list .app-theme-list-item.active .app-theme-list-link:before {
  content: "\f00c";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 0.875rem;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
.app-theme-panel .app-theme-panel-content .app-theme-mode-link {
  color: #FFFFFF;
  text-decoration: none;
  text-align: center;
  position: relative;
  display: block;
  transition: all 0.2s linear;
}
.app-theme-panel .app-theme-panel-content .app-theme-mode-link .img {
  position: relative;
}
.app-theme-panel .app-theme-panel-content .app-theme-mode-link .img:before {
  content: "\f00c";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 0.875rem;
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
.app-theme-panel .app-theme-panel-content .app-theme-mode-link .text {
  font-size: 0.65625rem;
  font-weight: 500;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.app-theme-panel .app-theme-panel-content .app-theme-mode-link:hover {
  opacity: 0.6;
}
.app-theme-panel .app-theme-panel-content .app-theme-mode-link.active {
  opacity: 1;
}
.app-theme-panel .app-theme-panel-content .app-theme-mode-link.active .img:before {
  color: #FFFFFF;
}
.app-theme-panel .app-theme-panel-content .app-theme-mode-link.active .img:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.app-theme-panel .app-theme-panel-content .app-theme-cover {
  margin: -0.25rem;
  display: flex;
  flex-wrap: wrap;
}
.app-theme-panel .app-theme-panel-content .app-theme-cover .app-theme-cover-item {
  padding: 0.25rem;
  width: 33.33%;
}
.app-theme-panel .app-theme-panel-content .app-theme-cover .app-theme-cover-item .app-theme-cover-link {
  display: block;
  text-decoration: none;
  position: relative;
  padding-top: 46%;
  background-size: cover;
  background-position: center;
  transition: all 0.2s linear;
}
.app-theme-panel .app-theme-panel-content .app-theme-cover .app-theme-cover-item .app-theme-cover-link:hover {
  opacity: 0.6;
}
.app-theme-panel .app-theme-panel-content .app-theme-cover .app-theme-cover-item .app-theme-cover-link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.25;
}
.app-theme-panel .app-theme-panel-content .app-theme-cover .app-theme-cover-item.active .app-theme-cover-link {
  opacity: 1;
}
.app-theme-panel .app-theme-panel-content .app-theme-cover .app-theme-cover-item.active .app-theme-cover-link:after {
  content: "\f00c";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 0.875rem;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
.app-theme-panel .app-theme-toggle-btn {
  position: absolute;
  top: 50%;
  margin-top: -1rem;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.09375rem;
  color: var(--bs-app-theme-panel-color);
  background: var(--bs-app-theme-panel-bg);
  text-align: center;
  left: 0;
  margin-left: -2rem;
}
.app-theme-panel.active {
  right: 0;
}

@keyframes topMenuControlSlideInLeft {
  from {
    left: -3.125rem;
  }
  to {
    left: 0;
  }
}
@keyframes topMenuControlSlideInRight {
  from {
    right: -3.125rem;
  }
  to {
    right: 0;
  }
}
.app-top-nav {
  background: var(--bs-app-top-nav-bg);
  left: 0;
  right: 0;
  z-index: 1010;
}
@media (min-width: 768px) {
  .app-top-nav {
    top: 3.25rem;
    position: fixed;
    height: 2.875rem;
  }
}
@media (max-width: 767.98px) {
  .app-top-nav {
    display: none;
  }
}
@media (min-width: 768px) {
  .app-top-nav .menu {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
}
@media (max-width: 767.98px) {
  .app-top-nav .menu {
    display: block;
    margin: 0 !important;
  }
}
.app-top-nav .menu .menu-item {
  position: relative;
}
.app-top-nav .menu .menu-item .menu-link {
  text-decoration: none;
  position: relative;
  padding: 0.75rem 1rem;
  color: var(--bs-app-top-nav-menu-link-color);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}
@media (max-width: 767.98px) {
  .app-top-nav .menu .menu-item .menu-link {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.app-top-nav .menu .menu-item .menu-link .menu-icon {
  width: 1.875rem;
  height: 1.875rem;
  font-size: 1.05rem;
  margin: -0.25rem 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
}
.app-top-nav .menu .menu-item .menu-link .menu-caret {
  margin: -0.3125rem 0;
  margin-left: 0.75rem;
}
.app-top-nav .menu .menu-item .menu-link .menu-text {
  flex: 1;
}
.app-top-nav .menu .menu-item .menu-link:hover, .app-top-nav .menu .menu-item .menu-link:focus {
  color: var(--bs-app-top-nav-menu-link-hover-color);
}
.app-top-nav .menu .menu-item .menu-submenu {
  background: var(--bs-app-top-nav-menu-submenu-bg);
  display: none;
  padding: 0.5rem 0;
  min-width: 12.5rem;
}
@media (min-width: 768px) {
  .app-top-nav .menu .menu-item .menu-submenu {
    position: absolute;
    max-height: calc(100vh - 6.125rem);
    left: 0;
  }
}
@media (max-width: 767.98px) {
  .app-top-nav .menu .menu-item .menu-submenu {
    padding-top: 0;
    padding-bottom: 1rem;
    padding-left: 2.625rem;
  }
}
.app-top-nav .menu .menu-item .menu-submenu .menu-item {
  position: relative;
}
.app-top-nav .menu .menu-item .menu-submenu .menu-item .menu-link {
  padding: 0.25rem 1rem;
}
.app-top-nav .menu .menu-item.active > .menu-link {
  color: var(--bs-app-top-nav-menu-link-active-color);
}
.app-top-nav .menu .menu-item.active > .menu-link .menu-icon {
  color: var(--bs-theme);
}
.app-top-nav .menu .menu-item.menu-control {
  position: absolute;
  top: 0;
  margin: 0;
  z-index: 10;
}
@media (max-width: 767.98px) {
  .app-top-nav .menu .menu-item.menu-control {
    display: none !important;
  }
}
.app-top-nav .menu .menu-item.menu-control > .menu-link {
  padding: 0;
  text-align: center;
  width: 2.875rem;
  height: 2.875rem;
  background: var(--bs-app-top-nav-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--bs-app-top-nav-menu-link-color);
}
.app-top-nav .menu .menu-item.menu-control > .menu-link i {
  float: none;
  margin: 0;
  font-size: 1.125rem;
  display: block;
  line-height: 2.5rem;
  width: auto;
}
.app-top-nav .menu .menu-item.menu-control > .menu-link:hover, .app-top-nav .menu .menu-item.menu-control > .menu-link:focus {
  color: var(--bs-app-top-nav-menu-link-hover-color);
}
.app-top-nav .menu .menu-item.menu-control.menu-control-start {
  left: -3.25rem;
}
.app-top-nav .menu .menu-item.menu-control.menu-control-start > .menu-link {
  box-shadow: 12px 0 10px -10px rgba(0, 0, 0, 0.5);
}
.app-top-nav .menu .menu-item.menu-control.menu-control-start.show {
  left: 0;
  animation: topMenuControlSlideInLeft 0.2s;
}
.app-top-nav .menu .menu-item.menu-control.menu-control-end {
  right: -3.25rem;
}
.app-top-nav .menu .menu-item.menu-control.menu-control-end > .menu-link {
  box-shadow: -12px 0 10px -10px rgba(0, 0, 0, 0.5);
}
.app-top-nav .menu .menu-item.menu-control.menu-control-end.show {
  right: 0;
  animation: topMenuControlSlideInRight 0.2s;
}
@media (min-width: 768px) {
  .app-top-nav .menu > .menu-item.has-sub:hover > .menu-submenu {
    display: block;
  }
}

.app {
  padding-top: 3.25rem;
}

@media (min-width: 992px) {
  .app-boxed-layout {
    min-height: 100vh;
  }
  .app-boxed-layout, .app-boxed-layout .app-header {
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
  }
  .app-boxed-layout .app-top-nav {
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
  .app-boxed-layout .app-top-nav .menu .menu-item .menu-submenu {
    position: fixed;
    left: auto;
    right: auto;
    top: auto;
  }
  .app-boxed-layout .app-sidebar {
    left: auto;
  }
}

.app-content-full-height .app-content {
  height: calc(100vh - 3.25rem);
}
.app-content-full-height.app-without-header .app-content {
  height: 100vh;
}

.app-content-full-width .app-content {
  margin: 0;
}
.app-content-full-width .app-footer {
  margin-left: 2rem;
}
@media (max-width: 767.98px) {
  .app-content-full-width .app-footer {
    margin-left: 1rem;
  }
}

.app-footer-fixed {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-full-height {
  min-height: 100vh;
}

@keyframes appSidebarCollapse {
  from {
    margin-left: 0;
    opacity: 1;
  }
  to {
    margin-left: -16.875rem;
    opacity: 0;
  }
}
@keyframes appContentCollapse {
  from {
    margin-left: 16.875rem;
  }
  to {
    margin-left: 0;
  }
}
@keyframes appFooterCollapse {
  from {
    margin-left: 16.875rem;
  }
  to {
    margin-left: 2rem;
  }
}
@media (min-width: 768px) {
  .app-sidebar-collapsed .app-sidebar {
    opacity: 0;
    margin-left: -16.875rem;
  }
  .app-init .app-sidebar-collapsed .app-sidebar {
    animation: appSidebarCollapse 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
  }
  .app-sidebar-collapsed .app-content {
    animation: appContentCollapse 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    margin-left: 0;
  }
  .app-sidebar-collapsed .app-footer {
    animation: appFooterCollapse 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    margin-left: 2rem;
  }
}

@keyframes appSidebarMobileSlideInLeft {
  from {
    left: -16.875rem;
  }
  to {
    left: 0;
  }
}
@keyframes appSidebarMobileBackdrop {
  from {
    background: transparent;
  }
  to {
    background: rgba(0, 0, 0, 0.25);
  }
}
@media (max-width: 767.98px) {
  .app-sidebar-mobile-toggled .app-sidebar {
    animation: appSidebarMobileSlideInLeft 0.1s ease;
    left: 0;
  }
  .app-sidebar-mobile-toggled .app-sidebar-mobile-backdrop:not(.app-sidebar-end) {
    display: block;
    background: rgba(0, 0, 0, 0.25);
    animation: appSidebarMobileBackdrop 0.1s ease;
  }
}

@keyframes appSidebarExpand {
  from {
    margin-left: -16.875rem;
  }
  to {
    margin-left: 0;
  }
}
@keyframes appContentExpand {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 16.875rem;
  }
}
@keyframes appFooterExpand {
  from {
    margin-left: 2rem;
  }
  to {
    margin-left: 18.875rem;
  }
}
@media (min-width: 768px) {
  .app-sidebar-toggled .app-sidebar {
    animation: appSidebarExpand 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    margin-left: 0;
  }
  .app-sidebar-toggled .app-content {
    animation: appContentExpand 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    margin-left: 16.875rem;
  }
  .app-sidebar-toggled .app-footer {
    animation: appFooterExpand 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    margin-left: 18.875rem;
  }
}

@media (min-width: 768px) {
  .app-with-top-nav {
    padding-top: 6.125rem;
  }
  .app-with-top-nav .app-sidebar {
    top: 6.125rem;
  }
}
@media (min-width: 768px) {
  .app-with-top-nav.app-without-header {
    padding-top: 2.875rem;
  }
  .app-with-top-nav.app-without-header .app-sidebar {
    top: 2.875rem;
  }
}

.app-without-header {
  padding-top: 0;
}

.app-without-sidebar .app-content {
  margin: 0;
}

.breadcrumb {
  font-weight: bold;
}
.breadcrumb .breadcrumb-item a {
  text-decoration: none;
}
.breadcrumb .breadcrumb-item a:hover {
  text-decoration: underline;
}
.btn-scroll-top {
  position: fixed;
  bottom: 1.875rem;
  width: 2.5rem;
  height: 2.5rem;
  background: rgba(53, 67, 74, 0.75);
  color: #FFFFFF;
  text-align: center;
  text-decoration: none;
  z-index: 1020;
  border-radius: 36px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1.875rem;
}
@media (max-width: 767.98px) {
  .btn-scroll-top {
    bottom: 1.25rem;
    z-index: 1020;
    right: 1.25rem;
  }
}
.btn-scroll-top:hover {
  background: var(--bs-theme);
  color: var(--bs-theme-color);
  text-decoration: none;
}

.card-expand .app-content {
  z-index: 1120;
  position: relative;
  height: calc(100vh - 3.25rem);
  overflow: hidden;
}

.card {
  --bs-border-color-rgb: var(--bs-body-color-rgb);
  position: relative;
  border: none;
}
.card.border-theme {
  --bs-border-color-rgb: var(--bs-theme-rgb);
}
.card.card-expand {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1120;
  border-radius: 0;
  margin: 0 !important;
  padding: 0.3125rem;
  background: var(--bs-app-header-bg);
}
.card.card-expand .card-header, .card.card-expand .card-body {
  border-radius: 0;
}
.card.card-expand .card-body {
  flex: 1 0 0;
  overflow: scroll;
}
.card.card-expand:not(.border-0):before {
  top: 5px;
  bottom: 5px;
  left: 20px;
  right: 20px;
}
.card.card-expand:not(.border-0):after {
  top: 20px;
  bottom: 20px;
  left: 5px;
  right: 5px;
}
.card.card-expand .card-arrow {
  margin: 5px;
}
.card .card-header .card-header-tabs .nav-item .nav-link.active {
  border-bottom-color: transparent;
}
.card .card-body .nav-tabs .nav-item .nav-link.active {
  background: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}
.card:before, .card:after {
  content: "";
  position: absolute;
}
.card:not(.border-0):before {
  left: 15px;
  right: 15px;
  top: 0;
  bottom: 0;
  border-top: 1px solid rgba(var(--bs-border-color-rgb), 0.3);
  border-bottom: 1px solid rgba(var(--bs-border-color-rgb), 0.3);
}
.card:not(.border-0):after {
  top: 15px;
  bottom: 15px;
  left: 0;
  right: 0;
  border-left: 1px solid rgba(var(--bs-border-color-rgb), 0.3);
  border-right: 1px solid rgba(var(--bs-border-color-rgb), 0.3);
}
.card > *:not(.card-arrow):not(.card-img-overlay):not(.card-img):not(.hljs-container) {
  position: relative;
  z-index: 10;
}
.card .card-arrow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.card .card-arrow-top-left, .card .card-arrow-top-right, .card .card-arrow-bottom-left, .card .card-arrow-bottom-right {
  width: 10px;
  height: 10px;
  position: absolute;
}
.card .card-arrow-top-left:before, .card .card-arrow-top-right:before, .card .card-arrow-bottom-left:before, .card .card-arrow-bottom-right:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 8px;
  background: rgba(var(--bs-border-color-rgb), 0.75);
}
.card .card-arrow-top-left:after, .card .card-arrow-top-right:after, .card .card-arrow-bottom-left:after, .card .card-arrow-bottom-right:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  background: rgba(var(--bs-border-color-rgb), 0.75);
}
.card .card-arrow-top-left, .card .card-arrow-top-right {
  top: 0;
}
.card .card-arrow-top-left:before, .card .card-arrow-top-right:before {
  top: 2px;
}
.card .card-arrow-top-left:after, .card .card-arrow-top-right:after {
  top: 0;
}
.card .card-arrow-bottom-left, .card .card-arrow-bottom-right {
  bottom: 0;
}
.card .card-arrow-bottom-left:before, .card .card-arrow-bottom-right:before {
  bottom: 2px;
}
.card .card-arrow-bottom-left:after, .card .card-arrow-bottom-right:after {
  bottom: 0;
}
.card .card-arrow-top-left, .card .card-arrow-bottom-left {
  left: 0;
}
.card .card-arrow-top-left:before, .card .card-arrow-top-left:after, .card .card-arrow-bottom-left:before, .card .card-arrow-bottom-left:after {
  left: 0;
}
.card .card-arrow-top-right, .card .card-arrow-bottom-right {
  right: 0;
}
.card .card-arrow-top-right:before, .card .card-arrow-top-right:after, .card .card-arrow-bottom-right:before, .card .card-arrow-bottom-right:after {
  right: 0;
}

@media (max-width: 991.98px) {
  .card-columns {
    column-count: 2;
  }
}
@media (max-width: 575.98px) {
  .card-columns {
    column-count: 1;
  }
}

.caret:before {
  content: "\f22c";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropup.caret, .dropup .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9 ;
}

.carousel-indicators [data-bs-target] {
  overflow: hidden;
}

@keyframes appDropdownShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
}
.dropdown-menu.show {
  animation: appDropdownShow 0.25s both cubic-bezier(0.7, 0, 0.3, 1);
}

@media (max-width: 575.98px) {
  .dropdown.dropdown-mobile-full {
    position: initial;
  }
  .dropdown.dropdown-mobile-full .dropdown-menu {
    left: 0.25rem !important;
    right: 0.25rem !important;
    width: auto !important;
  }
}

[data-bs-theme=dark] .form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23000000'/%3e%3c/svg%3e");
}

.form-select:not([multiple]) option {
  color: #000000;
}

[data-bs-theme=dark] .form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}
[data-bs-theme=dark] .form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}
[data-bs-theme=dark] .form-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000000'/%3e%3c/svg%3e");
}

hr.hr-bg, hr.hr-transparent, hr.hr-inverse, hr.hr-inverse-transparent {
  border: none;
  height: 1px;
}
hr.hr-transparent {
  background-color: rgba(0, 0, 0, 0.3);
}
hr.hr-inverse {
  background-color: #1a1a1a;
}
hr.hr-inverse-transparent {
  background-color: rgba(255, 255, 255, 0.3);
}

a {
  color: var(--bs-theme);
}
a:hover {
  color: rgba(var(--bs-theme-rgb), 0.75);
}

.modal .modal-content > * {
  position: relative;
}
.modal .modal-dialog.modal-full {
  max-width: 100%;
  width: 100%;
}
.modal .modal-dialog.modal-full .modal-content {
  border-radius: 0;
}
.modal.modal-cover .modal-dialog {
  min-height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.modal.modal-cover .modal-content, .modal.modal-cover .modal-header, .modal.modal-cover .modal-footer {
  background: none;
  border: none;
}

[data-bs-theme=dark] .modal-backdrop {
  --bs-backdrop-bg: #1d2835;
}

.nav.nav-tabs.nav-tabs-v2 > .nav-item > .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
  padding: 1rem 0;
  color: var(--bs-inverse);
}
.nav.nav-tabs.nav-tabs-v2 > .nav-item > .nav-link:hover, .nav.nav-tabs.nav-tabs-v2 > .nav-item > .nav-link.active {
  color: var(--bs-inverse);
  border-bottom-color: var(--bs-theme);
}
.nav.nav-tabs.nav-tabs-v2 > .nav-item.show > .nav-link {
  color: var(--bs-inverse);
  border-bottom-color: var(--bs-theme);
}

@media (max-width: 767.98px) {
  .nav-wizards-container {
    overflow: scroll;
  }
  .nav-wizards-container .nav.nav-wizards-1, .nav-wizards-container .nav.nav-wizards-2, .nav-wizards-container .nav.nav-wizards-3 {
    min-width: 37.5rem;
  }
}

.nav.nav-wizards-1 .nav-item {
  padding: 0;
}
.nav.nav-wizards-1 .nav-item .nav-link {
  position: relative;
  color: rgba(var(--bs-inverse-rgb), 0.75);
}
.nav.nav-wizards-1 .nav-item .nav-link .nav-no {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto;
  font-weight: 600;
  background: none;
  border: 2px solid var(--bs-border-color);
  position: relative;
  z-index: 10;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
}
.nav.nav-wizards-1 .nav-item .nav-link .nav-text {
  text-align: center;
  margin-top: 0.3125rem;
  font-weight: 600;
  font-size: 0.8125rem;
}
.nav.nav-wizards-1 .nav-item .nav-link.active, .nav.nav-wizards-1 .nav-item .nav-link.completed {
  color: var(--bs-theme);
}
.nav.nav-wizards-1 .nav-item .nav-link.active .nav-no, .nav.nav-wizards-1 .nav-item .nav-link.completed .nav-no {
  border-color: var(--bs-theme);
  color: var(--bs-theme);
}
.nav.nav-wizards-1 .nav-item .nav-link.active:before, .nav.nav-wizards-1 .nav-item .nav-link.active:after, .nav.nav-wizards-1 .nav-item .nav-link.completed:before, .nav.nav-wizards-1 .nav-item .nav-link.completed:after {
  background: var(--bs-theme);
}
.nav.nav-wizards-1 .nav-item .nav-link.active .nav-no {
  box-shadow: 0 0 0 5px rgba(var(--bs-theme-rgb), 0.2);
}
.nav.nav-wizards-1 .nav-item .nav-link.disabled {
  color: rgba(var(--bs-inverse-rgb), 0.25);
}
.nav.nav-wizards-1 .nav-item .nav-link:before {
  content: "";
  position: absolute;
  top: 1.625rem;
  height: 0.125rem;
  background: rgba(var(--bs-inverse-rgb), 0.3);
  transition: all 0.2s linear;
}
.nav.nav-wizards-1 .nav-item .nav-link:before {
  left: calc(-50% + 20px);
  right: calc(50% + 20px);
}
.nav.nav-wizards-1 .nav-item:first-child .nav-link:before {
  display: none;
}
.nav.nav-wizards-1 .nav-item:last-child .nav-link:after {
  display: none;
}
.nav.nav-wizards-2 .nav-item {
  padding: 0 1rem;
}
.nav.nav-wizards-2 .nav-item .nav-link {
  position: relative;
  color: rgba(var(--bs-inverse-rgb), 0.75);
  background: rgba(var(--bs-inverse-rgb), 0.15);
  border-radius: 60px;
}
.nav.nav-wizards-2 .nav-item .nav-link .nav-text {
  text-align: center;
  font-weight: 600;
  font-size: 0.8125rem;
}
.nav.nav-wizards-2 .nav-item .nav-link:before {
  content: "";
  position: absolute;
  left: -2rem;
  right: 100%;
  height: 0.125rem;
  top: 50%;
  margin-top: -0.0625rem;
  background-color: rgba(var(--bs-inverse-rgb), 0.3);
  transition: all 0.2s linear;
}
.nav.nav-wizards-2 .nav-item .nav-link.disabled {
  color: rgba(var(--bs-inverse-rgb), 0.35);
}
.nav.nav-wizards-2 .nav-item .nav-link.active, .nav.nav-wizards-2 .nav-item .nav-link.completed {
  background: var(--bs-theme);
  color: var(--bs-theme-color);
}
.nav.nav-wizards-2 .nav-item .nav-link.active:before, .nav.nav-wizards-2 .nav-item .nav-link.completed:before {
  background: var(--bs-theme);
}
.nav.nav-wizards-2 .nav-item .nav-link.active {
  box-shadow: 0 0 0 4px rgba(var(--bs-theme-rgb), 0.2);
}
.nav.nav-wizards-2 .nav-item:first-child .nav-link:before {
  display: none;
}
.nav.nav-wizards-3 .nav-item {
  padding: 0;
}
.nav.nav-wizards-3 .nav-item .nav-link {
  position: relative;
  color: rgba(var(--bs-inverse-rgb), 0.75);
}
.nav.nav-wizards-3 .nav-item .nav-link .nav-dot {
  width: 1rem;
  height: 1rem;
  margin: 0 auto;
  font-weight: 600;
  background: none;
  border: 3px solid var(--bs-border-color);
  position: relative;
  z-index: 10;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
}
.nav.nav-wizards-3 .nav-item .nav-link .nav-title {
  margin-top: 0.625rem;
  font-weight: 600;
  text-align: center;
}
.nav.nav-wizards-3 .nav-item .nav-link .nav-text {
  text-align: center;
  font-size: 0.8125rem;
}
.nav.nav-wizards-3 .nav-item .nav-link.active, .nav.nav-wizards-3 .nav-item .nav-link.completed {
  color: var(--bs-theme);
}
.nav.nav-wizards-3 .nav-item .nav-link.active .nav-dot, .nav.nav-wizards-3 .nav-item .nav-link.completed .nav-dot {
  border-color: var(--bs-theme);
  color: var(--bs-theme);
}
.nav.nav-wizards-3 .nav-item .nav-link.active:before, .nav.nav-wizards-3 .nav-item .nav-link.completed:before {
  background: var(--bs-theme);
}
.nav.nav-wizards-3 .nav-item .nav-link.active .nav-dot {
  box-shadow: 0 0 0 5px rgba(var(--bs-theme-rgb), 0.2);
}
.nav.nav-wizards-3 .nav-item .nav-link.disabled {
  color: rgba(var(--bs-inverse-rgb), 0.25);
}
.nav.nav-wizards-3 .nav-item .nav-link:before {
  content: "";
  position: absolute;
  left: calc(-50% + 8px);
  top: 0.875rem;
  right: calc(50% + 8px);
  height: 0.1875rem;
  background: rgba(var(--bs-inverse-rgb), 0.3);
}
.nav.nav-wizards-3 .nav-item:first-child .nav-link:before {
  display: none;
}

.navbar.navbar-sticky {
  top: 5.25rem;
  position: sticky;
  padding: 0;
  border-left: 1px solid var(--bs-border-color);
  margin-left: 1rem;
}
.navbar.navbar-sticky .nav {
  width: 100%;
  flex-direction: column;
}
.navbar.navbar-sticky .nav .nav-link {
  color: rgba(var(--bs-inverse-rgb), 0.6);
  padding: 0.25rem 1.5rem;
}
.navbar.navbar-sticky .nav .nav-link:hover {
  color: rgba(var(--bs-inverse-rgb), 1);
}
.navbar.navbar-sticky .nav .nav-link.active {
  color: var(--bs-theme);
  font-weight: 500;
}

.page-header {
  color: var(--bs-heading-color);
  padding: 0;
  border: none;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.75rem;
}
.page-header small, .page-header .small {
  font-size: 1rem;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  font-weight: 300;
}

.pagination .page-item .page-link {
  border-radius: 0.25rem;
}
.pagination .page-item + .page-item {
  margin-left: 0.3125rem;
}

pre {
  display: block;
  padding: 1rem;
  font-size: 0.875rem;
  color: #44555e;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #FFFFFF;
  border: 1px solid #a8b6bc;
  border-radius: 4px;
}

.row.row-grid > [class*=col-] + [class*=col-] {
  border-left: 1px solid rgba(var(--bs-inverse-rgb), 0.15);
}
.row.row-grid + .row-grid {
  border-top: 1px solid rgba(var(--bs-inverse-rgb), 0.15);
}

.table.table-card thead > tr td:first-child, .table.table-card thead > tr th:first-child, .table.table-card tbody > tr td:first-child, .table.table-card tbody > tr th:first-child {
  padding-left: 1rem;
}
.table.table-card thead > tr td:last-child, .table.table-card thead > tr th:last-child, .table.table-card tbody > tr td:last-child, .table.table-card tbody > tr th:last-child {
  padding-right: 1rem;
}
.table.table-xs {
  font-size: 0.75rem;
}
.table.table-xs thead th, .table.table-xs tbody th, .table.table-xs tfoot th {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
}
.table.table-xs thead td, .table.table-xs tbody td, .table.table-xs tfoot td {
  padding: 0.3125rem 0.5rem;
}

.toasts-container {
  position: fixed;
  top: 3.25rem;
  padding-top: 1rem;
  width: 21.875rem;
  right: 0;
  padding-right: 1rem;
}
@media (max-width: 767.98px) {
  .toasts-container {
    width: 18.75rem;
  }
}
.toasts-container .toast {
  width: 100%;
}

.apexcharts-canvas .apexcharts-tooltip {
  font-size: 0.8125rem;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
}
.apexcharts-canvas .apexcharts-tooltip * {
  font-family: inherit !important;
}
.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light, .apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-dark {
  border: none;
  background: rgba(var(--bs-body-bg-rgb), 0.9);
  color: var(--bs-inverse);
}
.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title, .apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title {
  background: rgba(var(--bs-inverse-rgb), 0.15);
  border-color: transparent;
  color: var(--bs-inverse);
  text-transform: uppercase;
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title {
  font-family: inherit !important;
  font-weight: 600;
  padding: 0.35rem 0.75rem;
  margin: 0;
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-series-group {
  padding-left: 0.666rem;
  padding-right: 0.666rem;
  padding-bottom: 0;
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-series-group .apexcharts-tooltip-marker:before {
  font-size: 1.05rem;
  display: block;
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-series-group .apexcharts-tooltip-y-group {
  padding: 0.4rem 0;
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-series-group.apexcharts-active + .apexcharts-tooltip-series-group.apexcharts-active {
  margin-top: -0.8rem;
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-series-group.active, .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-series-group:last-child {
  padding-bottom: 0;
}
.apexcharts-canvas .apexcharts-xaxistooltip * {
  font-family: inherit !important;
}
.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light, .apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-dark {
  color: #FFFFFF;
  background: #35434a;
  border-color: #35434a;
}
.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light.apexcharts-xaxistooltip-bottom:before, .apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-dark.apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: #35434a;
}
.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light.apexcharts-xaxistooltip-bottom:after, .apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-dark.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: #35434a;
}
.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light.apexcharts-xaxistooltip-top:before, .apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-dark.apexcharts-xaxistooltip-top:before {
  border-top-color: #35434a;
}
.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light.apexcharts-xaxistooltip-top:after, .apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-dark.apexcharts-xaxistooltip-top:after {
  border-top-color: #35434a;
}
.apexcharts-canvas .apexcharts-yaxistooltip * {
  font-family: inherit !important;
}
.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-light, .apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-dark {
  color: #FFFFFF;
  background: #35434a;
  border-color: #35434a;
}
.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-light.apexcharts-yaxistooltip-right:before, .apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-dark.apexcharts-yaxistooltip-right:before {
  border-right-color: #35434a;
}
.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-light.apexcharts-yaxistooltip-right:after, .apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-dark.apexcharts-yaxistooltip-right:after {
  border-right-color: #35434a;
}
.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-light.apexcharts-yaxistooltip-left:before, .apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-dark.apexcharts-yaxistooltip-left:before {
  border-left-color: #35434a;
}
.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-light.apexcharts-yaxistooltip-left:after, .apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-dark.apexcharts-yaxistooltip-left:after {
  border-left-color: #35434a;
}
.apexcharts-canvas .apexcharts-legend-marker {
  width: 0.625rem !important;
  height: 0.625rem !important;
  margin-right: 0.3125rem;
}
.apexcharts-canvas .apexcharts-menu {
  background: rgba(var(--bs-body-bg-rgb), 0.98);
  border: none;
  padding: 0;
  border-radius: 0;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
}
.apexcharts-canvas .apexcharts-menu .apexcharts-menu-item {
  color: var(--bs-body-color);
}
.apexcharts-canvas .apexcharts-menu .apexcharts-menu-item:hover {
  background: rgba(var(--bs-body-color-rgb), 0.15);
  color: var(--bs-body-color);
}
.apexcharts-canvas.apexcharts-theme-light .apexcharts-selection-icon:not(.apexcharts-selected):hover svg, .apexcharts-canvas.apexcharts-theme-light .apexcharts-zoom-icon:not(.apexcharts-selected):hover svg, .apexcharts-canvas.apexcharts-theme-light .apexcharts-zoomin-icon:hover svg, .apexcharts-canvas.apexcharts-theme-light .apexcharts-zoomout-icon:hover svg, .apexcharts-canvas.apexcharts-theme-light .apexcharts-reset-icon:hover svg, .apexcharts-canvas.apexcharts-theme-light .apexcharts-menu-icon:hover svg {
  fill: #FFFFFF;
}

.ui-menu.ui-autocomplete.search-bar-autocomplete {
  border: none;
  border-radius: 0;
  box-shadow: 0 0.125rem 0.375rem rgba(var(--bs-inverse-rgb), 0.3);
  font-family: inherit;
  z-index: 1030;
  padding: 0.3125rem 0rem;
  border-radius: 0.25rem;
}
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-menu-item a, .ui-menu.ui-autocomplete.search-bar-autocomplete .ui-menu-item .ui-menu-item-wrapper {
  padding: 0.5rem 0.9375rem;
  border-radius: 0;
  margin: 0;
  font-weight: 600;
}
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-state-hover, .ui-menu.ui-autocomplete.search-bar-autocomplete .ui-widget-content .ui-state-hover, .ui-menu.ui-autocomplete.search-bar-autocomplete .ui-widget-header .ui-state-hover, .ui-menu.ui-autocomplete.search-bar-autocomplete .ui-state-focus, .ui-menu.ui-autocomplete.search-bar-autocomplete .ui-widget-content .ui-state-focus, .ui-menu.ui-autocomplete.search-bar-autocomplete .ui-widget-header .ui-state-focus, .ui-menu.ui-autocomplete.search-bar-autocomplete .ui-state-active, .ui-menu.ui-autocomplete.search-bar-autocomplete .ui-widget-content .ui-state-active, .ui-menu.ui-autocomplete.search-bar-autocomplete .ui-widget-header .ui-state-active {
  border: none;
  background: #e6e6e6;
  color: #000000;
}

.datepicker.datepicker-dropdown {
  width: 18.75rem;
  padding: 0.375rem 0.75rem 0.75rem;
  z-index: 1065 !important;
  border-radius: 0;
}
.datepicker.datepicker-dropdown:before, .datepicker.datepicker-dropdown:after {
  display: none;
}
.datepicker .datepicker-switch, .datepicker .prev, .datepicker .next {
  padding: 0.625rem;
  min-width: 2.5rem;
}
.datepicker .datepicker-switch:hover, .datepicker .prev:hover, .datepicker .next:hover {
  background: none !important;
  color: var(--bs-theme);
}
.datepicker .next, .datepicker .prev {
  overflow: hidden;
  text-indent: -100px;
  max-width: 1.875rem;
  padding: 0.3125rem 0;
  text-align: center;
}
.datepicker .next:before, .datepicker .prev:before {
  font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  position: absolute;
  width: 1.875rem;
  text-indent: 0;
}
.datepicker .next:before {
  content: "\f061";
  right: 0;
}
.datepicker .prev:before {
  content: "\f060";
  left: 0;
}
.datepicker .datepicker-switch {
  font-weight: 600;
  font-size: 0.9625rem;
  color: var(--bs-theme);
  width: 100% !important;
}
.datepicker .datepicker-switch, .datepicker .next, .datepicker .prev {
  padding: 0.3125rem;
}
.datepicker .datepicker-days, .datepicker .datepicker-months, .datepicker .datepicker-years {
  position: relative;
}
.datepicker .datepicker-days table {
  width: 100%;
}
.datepicker .datepicker-days table thead tr th.dow {
  color: rgba(var(--bs-inverse-rgb), 0.5);
  font-size: 0.74375rem;
  font-weight: 600;
  border-radius: 0;
  padding: 0.3125rem 0;
}
.datepicker .datepicker-days table tbody tr td {
  padding: 0.5rem 0rem;
  border-radius: 0;
  font-weight: 600;
  font-size: 0.74375rem;
  border: 1px solid var(--bs-border-color-translucent);
  color: var(--bs-inverse);
  width: 14%;
}
.datepicker .datepicker-days table tbody tr td.old {
  color: rgba(var(--bs-inverse-rgb), 0.25);
}
.datepicker .datepicker-days table tbody tr td.new {
  color: rgba(var(--bs-inverse-rgb), 0.5);
}
.datepicker .datepicker-days table tbody tr td:hover, .datepicker .datepicker-days table tbody tr td.focused, .datepicker .datepicker-days table tbody tr td.range {
  color: var(--bs-theme-color);
  background: var(--bs-theme) !important;
}
.datepicker .datepicker-days table tbody tr td.today, .datepicker .datepicker-days table tbody tr td.active, .datepicker .datepicker-days table tbody tr td.selected {
  background: var(--bs-theme) !important;
  border-color: var(--bs-theme) !important;
  color: var(--bs-theme-color);
  text-shadow: none;
  position: relative;
}
.datepicker .datepicker-days table tbody tr td.today:before, .datepicker .datepicker-days table tbody tr td.today:after, .datepicker .datepicker-days table tbody tr td.active:before, .datepicker .datepicker-days table tbody tr td.active:after, .datepicker .datepicker-days table tbody tr td.selected:before, .datepicker .datepicker-days table tbody tr td.selected:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: -1px;
  background: var(--bs-theme);
  width: 1px;
  left: -1px;
}
.datepicker .datepicker-days table tbody tr td.today:after, .datepicker .datepicker-days table tbody tr td.active:after, .datepicker .datepicker-days table tbody tr td.selected:after {
  bottom: auto;
  top: -1px;
  width: auto;
  height: 1px;
  right: -1px;
}
.datepicker .datepicker-months table tbody tr td, .datepicker .datepicker-years table tbody tr td, .datepicker .datepicker-decades table tbody tr td, .datepicker .datepicker-centuries table tbody tr td {
  padding-top: 0.1875rem;
}
.datepicker .datepicker-months table tbody tr td span, .datepicker .datepicker-years table tbody tr td span, .datepicker .datepicker-decades table tbody tr td span, .datepicker .datepicker-centuries table tbody tr td span {
  width: 25%;
  margin: 0;
  border-radius: 0;
  border: 1px solid var(--bs-border-color-translucent);
  font-weight: 600;
  font-size: 0.7875rem;
  margin: 0 -1px -1px 0;
  color: var(--bs-inverse);
}
.datepicker .datepicker-months table tbody tr td span:hover, .datepicker .datepicker-months table tbody tr td span.focused, .datepicker .datepicker-years table tbody tr td span:hover, .datepicker .datepicker-years table tbody tr td span.focused, .datepicker .datepicker-decades table tbody tr td span:hover, .datepicker .datepicker-decades table tbody tr td span.focused, .datepicker .datepicker-centuries table tbody tr td span:hover, .datepicker .datepicker-centuries table tbody tr td span.focused {
  color: var(--bs-theme-color) !important;
  background: var(--bs-theme) !important;
}
.datepicker .datepicker-months table tbody tr td span.focused, .datepicker .datepicker-years table tbody tr td span.focused, .datepicker .datepicker-decades table tbody tr td span.focused, .datepicker .datepicker-centuries table tbody tr td span.focused {
  color: var(--bs-theme);
}
.datepicker .datepicker-months table tbody tr td span.active, .datepicker .datepicker-years table tbody tr td span.active, .datepicker .datepicker-decades table tbody tr td span.active, .datepicker .datepicker-centuries table tbody tr td span.active {
  border-color: var(--bs-theme);
  position: relative;
  background: var(--bs-theme) !important;
  color: var(--bs-theme-color) !important;
  text-shadow: none;
}

.input-daterange input.form-control:first-child {
  border-radius: 4px 0 0 4px;
}
.input-daterange input.form-control:last-child {
  border-radius: 0 4px 4px 0;
}

body .daterangepicker {
  z-index: 1065 !important;
  border-color: var(--bs-border-color-translucent);
  font-family: inherit;
  font-size: inherit;
  background: rgba(var(--bs-body-bg-rgb), 0.98);
  border-radius: 0;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  width: auto;
}
body .daterangepicker:before, body .daterangepicker:after {
  display: none;
}
body .daterangepicker:after {
  border-width: 0.4375rem;
  top: -0.4375rem;
  left: 0.625rem;
}
body .daterangepicker.opensright:after {
  right: 0.625rem;
  left: auto;
}
body .daterangepicker select.monthselect, body .daterangepicker select.yearselect {
  font-size: 0.7875rem;
}
body .daterangepicker .drp-calendar {
  max-width: inherit;
  padding: 0.75rem;
}
body .daterangepicker .drp-calendar .calendar-table {
  background: none;
  border: none;
  border-radius: 0;
}
body .daterangepicker .drp-calendar .calendar-table table thead .week, body .daterangepicker .drp-calendar .calendar-table table tbody .week {
  font-size: 0.74375rem;
  color: #c0cacf;
  font-weight: 600;
}
body .daterangepicker .drp-calendar .calendar-table table thead tr th {
  min-width: auto;
  width: auto;
  height: auto;
  line-height: 1;
  padding: 0.375rem 0.75rem;
  color: var(--bs-inverse);
  font-weight: 600;
  font-size: inherit;
  border: none;
}
body .daterangepicker .drp-calendar .calendar-table table thead tr th.prev span, body .daterangepicker .drp-calendar .calendar-table table thead tr th.next span {
  border-color: rgba(var(--bs-inverse-rgb), 0.5);
}
body .daterangepicker .drp-calendar .calendar-table table thead tr th.prev:hover, body .daterangepicker .drp-calendar .calendar-table table thead tr th.prev:focus, body .daterangepicker .drp-calendar .calendar-table table thead tr th.next:hover, body .daterangepicker .drp-calendar .calendar-table table thead tr th.next:focus {
  background: none;
  color: var(--bs-theme);
}
body .daterangepicker .drp-calendar .calendar-table table thead tr th.prev:hover span, body .daterangepicker .drp-calendar .calendar-table table thead tr th.prev:focus span, body .daterangepicker .drp-calendar .calendar-table table thead tr th.next:hover span, body .daterangepicker .drp-calendar .calendar-table table thead tr th.next:focus span {
  border-color: var(--bs-theme);
}
body .daterangepicker .drp-calendar .calendar-table table thead tr th.month .yearselect, body .daterangepicker .drp-calendar .calendar-table table thead tr th.month .monthselect {
  border-color: var(--bs-border-color);
  background: transparent;
  border-radius: 4px;
  color: var(--bs-inverse);
  transition: all 0.2s linear;
}
body .daterangepicker .drp-calendar .calendar-table table thead tr th.month .yearselect:focus, body .daterangepicker .drp-calendar .calendar-table table thead tr th.month .monthselect:focus {
  outline: none;
  border-color: rgba(var(--bs-inverse-rgb), 0.5);
  box-shadow: 0 0 0 0.125rem rgba(var(--bs-inverse-rgb), 0.5);
}
body .daterangepicker .drp-calendar .calendar-table table thead tr:last-child th {
  font-size: 0.74375rem;
  padding-bottom: 0.5rem;
}
body .daterangepicker .drp-calendar .calendar-table table tbody tr td {
  min-width: auto;
  width: auto;
  height: auto;
  line-height: 1.3;
  padding: 0.625rem 0.375rem;
  font-weight: 400;
  color: rgba(var(--bs-inverse-rgb), 0.75);
  border-color: var(--bs-border-color-translucent);
  font-size: 0.7875rem;
  border-radius: 0 !important;
}
body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available:hover, body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available:focus {
  background: var(--bs-theme);
  border-color: var(--bs-theme);
  color: var(--bs-theme-color);
}
body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available.in-range {
  background: rgba(var(--bs-theme-rgb), 0.25);
  border-color: rgba(var(--bs-theme-rgb), 0.5);
  color: var(--bs-inverse);
  border-radius: 0;
}
body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available.active {
  background: var(--bs-theme);
  border-color: var(--bs-theme);
  color: var(--bs-theme-color);
}
body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available.start-date {
  border-radius: 0;
}
body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available.end-date {
  border-radius: 0;
}
body .daterangepicker .drp-calendar .calendar-table table tbody tr td.off {
  background: none;
  color: rgba(var(--bs-inverse-rgb), 0.25);
}
body .daterangepicker .drp-calendar.left {
  padding: 0.75rem !important;
}
body .daterangepicker .drp-calendar.left .calendar-table {
  padding: 0 !important;
}
body .daterangepicker .drp-calendar.right {
  padding: 0.75rem !important;
  border-left: 1px solid var(--bs-border-color);
}
@media (max-width: 575.98px) {
  body .daterangepicker .drp-calendar.right {
    border-top: 1px solid var(--bs-border-color);
    border-left: none;
  }
}
body .daterangepicker .drp-buttons {
  padding: 0.75rem 0.75rem;
  border-color: var(--bs-border-color);
  background: rgba(var(--bs-inverse-rgb), 0.05);
  border: none;
}
body .daterangepicker .drp-buttons .btn.btn-primary {
  background: none;
  border: 1px solid var(--bs-btn-border-color);
  color: var(--bs-btn-color);
  --bs-btn-color: #249d79;
  --bs-btn-border-color: #249d79;
  --bs-btn-hover-color: #249d79;
  --bs-btn-hover-bg: #249d79;
  --bs-btn-hover-border-color: #249d79;
  --bs-btn-focus-shadow-rgb: 36, 157, 121;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #249d79;
  --bs-btn-active-border-color: #249d79;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #249d79;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #249d79;
  --bs-gradient: none;
}
body .daterangepicker .drp-buttons .btn.btn-primary:hover, body .daterangepicker .drp-buttons .btn.btn-primary:active {
  background: var(--bs-btn-hover-bg);
}
body .daterangepicker .drp-buttons .btn.btn-default {
  background: none;
  border: 1px solid var(--bs-btn-border-color);
  color: var(--bs-btn-color);
  --bs-btn-color: #798e98;
  --bs-btn-border-color: #798e98;
  --bs-btn-hover-color: #798e98;
  --bs-btn-hover-bg: #798e98;
  --bs-btn-hover-border-color: #798e98;
  --bs-btn-focus-shadow-rgb: 121, 142, 152;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #798e98;
  --bs-btn-active-border-color: #798e98;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #798e98;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #798e98;
  --bs-gradient: none;
}
body .daterangepicker .drp-buttons .btn.btn-default:hover, body .daterangepicker .drp-buttons .btn.btn-default:active {
  color: #000000;
  background: var(--bs-btn-hover-bg);
}
body .daterangepicker .ranges {
  display: none;
}
body .daterangepicker.show-ranges .ranges {
  position: relative;
  display: block;
}
@media (max-width: 767.98px) {
  body .daterangepicker.show-ranges .ranges {
    float: none;
    margin: 0;
    padding: 0.625rem;
    border-bottom: 1px solid var(--bs-border-color);
    overflow: scroll;
    max-width: 35rem;
  }
}
@media (max-width: 767.98px) {
  body .daterangepicker.show-ranges .ranges ul {
    width: auto;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    padding-right: 0.625rem;
  }
}
body .daterangepicker.show-ranges .ranges ul li {
  color: rgba(var(--bs-inverse-rgb), 0.5);
  font-size: 0.8125rem;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  body .daterangepicker.show-ranges .ranges ul li {
    border-radius: 4px;
    margin-right: 0.625rem !important;
  }
}
body .daterangepicker.show-ranges .ranges ul li:hover, body .daterangepicker.show-ranges .ranges ul li:focus {
  background: rgba(var(--bs-inverse-rgb), 0.15);
  color: rgba(var(--bs-inverse-rgb), 0.75);
}
body .daterangepicker.show-ranges .ranges ul li.active {
  background: var(--bs-theme);
  color: var(--bs-theme-color);
  margin-right: -0.0625rem;
}
body .daterangepicker.show-ranges .drp-calendar.left {
  border-left: 1px solid rgba(var(--bs-inverse-rgb), 0.15) !important;
}
@media (max-width: 767.98px) {
  body .daterangepicker.show-ranges .drp-calendar.left {
    border-left: none !important;
  }
}

.app .slider.slider-horizontal {
  height: 1.5rem;
  width: 100%;
}
.app .slider.slider-horizontal .slider-track {
  height: 0.375rem;
  margin-top: -0.1875rem;
}
.app .slider.slider-vertical {
  margin: 0 auto;
  display: block;
}
.app .slider.slider-vertical .slider-track {
  width: 0.375rem;
  left: 50%;
  margin-left: -0.1875rem;
}
.app .slider.slider-disabled {
  opacity: 0.5;
}
.app .slider.slider-disabled .slider-track {
  background: rgba(var(--bs-inverse-rgb), 0.15);
}
.app .slider.slider-disabled .slider-handle {
  background: #FFFFFF;
  cursor: not-allowed;
}
.app .slider .slider-track {
  box-shadow: none;
  background: rgba(var(--bs-inverse-rgb), 0.15);
  border-radius: 4px;
}
.app .slider .slider-track .slider-selection {
  background: var(--bs-theme);
  box-shadow: none;
}
.app .slider .slider-handle {
  background: #FFFFFF;
  box-shadow: 0 0.0625rem 0.375rem rgba(var(--bs-inverse-rgb), 0.25);
}
.app .slider .tooltip {
  position: absolute;
}
.app .slider .tooltip.in {
  opacity: 1;
}
.app .slider .tooltip.top {
  margin-top: -1.875rem;
}
.app .slider .tooltip.bottom {
  margin-top: 1.5625rem;
}
.app .slider .tooltip.left {
  margin-left: -1.875rem;
}
.app .slider .tooltip.right {
  margin-left: 0.3125rem;
}

.bootstrap-timepicker-widget.dropdown-menu.open {
  width: 15rem;
  padding: 0.9375rem;
  z-index: 1065 !important;
}
.bootstrap-timepicker-widget.dropdown-menu.open:before, .bootstrap-timepicker-widget.dropdown-menu.open:after {
  display: none;
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td {
  padding: 0;
  height: auto;
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td.separator {
  padding: 0 0.3125rem;
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td:not(.separator) {
  min-width: 3.75rem;
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td input {
  border-radius: 4px;
  border: 1px solid var(--bs-border-color);
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.3125rem;
  line-height: 1.5;
  background: none;
  color: var(--bs-body-color);
  display: block;
  width: 100%;
  margin: 0.3125rem 0;
  transition: all 0.15s ease-in-out;
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td input:focus {
  border-color: rgba(var(--bs-inverse-rgb), 0.5);
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-inverse-rgb), 0.25);
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a {
  padding: 0;
  outline: 0;
  color: rgba(var(--bs-inverse-rgb), 0.3);
  display: block;
  line-height: 2.25rem;
  height: 2.25rem;
  width: auto;
  margin: 0 auto;
  border: none;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.15s linear;
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a:hover {
  background: rgba(var(--bs-inverse-rgb), 0.15);
  color: rgba(var(--bs-inverse-rgb), 0.75);
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a .glyphicon {
  font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a .glyphicon.glyphicon-chevron-up:before {
  content: "\f077";
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a .glyphicon.glyphicon-chevron-down:before {
  content: "\f078";
}

.bootstrap-table .dropdown-toggle .caret {
  display: none;
}
.bootstrap-table .fixed-table-container.fixed-height .fixed-table-header .table {
  border-bottom: none;
}
.bootstrap-table .fixed-table-container.fixed-height .fixed-table-body .table thead tr th {
  border-top: none;
  border-bottom: none;
}
.bootstrap-table .fixed-table-container .table thead tr {
  border-bottom-width: 0 !important;
}
.bootstrap-table .fixed-table-container .table thead tr th {
  border-bottom-color: initial !important;
}
.bootstrap-table .fixed-table-container .table thead tr th .sortable {
  position: relative;
  background-image: none !important;
}
.bootstrap-table .fixed-table-container .table thead tr th .sortable:after {
  content: "";
  position: absolute;
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  right: 0.625rem;
}
.bootstrap-table .fixed-table-container .table thead tr th .sortable.both:after {
  content: "\f0dc";
  color: rgba(var(--bs-inverse-rgb), 0.5);
}
.bootstrap-table .fixed-table-container .table thead tr th .sortable.asc:after {
  content: "\f0de";
  color: var(--bs-theme);
}
.bootstrap-table .fixed-table-container .table thead tr th .sortable.desc:after {
  content: "\f0dd";
  color: var(--bs-theme);
}
.bootstrap-table .fixed-table-container .table tbody tr:first-child {
  border-top: none;
}
.bootstrap-table .fixed-table-container .table tbody tr:first-child td {
  border-top: none;
}
.bootstrap-table.fullscreen {
  background: var(--bs-app-header-bg) !important;
}

body .dt-bootstrap5 .dataTable.dtr-inline tr td.dtr-control:before {
  content: "\f64d" !important;
  background: none;
  border: 1px solid var(--bs-border-color) !important;
  color: var(--bs-inverse);
  font-size: 0.65rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0;
  box-shadow: none;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: left;
  margin-right: 0.5rem !important;
}
body .dt-bootstrap5 .dataTable.dtr-inline tr td.dtr-control:hover:before, body .dt-bootstrap5 .dataTable.dtr-inline tr td.dtr-control:focus:before {
  border-color: var(--bs-theme) !important;
}
body .dt-bootstrap5 .dataTable.dtr-inline tr.dt-hasChild > td.dtr-control:before, body .dt-bootstrap5 .dataTable.dtr-inline tr.dt-hasChild > th.dtr-control:before {
  content: "\f068";
  background: var(--bs-theme);
  color: var(--bs-theme-color);
}
body .dt-bootstrap5 .dataTable thead > tr > th.sorting:hover, body .dt-bootstrap5 .dataTable thead > tr > th.sorting:focus, body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-asc:hover, body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-asc:focus, body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-desc:hover, body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-desc:focus, body .dt-bootstrap5 .dataTable thead > tr > td.sorting:hover, body .dt-bootstrap5 .dataTable thead > tr > td.sorting:focus, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-asc:hover, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-asc:focus, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-desc:hover, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-desc:focus {
  outline: 2px solid var(--bs-border-color);
  outline-offset: -2px;
}
body .dt-bootstrap5 .dataTable thead > tr > th.sorting .dt-column-order, body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-asc .dt-column-order, body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-desc .dt-column-order, body .dt-bootstrap5 .dataTable thead > tr > td.sorting .dt-column-order, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-asc .dt-column-order, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-desc .dt-column-order {
  right: 0.375rem;
}
body .dt-bootstrap5 .dataTable thead > tr > th.sorting .dt-column-order:before, body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-asc .dt-column-order:before, body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-desc .dt-column-order:before, body .dt-bootstrap5 .dataTable thead > tr > td.sorting .dt-column-order:before, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-asc .dt-column-order:before, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-desc .dt-column-order:before {
  display: none;
}
body .dt-bootstrap5 .dataTable thead > tr > th.sorting .dt-column-order:after, body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-asc .dt-column-order:after, body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-desc .dt-column-order:after, body .dt-bootstrap5 .dataTable thead > tr > td.sorting .dt-column-order:after, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-asc .dt-column-order:after, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-desc .dt-column-order:after {
  opacity: 1;
  color: rgba(var(--bs-body-color-rgb), 0.5);
  top: 0;
  bottom: 0;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-asc.dt-orderable-desc .dt-column-order:after, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-asc.dt-orderable-desc .dt-column-order:after {
  content: "\f0dc";
}
body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-asc.dt-orderable-desc.dt-ordering-asc .dt-column-order:after, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-asc.dt-orderable-desc.dt-ordering-asc .dt-column-order:after {
  content: "\f0dd";
  color: var(--bs-theme);
}
body .dt-bootstrap5 .dataTable thead > tr > th.dt-orderable-asc.dt-orderable-desc.dt-ordering-desc .dt-column-order:after, body .dt-bootstrap5 .dataTable thead > tr > td.dt-orderable-asc.dt-orderable-desc.dt-ordering-desc .dt-column-order:after {
  content: "\f0de";
  color: var(--bs-theme);
}
body .dt-bootstrap5 .dataTable.table-bordered th:last-child, body .dt-bootstrap5 .dataTable.table-bordered td:last-child {
  border-color: var(--bs-table-border-color);
}
body .dt-bootstrap5 .dt-paging .pagination {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-border-radius: 2px;
  justify-content: flex-end;
  font-size: 0.8125rem;
}
body .dt-bootstrap5 .dt-scroll .dt-scroll-head .table-bordered {
  border-top: 1px solid var(--bs-table-border-color);
  border-bottom: 1px solid var(--bs-table-border-color);
}
body .dt-bootstrap5 .dt-scroll .dt-scroll-body .table-bordered tbody tr {
  border-bottom: none;
}
body .dt-bootstrap5 .dt-scroll .dt-scroll-body .table-bordered tbody tr:not(:last-child) td {
  border-bottom: 1px solid var(--bs-table-border-color);
}
body .dt-bootstrap5 .dt-scroll .dt-scroll-foot .table-bordered {
  border-bottom: 1px solid var(--bs-table-border-color);
}
body .dt-bootstrap5 .dt-scroll .dtfc-scrolling-left tr > .dtfc-fixed-left::after {
  border-left: 1px solid var(--bs-table-border-color);
  box-shadow: none;
}
body .dt-bootstrap5 .dt-info {
  padding-top: 0 !important;
}
body .dt-bootstrap5 .btn.btn-secondary[class*=btn-outline-]:not(:hover):not(:focus) {
  background: none;
}

.fc-event-list .fc-event-item .fc-event-link {
  padding: 0.35rem 0.7rem;
  color: var(--bs-inverse);
  position: relative;
  border: 1px solid var(--bs-border-color);
  display: flex;
  align-items: center;
  border-radius: 4px;
}
.fc-event-list .fc-event-item .fc-event-link:hover {
  background: rgba(var(--bs-inverse-rgb), 0.1);
}
.fc-event-list .fc-event-item .fc-event-link:after {
  content: "\f0b2";
  color: rgba(var(--bs-inverse-rgb), 0.5);
  line-height: 1;
  margin-left: auto;
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
.fc-event-list .fc-event-item + .fc-event-item {
  margin-top: 0.5rem;
}

.fc-event-link.fc-dragging, .fc-event-link.fc-event-dragging {
  padding: 0.35rem 0.7rem;
  background: rgba(var(--bs-inverse-rgb), 0.15);
  border: 1px solid var(--bs-border-color);
  color: var(--bs-inverse);
  display: block;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
}

.fc .fc-toolbar, .fc .fc-header-toolbar {
  margin-bottom: 0 !important;
  flex-wrap: wrap;
  align-items: center;
}
.fc .fc-toolbar .fc-left, .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1), .fc .fc-header-toolbar .fc-left, .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
  border-bottom: 1px solid var(--bs-border-color);
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575.98px) {
  .fc .fc-toolbar .fc-left .btn-group, .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn-group, .fc .fc-header-toolbar .fc-left .btn-group, .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn-group {
    width: 100%;
  }
}
.fc .fc-toolbar .fc-left .btn, .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn, .fc .fc-header-toolbar .fc-left .btn, .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn {
  min-width: 6.25rem;
  background: none;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  color: var(--bs-btn-color);
  --bs-btn-color: #798e98;
  --bs-btn-border-color: #798e98;
  --bs-btn-hover-color: #798e98;
  --bs-btn-hover-bg: #798e98;
  --bs-btn-hover-border-color: #798e98;
  --bs-btn-focus-shadow-rgb: 121, 142, 152;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #798e98;
  --bs-btn-active-border-color: #798e98;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #798e98;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #798e98;
  --bs-gradient: none;
}
@media (max-width: 991.98px) {
  .fc .fc-toolbar .fc-left .btn, .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn, .fc .fc-header-toolbar .fc-left .btn, .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn {
    min-width: 5.625rem;
  }
}
.fc .fc-toolbar .fc-left .btn:hover, .fc .fc-toolbar .fc-left .btn:active, .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:hover, .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:active, .fc .fc-header-toolbar .fc-left .btn:hover, .fc .fc-header-toolbar .fc-left .btn:active, .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn:hover, .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn:active {
  color: #000000;
  background: var(--bs-btn-hover-bg);
}
.fc .fc-toolbar .fc-center, .fc .fc-toolbar .fc-toolbar-chunk:nth-child(2), .fc .fc-header-toolbar .fc-center, .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
  flex: 1;
  padding: 1rem;
}
.fc .fc-toolbar .fc-right, .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3), .fc .fc-header-toolbar .fc-right, .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
  padding: 1rem;
}
.fc .fc-toolbar .fc-right .btn, .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn, .fc .fc-header-toolbar .fc-right .btn, .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  background: none;
  --bs-btn-color: #798e98;
  --bs-btn-border-color: #798e98;
  --bs-btn-hover-color: #798e98;
  --bs-btn-hover-bg: #798e98;
  --bs-btn-hover-border-color: #798e98;
  --bs-btn-focus-shadow-rgb: 121, 142, 152;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #798e98;
  --bs-btn-active-border-color: #798e98;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #798e98;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #798e98;
  --bs-gradient: none;
}
.fc .fc-toolbar .fc-right .btn:hover, .fc .fc-toolbar .fc-right .btn:active, .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:hover, .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:active, .fc .fc-header-toolbar .fc-right .btn:hover, .fc .fc-header-toolbar .fc-right .btn:active, .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn:hover, .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn:active {
  color: #000000;
}
.fc .fc-view .fc-event, .fc .fc-view .fc-event-dot {
  background-color: rgba(var(--bs-inverse-rgb), 0.15);
  border-color: transparent;
}
.fc .fc-view .fc-event.fc-daygrid-dot-event, .fc .fc-view .fc-event-dot.fc-daygrid-dot-event {
  background-color: rgba(var(--bs-inverse-rgb), 0.15);
  border-color: transparent;
}
.fc .fc-view.fc-dayGrid-view .fc-week-number, .fc .fc-view.fc-dayGrid-view .fc-day-number, .fc .fc-view.fc-dayGrid-view .fc-daygrid-day-number, .fc .fc-view.fc-dayGridMonth-view .fc-week-number, .fc .fc-view.fc-dayGridMonth-view .fc-day-number, .fc .fc-view.fc-dayGridMonth-view .fc-daygrid-day-number {
  padding: 0.125rem 0.25rem;
}
.fc .fc-view.fc-dayGrid-view .fc-day-grid-event, .fc .fc-view.fc-dayGrid-view .fc-daygrid-day-events, .fc .fc-view.fc-dayGridMonth-view .fc-day-grid-event, .fc .fc-view.fc-dayGridMonth-view .fc-daygrid-day-events {
  margin-top: 0.125rem;
}
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-slats table, .fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-bg table {
  border: none;
}
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-slats table tbody tr:first-child td, .fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-slats table thead tr:first-child td, .fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-bg table tbody tr:first-child td, .fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-bg table thead tr:first-child td {
  border-top: none;
}
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-content-skeleton table {
  border: none !important;
}
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-content-skeleton table td, .fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-content-skeleton table th {
  border: none !important;
}
.fc .fc-view a {
  text-decoration: none;
}
.fc .fc-view .fc-daygrid-event, .fc .fc-view .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc .fc-view .fc-timegrid-event.fc-event-mirror, .fc .fc-view .fc-timegrid-more-link {
  box-shadow: none;
  border-radius: 0;
}
.fc td, .fc th {
  border-width: 1px;
  border-color: var(--bs-border-color);
}
.fc .fc-view-harness {
  height: auto !important;
}
.fc .fc-scroller-liquid-absolute {
  position: relative !important;
  overflow: initial !important;
}
.fc a:not([href]) {
  color: inherit;
}

.hljs {
  display: block;
  overflow-x: auto;
  color: var(--bs-gray-500);
  font-size: 0.7875rem;
}

.hljs-code,
.hljs-emphasis {
  font-style: italic;
}

.hljs-tag,
.hljs-name {
  color: var(--bs-gray-300);
}

.hljs-string,
.hljs-bullet {
  color: var(--bs-indigo);
}

.hljs-attr {
  color: var(--bs-teal);
}

.hljs a {
  color: inherit;
}
.hljs a:focus, .hljs a:hover {
  color: inherit;
  text-decoration: underline;
}

.hljs-container {
  padding: 1rem 1rem;
  border-top: 1px solid var(--bs-border-color);
  position: relative;
  z-index: 1;
}
.hljs-container pre {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  border-radius: 0;
}

body .typeahead__container {
  font: inherit;
}
body .typeahead__container .typeahead__field .typeahead__query input {
  background: none;
  border-color: var(--bs-border-color);
  border-radius: 0 4px 4px 0;
}
body .typeahead__container .typeahead__field .typeahead__query input:focus {
  border-color: rgba(var(--bs-inverse-rgb), 0.5);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-inverse-rgb), 0.25);
}
body .typeahead__container .typeahead__field .typeahead__query .typeahead__cancel-button {
  color: transparent;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
body .typeahead__container .typeahead__field .typeahead__query .typeahead__cancel-button:before {
  content: "\f00d";
  position: absolute;
  color: var(--bs-body-color);
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
body .typeahead__container .typeahead__result .typeahead__list {
  background: rgba(var(--bs-body-bg-rgb), 0.98);
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  border: none;
  border-radius: 4px;
}
body .typeahead__container .typeahead__result .typeahead__list .typeahead__item > a {
  color: var(--bs-body-color);
}
body .typeahead__container .typeahead__result .typeahead__list .typeahead__item > a:hover, body .typeahead__container .typeahead__result .typeahead__list .typeahead__item > a:focus {
  background: var(--bs-border-color);
}
body .typeahead__container .typeahead__result .typeahead__list .typeahead__item.active > a {
  background: rgba(var(--bs-body-color-rgb), 0.15);
}
body .typeahead__container .typeahead__result .typeahead__list .typeahead__item + .typeahead__item {
  border-top: 1px solid var(--bs-border-color);
}

.jvectormap-container {
  position: relative;
}
.jvectormap-container .jvectormap-zoomin, .jvectormap-container .jvectormap-zoomout, .jvectormap-container .jvectormap-goback {
  width: 1.125rem;
  height: 1.125rem;
  padding: 0 0 0.125rem;
  box-sizing: border-box;
  font-size: 1rem;
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border: 1px solid var(--bs-border-color);
  left: 1rem;
  box-shadow: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  left: 1rem;
}
.jvectormap-without-padding .jvectormap-container .jvectormap-zoomin, .jvectormap-without-padding .jvectormap-container .jvectormap-zoomout, .jvectormap-without-padding .jvectormap-container .jvectormap-goback {
  left: 0;
}
.jvectormap-container .jvectormap-zoomin:hover, .jvectormap-container .jvectormap-zoomout:hover, .jvectormap-container .jvectormap-goback:hover {
  color: var(--bs-theme-color);
  background: var(--bs-theme);
}
.jvectormap-container .jvectormap-zoomin {
  top: 1rem;
}
.jvectormap-without-padding .jvectormap-container .jvectormap-zoomin {
  top: 0;
}
.jvectormap-container .jvectormap-zoomout {
  top: 2.5625rem;
}
.jvectormap-without-padding .jvectormap-container .jvectormap-zoomout {
  top: 1.5625rem;
}
.jvectormap-container .jvectormap-legend {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  border-radius: 0;
}
.jvectormap-container .jvectormap-legend-title {
  font-size: 0.6875rem;
  padding: 0.3rem 0.5rem;
  background: rgba(var(--bs-inverse-rgb), 0.15);
}
.jvectormap-container .jvectormap-legend-cnt-v .jvectormap-legend {
  padding: 0;
  margin: 1rem 1rem 0 0;
}
.jvectormap-container .jvectormap-legend-cnt-v .jvectormap-legend .jvectormap-legend-inner {
  padding: 0.5rem;
}
.jvectormap-container .jvectormap-legend-cnt-v .jvectormap-legend .jvectormap-legend-tick {
  display: flex;
  align-items: center;
}
.jvectormap-container .jvectormap-legend-cnt-v .jvectormap-legend .jvectormap-legend-tick-sample {
  width: 1rem;
  height: 1rem;
}
.jvectormap-container .jvectormap-legend-cnt-v .jvectormap-legend .jvectormap-legend-tick-text {
  padding-left: 0.5rem;
}

.jvectormap-tip {
  background: rgba(var(--bs-inverse-rgb), 0.9) !important;
  color: var(--bs-body-bg) !important;
  font-weight: 600 !important;
  padding: 0.25rem 0.5rem !important;
  border: none !important;
  font-family: inherit !important;
  font-size: 0.7875rem !important;
  z-index: 1000;
  border-radius: 4px !important;
}

.pace {
  pointer-events: none;
  user-select: none;
}
.pace .pace-progress {
  position: fixed;
  z-index: 2000;
  top: 3.25rem;
  width: 100%;
  height: 0.125rem;
  background: var(--bs-theme);
  right: 100%;
}
.pace-top .pace .pace-progress {
  top: 0;
}
.pace .pace-progress-inner {
  display: block;
  position: absolute;
  width: 6.25rem;
  height: 100%;
  opacity: 1;
  box-shadow: none;
  right: 0;
}
.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 4.25rem;
  width: 1.125rem;
  height: 1.125rem;
  border: solid 0.125rem transparent;
  border-top-color: var(--bs-theme);
  border-left-color: rgba(var(--bs-inverse-rgb), 0.25);
  right: 1rem;
}
.pace-top .pace .pace-activity {
  top: 1rem;
}
.pace.pace-inactive {
  display: none;
}

@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ps {
  position: relative;
}
.ps .ps__rail-x, .ps .ps__rail-y {
  z-index: 10;
}
.ps .ps__rail-x.ps--clicking, .ps .ps__rail-x:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y.ps--clicking, .ps .ps__rail-y:hover, .ps .ps__rail-y:focus {
  background: none;
}
.ps .ps__thumb-y {
  border-radius: 0;
  background: none;
}
.ps .ps__thumb-y:before {
  content: "";
  background: #aaa;
  position: absolute;
  top: 0.0625rem;
  bottom: 0.0625rem;
  right: 0;
  left: 0;
}

.app-sidebar .ps__rail-x,
.app-sidebar .ps__rail-y {
  display: none;
}

body .picmo__light.picmo__picker, body .picmo__auto.picmo__picker {
  --background-color: rgba(var(--bs-body-bg-rgb), 0.98);
  --secondary-background-color: rgba(var(--bs-body-bg-rgb), 0.98);
  --border-color: var(--bs-border-color);
  --emoji-font: Chakra Petch, sans-serif;
  --ui-font: Chakra Petch, sans-serif;
  --text-color: var(--bs-body-color);
  --accent-color: var(--bs-theme);
  --category-name-background-color: rgba(var(--bs-inverse-rgb), 0.15);
  --category-name-text-color: var(--bs-body-color);
  --search-background-color: var(--bs-body-bg);
  --search-focus-background-color: var(--bs-body-bg);
  --search-icon-color: var(--bs-body-color);
  --category-tab-active-color: var(--bs-theme);
  --hover-background-color: rgba(var(--bs-inverse-rgb), 0.15);
  --tag-background-color: var(--bs-body-bg);
  --border-radius: 0;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
}
body .picmo__light.picmo__picker .emojiCategory .categoryName, body .picmo__auto.picmo__picker .emojiCategory .categoryName {
  font-size: 0.8125rem;
  height: auto;
}
body .picmo__popupContainer {
  z-index: 9999;
}
body .picmo__popupContainer .picmo__closeButton {
  background: var(--bs-border-color);
}

body .picker {
  display: block;
  border: var(--bs-border-width) solid var(--bs-border-color);
  background-color: transparent;
  border-radius: 4px;
}
body .picker > .pc-element {
  margin: 0.375rem 0 0 0.75rem;
  padding: 0.125rem 0.625rem !important;
  border: none;
  background: var(--bs-tertiary-bg);
  color: var(--bs-body-color);
  border-radius: 30px;
}
body .picker > .pc-element:hover {
  background: var(--bs-tertiary-bg);
  color: var(--bs-body-color);
}
body .picker > .pc-element .pc-close {
  position: relative;
  margin: 0;
  top: 0;
  color: var(--bs-body-color);
  opacity: 0.5;
  font-size: 0.75rem;
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  margin-left: 0.5rem;
  right: 0;
}
body .picker > .pc-element .pc-close:after {
  content: "\f00d" !important;
}
body .picker > .pc-element .pc-close:hover {
  opacity: 1;
}
body .picker > .pc-element + .pc-select {
  padding-top: 0.375rem;
}
body .picker > .pc-element + .pc-select .pc-element {
  margin-top: -0.375rem;
}
body .picker .pc-select {
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: initial;
}
body .picker .pc-select .pc-element {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2335434a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  margin: 0;
  appearance: none;
  border: none;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border-radius: 4px;
  font-size: 0.875rem;
}
body .picker .pc-select .pc-list {
  padding: 0.5rem 0;
  top: 100%;
  background: rgba(var(--bs-body-bg-rgb), 0.98);
  border: var(--bs-border-width) solid var(--bs-border-color-translucent);
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  border-radius: 0;
}
body .picker .pc-select .pc-list input[type=search] {
  display: block;
  width: calc(100% - 20px);
  margin: 0 0.625rem 0.3125rem;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 4px;
  font-size: 0.875rem;
}
body .picker .pc-select .pc-list input[type=search]:focus {
  color: var(--bs-body-color);
  background-color: transparent;
  border-color: rgba(var(--bs-inverse-rgb), 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-inverse-rgb), 0.25);
}
body .picker .pc-select .pc-list ul > li {
  padding: 0.375rem 1rem;
  background: none;
  color: var(--bs-body-color);
}
body .picker .pc-select .pc-list ul > li:nth-child(even) {
  background: none;
}
body .picker .pc-select .pc-list ul > li:hover {
  background: rgba(var(--bs-body-color-rgb), 0.15);
  color: var(--bs-body-color);
}

[data-bs-theme=dark] body .picker .pc-select .pc-element {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.sp-original-input-container .sp-add-on {
  min-width: 2.1875rem;
  background-image: none;
}
.sp-original-input-container .sp-add-on .sp-colorize {
  position: relative;
  overflow: hidden;
}
.sp-original-input-container .sp-add-on .sp-colorize:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 5px solid transparent;
}

body .sp-container {
  background: rgba(var(--bs-body-bg-rgb), 0.98);
  border: var(--bs-border-width) solid var(--bs-border-color-translucent);
  border-radius: 0;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
}
body .sp-container .sp-palette-container {
  border-color: rgba(var(--bs-inverse-rgb), 0.15);
}
body .sp-container .sp-picker-container .sp-input-container .sp-input {
  color: var(--bs-body-color) !important;
  border-color: var(--bs-border-color) !important;
}
body .sp-container .sp-picker-container .sp-cancel {
  background: none;
  border: 1px solid var(--bs-default);
  color: var(--bs-default);
}
body .sp-container .sp-picker-container .sp-cancel:hover, body .sp-container .sp-picker-container .sp-cancel:focus {
  background: var(--bs-default);
  color: var(--bs-inverse);
}
body .sp-container .sp-picker-container .sp-choose {
  background: var(--bs-theme);
  border: 1px solid var(--bs-theme);
  color: var(--bs-theme-color);
}
body .sp-container .sp-picker-container .sp-choose:hover, body .sp-container .sp-picker-container .sp-choose:focus {
  background: var(--bs-theme);
  color: var(--bs-theme-color);
}

.note-editor {
  background: none;
  font-family: "Chakra Petch", sans-serif !important;
}
.note-editor.note-frame, .note-editor.note-airframe {
  color: var(--bs-body-color);
}
.note-editor.note-frame .note-toolbar, .note-editor.note-airframe .note-toolbar {
  padding: 0.5rem 1rem 0.75rem;
  background: none;
  border-color: var(--bs-border-color);
  border-radius: 0;
  color: var(--bs-body-color);
}
.note-editor.note-frame .note-toolbar .note-btn-group, .note-editor.note-airframe .note-toolbar .note-btn-group {
  display: inline-flex;
}
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn, .note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: rgba(0, 0, 0, 0.75);
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-radius: 4px;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-font-weight: $btn-font-weight;
  --bs-btn-color: var(--bs-component-color);
  --bs-btn-bg: var(--bs-body-bg);
  --bs-btn-border-color: var(--bs-border-color);
  --bs-btn-hover-color: var(--bs-component-color);
  --bs-btn-hover-bg: var(--bs-border-color);
  --bs-btn-hover-border-color: var(--bs-border-color);
  --bs-btn-focus-shadow-rgb: var(--bs-violet-rgb);
  --bs-btn-active-color: var(--bs-component-color);
  --bs-btn-active-bg: var(--bs-border-color);
  --bs-btn-active-border-color: var(--bs-border-color);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .note-editor.note-frame .note-toolbar .note-btn-group > .note-btn, .note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn {
    transition: none;
  }
}
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn:hover, .note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn:first-child:not(:last-child):not(.dropdown-toggle), .note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn:last-child:not(:first-child):not(.dropdown-toggle), .note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn:last-child:not(:first-child):not(.dropdown-toggle) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn:not(:first-child):not(:last-child):not(.dropdown-toggle), .note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn .note-icon-caret:before, .note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn .note-icon-caret:before {
  content: "";
}
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn > .note-icon-caret:first-child, .note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn > .note-icon-caret:first-child {
  margin-left: -0.4375rem;
}
.note-editor.note-frame .note-toolbar .note-btn-group.note-color > .note-btn.dropdown-toggle, .note-editor.note-airframe .note-toolbar .note-btn-group.note-color > .note-btn.dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.note-editor.note-frame .note-toolbar .note-btn-group.note-para > .note-btn-group > .note-btn, .note-editor.note-airframe .note-toolbar .note-btn-group.note-para > .note-btn-group > .note-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.note-editor.note-frame .note-toolbar .note-dropdown-menu, .note-editor.note-airframe .note-toolbar .note-dropdown-menu {
  border: 0;
  padding: 0.5rem 0;
  background: rgba(var(--bs-body-bg-rgb), 0.98);
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  border-radius: 4px;
}
.note-editor.note-frame .note-toolbar .note-dropdown-menu .note-dropdown-item, .note-editor.note-airframe .note-toolbar .note-dropdown-menu .note-dropdown-item {
  padding: 0.375rem 1rem;
  color: var(--bs-body-color);
}
.note-editor.note-frame .note-toolbar .note-dropdown-menu .note-dropdown-item:hover, .note-editor.note-airframe .note-toolbar .note-dropdown-menu .note-dropdown-item:hover {
  background: rgba(var(--bs-body-color-rgb), 0.15);
  color: var(--bs-body-color);
}
.note-editor.note-frame .note-toolbar .note-palette .note-palette-title, .note-editor.note-airframe .note-toolbar .note-palette .note-palette-title {
  border-color: var(--bs-border-color) !important;
}
.note-editor.note-frame .note-toolbar .note-palette .note-palette-title, .note-editor.note-frame .note-toolbar .note-palette .note-color-reset, .note-editor.note-frame .note-toolbar .note-palette .note-color-select, .note-editor.note-airframe .note-toolbar .note-palette .note-palette-title, .note-editor.note-airframe .note-toolbar .note-palette .note-color-reset, .note-editor.note-airframe .note-toolbar .note-palette .note-color-select {
  margin-left: 0 !important;
  margin-right: 0 !important;
  border-radius: 0 !important;
}
.note-editor.note-frame .note-toolbar .note-palette .note-color-palette .note-color-row, .note-editor.note-airframe .note-toolbar .note-palette .note-color-palette .note-color-row {
  display: flex;
}
.note-editor.note-frame .note-toolbar .note-palette .note-color-palette .note-color-row .note-color-btn, .note-editor.note-airframe .note-toolbar .note-palette .note-color-palette .note-color-row .note-color-btn {
  display: block;
  min-height: initial;
}
.note-editor.note-frame .note-toolbar .note-para .note-dropdown-menu, .note-editor.note-airframe .note-toolbar .note-para .note-dropdown-menu {
  min-width: 14.6875rem;
}
.note-editor.note-frame .note-editing-area .note-editable, .note-editor.note-airframe .note-editing-area .note-editable {
  padding: 1rem 1rem !important;
}
.note-editor.note-frame.fullscreen, .note-editor.note-airframe.fullscreen {
  top: 3.25rem !important;
  background: rgba(var(--bs-body-bg-rgb), 0.98);
}
@media (min-width: 768px) {
  .note-editor.note-frame.fullscreen, .note-editor.note-airframe.fullscreen {
    left: 16.875rem !important;
  }
}
.note-editor.note-frame.codeview .note-editing-area .note-codable, .note-editor.note-airframe.codeview .note-editing-area .note-codable {
  background: none;
}
.note-editor.note-frame .note-statusbar .note-resizebar .note-icon-bar, .note-editor.note-airframe .note-statusbar .note-resizebar .note-icon-bar {
  border-color: rgba(var(--bs-inverse-rgb), 0.25) !important;
}
.note-editor .note-tooltip .note-tooltip-content {
  border-radius: 2px;
}
.note-editor .note-modal {
  padding: 3.25rem 0;
  overflow: scroll;
}
.note-editor .note-modal .note-modal-content {
  border: 0;
  background: var(--bs-body-bg);
  border-radius: 0;
  box-shadow: none;
}
.note-editor .note-modal .note-modal-content .note-modal-header {
  border: 0;
  padding: 1rem;
  border-bottom: 1px solid var(--bs-border-color);
}
.note-editor .note-modal .note-modal-content .note-modal-header .note-modal-title {
  color: var(--bs-body-color);
  font-weight: 600;
  font-size: 1.09375rem;
}
.note-editor .note-modal .note-modal-content .note-modal-header .close {
  color: var(--bs-body-color);
}
.note-editor .note-modal .note-modal-content .note-modal-body {
  padding: 1rem;
}
.note-editor .note-modal .note-modal-content .note-modal-body .form-group {
  padding-bottom: 1rem;
}
.note-editor .note-modal .note-modal-content .note-modal-body .form-group .note-form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: inherit;
  display: block;
  padding: 0;
}
.note-editor .note-modal .note-modal-content .note-modal-body .form-group .note-form-control {
  border: var(--bs-border-width) solid var(--bs-border-color);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background: transparent;
}
.note-editor .note-modal .note-modal-content .note-modal-body .form-group .note-form-control:focus {
  border-color: rgba(var(--bs-inverse-rgb), 0.5);
}
.note-editor .note-modal .note-modal-content .note-modal-body .checkbox label {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.note-editor .note-modal .note-modal-content .note-modal-body .checkbox label input {
  margin-right: 0.5rem;
}
.note-editor .note-modal .note-modal-content .note-modal-footer {
  border: 0;
  height: auto;
  padding: 1rem;
  border-top: 1px solid var(--bs-border-color);
  display: flex;
  justify-content: flex-end;
}
.note-editor .note-modal .note-modal-content .note-modal-footer .note-btn {
  border: none;
  border-radius: 4px;
}
.note-editor .note-modal .note-modal-content .note-modal-footer .note-btn.note-btn-primary {
  background: none;
  border: 1px solid var(--bs-theme);
  color: var(--bs-theme);
}
.note-editor .note-modal .note-modal-content .note-modal-footer .note-btn.note-btn-primary:hover, .note-editor .note-modal .note-modal-content .note-modal-footer .note-btn.note-btn-primary:focus {
  background: var(--bs-theme);
  color: var(--bs-theme-color);
}

body .note-modal-backdrop {
  background: #e6e6e6;
  opacity: 0.95;
  display: none !important;
}

.card > .note-editor.note-frame {
  border: none;
}
.card > form > .note-editor.note-frame {
  border: none;
}

.tagit {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.tagit.form-control {
  height: auto;
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
.tagit.ui-widget {
  margin-bottom: 0;
  font-family: inherit;
  box-shadow: none !important;
  border-color: var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
  position: relative !important;
  padding: 0.1875rem 0.75rem !important;
  line-height: 1.3125rem !important;
  border-radius: 4px !important;
  transition: all 0.15s ease-in-out;
  display: flex;
  flex-wrap: wrap;
}
.tagit.ui-widget li {
  float: none;
}
.tagit.ui-widget li.tagit-choice {
  font-size: 0.8125rem !important;
  font-weight: 400;
  padding: 0.125rem 0.625rem !important;
  margin-top: 0.0625rem !important;
  margin-bottom: 0.0625rem !important;
  border: none;
  background: rgba(var(--bs-inverse-rgb), 0.15) !important;
  color: var(--bs-body-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin-right: 4px !important;
}
.tagit.ui-widget li.tagit-choice .tagit-close {
  position: relative;
  line-height: 1.3125rem;
  text-align: center;
  top: 0;
  height: 1.3125rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 0 -0.3125rem -0.0625rem 0.3125rem;
}
.tagit.ui-widget li.tagit-choice .tagit-close .ui-icon-close {
  font-size: 0.625rem;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  text-indent: 0;
  height: 100%;
  padding-top: 0.125rem;
  background-image: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
.tagit.ui-widget li.tagit-choice .tagit-close .ui-icon-close:after {
  content: "\f00d" !important;
}
.tagit.ui-widget li.tagit-choice .tagit-close .ui-icon-close:hover {
  box-shadow: none !important;
  color: rgba(var(--bs-inverse-rgb), 0.75);
}
.tagit.ui-widget li.tagit-choice .tagit-close:hover .ui-icon-close:before, .tagit.ui-widget li.tagit-choice .tagit-close:focus .ui-icon-close:before {
  color: rgba(var(--bs-inverse-rgb), 0.4);
}
.tagit.ui-widget li.tagit-new {
  margin: 0.125rem 0;
  height: 1.4375rem;
  display: flex;
  align-items: center;
}
.tagit.ui-widget li.tagit-new input {
  color: var(--bs-body-color);
}

.tagit-autocomplete {
  border: var(--bs-border-width) solid var(--bs-border-color-translucent) !important;
  padding: 0.5rem 0;
  background: rgba(var(--bs-body-bg-rgb), 0.98);
  border-radius: 0;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  z-index: 1065 !important;
}
.tagit-autocomplete .ui-menu-item .ui-menu-item-wrapper {
  padding: 0.375rem 1rem;
  border: none;
  color: var(--bs-body-color);
}
.tagit-autocomplete .ui-menu-item .ui-menu-item-wrapper.ui-state-active {
  background: rgba(var(--bs-body-color-rgb), 0.15);
  color: var(--bs-body-color);
  margin: 0;
}

.calendar {
  min-height: calc(100vh - 3.25rem);
  display: flex;
  border-left: 1px solid var(--bs-border-color);
}
@media (max-width: 767.98px) {
  .calendar {
    border: none;
  }
}
.calendar .calendar-body {
  flex: 1;
  height: calc(100vh - 3.25rem);
}
.calendar .calendar-body + .calendar-sidebar {
  border-left: 1px solid var(--bs-border-color);
}
.calendar .calendar-body .fc .fc-head-container th {
  border-bottom-width: 0;
}
.calendar .calendar-body .fc table {
  border: none !important;
}
.calendar .calendar-body .fc table thead tr, .calendar .calendar-body .fc table tbody tr {
  border-width: 0;
}
.calendar .calendar-body .fc table thead tr th:first-child, .calendar .calendar-body .fc table thead tr td:first-child, .calendar .calendar-body .fc table tbody tr th:first-child, .calendar .calendar-body .fc table tbody tr td:first-child {
  border-left-width: 0 !important;
}
.calendar .calendar-body .fc table thead tr th:last-child, .calendar .calendar-body .fc table thead tr td:last-child, .calendar .calendar-body .fc table tbody tr th:last-child, .calendar .calendar-body .fc table tbody tr td:last-child {
  border-right-width: 0 !important;
}
.calendar .calendar-body .fc table .fc-head-container {
  border: none;
}
.calendar .calendar-sidebar {
  width: 13.75rem;
  padding: 1rem;
}
@media (min-width: 992px) {
  .calendar .calendar-sidebar {
    height: calc(100vh - 3.25rem);
  }
}
@media (max-width: 991.98px) {
  .calendar .calendar-sidebar {
    display: none;
  }
}
.calendar .calendar-sidebar + .calendar-body {
  border-left: 1px solid var(--bs-border-color);
}
.calendar .calendar-sidebar .calendar-sidebar-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  display: flex;
  align-items: flex-end;
}

.coming-soon {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.coming-soon .coming-soon-img img {
  max-width: 100%;
}
.coming-soon .coming-soon-timer {
  padding: 2rem;
  min-height: 50vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.coming-soon .coming-soon-content {
  min-height: 50vh;
  position: relative;
  text-align: center;
  margin: 0 auto;
  padding: 2rem;
  background: linear-gradient(to bottom, rgba(var(--bs-inverse-rgb), 0.15) 0%, transparent 100%);
  flex: 1;
}
.coming-soon .coming-soon-content > * {
  max-width: 32.5rem;
  margin-left: auto;
  margin-right: auto;
}
.coming-soon .is-countdown {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}
.coming-soon .is-countdown:before, .coming-soon .is-countdown:after {
  content: "";
  display: table;
  clear: both;
}
.coming-soon .is-countdown .countdown-row {
  display: block;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coming-soon .is-countdown .countdown-row .countdown-section {
  width: 7.5rem;
  padding: 1rem;
  background: rgba(var(--bs-inverse-rgb), 0.15);
  margin: 0.75rem;
  color: var(--bs-inverse);
  border-radius: 12px;
}
@media (max-width: 575.98px) {
  .coming-soon .is-countdown .countdown-row .countdown-section {
    width: 5rem;
    padding: 0.5rem 0;
    margin: 0.25rem;
  }
}
.coming-soon .is-countdown .countdown-amount {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.25;
}
@media (max-width: 575.98px) {
  .coming-soon .is-countdown .countdown-amount {
    font-size: 1.75rem;
  }
}
.coming-soon .is-countdown .countdown-period {
  font-size: 1.00625rem;
  color: rgba(var(--bs-inverse-rgb), 0.75);
  line-height: 1.5;
}
@media (max-width: 575.98px) {
  .coming-soon .is-countdown .countdown-period {
    font-size: 0.7875rem;
  }
}

.data-management .dataTables_wrapper .dataTable {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  color: var(--bs-body-color);
}
.data-management .dataTables_wrapper .dataTables_filter {
  text-align: left;
  margin-bottom: 1rem;
  font-size: 0.75rem;
}
.data-management .dataTables_wrapper .dataTables_filter label {
  display: flex;
  align-items: center;
}
.data-management .dataTables_wrapper .dataTables_filter input {
  border-radius: 4px;
  font-size: 0.8125rem;
  min-width: 12.5rem;
}
@media (max-width: 991.98px) {
  .data-management .dataTables_wrapper .dataTables_filter input {
    min-width: inherit;
    flex: 1;
    width: 100%;
  }
}

.error-page {
  min-height: 100vh;
  padding: 4rem 0;
  display: flex;
  align-items: center;
}
.error-page .error-page-content {
  margin: 0 auto;
  position: relative;
  padding: 0 1rem;
  text-align: center;
}
.error-page .error-page-content .error-code {
  color: var(--bs-inverse);
  left: 0;
  right: 0;
  font-size: 7.375rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.25;
  letter-spacing: 5px;
  text-shadow: 10px 10px rgba(var(--bs-inverse-rgb), 0.15);
}
@media (max-width: 575.98px) {
  .error-page .error-page-content .error-code {
    font-size: 6.375rem;
  }
}
.error-page .error-page-content .error-page-icon i {
  font-size: 8rem;
  color: #90a2aa;
}
.error-page .error-page-content h1, .error-page .error-page-content .h1 {
  font-size: 3rem;
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .error-page .error-page-content h1, .error-page .error-page-content .h1 {
    font-size: 3.25rem;
  }
}
.error-page .error-page-content h3, .error-page .error-page-content .h3 {
  font-size: 1.125rem;
  font-weight: 400;
}
@media (max-width: 575.98px) {
  .error-page .error-page-content h3, .error-page .error-page-content .h3 {
    font-size: 1rem;
  }
}
.error-page .error-page-content .link-divider {
  width: 0.25rem;
  height: 0.25rem;
  display: inline-block;
  background: rgba(var(--bs-inverse-rgb), 0.3);
  position: relative;
  top: -0.125rem;
  margin: 0 1rem;
  border-radius: 4px;
}

.file-tree {
  position: relative;
}
.file-tree .file-node {
  position: relative;
  padding-left: 0.9375rem;
}
.file-tree .file-node .file-link {
  text-decoration: none;
  padding: 0.25rem 0.25rem;
  color: var(--bs-body-text);
  position: relative;
  border-radius: 4px;
  display: flex;
  font-size: 0.8125rem;
}
.file-tree .file-node .file-link > *:hover, .file-tree .file-node .file-link > *:focus {
  opacity: 0.75;
}
.file-tree .file-node .file-link .file-info {
  display: flex;
  flex: 1;
}
.file-tree .file-node .file-link .file-icon {
  font-size: 1rem;
  width: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.35rem;
}
.file-tree .file-node .file-link .file-text {
  flex: 1;
}
.file-tree .file-node.has-sub > .file-link .file-arrow:before {
  content: "+";
  position: absolute;
  top: 50%;
  margin-top: -0.65625rem;
  width: 0.75rem;
  display: flex;
  justify-content: center;
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  left: -0.75rem;
}
.file-tree .file-node.has-sub.expand > .file-tree {
  display: block;
}
.file-tree .file-node.has-sub.expand > .file-link .file-arrow:before {
  content: "\f068";
}
.file-tree .file-node.selected > .file-link {
  font-weight: 600;
}
.file-tree .file-node.selected > .file-link > .file-info {
  opacity: 1;
}
.file-tree .file-tree {
  display: none;
}

.file-manager {
  --bs-file-manager-sidebar-width: 15.625rem;
  --bs-file-manager-mobile-sidebar-margin: -15.625rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.file-manager .file-manager-toolbar {
  background: rgba(var(--bs-body-bg-rgb), 0.5);
  padding: 0.25rem;
  border-bottom: 1px solid var(--bs-border-color);
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 991.98px) {
  .file-manager .file-manager-toolbar {
    overflow: scroll;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
}
.file-manager .file-manager-container {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}
.file-manager .file-manager-container .file-manager-sidebar {
  display: flex;
  flex-direction: column;
  width: var(--bs-file-manager-sidebar-width);
  min-width: var(--bs-file-manager-sidebar-width);
}
@media (max-width: 991.98px) {
  .file-manager .file-manager-container .file-manager-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(var(--bs-body-bg-rgb), 0.95);
    z-index: 10;
    transition: all 0.2s ease-in-out;
    left: var(--bs-file-manager-mobile-sidebar-margin);
    margin-left: 0.0625rem;
    border-right: 1px solid var(--bs-border-color-translucent);
  }
}
.file-manager .file-manager-container .file-manager-sidebar .file-manager-sidebar-mobile-toggler {
  position: absolute;
  top: 1rem;
  width: 2rem;
  left: 100%;
  margin-left: 0.0625rem;
}
.file-manager .file-manager-container .file-manager-sidebar .file-manager-sidebar-mobile-toggler .btn {
  background: rgba(var(--bs-body-bg-rgb), 0.95);
  color: var(--bs-component-color);
  border-radius: 0;
  padding: 0.5rem 0.65rem;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  transition: all 0.2s ease-in-out;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid var(--bs-border-color-translucent);
  border-left: none;
}
@media (min-width: 992px) {
  .file-manager .file-manager-container .file-manager-sidebar .file-manager-sidebar-mobile-toggler {
    display: none;
  }
}
.file-manager .file-manager-container .file-manager-sidebar .file-manager-sidebar-content {
  flex: 1;
  overflow: hidden;
}
.file-manager .file-manager-container .file-manager-sidebar .file-manager-sidebar-footer {
  padding: 0.5rem 1rem;
  border-top: 1px solid var(--bs-border-color);
}
.file-manager .file-manager-container .file-manager-content {
  flex: 1;
}
@media (min-width: 992px) {
  .file-manager .file-manager-container .file-manager-content {
    border-left: 1px solid var(--bs-border-color);
  }
}
@media (max-width: 767.98px) {
  .file-manager .file-manager-container .file-manager-content {
    overflow: scroll;
    flex-wrap: nowrap;
  }
}
.file-manager.file-manager-sidebar-mobile-toggled .file-manager-sidebar {
  left: 0;
}
@media (max-width: 991.98px) {
  .file-manager.file-manager-sidebar-mobile-toggled .file-manager-sidebar {
    box-shadow: 0 1rem 3rem rgba(var(--bs-inverse-rgb), 0.175);
  }
}
@media (max-width: 991.98px) {
  .file-manager.file-manager-sidebar-mobile-toggled .file-manager-sidebar .file-manager-sidebar-mobile-toggler .btn {
    box-shadow: none;
  }
}

.gallery-menu-container {
  min-width: 12.5rem;
}
@media (min-width: 768px) {
  .gallery-menu-container {
    min-height: calc(100vh - 3.25rem);
    border-right: 1px solid var(--bs-border-color);
  }
}

.gallery-menu {
  padding: 1rem;
  padding: 0;
}
@media (max-width: 767.98px) {
  .gallery-menu {
    overflow: scroll;
    border-bottom: 1px solid var(--bs-border-color);
    position: sticky;
    top: 3.25rem;
    display: flex;
    flex-wrap: nowrap;
  }
}
.gallery-menu .gallery-menu-header {
  padding: 1rem 1rem 0.25rem;
  font-weight: 600;
  font-size: 90%;
}
@media (max-width: 767.98px) {
  .gallery-menu .gallery-menu-header {
    display: none;
  }
}
.gallery-menu .gallery-menu-item .gallery-menu-link {
  padding: 0.35rem 1.25rem;
  display: block;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  text-decoration: none;
}
@media (max-width: 767.98px) {
  .gallery-menu .gallery-menu-item .gallery-menu-link {
    white-space: nowrap;
    padding: 0.9375rem 1.25rem 0.625rem;
  }
}
.gallery-menu .gallery-menu-item .gallery-menu-link .fa {
  color: rgba(var(--bs-inverse-rgb), 0.5);
}
@media (max-width: 767.98px) {
  .gallery-menu .gallery-menu-item .gallery-menu-link .fa {
    display: block;
    margin: 0 0 0.1875rem !important;
    width: auto !important;
    font-size: 1.125rem;
  }
}
.gallery-menu .gallery-menu-item .gallery-menu-link:hover {
  color: rgba(var(--bs-inverse-rgb), 0.75);
}
.gallery-menu .gallery-menu-item .gallery-menu-link.active {
  color: var(--bs-theme);
  font-weight: 600;
}
.gallery-menu .gallery-menu-item .gallery-menu-link.active .fa {
  color: inherit;
}

.gallery-content-container {
  width: 100%;
}
@media (min-width: 768px) {
  .gallery-content-container {
    min-height: calc(100vh - 3.25rem);
  }
}

.gallery {
  padding: 1rem;
}
.gallery + .gallery {
  border-top: 1px solid var(--bs-border-color);
}
.gallery .gallery-title {
  font-size: 1.09375rem;
  color: var(--bs-inverse);
  font-weight: 600;
  margin-bottom: 1rem;
}
.gallery .gallery-title a {
  color: var(--bs-inverse);
  text-decoration: none;
}
.gallery .gallery-title a i {
  font-size: 0.875rem;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  margin-left: 0.3125rem;
}
.gallery .gallery-title small, .gallery .gallery-title .small {
  display: block;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  font-size: 0.7875rem;
}
.gallery .gallery-image-list {
  list-style-type: none;
  margin: -0.15rem;
  padding: 0;
}
.gallery .gallery-image-list:before, .gallery .gallery-image-list:after {
  content: "";
  display: table;
  clear: both;
}
.gallery .gallery-image-list > li {
  position: relative;
  padding-top: 12.5%;
  width: 16.6667%;
  float: left;
}
@media (max-width: 991.98px) {
  .gallery .gallery-image-list > li {
    width: 25%;
    padding-top: 18.75%;
  }
}
@media (max-width: 767.98px) {
  .gallery .gallery-image-list > li {
    width: 33.33%;
    padding-top: 33.33%;
  }
}
@media (max-width: 575.98px) {
  .gallery .gallery-image-list > li {
    width: 50%;
    padding-top: 50%;
  }
}
.gallery .gallery-image-list > li:hover {
  z-index: 10;
}
.gallery .gallery-image-list > li a {
  position: absolute;
  left: 0.15rem;
  right: 0.15rem;
  bottom: 0.15rem;
  top: 0.15rem;
  overflow: hidden;
  background: rgba(var(--bs-inverse-rgb), 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 992px) {
  .gallery .gallery-image-list > li a:hover {
    left: -15%;
    right: -15%;
    top: -15%;
    bottom: -15%;
  }
}
.gallery .gallery-image-list > li img, .gallery .gallery-image-list > li .img {
  max-width: 100%;
}
.gallery .gallery-image-list > li .img-portrait {
  height: 100%;
  width: auto;
  max-height: 100%;
  max-width: inherit;
}
@media (max-width: 767.98px) {
  .gallery .gallery-image-list > li .img-portrait-xs {
    height: 100%;
    width: auto;
    max-height: 100%;
    max-width: inherit;
  }
}

.login {
  min-height: 100vh;
  padding: 3rem 1rem;
  display: flex;
  align-items: center;
}
@media (max-width: 767.98px) {
  .login {
    padding: 1rem;
  }
}
.login .login-content {
  max-width: 22.5rem;
  margin: 0 auto;
  position: relative;
  flex: 1;
}

.mailbox {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mailbox .mailbox-toolbar {
  padding: 0.75rem;
  display: flex;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-toolbar {
    overflow: scroll;
    white-space: nowrap;
    padding: 0.5rem;
    flex-wrap: nowrap;
  }
}
.mailbox .mailbox-toolbar .mailbox-toolbar-text {
  color: rgba(var(--bs-inverse-rgb), 0.5);
  padding: 0.5rem;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-toolbar .mailbox-toolbar-text {
    padding: 0;
  }
}
.mailbox .mailbox-toolbar .mailbox-toolbar-link {
  padding: 0.5rem 1rem;
}
.mailbox .mailbox-toolbar .mailbox-toolbar-item {
  padding: 0.5rem;
}
.mailbox .mailbox-toolbar .mailbox-toolbar-link {
  color: rgba(var(--bs-inverse-rgb), 0.75);
  text-decoration: none;
  border-radius: 4px;
}
.mailbox .mailbox-toolbar .mailbox-toolbar-link:hover {
  background: rgba(var(--bs-inverse-rgb), 0.25);
}
.mailbox .mailbox-toolbar .mailbox-toolbar-link.active {
  background: var(--bs-theme);
  color: #FFFFFF;
  font-weight: 600;
}
.mailbox .mailbox-body {
  border-top: 1px solid var(--bs-border-color);
  overflow: hidden;
  display: flex;
  flex: 1;
}
.mailbox .mailbox-body .mailbox-sidebar {
  max-width: 22.5rem;
  height: 100%;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-body .mailbox-sidebar {
    max-width: 100%;
    height: auto;
  }
}
.mailbox .mailbox-body .mailbox-sidebar + .mailbox-content {
  border-left: 1px solid var(--bs-border-color);
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-body .mailbox-sidebar + .mailbox-content {
    border-left: none;
  }
}
.mailbox .mailbox-body .mailbox-content {
  height: 100%;
  flex: 1;
}
.mailbox .mailbox-empty-message {
  min-height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mailbox .mailbox-empty-message .mailbox-empty-message-icon i {
  font-size: 6.125rem;
}
.mailbox .mailbox-empty-message .mailbox-empty-message-img {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}
.mailbox .mailbox-empty-message .mailbox-empty-message-img img {
  width: 100%;
  height: 100%;
  max-width: 30rem;
  max-height: 30rem;
  opacity: 0.5;
}
.mailbox .mailbox-empty-message .mailbox-empty-message-title {
  font-size: 1.3125rem;
  font-weight: 600;
  color: rgba(var(--bs-inverse-rgb), 0.5);
}
.mailbox .mailbox-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.mailbox .mailbox-list .mailbox-list-item {
  padding: 1rem;
  display: flex;
}
.mailbox .mailbox-list .mailbox-list-item + .mailbox-list-item {
  border-top: 1px solid var(--bs-border-color);
}
.mailbox .mailbox-list .mailbox-list-item:hover {
  background-color: rgba(var(--bs-inverse-rgb), 0.1);
}
.mailbox .mailbox-list .mailbox-list-item.unread .mailbox-message .mailbox-title {
  font-weight: 600;
  color: var(--bs-inverse);
}
.mailbox .mailbox-list .mailbox-list-item.active {
  background: rgba(var(--bs-inverse-rgb), 0.1);
}
.mailbox .mailbox-list .mailbox-list-item.has-attachment .mailbox-checkbox:after {
  content: "\f0c1";
  position: absolute;
  top: 1.5625rem;
  text-align: center;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  width: 1.125rem;
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  left: 0;
}
.mailbox .mailbox-list .mailbox-checkbox {
  position: relative;
}
.mailbox .mailbox-list .mailbox-checkbox + .mailbox-message {
  padding-left: 0.5rem;
}
.mailbox .mailbox-list .mailbox-message {
  flex: 1;
}
.mailbox .mailbox-list .mailbox-message .mailbox-list-item-link {
  display: block;
  color: var(--bs-inverse);
  text-decoration: none;
  position: relative;
}
.mailbox .mailbox-list .mailbox-message .mailbox-sender {
  position: relative;
  display: flex;
  align-items: center;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-list .mailbox-message .mailbox-sender {
    display: block;
  }
}
.mailbox .mailbox-list .mailbox-message .mailbox-sender .mailbox-sender-name {
  font-weight: 600;
  color: var(--bs-theme);
  flex: 1;
  margin-right: auto;
}
.mailbox .mailbox-list .mailbox-message .mailbox-sender .mailbox-time {
  font-size: 0.75rem;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  font-weight: 400;
  padding-left: 0.5rem;
}
.mailbox .mailbox-list .mailbox-message .mailbox-title {
  color: rgba(var(--bs-inverse-rgb), 0.75);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 18.125rem;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-list .mailbox-message .mailbox-title {
    max-width: initial;
    white-space: initial;
  }
}
.mailbox .mailbox-list .mailbox-message .mailbox-desc {
  position: relative;
  font-size: 0.8125rem;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  max-height: 2.625rem;
  overflow: hidden;
}
.mailbox .mailbox-detail {
  padding: 1.5rem;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-detail {
    padding: 1rem;
  }
}
.mailbox .mailbox-detail .mailbox-detail-header {
  border-bottom: 1px solid var(--bs-border-color);
  padding: 0 0 1.5rem;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-detail .mailbox-detail-header {
    padding-bottom: 1rem;
  }
}
.mailbox .mailbox-detail .mailbox-detail-content {
  padding: 1.5rem 0;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-detail .mailbox-detail-content {
    padding: 1rem 0;
  }
}
.mailbox .mailbox-detail .mailbox-detail-body {
  font-size: 0.875rem;
}
.mailbox .mailbox-detail .mailbox-detail-attachment {
  display: flex;
  flex-wrap: wrap;
}
.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment {
  background: rgba(var(--bs-inverse-rgb), 0.15);
  width: 7.5rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  margin-right: 0.5rem;
}
.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment a {
  display: block;
  text-decoration: none;
}
.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment .document-file {
  height: 3.75rem;
  overflow: hidden;
  text-align: center;
  background: rgba(var(--bs-inverse-rgb), 0.1);
}
.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment .document-file i {
  display: block;
  line-height: 3.75rem;
  font-size: 2rem;
  color: var(--bs-inverse);
}
.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment .document-file img {
  max-width: 100%;
}
.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment .document-name {
  color: var(--bs-inverse);
  padding: 0.125rem 0.625rem;
  font-size: 0.8125rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mailbox .mailbox-form .mailbox-form-header {
  padding: 1rem 1.5rem;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-form .mailbox-form-header {
    padding: 0.75rem 1rem;
  }
}
.mailbox .mailbox-form .mailbox-form-header .form-control {
  background: rgba(var(--bs-inverse-rgb), 0.1);
}
.mailbox .mailbox-form .note-editor {
  border: none;
  border-radius: 0;
}
.mailbox .mailbox-form .note-editor .note-toolbar {
  border-top: 1px solid var(--bs-border-color);
  border-bottom: 1px solid var(--bs-border-color);
}
.mailbox .mailbox-form .note-editor .note-editing-area {
  background: rgba(var(--bs-inverse-rgb), 0.1);
}

.messenger {
  height: 100%;
  display: flex;
}
.messenger .messenger-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media (min-width: 992px) {
  .messenger .messenger-sidebar {
    max-width: 22.5rem;
    border-right: 1px solid var(--bs-border-color);
  }
}
.messenger .messenger-sidebar .messenger-sidebar-header {
  border-bottom: 1px solid var(--bs-border-color);
  padding: 1rem;
}
.messenger .messenger-sidebar .messenger-sidebar-body {
  flex: 1;
  overflow: hidden;
}
.messenger .messenger-sidebar .messenger-item {
  border-bottom: 1px solid var(--bs-border-color);
}
.messenger .messenger-sidebar .messenger-item .messenger-link {
  display: flex;
  padding: 0.85rem 1rem;
  text-decoration: none;
  color: var(--bs-inverse);
}
.messenger .messenger-sidebar .messenger-item .messenger-link.active {
  background-color: rgba(var(--bs-inverse-rgb), 0.15);
}
.messenger .messenger-sidebar .messenger-item .messenger-link:hover, .messenger .messenger-sidebar .messenger-item .messenger-link:focus {
  background-color: rgba(var(--bs-inverse-rgb), 0.1);
}
.messenger .messenger-sidebar .messenger-item .messenger-link .messenger-media {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.messenger .messenger-sidebar .messenger-item .messenger-link .messenger-media img {
  max-width: 100%;
}
.messenger .messenger-sidebar .messenger-item .messenger-link .messenger-media + .messenger-info {
  padding-left: 0.85rem;
}
.messenger .messenger-sidebar .messenger-item .messenger-link .messenger-info {
  flex: 1;
  white-space: nowrap;
}
.messenger .messenger-sidebar .messenger-item .messenger-link .messenger-time-badge {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.messenger .messenger-sidebar .messenger-item .messenger-link .messenger-time {
  font-size: 0.690625rem;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  margin-bottom: 0.25rem;
}
.messenger .messenger-sidebar .messenger-item .messenger-link .messenger-text, .messenger .messenger-sidebar .messenger-item .messenger-link .messenger-name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 13.75rem;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  font-size: 0.8125rem;
}
.messenger .messenger-sidebar .messenger-item .messenger-link .messenger-name {
  font-weight: 600;
  color: var(--bs-inverse);
}
.messenger .messenger-sidebar .messenger-item .messenger-link .messenger-badge {
  background: var(--bs-theme);
  color: var(--bs-theme-color);
  display: inline-flex;
  border-radius: 1.875rem;
  padding: 0 0.1875rem;
  min-width: 1.125rem;
  min-height: 1.125rem;
  align-items: center;
  justify-content: center;
  font-size: 0.56875rem;
  font-weight: 600;
  margin-left: auto;
}
.messenger .messenger-sidebar .messenger-item .messenger-link .messenger-badge.empty {
  display: none;
}
.messenger .messenger-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media (max-width: 991.98px) {
  .messenger .messenger-content {
    display: none;
  }
}
.messenger .messenger-content .messenger-content-header {
  display: flex;
  border-bottom: 1px solid var(--bs-border-color);
  padding: 0.75rem 1rem;
  align-items: center;
}
.messenger .messenger-content .messenger-content-header .messenger-content-header-mobile-toggler {
  display: none;
}
@media (max-width: 991.98px) {
  .messenger .messenger-content .messenger-content-header .messenger-content-header-mobile-toggler {
    display: block;
  }
}
.messenger .messenger-content .messenger-content-header .messenger-content-header-mobile-toggler a {
  color: rgba(var(--bs-inverse-rgb), 0.5);
  font-size: 1.125rem;
}
.messenger .messenger-content .messenger-content-header .messenger-content-header-media .media {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.messenger .messenger-content .messenger-content-header .messenger-content-header-media + .messenger-content-header-info {
  margin-left: 1rem;
}
.messenger .messenger-content .messenger-content-header .messenger-content-header-info {
  font-size: 0.875rem;
  color: var(--bs-inverse);
  font-weight: 600;
  font-size: 0.9375rem;
  flex: 1;
}
.messenger .messenger-content .messenger-content-header .messenger-content-header-info small, .messenger .messenger-content .messenger-content-header .messenger-content-header-info .small {
  display: block;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  font-weight: normal;
  font-size: 0.6875rem;
}
.messenger .messenger-content .messenger-content-header .messenger-content-header-btn {
  display: flex;
}
.messenger .messenger-content .messenger-content-header .messenger-content-header-btn .btn {
  font-size: 1rem;
  color: rgba(var(--bs-inverse-rgb), 0.5);
}
.messenger .messenger-content .messenger-content-header .messenger-content-header-btn .btn:hover, .messenger .messenger-content .messenger-content-header .messenger-content-header-btn .btn:focus {
  color: var(--bs-inverse);
}
.messenger .messenger-content .messenger-content-body {
  flex: 1;
  overflow: hidden;
}
.messenger .messenger-content .messenger-content-body .widget-chat {
  padding: 1rem;
}
.messenger .messenger-content .messenger-content-footer {
  border-top: 1px solid var(--bs-border-color);
  padding: 1rem;
}
@media (max-width: 991.98px) {
  .messenger.messenger-content-toggled .messenger-sidebar {
    display: none;
  }
  .messenger.messenger-content-toggled .messenger-content {
    display: flex;
  }
}

.pos {
  background: var(--app-pos-bg);
  height: 100%;
}
.pos .pos-container {
  height: 100%;
  padding: 0;
}
@media (min-width: 992px) {
  .pos .pos-container {
    display: flex;
  }
  .pos .pos-container > div + div {
    border-left: 1px solid var(--bs-border-color);
  }
}
.pos .pos-menu {
  display: flex;
}
@media (min-width: 992px) {
  .pos .pos-menu {
    width: 140px;
    flex-direction: column;
  }
}
@media (max-width: 991.98px) {
  .pos .pos-menu {
    overflow: scroll;
    align-items: center;
    border-bottom: 1px solid var(--bs-border-color);
  }
}
.pos .pos-menu .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
@media (max-width: 991.98px) {
  .pos .pos-menu .logo {
    width: 140px;
    height: 5.9375rem;
    flex: 0 0 140px;
  }
}
.pos .pos-menu .logo a {
  display: block;
  text-decoration: none;
  color: inherit;
}
.pos .pos-menu .logo .logo-img {
  text-align: center;
  height: 2.875rem;
}
.pos .pos-menu .logo .logo-text {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 0.25rem;
}
.pos .pos-menu .logo img {
  max-width: 100%;
  max-height: 100%;
}
.pos .pos-menu .nav-container {
  flex: 1;
}
@media (min-width: 992px) {
  .pos .pos-menu .nav-container {
    overflow: hidden;
    padding: 0;
  }
}
.pos .pos-menu .nav.nav-tabs {
  margin: 0;
  padding: 0;
  display: block;
  border: 0;
}
@media (max-width: 991.98px) {
  .pos .pos-menu .nav.nav-tabs {
    display: flex;
    flex-wrap: nowrap;
  }
}
@media (max-width: 991.98px) {
  .pos .pos-menu .nav.nav-tabs .nav-item {
    min-width: 5.625rem;
  }
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link {
  position: relative;
  padding: 1rem;
  white-space: nowrap;
  text-align: center;
  color: rgba(var(--bs-inverse), 0.25);
  border: none;
  font-size: 0.65625rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0;
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link .card {
  margin: -0.75rem -0.25rem;
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link .card .card-body {
  padding: 0.5rem 0.7rem;
  margin: 0.5rem;
  flex: 1;
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link .card:before, .pos .pos-menu .nav.nav-tabs .nav-item .nav-link .card:after, .pos .pos-menu .nav.nav-tabs .nav-item .nav-link .card .card-arrow {
  visibility: hidden;
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link .fa {
  display: block;
  margin: 0.225rem auto 0.35rem auto !important;
  font-size: 1.25rem;
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link:hover {
  color: rgba(var(--bs-inverse), 0.5);
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link.active {
  color: var(--bs-inverse);
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link.active .card:before, .pos .pos-menu .nav.nav-tabs .nav-item .nav-link.active .card:after, .pos .pos-menu .nav.nav-tabs .nav-item .nav-link.active .card .card-arrow {
  visibility: visible;
}
.pos .pos-content {
  flex: 1;
}
@media (min-width: 992px) {
  .pos .pos-content {
    overflow: scroll;
  }
}
@media (max-width: 991.98px) {
  .pos .pos-content {
    height: calc(100% - 96px);
  }
}
.pos .pos-content .pos-content-container {
  padding: 0 2rem;
}
.pos .pos-sidebar {
  width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 0 360px;
}
@media (max-width: 991.98px) {
  .pos .pos-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    z-index: 1021;
    background: var(--bs-body-bg);
    background: var(--bs-body-bg-gradient);
    display: none;
  }
}
.pos .pos-sidebar .pos-sidebar-header {
  padding: 1rem 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
}
.pos .pos-sidebar .pos-sidebar-header .back-btn {
  border: none;
  display: none;
  margin-right: 1rem;
}
@media (max-width: 991.98px) {
  .pos .pos-sidebar .pos-sidebar-header .back-btn {
    display: block;
  }
}
.pos .pos-sidebar .pos-sidebar-header .back-btn .btn {
  color: var(--bs-inverse);
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 1.09375rem;
}
.pos .pos-sidebar .pos-sidebar-header .icon img {
  height: 1.875rem;
}
.pos .pos-sidebar .pos-sidebar-header .icon + .title {
  padding-left: 0.625rem;
}
.pos .pos-sidebar .pos-sidebar-header .title {
  font-weight: 600;
  flex: 1;
}
.pos .pos-sidebar .pos-sidebar-header .order {
  display: block;
}
.pos .pos-sidebar .pos-sidebar-nav .nav.nav-tabs {
  padding: 0 1.5rem;
}
.pos .pos-sidebar .pos-sidebar-nav .nav.nav-tabs .nav-item .nav-link {
  color: rgba(var(--bs-inverse), 0.5);
}
.pos .pos-sidebar .pos-sidebar-nav .nav.nav-tabs .nav-item .nav-link.active {
  color: var(--bs-inverse);
}
.pos .pos-sidebar .pos-sidebar-nav .nav.nav-tabs .nav-item + .nav-item {
  margin-left: 0.5rem;
}
.pos .pos-sidebar .pos-sidebar-body {
  padding: 0;
  margin: 0;
  overflow: scroll;
  flex: 1;
}
.pos .pos-sidebar .pos-sidebar-footer {
  border-top: 1px solid var(--bs-border-color);
  padding: 1rem 1.5rem;
}
.pos .pos-sidebar .pos-order {
  padding: 1.5rem;
  position: relative;
  display: flex;
}
.pos .pos-sidebar .pos-order .pos-order-product {
  display: flex;
  flex: 1;
}
.pos .pos-sidebar .pos-order .pos-order-product .img {
  width: 4.5rem;
  height: 4.5rem;
  background-position: center;
  background-size: cover;
}
.pos .pos-sidebar .pos-order .pos-order-product > * + * {
  margin-left: 1rem;
}
.pos .pos-sidebar .pos-order > * + * {
  margin-left: 1rem;
}
.pos .pos-sidebar .pos-order .pos-order-confirmation {
  position: absolute;
  top: 0;
  left: 0.0625rem;
  right: 0.0625rem;
  bottom: 0;
  z-index: 10;
  background: rgba(235, 235, 235, 0.88);
  padding: 1rem;
  margin: 0;
}
.pos .pos-sidebar .pos-order .pos-order-confirmation > * {
  position: relative;
}
.pos .pos-sidebar .pos-order .pos-order-confirmation .btn {
  min-width: 3.75rem;
}
.pos .pos-sidebar .pos-order + .pos-order {
  border-top: 1px solid var(--bs-border-color);
}
.pos .pos-header {
  padding: 0.66rem 1.75rem;
  height: 3.25rem;
  display: flex;
  align-items: center;
}
.pos .pos-header .logo, .pos .pos-header .time, .pos .pos-header .nav {
  width: 33.33%;
}
@media (max-width: 767.98px) {
  .pos .pos-header .logo, .pos .pos-header .time, .pos .pos-header .nav {
    width: 50%;
  }
}
.pos .pos-header .logo {
  display: flex;
}
.pos .pos-header .logo > div, .pos .pos-header .logo > a {
  text-align: center;
  color: var(--bs-inverse);
  text-decoration: none;
  display: flex;
  align-items: center;
}
.pos .pos-header .logo > div .logo-img img, .pos .pos-header .logo > a .logo-img img {
  height: 2rem;
}
.pos .pos-header .logo > div .logo-text, .pos .pos-header .logo > a .logo-text {
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  margin-left: 1rem;
}
.pos .pos-header .time {
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767.98px) {
  .pos .pos-header .time {
    display: none;
  }
}
.pos .pos-header .nav {
  align-items: center;
  justify-content: flex-end;
}
.pos .pos-header .nav .nav-item .nav-link {
  padding: 0.333rem 1rem;
  color: var(--bs-inverse);
}
@media (max-width: 767.98px) {
  .pos .pos-header .nav .nav-item .nav-link {
    padding: 0.333rem 0.66rem;
  }
}
.pos .pos-header .nav .nav-item .nav-link .nav-icon {
  height: 1.25rem;
  font-size: 1.25rem;
}
.pos .pos-header .nav .nav-item .nav-link:hover {
  color: rgba(var(--bs-inverse), 0.5);
}
.pos .pos-product {
  text-decoration: none;
  color: var(--bs-inverse);
  position: relative;
  transition: all 0.2s linear;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pos .pos-product .img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 11.25rem;
  transition: all 0.2s linear;
}
.pos .pos-product .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: rgba(var(--bs-inverse), 0.1);
  padding: 1rem;
}
.pos .pos-product .info .title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--app-component-color);
  margin: 0 0 0.125rem;
}
.pos .pos-product .info .desc {
  color: rgba(var(--bs-inverse), 0.5);
  margin: 0 0 1rem;
  line-height: 1.4;
  flex: 1;
}
.pos .pos-product .info .price {
  margin: 0;
  font-weight: 600;
  color: var(--app-component-color);
  font-size: 1rem;
}
.pos .pos-product.not-available {
  cursor: not-allowed;
}
.pos .pos-product.not-available .not-available-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 11.25rem;
  background: rgba(235, 235, 235, 0.88);
  color: var(--bs-inverse);
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pos .pos-table-booking {
  display: block;
  margin-bottom: 1.25rem;
  text-decoration: none;
  color: var(--bs-inverse);
}
.pos .pos-table-booking:hover .pos-table-booking-container {
  background: rgba(var(--bs-inverse), 0.2);
}
.pos .pos-table-booking .pos-table-booking-container {
  padding: 0;
  background: rgba(var(--bs-inverse), 0.1);
}
.pos .pos-table-booking .pos-table-booking-container .pos-table-booking-header {
  padding: 1rem;
  border-bottom: 1px solid var(--bs-border-color);
  color: rgba(var(--bs-inverse), 0.5);
  font-weight: 600;
}
.pos .pos-table-booking .pos-table-booking-container .pos-table-booking-header .no {
  font-size: 2.5rem;
  color: var(--bs-inverse);
}
.pos .pos-table-booking .pos-table-booking-container .pos-table-booking-body .booking {
  font-size: 0.8125rem;
  padding: 0.333rem 1rem;
  display: flex;
  align-items: center;
}
.pos .pos-table-booking .pos-table-booking-container .pos-table-booking-body .booking .time {
  width: 4.6875rem;
  font-weight: 600;
  font-size: 0.74375rem;
  color: rgba(var(--bs-inverse), 0.5);
}
.pos .pos-table-booking .pos-table-booking-container .pos-table-booking-body .booking .info {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
}
.pos .pos-table-booking .pos-table-booking-container .pos-table-booking-body .booking .status {
  font-size: 0.375rem;
  color: #617a86;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}
.pos .pos-table-booking .pos-table-booking-container .pos-table-booking-body .booking .status.upcoming {
  color: #ff9f0c;
}
.pos .pos-table-booking .pos-table-booking-container .pos-table-booking-body .booking .status.in-progress {
  color: #249d79;
}
.pos .pos-table-booking .pos-table-booking-container .pos-table-booking-body .booking.highlight {
  color: var(--bs-theme);
}
.pos .pos-table-booking .pos-table-booking-container .pos-table-booking-body .booking.highlight .time {
  color: rgba(var(--bs-theme-rgb), 0.75);
}
.pos .pos-table-booking .pos-table-booking-container .pos-table-booking-body .booking + .booking {
  border-top: 1px solid var(--bs-border-color);
}
.pos .pos-task {
  border-bottom: 1px solid var(--bs-border-color);
}
@media (min-width: 768px) {
  .pos .pos-task {
    display: flex;
  }
}
.pos .pos-task .pos-task-info {
  width: 30%;
  padding: 1.5rem;
  text-align: right;
}
@media (max-width: 767.98px) {
  .pos .pos-task .pos-task-info {
    width: auto;
    position: relative;
    text-align: left;
  }
}
.pos .pos-task .pos-task-body {
  padding: 1.5rem;
  flex: 1;
  border-left: 1px solid var(--bs-border-color);
}
@media (max-width: 767.98px) {
  .pos .pos-task .pos-task-body {
    border-left: none;
    padding-top: 0;
  }
}
.pos .pos-task .pos-task-body .pos-task-completed {
  font-size: 1rem;
  line-height: 2.25rem;
}
@media (max-width: 767.98px) {
  .pos .pos-task .pos-task-body .pos-task-completed {
    border-top: 1px solid var(--bs-border-color);
    margin-top: -0.625rem;
    padding-top: 0.625rem;
  }
}
.pos .pos-task .pos-task-body .pos-task-product {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pos .pos-task .pos-task-body .pos-task-product .pos-task-product-img {
  padding-top: 75%;
  position: relative;
  margin-bottom: 1rem;
}
.pos .pos-task .pos-task-body .pos-task-product .pos-task-product-img .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}
.pos .pos-task .pos-task-body .pos-task-product .pos-task-product-img .caption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(53, 67, 74, 0.75);
  color: var(--bs-white);
  font-size: 1.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pos .pos-task .pos-task-body .pos-task-product .pos-task-product-info {
  display: flex;
  flex: 1;
  margin-bottom: 1rem;
}
.pos .pos-task .pos-task-body .pos-task-product .pos-task-product-action .btn {
  display: block;
}
.pos .pos-task .pos-task-body .pos-task-product .pos-task-product-action .btn + .btn {
  margin-top: 0.75rem;
}
.pos .pos-task .pos-task-body .pos-task-product.completed .pos-task-product-info {
  opacity: 0.33;
}
.pos .pos-checkout-table {
  padding: 0.0625rem;
  margin: 0;
  height: 100%;
}
.pos .pos-checkout-table .pos-checkout-table-container {
  color: var(--bs-inverse);
  display: block;
  padding: 0;
  height: 100%;
  text-decoration: none;
  position: relative;
  transition: all 0.1s linear;
  margin: 0.25rem;
}
.pos .pos-checkout-table .pos-checkout-table-container:before {
  transition: all 0.1s linear;
}
.pos .pos-checkout-table .pos-checkout-table-container .pos-checkout-table-header {
  padding: 1rem;
  text-align: center;
}
.pos .pos-checkout-table .pos-checkout-table-container .pos-checkout-table-header .status {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  font-size: 0.5rem;
}
.pos .pos-checkout-table .pos-checkout-table-container .pos-checkout-table-info > .row {
  border-top: 1px solid var(--bs-border-color);
  margin: 0;
}
.pos .pos-checkout-table .pos-checkout-table-container .pos-checkout-table-info > .row > div {
  padding: 0.25rem 0.5rem;
  text-align: center;
}
.pos .pos-checkout-table .pos-checkout-table-container .pos-checkout-table-info > .row > div + div {
  border-left: 1px solid var(--bs-border-color);
}
.pos .pos-checkout-table.in-use:hover:not(.disabled) {
  position: relative;
  z-index: 10;
}
.pos .pos-checkout-table.in-use:hover:not(.disabled) .table-container {
  position: relative;
}
.pos .pos-checkout-table.in-use:hover:not(.disabled) .table-container:before {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(45, 51, 58, 0.75);
}
.pos .pos-checkout-table.in-use:hover:not(.disabled) .table-container:after {
  content: "\f00c";
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--app-component-color);
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
.pos .pos-checkout-table.selected:not(.disabled) .pos-checkout-table-container .table-info-row, .pos .pos-checkout-table.selected:not(.disabled):hover .pos-checkout-table-container .table-info-row {
  color: var(--app-component-color);
}
.pos .pos-checkout-table.selected:not(.disabled) .pos-checkout-table-container:before, .pos .pos-checkout-table.selected:not(.disabled):hover .pos-checkout-table-container:before {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(var(--bs-theme-rgb), 0.5);
}
.pos .pos-checkout-table.selected:not(.disabled) .pos-checkout-table-container:after, .pos .pos-checkout-table.selected:not(.disabled):hover .pos-checkout-table-container:after {
  content: "\f00c";
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--bs-inverse);
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Font Awesome\ 6 Free, Font Awesome\ 6 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
.pos .pos-checkout-table.disabled .pos-checkout-table-container {
  cursor: not-allowed;
  background: var(--app-component-bg);
  color: var(--app-component-color);
}
.pos .pos-checkout-table.disabled .pos-checkout-table-container .status {
  color: #e00000;
}
.pos .pos-checkout-table.available .pos-checkout-table-container .status {
  color: #249d79;
}
.pos .pos-checkout-table.available .pos-checkout-table-container .pos-checkout-table-info {
  color: transparent;
}
.pos .pos-checkout-table.available .pos-checkout-table-container .pos-checkout-table-info i {
  color: transparent !important;
}
.pos .pos-checkout-table.in-use .pos-checkout-table-container {
  background: rgba(var(--bs-inverse), 0.15);
  position: relative;
}
.pos .pos-checkout-table.in-use .pos-checkout-table-container .status {
  color: #ff9f0c;
}
.pos.pos-vertical > .pos-container {
  flex-direction: column;
}
.pos.pos-vertical > .pos-container .pos-header + .pos-content {
  height: calc(100% - 52px);
}
.pos.pos-vertical > .pos-container > div + div {
  border: 0;
  border-top: 1px solid var(--bs-border-color);
}
@media (max-width: 991.98px) {
  .pos.pos-mobile-sidebar-toggled {
    z-index: 20;
  }
  .pos.pos-mobile-sidebar-toggled .pos-content, .pos.pos-mobile-sidebar-toggled .pos-menu {
    opacity: 0.25;
  }
  .pos.pos-mobile-sidebar-toggled .pos-sidebar {
    display: flex;
  }
  .pos.pos-mobile-sidebar-toggled.pos-vertical > .pos-container > .pos-content {
    opacity: 1;
  }
}

.modal.modal-pos .close {
  position: absolute;
  top: 1.5625rem;
  z-index: 10;
  right: 1.5625rem;
}
@media (min-width: 992px) {
  .modal.modal-pos .modal-pos-product {
    display: flex;
  }
  .modal.modal-pos .modal-pos-product .modal-pos-product-img, .modal.modal-pos .modal-pos-product .modal-pos-product-info {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}
.modal.modal-pos .modal-pos-product .modal-pos-product-img {
  padding: 1rem;
}
.modal.modal-pos .modal-pos-product .modal-pos-product-img .img {
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 15.625rem;
}
@media (min-width: 992px) {
  .modal.modal-pos .modal-pos-product .modal-pos-product-img .img {
    min-height: 18.75rem;
  }
}
.modal.modal-pos .modal-pos-product .modal-pos-product-info {
  padding: 1.5rem;
  border-left: 1px solid var(--bs-border-color);
}
.modal.modal-pos .modal-pos-product .modal-pos-product-info .option-list {
  margin: -0.333rem;
  padding: 0.333rem 0;
  display: flex;
  flex-wrap: wrap;
}
.modal.modal-pos .modal-pos-product .modal-pos-product-info .option-list .option {
  width: 33.33%;
  max-width: 33.33%;
  padding: 0.333rem;
  flex: 0 0 33.33%;
}
.modal.modal-pos .modal-pos-product .modal-pos-product-info .option-list .option .option-input {
  display: none;
}
.modal.modal-pos .modal-pos-product .modal-pos-product-info .option-list .option .option-input:checked + .option-label {
  color: var(--bs-theme);
  border: 1px solid var(--bs-theme);
  background: rgba(var(--bs-theme-rgb), 0.1);
}
.modal.modal-pos .modal-pos-product .modal-pos-product-info .option-list .option .option-label {
  border: 1px solid rgba(var(--bs-inverse-rgb), 0.25);
  padding: 0.625rem;
  height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal.modal-pos .modal-pos-product .modal-pos-product-info .option-list .option .option-label .option-text {
  display: block;
  line-height: 1.2;
  opacity: 0.75;
  font-size: 0.8125rem;
  text-align: center;
}
.modal.modal-pos .modal-pos-product .modal-pos-product-info .option-list .option .option-label .option-price {
  font-weight: 600;
  margin-top: 0.25rem;
  line-height: 1.2;
}

.pos-mobile-sidebar-toggler {
  position: fixed;
  bottom: 3rem;
  right: 1rem;
  z-index: 1020;
  background: var(--bs-white);
  padding: 0.5rem;
  width: 3.75rem;
  height: 3.75rem;
  text-decoration: none;
  color: var(--bs-black);
  display: none;
  border-radius: 50px;
  box-shadow: 0 1rem 3rem rgba(var(--bs-inverse-rgb), 0.175);
  align-items: center;
  z-index: 10;
}
.pos-mobile-sidebar-toggler:before {
  content: "";
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  right: -0.25rem;
  bottom: -0.25rem;
  border: 2px solid var(--bs-white);
  border-radius: 50px;
}
@media (max-width: 991.98px) {
  .pos-mobile-sidebar-toggler {
    display: flex;
  }
}
.pos-mobile-sidebar-toggler:hover {
  color: #526872;
}
.pos-mobile-sidebar-toggler i {
  font-size: 2rem;
  display: block;
  margin: 0 auto;
}
.pos-mobile-sidebar-toggler .badge {
  position: absolute;
  right: -0.3rem;
  top: -0.3rem;
  background-color: var(--bs-theme);
  color: var(--bs-theme-color);
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

[data-bs-theme=dark] .pos .pos-product.not-available .not-available-text,
[data-bs-theme=dark] .pos .pos-sidebar .pos-order .pos-order-confirmation {
  background: rgba(59, 70, 82, 0.88);
}

.profile .profile-img {
  height: 12.5rem;
  overflow: hidden;
  margin-bottom: 1rem;
  z-index: 10;
}
.profile .profile-img img {
  max-width: 100%;
  width: 100%;
}
.profile .profile-tab {
  position: relative;
  padding: 0 1.5rem;
  flex: 1;
}
@media (max-width: 991.98px) {
  .profile .profile-tab {
    overflow: scroll;
    padding: 0 1rem;
    display: flex;
    flex-wrap: nowrap;
  }
}
.profile .profile-tab .nav-item .nav-link {
  text-align: center;
}
.profile .profile-tab .nav-item .nav-link .nav-value {
  font-size: 1.3125rem;
  font-weight: 600;
  margin-bottom: -0.35rem;
}
@media (max-width: 767.98px) {
  .profile .profile-tab .nav-item .nav-link .nav-value {
    font-size: 1.00625rem;
  }
}
.profile .profile-tab .nav-item + .nav-item {
  margin-left: 1.5rem;
}
.profile .profile-container {
  display: flex;
}
@media (max-width: 991.98px) {
  .profile .profile-container {
    display: block;
  }
}
.profile .profile-container .profile-sidebar {
  width: 15.625rem;
  padding: 1.5rem;
  border-right: 1px solid var(--bs-border-color);
}
@media (max-width: 991.98px) {
  .profile .profile-container .profile-sidebar {
    display: none;
  }
}
.profile .profile-container .profile-content {
  flex: 1;
}
.profile .profile-container .profile-content .profile-content-container {
  padding: 1.5rem;
}
@media (max-width: 991.98px) {
  .profile .profile-container .profile-content .profile-content-container {
    padding: 1rem;
  }
}
.profile .profile-img-list {
  list-style-type: none;
  margin: -0.25rem;
  padding: 0;
}
.profile .profile-img-list:before, .profile .profile-img-list:after {
  content: "";
  display: table;
  clear: both;
}
.profile .profile-img-list .profile-img-list-item {
  width: 25%;
  padding: 0.25rem;
  float: left;
}
.profile .profile-img-list .profile-img-list-item.main {
  width: 50%;
}
.profile .profile-img-list .profile-img-list-item .profile-img-list-link {
  display: block;
  padding-top: 75%;
  overflow: hidden;
  position: relative;
}
.profile .profile-img-list .profile-img-list-item .profile-img-list-link img, .profile .profile-img-list .profile-img-list-item .profile-img-list-link .profile-img-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.profile .profile-img-list .profile-img-list-item .profile-img-list-link img:before, .profile .profile-img-list .profile-img-list-item .profile-img-list-link .profile-img-content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgba(var(--bs-inverse-rgb), 0.15);
}
.profile .profile-img-list .profile-img-list-item.with-number .profile-img-number {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: #FFFFFF;
  font-size: 1.53125rem;
  font-weight: 600;
  line-height: 1.625rem;
  margin-top: -0.765625rem;
  text-align: center;
}

.register {
  min-height: 100vh;
  padding: 3rem 1rem;
  display: flex;
  align-items: center;
}
@media (max-width: 767.98px) {
  .register {
    padding: 1rem;
  }
}
.register .register-content {
  flex: 1;
  max-width: 22.5rem;
  margin: 0 auto;
  position: relative;
}

.search-result .search-input {
  position: relative;
  max-width: 45rem;
  margin-bottom: 0.9375rem;
}
.search-result .search-close {
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
  width: 1.625rem;
  height: 1.625rem;
  text-align: center;
  font-size: 1.25rem;
  padding: 0 0 0.125rem;
  color: var(--bs-inverse);
  background: rgba(var(--bs-inverse-rgb), 0.25);
  text-decoration: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0.75rem;
}
.search-result .search-close:hover, .search-result .search-close:focus {
  text-decoration: none;
  background: rgba(var(--bs-inverse-rgb), 0.5);
  color: var(--bs-inverse);
}
.search-result .search-tab {
  list-style-type: none;
  margin: 0 -2rem;
  padding: 0 2rem;
  border-bottom: 1px solid var(--bs-border-color);
  white-space: nowrap;
  display: flex;
}
@media (max-width: 767.98px) {
  .search-result .search-tab {
    margin: 0 -0.9375rem;
    padding: 0 0.9375rem;
    overflow: scroll;
  }
}
.search-result .search-tab .search-tab-item .search-tab-link {
  display: block;
  padding: 0.625rem;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  text-decoration: none;
  border-bottom: 3px solid transparent;
  font-weight: 500;
  margin-bottom: -1px;
}
.search-result .search-tab .search-tab-item .search-tab-link:hover, .search-result .search-tab .search-tab-item .search-tab-link:focus {
  border-bottom-color: #90a2aa;
}
.search-result .search-tab .search-tab-item .search-tab-link.active {
  font-weight: 600;
  color: var(--bs-theme);
  border-bottom-color: var(--bs-theme);
}
.search-result .search-tab .search-tab-item + .search-tab-item {
  margin-left: 1rem;
}
.search-result .search-result-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.search-result .search-result-list .search-result-item {
  padding: 2rem 0;
  display: flex;
}
.search-result .search-result-list .search-result-item:before, .search-result .search-result-list .search-result-item:after {
  content: "";
  display: table;
  clear: both;
}
.search-result .search-result-list .search-result-item:last-child {
  border-bottom: 1px solid var(--bs-border-color);
}
.search-result .search-result-list .search-result-item + .search-result-item {
  border-top: 1px solid var(--bs-border-color);
}
.search-result .search-result-list .search-result-item .search-result-media {
  width: 10rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767.98px) {
  .search-result .search-result-list .search-result-item .search-result-media {
    width: 5rem;
    height: 3.75rem;
  }
}
.search-result .search-result-list .search-result-item .search-result-media img {
  max-width: 100%;
  max-height: 100%;
}
.search-result .search-result-list .search-result-item .search-result-media + .search-result-content {
  padding-left: 1.875rem;
}
.search-result .search-result-list .search-result-item .search-result-content {
  flex: 1;
}
.search-result .search-result-list .search-result-item .search-result-content h3, .search-result .search-result-list .search-result-item .search-result-content .h3 {
  margin: 0 0 0.3125rem;
  font-size: 1.25rem;
}
.search-result .search-result-list .search-result-item .search-result-content h3 a, .search-result .search-result-list .search-result-item .search-result-content .h3 a {
  color: var(--bs-inverse);
  text-decoration: none;
}
.search-result .search-result-list .search-result-item .search-result-content h3 a:hover, .search-result .search-result-list .search-result-item .search-result-content .h3 a:hover {
  text-decoration: underline;
}

.widget-chat {
  margin: 0;
  padding: 0;
}
.widget-chat .widget-chat-item {
  display: flex;
  align-items: flex-end;
}
.widget-chat .widget-chat-item + .widget-chat-item {
  margin-top: 1rem;
}
.widget-chat .widget-chat-item > div + div {
  padding-left: 0.75rem;
}
.widget-chat .widget-chat-date {
  font-size: 0.75rem;
  font-weight: 600;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  padding: 0.75rem;
  text-align: center;
}
.widget-chat .widget-chat-content {
  flex: 1;
}
.widget-chat .widget-chat-name, .widget-chat .widget-chat-status {
  font-size: 0.6875rem;
  font-weight: 600;
  color: rgba(var(--bs-inverse-rgb), 0.75);
  margin: 0.25rem 0;
  line-height: 1.5;
}
.widget-chat .widget-chat-media {
  width: 1.75rem;
}
.widget-chat .widget-chat-media img {
  max-width: 100%;
  display: block;
  border-radius: 28px;
}
.widget-chat .widget-chat-message {
  background: #FFFFFF;
  color: #000000;
  padding: 0.5rem 1rem;
  display: inline-block;
  position: relative;
  border-radius: 8px;
}
.widget-chat .widget-chat-message + .widget-chat-message {
  margin-top: 0.3rem;
}
.widget-chat .widget-chat-message.last:before {
  content: "";
  border: 0.3125rem solid transparent;
  border-bottom-color: #FFFFFF;
  position: absolute;
  bottom: 0.375rem;
  left: -0.25rem;
  border-right-color: #FFFFFF;
}
.widget-chat .reply .widget-chat-content {
  text-align: right;
}
.widget-chat .reply .widget-chat-message {
  background: var(--bs-theme);
  color: var(--bs-theme-color);
}
.widget-chat .reply .widget-chat-message.last:before {
  border-color: transparent;
  border-bottom-color: var(--bs-theme);
  border-left-color: var(--bs-theme);
  left: auto;
  right: -0.25rem;
}

.widget-img-list {
  margin: -0.0625rem;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.widget-img-list .widget-img-list-item {
  padding: 0.0625rem;
  width: 20%;
}
.widget-img-list .widget-img-list-item a {
  display: block;
  text-decoration: none;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}
.widget-img-list .widget-img-list-item a img, .widget-img-list .widget-img-list-item a .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
}
.widget-img-list .widget-img-list-item a .img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.widget-reminder .widget-reminder-item {
  position: relative;
  display: flex;
}
.widget-reminder .widget-reminder-header + .widget-reminder-item, .widget-reminder .widget-reminder-item + .widget-reminder-header, .widget-reminder .widget-reminder-item + .widget-reminder-item {
  border-top: 1px solid var(--bs-border-color);
}
.widget-reminder .widget-reminder-time, .widget-reminder .widget-reminder-content {
  padding: 1rem;
}
.widget-reminder .widget-reminder-content {
  flex: 1;
}
.widget-reminder .widget-reminder-time {
  width: 5rem;
  font-size: 0.8125rem;
  text-align: right;
}
.widget-reminder .widget-reminder-divider {
  width: 0.1875rem;
  background: var(--bs-border-color);
}
.widget-reminder + .card-header, .widget-reminder + .card-footer, .widget-reminder + .card-body {
  border-top: 1px solid var(--bs-border-color);
}

.widget-user-list {
  display: flex;
  align-items: center;
}
.widget-user-list .widget-user-list-item .widget-user-list-link {
  border: 2px solid var(--bs-body-bg);
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}
.widget-user-list .widget-user-list-item .widget-user-list-link img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.widget-user-list .widget-user-list-item + .widget-user-list-item {
  margin-left: -1.25rem;
}