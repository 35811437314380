.typeahead__container {
  body & {
    font: inherit;
    
    & .typeahead__field {
      & .typeahead__query {
        & input {
          background: none;
          border-color: $input-border-color;
          
          @if $enable-rtl {
            border-radius: $border-radius 0 0 $border-radius;
          }
          @else {
            border-radius: 0 $border-radius $border-radius 0;
          }
          
          &:focus {
            border-color: $input-focus-border-color;
            box-shadow: $input-focus-box-shadow;
          }
        }
        & .typeahead__cancel-button {
          color: transparent;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 100;
          
          &:before {
            content: '\f00d';
            position: absolute;
            color: var(--#{$prefix}body-color);
            @include fontawesome();
          }
        }
      }
    }
    & .typeahead__result {
      & .typeahead__list {
        background: $dropdown-bg;
        box-shadow: $dropdown-box-shadow;
        border: none;
        border-radius: $border-radius;
        
        & .typeahead__item {
          & > a {
            color: $dropdown-color;
            
            &:hover,
            &:focus {
              background: var(--#{$prefix}border-color);
            }
          }
          &.active {
            & > a {
              background: $dropdown-link-hover-bg;
            }
          }
          & + .typeahead__item {
            border-top: 1px solid var(--#{$prefix}border-color);
          }
        }
      }
    }
  }
}